import React, { useEffect, useState } from "react";
import { Contract } from "store/models/Contract";
import api from "store/api";
import ClientContract from './ClientContract';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { selectCurrentClient } from 'store/slices/clientSlice';
import { useSelector } from "react-redux";
import Search from "@mui/icons-material/Search";
import { contractIsActive } from "store/slices/contractSlice";

interface Props {}
const ClientContracts: React.FC<Props> = () => {
  const currentClient = useSelector(selectCurrentClient);

  const [searchText, setSearchText] = useState("");
  const [showIsActive, setShowIsActive] = useState(false);
  const [clientContracts, changeClientContracts] = useState<Contract[]>([]);
  const [loadingContracts, changeLoadingContracts] = useState(true);
  
  const visibleContracts = clientContracts.filter((contract) => {
    const isActive = () => {
      return showIsActive ? contractIsActive(contract) : true;
    }

    const matchesSearch = () => {
      return Boolean(searchText) ? contract.projectNumber.toLowerCase().includes(searchText.toLocaleLowerCase()) : true;
    }
    
    return matchesSearch() && isActive();
  })

  // Normally we'd want to just filter the store but the store doesn't always contain all contracts so we need to perform a fresh get here
  useEffect(() => {
    if (currentClient?._id) {
      api.clients.contracts(currentClient._id, {
        onData: changeClientContracts,
        currentlySubmitting: changeLoadingContracts,
      });
    }
  }, [currentClient?._id]);

  const contractsList = () => {
    return (
      <List>
        {visibleContracts.length ? (
          visibleContracts.map((contract) => (
            <ClientContract key={contract._id} contract={contract} />
          ))
        ) : (
          <ListItem>
            <ListItemText>
              {clientContracts.length
                ? "No Contracts Match Current Filters"
                : "Client does not have any contracts in Starfleet yet"}
            </ListItemText>
          </ListItem>
        )}
      </List>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          columnGap: 1,
          positon: "sticky",
          top: 0,
        }}
      >
        <TextField
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          fullWidth
          InputProps={{
            endAdornment: <Search />
          }}
          sx={{
            flex: 3
          }}
        />
        <Button color={showIsActive ? "primary" : "inherit"} variant="outlined" onClick={() => setShowIsActive((value) => !value)}> Is Active </Button>
      </Box>
      {loadingContracts ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 6 }}>
          <CircularProgress />
        </Box>
      ) : (
        contractsList()
      )}
    </Box>
  );
};
export default ClientContracts;
