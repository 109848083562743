import { AnimatePresence, m } from "framer-motion";
import { ReactElement } from "react";
import { SxProps, alpha, styled } from "@mui/material/styles";
import {
  IconButton,
  Divider,
  Typography,
  Stack,
  Backdrop,
  Box,
} from "@mui/material";
import cssStyles from "utils/cssStyles";
import { varFade } from "components/animate";
import Iconify from "components/iconify/iconify"
import Scrollbar from "components/Scrollbar";
import { defaultSettings } from "config";
import _ from "lodash";

const DRAWER_WIDTH = 550;

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({
    color: theme.palette.background.paper,
    opacity: 0.9,
  }),
  top: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  position: "fixed",
  overflow: "hidden",
  width: DRAWER_WIDTH,
  flexDirection: "column",
  margin: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === "light"
      ? theme.palette.grey[600]
      : theme.palette.common.black,
    0.16
  )}`,
  [theme.breakpoints.down('sm')]: {
    margin: 1,
    paddingBottom: 0,
    width: "100%",
  }
}));

interface Props {
  open: boolean;
  className?: string;
  title?: string | ReactElement<any, any>;
  changeOpen: Function;
  backgroundColor?: "grey" | "white";
  size?: "small" | "medium" | "large";
  onTop?: boolean;
  actions?: ReactElement<any, any>[];
  enableBackDrop?: boolean;
  bladeStyle?: SxProps<any>
}

const Blade: React.FC<Props> = ({
  children,
  title,
  changeOpen,
  open,
  actions,
  onTop,
  enableBackDrop,
  bladeStyle
}) => {
  const varSidebar =
    defaultSettings.themeDirection !== "rtl"
      ? varFade({
          distance: DRAWER_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inRight
      : varFade({
          distance: DRAWER_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inLeft;

  const handleClose = () => {
    changeOpen(false);
  };

  return (
    <>
      {enableBackDrop && (
        <Backdrop
          open={open}
          onClick={handleClose}
          sx={{
            background: "transparent",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        />
      )}
      <AnimatePresence>
        {open && (
          <RootStyle
            {...varSidebar}
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1 + (onTop ? 1 : 0),
              ...bladeStyle
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ py: 2, pr: 1, pl: 2.5 }}
            >
              <Box sx={{ flex: 2 }}>
                {typeof title === "string" ? (
                  <Typography variant="h5">
                    {_.truncate(title, { length: 100 })}
                  </Typography>
                ) : (
                  title && title
                )}
              </Box>

              <Box
                sx={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}
              >
                {actions}
                <IconButton onClick={handleClose}>
                  <Iconify icon="iconamoon:arrow-right-2" width={20} height={20} />
                </IconButton>
              </Box>
            </Stack>

            <Divider sx={{ borderStyle: "dashed" }} />

            <Scrollbar sx={{ flexGrow: 1 }}>
              <Stack
                spacing={4}
                sx={(theme) => ({
                  p: 3,
                  [theme.breakpoints.down("sm")]: {
                    p: 1,
                  },
                })}
              >
                {children}
              </Stack>
            </Scrollbar>
          </RootStyle>
        )}
      </AnimatePresence>
    </>
  );
};

export default Blade;
