import React from "react";
import { Field, FastFieldProps } from "formik";
import { isValid } from "date-fns/fp";
import { TextField } from "@mui/material";
import { toMMDDYYYY } from "utils/util";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const FastDateInput: React.FC<{ label: string; name: string }> = ({
  label,
  name,
  ...props
}) => {
  return (
    <Field {...props} name={name}>
      {({ field, form, meta }: FastFieldProps) => {
        return (
          <DatePicker
            {...field}
            label={label}
            openTo="year"
            inputFormat="MM-dd-yyyy"
            disableMaskedInput
            onChange={(newDate) => {
              if (isValid(newDate)) {
                form.setFieldValue(name, toMMDDYYYY(newDate as Date));
              } else {
                form.setFieldValue(name, newDate);
              }
            }}
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                name={name}
                placeholder=""
                {...params}
                onBlur={field.onBlur}
                error={Boolean(meta.touched && meta.error)}
              />
            )}
          />
        );
      }}
    </Field>
  );
};

export default FastDateInput;
