import { ButtonGroupProps, ButtonGroup, Button } from "@mui/material";
import React, { FC } from "react";

interface Props extends Omit<ButtonGroupProps, "onClick"> {
  onClick: (increment: number) => void;
  value: number;
}

const NumberIncrementor: FC<Props> = ({ onClick, value, ...rest}) => {
  return (
    <ButtonGroup variant="outlined" fullWidth {...rest}>
      <Button onClick={() => onClick(value + -1)}>- 1</Button>
      <Button onClick={() => onClick(value + -0.5)}>- 0.5</Button>
      <Button onClick={() => onClick(value + 0.5)}>+ 0.5</Button>
      <Button onClick={() => onClick(value + 1)}>+ 1</Button>
    </ButtonGroup>
  )
}

export default NumberIncrementor;
