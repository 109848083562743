import { Box } from '@mui/material';
import Image from 'components/image/Image';
import React from 'react';

interface Props {
  src?: string;
}

const AssetImage: React.FC<Props> = ({ src = '/defaultUnitPicture.jpg' }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Image
        sx={{
          maxWidth: "100%",
          background: "transparent no-repeat center",
          backgroundSize: "cover",
          borderRadius: 5,
          opacity: 0.2,
        }}
        src={src}
      />
      {src === "/defaultUnitPicture.jpg" ? (
        <Box
          sx={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            left: "50%",
            top: "50%",
            fontSize: "40px",
            fontWeight: "700",
            color: "gray",
            opacity: 0.5,
          }}
        >
          No Image
        </Box>
      ) : null}
    </Box>
  );
};
export default AssetImage;
