import { Photo } from "store/models/Photo";
import { sendToBackend } from "store/api/util";
import { errorHandler } from "./shared";
import { Asset } from "store/models/Asset";

const assetPhotosApi = {
  getAllByAsset: (
    assetId: string,
    actions?: {
      onData?: (data: Photo[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Photo[]>({
      route: `assetPhoto/byAssetId?assetId=${assetId}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  createOne: (
    assetId: Asset["_id"],
    form: FormData,
    actions?: {
      onData?: (data: Photo) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Photo>({
      route: `assetPhoto/?assetId=${assetId}`,
      method: "POST",
      file: form,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  deleteOne: (
    assetId: string,
    photoId: string,
    actions?: {
      onData?: (data: undefined) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<undefined>({
      route: `assetPhoto`,
      method: "DELETE",
      body: {assetId, photoId},
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
};

export default assetPhotosApi;
