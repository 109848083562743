import { Typography, Grid } from "@mui/material";
import { ReactNode, FC } from "react";


interface FormRowProps {
    label: string;
    input?: ReactNode;
  }
  
  const FormRow: FC<FormRowProps> = ({ label, input }) => {
    return (
      <Grid container alignItems="center" mb={1.5} minHeight="40px">
        <Grid item xs={4} sx={{ color: "text.secondary" }}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item xs={8} sx={{ textAlign: typeof input === "string" ? "right" : "" }}>
          {input}
        </Grid>
      </Grid>
    );
  };

  export default FormRow
  