import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router";

function Page500({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}) {
  const navigate = useNavigate();
  function handleErrorClick() {
    resetErrorBoundary();
    navigate(0);
  }

  return (
    <Container
      sx={{
        textAlign: "center",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 8
      }}
    >
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        500
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Oops! Something went wrong...
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {error.message
          ? error.message
          : "The server encountered something unexpected that didn't allow it to complete the request."}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        onClick={handleErrorClick}
      >
        Return to website
      </Button>
    </Container>
  );
}

export default Page500;
