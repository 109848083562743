import { List, Box, ListSubheader } from '@mui/material';
import { NavSectionProps } from './type';
import { NavListRoot } from './NavList';

export default function NavSection({ navConfig, isCollapse = false, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheader
            sx={{
              ...(isCollapse && {
                opacity: 0
              }),
              paddingTop: 2,
              paddingLeft: 2,
              paddingBottom: 1,
              color: "text.primary",
              transition: (theme) =>
                theme.transitions.create('opacity', {
                  duration: theme.transitions.duration.shorter
                }),
              typography: "overline"
            }}
            disableSticky
            disableGutters
          >
              {group.subheader}
          </ListSubheader>

          {group.items.map((list) => (
            !list.hidden && <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  );
}
