import { Box, BoxProps } from "@mui/material";
import React, { ReactNode, FC } from "react";

interface Props extends BoxProps {
  children?: ReactNode;
}

const DisablePropagation: FC<Props> = ({ children, ...rest }) => {
  return (
    <Box
      {...rest}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </Box>
  );
};

export default DisablePropagation;
