import React, { FC, ReactNode } from "react";
import { Box, Chip, Checkbox, Typography } from "@mui/material";
import UserData from "store/models/UserData";
import { titleCase, toPrettyDateTime } from "utils/util";
import { selectBranches } from "store/slices/optionSlice";
import { useSelector } from "react-redux";

interface UserRowProps {
  label: string;
  input?: ReactNode;
}

const UserRow: FC<UserRowProps> = ({ label, input }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignContent="center">
      <Typography sx={{ alignSelf: "center" }}>{label}</Typography>
      {input}
    </Box>
  );
};

interface Props {
  user: UserData;
}

const UserView: FC<Props> = ({ user }) => {
  const branches = useSelector(selectBranches);

  return (
    <Box display="flex" flexDirection="column" rowGap={3} mt={1}>
      <UserRow
        label="Last Login"
        input={user.lastLoggedIn && toPrettyDateTime(user.lastLoggedIn)}
      />

      <UserRow label="Email" input={user.email} />

      <UserRow label="Phone" input={user.phone} />

      <UserRow label="Role" input={titleCase(user.role)} />

      <UserRow
        label="Disabled"
        input={<Checkbox disabled checked={user.isDisabled} />}
      />

      <UserRow label="Countries" />
      <Box width="100%">
        {user.countries.map((country) => (
          <Chip
            variant="outlined"
            color="primary"
            key={country}
            label={country}
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
      </Box>

      <UserRow label="Branches" />
      <Box width="100%">
        {user.branches.map((branchId) => (
          <Chip
            variant="outlined"
            color="primary"
            key={branchId}
            label={branches[branchId].name}
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default UserView;
