import React from 'react';
import { useSelector } from 'react-redux';
import { clearGlobalMessage, selectGlobalMessage } from 'store/slices/systemSlice';
import { ClickAwayListenerProps } from '@mui/material';
import Toast from 'components/Toast';
import { useAppDispatch } from 'store/store';

interface Props {
}

const GlobalMessage: React.FC<Props> = () => {
  const globalMessage = useSelector(selectGlobalMessage);
  const dispatch = useAppDispatch();
  const defaultTimeOut = globalMessage.action ? 5000 : 3000;

  const clickAwayListenerProps:
    | Partial<ClickAwayListenerProps>
    | undefined = {
      onClickAway: () => clearGlobalMessage(),
      mouseEvent: "onMouseDown",
      touchEvent: "onTouchStart",

    };
  if (globalMessage.disableClickAwayListener) clickAwayListenerProps.onClickAway = () => {};

  const message = () => {

    const arrayInMessage = findArrayInMessage();
    const arrayHeader = findArrayHeader()

    if (globalMessage?.messageText.includes("TypeError: Failed to fetch")) {
      return "We are having trouble connecting...";
    }

    if (failedToFetchOnMac()) {
      return "We are having trouble connecting...";
    }
    
    if (arrayInMessage) {
      return (
        <>
          {arrayHeader}
          {arrayInMessage.map(subMessage => {
          return (
            <ul>
              <li>{subMessage}</li>
            </ul>
          )})}
        </>
      )
    } else {
      return globalMessage?.messageText
    }

    function findArrayInMessage() {
      const indexOfOpening = globalMessage?.messageText.indexOf("[")
      const indexOfClosing= globalMessage?.messageText.indexOf("]")
      if (indexOfOpening >= 0 && indexOfClosing >= 0) {
        return JSON.parse(globalMessage?.messageText.substring(indexOfOpening, indexOfClosing + 1)) as string[];
      }
    }
    function findArrayHeader() {
      const [header] = globalMessage?.messageText.split("[") || "";
      return header.trim()
    }

    function failedToFetchOnMac() {
      return globalMessage?.messageText.includes("TypeError: Load failed")
    }
  }

  const clearErrorMessage =  () => {
    dispatch(clearGlobalMessage());
  };

  return (
    <Toast
      messageContent={message()}
      onClose={clearErrorMessage}
      duration={defaultTimeOut}
      disableAutoHide={globalMessage.disableAutoHide}
      {...globalMessage}
    />
  );
};

export default GlobalMessage;
