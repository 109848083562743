import { FC } from "react";
import { Page, View, Text, Image, Document, StyleSheet, Font,  } from '@react-pdf/renderer';
import { getFullName, toDDMMMYYYY } from "utils/util";
import { Branch } from "store/models/Branch";
import { Asset } from "store/models/Asset";
import { Contract } from "store/models/Contract";
import { Client } from "store/models/Client";
import UserData from "store/models/UserData";
import { WebWOQueryResponse } from "store/services/workOrder";
import { ClientContact } from "store/models/ClientContact";
import { fCurrency } from "utils/formatNumber";
import { IInspection } from "store/models/Inspection";




Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});


interface Props {
  user: UserData;
  users: Record<string, UserData>;
  asset: Asset;
  contract?: Contract;
  client?: Client;
  branch?: Branch
  clientContact: ClientContact[];
  workOrder?: WebWOQueryResponse;
  inspection?: IInspection;
  images: Array<string>;
}

const WorkOrderPDF: FC<Props> = ({
  user,
  branch,
  asset,
  contract,
  client,
  clientContact,
  workOrder,
  users,
  images,
  inspection,
}) => {

  const isUSA = branch?.country === "USA";

  const estimatedTotalLabourCost = workOrder?.estimatedHours ? (branch?.labourCost.amount || 0) * workOrder.estimatedHours : 0;
  const estimatedTotal = fCurrency(estimatedTotalLabourCost + (workOrder?.estimatedMaterials?.amount || 0));

  const actualTotalLabourCost = workOrder?.actualHoursToComplete ? (branch?.labourCost.amount || 0) * workOrder.actualHoursToComplete : 0;
  const actualTotal = fCurrency(actualTotalLabourCost + (workOrder?.actualMaterialsToComplete?.amount || 0));

  const assetInfo = {
    "Serial Number": asset?.serialNumber,
    "Asset Number": asset?.assetNumber,
    "Size Code": asset?.sizeCode,
    "Asset Category": asset?.category,
    "Asset Sub Category": asset?.subCategory,
    Branch: branch?.name,
  };

  const standardWorkOrderInfo = {
    Title: workOrder?.inspection ? workOrder?.inspectionType : workOrder?.title,
    Description: workOrder?.inspection
      ? "ATCO Structures Contract Inspection"
      : workOrder?.description,
    Number: workOrder?.number,
    Status: workOrder?.status,
    "Due Date": workOrder?.dueDate,
    "Completed Date": toDDMMMYYYY(workOrder?.completedDate)
      ? (toDDMMMYYYY(workOrder?.completedDate) as string)
      : "Not Yet Complete",
    Priority: workOrder?.inspection ? "STANDARD" : workOrder?.priority,
    "Created On": workOrder?.createdOn,
    "Created By": getFullName(workOrder?.createdBy),
    "Assigned To": workOrder?.assignedTo?._id
      ? getFullName(workOrder?.assignedTo)
      : "Unassigned",
  };

  const workOrderInfo = {
    "WO Category": workOrder?.category,
    "WO Sub Category": workOrder?.subcategory,
    Priority: workOrder?.priority,
    "Estimated Hours": workOrder?.estimatedHours || "Not yet estimated",
    "Actual Hours": workOrder?.actualHoursToComplete || "Not yet Complete",
    "Estimated Materials": `${
      workOrder?.estimatedMaterials?.amount
        ? `${fCurrency(workOrder?.estimatedMaterials?.amount)} ${
            workOrder?.estimatedMaterials?.currency
          }`
        : "No Estimated Materials"
    }`,
    "Actual Materials": `${
      workOrder?.actualMaterialsToComplete?.amount
        ? `${fCurrency(workOrder?.actualMaterialsToComplete?.amount)} ${
            workOrder?.actualMaterialsToComplete?.currency
          }`
        : "Not yet Complete"
    }`,
    "Estimated Total": estimatedTotal ? `${estimatedTotal} ${branch?.labourCost.currency}` : "-",
    "Actual Total": workOrder?.status !== "COMPLETE" ? "Not Yet Complete" : actualTotal ? `${actualTotal} ${branch?.labourCost.currency}` : "-",
    "Billable To Client": workOrder?.billableToClient ? "Yes" : "No",
    "Must be fixed before renting": workOrder?.contract
      ? "N/A"
      : workOrder?.isBlocking
      ? "Yes"
      : "No",
    "Parent Work Order": workOrder?.parent?.workOrder?.number
      ? workOrder?.parent.workOrder.number
      : "N/A",
  };

  const contractInfo = {
    "Project Number": contract?.projectNumber,
    "Contrat Status": contract?.status,
    Client: contract?.customerName,
    "Client Phone": client?.phoneNumber,
    "Asset Location": contract?.billTo.address,
    "Client Contact": clientContact.length
      ? getFullName(clientContact[0])
      : "N/A",
    "Contact Phone": clientContact.length
      ? clientContact[0].primaryPhone
      : "N/A",
    "Contact Email": clientContact.length ? clientContact[0].email : "N/A",
  };

  return (
    <Document style={{ height: "100%", width: "100%" }}>
      <Page size="A4" style={styles.page}>
        <View
          style={[
            styles.flexContainer,
            { marginHorizontal: 8, marginBottom: 20 },
          ]}
          fixed
        >
          <Image source="/atco-logo.png" style={{ height: 32 }} />
          <View
            style={{ alignItems: "flex-end", flexDirection: "column", gap: 0 }}
          >
            <Text
              style={{ fontSize: 12, fontFamily: "Roboto", fontWeight: 800 }}
            >
              {isUSA
                ? "ATCO Structures & Logistics USA Ltd"
                : "ATCO Structures & Logistics Ltd"}
            </Text>
            <Text style={{ fontSize: 9, fontFamily: "Roboto" }}>
              {branch?.name}, {branch?.address.prefix || ""}{" "}
              {branch?.address.address}
            </Text>
            <Text style={{ fontSize: 9, fontFamily: "Roboto" }}>
              T: {branch?.phone}
            </Text>
            <Text style={{ fontSize: 9, fontFamily: "Roboto" }}>
              {branch?.fax ? `F: ${branch.fax}` : ""}
            </Text>
            <Text style={{ fontSize: 9, fontFamily: "Roboto" }}>
              https://structures.atco.com
            </Text>
          </View>
        </View>
        <View style={{ marginBottom: 20 }}>
          <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {contract &&
              Object.entries(contractInfo).map(([label, value]) => (
                <InfoCell key={label} label={label} value={value} />
              ))}
            {Object.entries(assetInfo).map(([label, value]) => (
              <InfoCell key={label} label={label} value={value} />
            ))}
            {Object.entries(standardWorkOrderInfo).map(([label, value]) => (
              <InfoCell key={label} label={label} value={value} />
            ))}
            {!workOrder?.inspection &&
              Object.entries(workOrderInfo).map(([label, value]) => (
                <InfoCell key={label} label={label} value={value} />
              ))}
          </View>
        </View>
        {workOrder?.inspection && (
          <InspectionChecklistItemsTable
            items={inspection?.inspectionChecklist}
            workOrder={workOrder}
          />
        )}
        {workOrder?.tasks?.length && (
          <WorkOrderTasksTable tasks={workOrder?.tasks} users={users} />
        )}

        {images.length ? <WorkOrderImages images={images} /> : null}
        <View style={styles.footer} fixed>
          <Text
            render={({ pageNumber, totalPages }) =>
              `pg ${pageNumber} / ${totalPages}`
            }
            style={{ textAlign: "center" }}
          />
        </View>
      </Page>
    </Document>
  );
};


function InfoCell({
  label,
  value,
}: {
  label: string;
  value: string | number | undefined;
}) {
  return (
    <View
      style={styles.infoCell}
    >
      <Text style={{ fontWeight: 800, marginLeft: 4 }}>
        {label}:
      </Text>
      <Text wrap style={{marginLeft: 4}}>{value}</Text>
    </View>
  );
}

function WorkOrderTasksTable({
  tasks = [],
  users,
}: {
  tasks?: WebWOQueryResponse["tasks"];
  users: Record<string, UserData>;
}) {
  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <View style={styles.tableRow}>
          <View style={styles.descriptionColumn}>
            <Text style={styles.subtitle2}>Description</Text>
          </View>
          <View style={styles.completeColumn}>
            <Text style={styles.subtitle2}>Complete</Text>
          </View>
          <View style={styles.completedByColumn}>
            <Text style={styles.subtitle2}>Completed By</Text>
          </View>
          <View style={[styles.completedDateColumn, styles.alignCenter]}>
            <Text style={styles.subtitle2}>Completed Date</Text>
          </View>
        </View>
      </View>
      <View style={styles.tableBody}>
        {tasks.map((task) => (
          <View key={task.description}>
            <View style={styles.tableRow}>
              <View style={styles.descriptionColumn}>
                <Text style={styles.body1}>{task.description}</Text>
              </View>
              <View style={styles.completeColumn}>
                <Text style={styles.body1}>
                  {task.complete ? "Yes" : "No"}
                </Text>
              </View>
              <View style={styles.completedByColumn}>
                <Text style={styles.body1}>
                  {task.completedBy ? getFullName(users[task.completedBy]) : "N/A"}
                </Text>
              </View>
              <View style={[styles.completedDateColumn, styles.alignCenter]}>
                <Text style={styles.body1}>
                  {task.completedDate
                    ? toDDMMMYYYY(task.completedDate)
                    : "N/A"}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

function InspectionChecklistItemsTable({
  items = [],
  workOrder
}: {
  items?: IInspection["inspectionChecklist"];
  workOrder: WebWOQueryResponse;

}) {
  return (
    <View style={styles.table}>
      <View style={styles.tableHeader} fixed>
        <View style={styles.tableRow}>
          <View style={styles.checklistCategory}>
            <Text style={styles.subtitle2}>Category</Text>
          </View>
          <View style={[styles.checklistTask, {paddingLeft: 0}]}>
            <Text style={styles.subtitle2}>Task</Text>
          </View>
          <View style={styles.checklistQuantity}>
            <Text style={styles.subtitle2}>Qty</Text>
          </View>
          <View style={styles.checklistPassFail}>
            <Text style={styles.subtitle2}>Pass / Fail</Text>
          </View>
          <View style={styles.checklistHours}>
            <Text style={styles.subtitle2}>Hours</Text>
          </View>
          <View style={styles.checklistCost}>
            <Text style={styles.subtitle2}>Cost</Text>
          </View>
          <View style={styles.checklistBillClient}>
            <Text style={styles.subtitle2}>Bill Client</Text>
          </View>
        </View>
      </View>
      <View style={styles.tableBody}>
       {items.map((item) => (
          <View key={item._id}>
            <View style={styles.tableRow} wrap={false}>
              <View style={styles.checklistCategory}>
                <Text style={[styles.body1,{alignSelf: "center"}]}>{item.category}</Text>
              </View>
              <View style={styles.checklistTask}>
                <Text style={styles.body1}>{item.taskName}</Text>
              </View>
              <View style={styles.checklistQuantity}>
                <Text style={styles.body1}>{item.quantity || '-'}</Text>
              </View>
              <View style={styles.checklistPassFail}>
                <Text style={styles.body1}>{workOrder.status === "COMPLETE" ? item.result : "Pass / Fail"}</Text>
              </View>
              <View style={styles.checklistHours}>
                <Text style={styles.body1}>{item.estimatedHours}</Text>
              </View>
              <View style={styles.checklistCost}>
                <Text style={styles.body1}>{item.estimatedMaterials.amount}</Text>
              </View>
              <View style={styles.checklistBillClient}>
                <Text style={styles.body1}>{workOrder.status !== "COMPLETE" ? "Yes/No" : item.billableToClient ? "Yes" : "No"}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

function WorkOrderImages({ images }: { images: Array<string> }) {
  return (
    <View style={styles.imageContainer} break>
      {images.map((img, index) => (
        <Image key={index} src={img} style={styles.image} />
      ))}
    </View>
  );
}


export default WorkOrderPDF;

const styles = StyleSheet.create({
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  body1: { fontSize: 9 },
  subtitle2: { fontSize: 10, fontWeight: 600, marginHorizontal: 5, paddingVertical: 2 },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  alignLeft: { textAlign: "left" },
  page: {
    padding: "40px 24px 0 24px",
    fontSize: 9,
    lineHeight: 1.6,
    backgroundColor: "#fff",
    fontFamily: "Roboto",
    flex: 1,
    height: '100%',
  },
  footer: {
    display: "flex", 
    flex: 1,
    justifyContent: "flex-end",
    margin: 10,
    padding: 10,
    width: "100%",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  table: {
    width: "100%",
    fontSize: 9,
    marginVertical: 4,
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#80808080",
  },
  tableBody: {
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "black",
    alignItems: "center",
    pageBreakInside: "avoid",
  },
  tableTitle: {
    width: "100%",
    fontSize: 12,
    padding: "6px 12px",
    paddingBottom: 0,
  },
  descriptionColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    width: "50%",
    marginLeft: 4,
  },
  infoCell: {
    flexDirection: "row",
    fontSize: 10,
    alignItems: "center",
    minHeight: 20,
    paddingTop: 3,
    width: "50%",
    fontFamily: 'Roboto',
    flexWrap: "wrap",
    border: '0.5px solid black'
  },
  completeColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "15%",
    textAlign: "center"
  },
  completedByColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "20%",
    textAlign: "center"
  },
  completedDateColumn: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 2,
    borderLeft: "1px solid black",
    width: "20%",
  },
  underline: {
    borderBottom: "1px solid black",
  },
  checklistCategory: {
    width: "15%",
    fontFamily: 'Roboto',
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 3
  },
  checklistTask: {
    width: "35%",
    textAlign: "left",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 4,
    paddingTop: 3
  },
  checklistQuantity: {
    width: "5%",
    fontFamily: 'Roboto',
    alignItems: "center",
    display: "flex",
    borderLeft: "1px solid black",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 3
  },
  checklistPassFail: {
    width: "15%",
    textAlign: "center",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    paddingTop: 3
  },
  checklistHours: {
    width: "10%",
    textAlign: "center",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    paddingTop: 3
  },
  checklistCost: {
    width: "10%",
    textAlign: "center",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    paddingTop: 3
  },
  checklistBillClient: {
    width: "15%",
    textAlign: "center",
    fontFamily: 'Roboto',
    borderLeft: "1px solid black",
    paddingTop: 3
  },
  image: {
    height: 300,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 20
  },
});

