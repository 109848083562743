import React, { lazy } from "react"
import usePermissions, {
  FeatureType,
  ActionType,
} from "hooks/usePermissions";
import { PATH_DASHBOARD, PATH_MAINTENANCE } from "./paths";
import { Navigate, useRoutes } from "react-router-dom";
import Page404 from "./Page404";
import SerialNumber404 from "features/assets/page/SerialNumber404";
import Main from "./Main";
import OptisignsScreen from "features/tv/OptisignsScreen";
import BranchSettings from "features/settings/branch/BranchSettings";
import SizeCodes from "features/settings/Settings";
import PDFPlayGround from "features/pdfPlayground/PDFPlayground";


const Assets = lazy(() => import("features/assets/Assets"));
const AssetActionRecords = lazy(() => import("features/assets/AssetActionRecords"));
const Clients = lazy(() => import("features/clients/Clients"));
const Contracts = lazy(() => import("features/contracts/Contracts"));
const FinanceDashboard = lazy(() => import("features/dashboards/finance/FinanceDashboard"));
const GeneralDashboard = lazy(() => import("features/dashboards/app/AppDashboard"));
const FleetDashboard = lazy(() => import("features/dashboards/fleet/FleetDashboard"));
const GeoDashboard = lazy(() => import("features/dashboards/geo/GeoDashboard"));
const MovementsDashboard = lazy(() => import("features/dashboards/movements/MovementsDashboard"));
const Permissions = lazy(() => import("features/permissions/Permissions"));
const Maintenance = lazy(() => import("features/maintenance/Maintenance"));
const Movements = lazy(() => import("features/movements/Movements"));
const Users = lazy(() => import("features/users/Users"));
const Leads = lazy(() => import("features/leads/Leads"));
const Logout = lazy(() => import("auth/Logout"));
const MasterUnitList = lazy(() => import("features/masterUnitList/MasterUnitList"));
const Profile = lazy(() => import("features/profile/Profile"))
const MaintenanceTv = lazy(() => import("features/tv/MaintenanceTv"));
const InOutMovementsTv = lazy(() => import("features/tv/InOutMovementTv"));
const ClientContacts = lazy(() => import("features/clients/contacts/ClientContacts"))
const TransportCompanies = lazy(() => import("features/transportCompanies/TransportCompanies"))

const Router = () => {
  const checkUserPermissions = usePermissions(FeatureType.USER);
  const userCanViewDashboards = usePermissions(FeatureType.DASHBOARD)(ActionType.READ);
  const userCanViewUsers = checkUserPermissions(ActionType.READ);
  const userCanViewAssets = usePermissions(FeatureType.ASSET)(ActionType.READ);
  const userCanViewLeads = usePermissions(FeatureType.LEAD)(ActionType.READ);
  const userCanViewContracts = usePermissions(FeatureType.CONTRACT)(ActionType.READ);
  const userCanViewClients = usePermissions(FeatureType.CLIENTS)(ActionType.READ);
  const userCanViewMovements = usePermissions(FeatureType.MOVEMENT)(ActionType.READ);
  const userHasFullMaintenanceRead = usePermissions(FeatureType.MAINTENANCE)(ActionType.READ);
  const userHasLimitedMaintenanceRead = usePermissions(FeatureType.MAINTENANCE)(ActionType.LIMITED_READ);
  const userCanReadPermissions = usePermissions(FeatureType.PERMISSION)(ActionType.READ);
  const userCanUpdateSettings = usePermissions(FeatureType.PERMISSION)(ActionType.UPDATE)

  const RedirectTo404 = () => <Navigate to="/404" replace />
  // useRoutes is used to prevent app rerendering when hovering the side nav
  return useRoutes([
    {
      path: '/',
      element: <Main />,
      children: [
        { path: "/", element: <Navigate to={userCanViewDashboards ? PATH_DASHBOARD.app : PATH_MAINTENANCE.root} replace /> },
        { path: "/dashboard", 
          children: [
            { path: 'app', element: userCanViewDashboards ? <GeneralDashboard /> : <RedirectTo404 /> },
            { path: 'fleet', element: userCanViewDashboards ? <FleetDashboard /> : <RedirectTo404 /> },
            { path: 'finance', element: userCanViewDashboards ? <FinanceDashboard /> : <RedirectTo404 /> },
            { path: 'geo', element: userCanViewDashboards ? <GeoDashboard /> : <RedirectTo404 /> },
            { path: 'movements', element: userCanViewDashboards ? <MovementsDashboard /> : <RedirectTo404 /> },
          ]
        },
        { path: "/assets", element: userCanViewAssets ? <Assets /> : <RedirectTo404 /> },
        { path: "/mul", element: userCanViewAssets ? <MasterUnitList /> : <RedirectTo404 /> },
        { path: "/assetActionRecords", element: userCanViewAssets ? <AssetActionRecords /> : <RedirectTo404 /> },
        { path: "/leads", element: userCanViewLeads ? <Leads /> : <RedirectTo404 /> },
        { path: "/contracts", element: userCanViewContracts ? <Contracts /> : <RedirectTo404 /> },
        { path: "/clients", 
          children: [
          { index: true, element: <Navigate to="/clients/clients" /> },
          { path: 'clients', element: userCanViewClients ? <Clients /> : <RedirectTo404 /> },
          { path: 'contacts', element: userCanViewClients ? <ClientContacts /> : <RedirectTo404 /> },
        ]},
        { path: "/movements", 
        children: [
          { path: 'list', element: userCanViewMovements ? <Movements /> : <RedirectTo404 />, },
          { path: 'transportCompanies', element: userCanViewMovements ? <TransportCompanies /> : <RedirectTo404 />, }
        ]},
        { path: "/maintenance", element: userHasFullMaintenanceRead || userHasLimitedMaintenanceRead ? <Maintenance /> : <RedirectTo404 /> },
        { path: "/listUsers", element: userCanViewUsers ? <Users /> : <RedirectTo404 /> },
        { path: "/settings", 
          children: [
          { path: 'branch', element: userCanUpdateSettings ? <BranchSettings /> : <RedirectTo404 /> },
          { path: 'sizeCodes', element: userCanUpdateSettings ? <SizeCodes /> : <RedirectTo404 /> },
          { path: "permissions", element: userCanReadPermissions ? <Permissions /> : <RedirectTo404 />},
          { path: "profile", element: <Profile /> },
        ]
      },
        { path: "/logout", element: <Logout /> },
        { path: "/pdfPlayground", element: process.env.NODE_ENV !== 'production' ? <PDFPlayGround /> : null },
        {
          path: '/optisigns', 
          element: <OptisignsScreen />, 
          children: [
            { path: 'inOutMovements', element: <InOutMovementsTv /> },
            { path: 'maintenance', element: <MaintenanceTv /> },
        ]},
      ]
    },
    { path: '/404', element: <Page404 /> },
    { path: '/asset/404', element: <SerialNumber404 /> },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    }
  ]);
};

export default Router;