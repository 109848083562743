import { Currency } from "dinero.js";

export interface ICurrency {
    amount: number, 
    currency: Currency,
  }
  
  
export const initCurrency = (currency?: Partial<ICurrency>): ICurrency => ({
amount: 0,
currency: "CAD",
...currency,
});
  