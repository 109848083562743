import { sendToBackend } from "store/api/util";
import { errorHandler } from "./shared";
import { AssetActionRecord } from 'store/models/AssetActionRecord';

const assetActionRecordApi = {
  getAllAssetActionRecords: (
    query: Object,
    actions?: {
      onData?: (data: AssetActionRecord[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
    }
  ) => {
    sendToBackend<AssetActionRecord[]>({
      method: "POST",
      body: query,
      route: `asset/actionRecords`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
    });
  }
};

export default assetActionRecordApi;
