import { darken, Theme } from "@mui/material/styles";

export default function CssBaseline(theme: Theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        "&::-webkit-scrollbar": {
          width: "14px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : darken(theme.palette.grey[500], 0.05),
          borderRadius: "20px",
          border: "4px solid transparent",
          backgroundClip: "content-box",
          cursor: "pointer",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          width: "18px",
        },
      },
    },
  };
}
