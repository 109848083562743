import UserData from "store/models/UserData";
import { useSelector } from "react-redux";
import { IPermissions, selectPermissions } from "store/slices/configSlice";
import { selectLoggedInUser, simpleGlobalMessage } from "store/slices/systemSlice";
import { useAppDispatch } from 'store/store';

export enum FeatureType {
  ASSET = "ASSET",
  LEAD = "LEAD",
  CONTRACT = "CONTRACT",
  CLIENTS = "CLIENTS",
  MOVEMENT = "MOVEMENT",
  BILL_OF_LADING = "BILL OF LADING",
  MAINTENANCE = "MAINTENANCE",
  USER = "USER",
  PERMISSION = "PERMISSION",
  USER_PREFERENCE = "USER PREFERENCE",
  DASHBOARD = "DASHBOARD",
  BRANCH = "BRANCH"
}

export enum ActionType {
  CREATE = "CREATE",
  READ = "READ",
  LIMITED_READ = "LIMITED_READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  ARCHIVE = "ARCHIVE",
  LIMITED_UPDATE = "LIMITED_UPDATE",
  SYNC = "SYNC",
  PROMOTE = "PROMOTE",
}

export enum UIActionType {
  DISPLAY = "DISPLAY",
  ONCLICK = "ONCLICK",
}

export enum USER_ROLE {
  SUPER_USER = "SUPER USER",
  ADMINISTRATOR = "ADMINISTRATOR",
  SALES_COORDINATOR = "SALES COORDINATOR",
  MAINTENANCE_MANAGER = "MAINTENANCE MANAGER",
  SALES_MANAGER = "SALES MANAGER",
  SALES_PERSON = "SALES PERSON",
  VIEWER = "VIEWER",
}

export const userIsSuper = (user?: UserData) => {
  return user?.role === USER_ROLE.SUPER_USER
}

export const userIsAdministrator = (user?: UserData) => {
  return user?.role === USER_ROLE.ADMINISTRATOR
}

const usePermissions = (module: FeatureType) => {
  const dispatch = useAppDispatch();
  const permissionsConfig: IPermissions | undefined = useSelector(selectPermissions);
  const loggedInUser = useSelector(selectLoggedInUser);
  if (!permissionsConfig) return () => false;
  if (loggedInUser) {
    return (action: ActionType, uiActionType:UIActionType = UIActionType.DISPLAY) => {
      const userHasPermission =
        permissionsConfig[loggedInUser.role][module][action];
        if(!userHasPermission && uiActionType === UIActionType.ONCLICK){
          dispatch(simpleGlobalMessage("You do not have permission to perform this action"))
        }
      return userHasPermission;
    };
  }
  return () => false;
};

export default usePermissions;
