import React from 'react'
import StarfleetLoading from './StarfleetLoading';
import { Box } from '@mui/material';

interface Props {
    isLoading: boolean
    children: React.ReactNode
}

const BladeLoading: React.FC<Props> = ({isLoading, children}) => {
  return (
    <div>
      {isLoading ? (
        <Box sx={{display: "flex", height: "60vh", justifyContent: "center", alignItems: "center"}}>
          <StarfleetLoading />
        </Box>
      ) : (
        children
      )}
    </div>
  );
}

export default BladeLoading