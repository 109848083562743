import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import { MotionContainer, varBounce } from 'components/animate';
import Page from 'components/Page';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function SerialNumber404() {
  return (
    <Page title="Asset Not Found" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Sorry, Asset not found!
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn&lsquo;t find the Asset you&lsquo;re looking for. Please contact your Adminstrator
            </Typography>
            <Button to="/" size="large" variant="contained" component={RouterLink} sx={{ mt: 3 }}>
              Return To Website
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
