import useSocketController from "hooks/useSocketController";
import { createContext, FC, useMemo } from "react";
import { Socket } from "socket.io-client";

export interface SocketContextType {
  socket?: Socket;
}

export const SocketContext = createContext<SocketContextType | null>(
  null
);

export const SocketContextProvider: FC = ({ children }) => {
  const socket = useSocketController();
  const value = useMemo(() => ({ socket }), [socket])
  return (
    <SocketContext.Provider value={value}>
      {children}
    </SocketContext.Provider>
  );
}

export default SocketContextProvider;