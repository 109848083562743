import {
  CountOfAssetsByStatusForEachBranch,
  ContractsEndingAndBeginningByBranch,
} from "types/Analytics";
import { errorHandler } from './shared';
import { sendToBackend } from "store/api/util";

export interface BranchAssetConditionRanges {
  branchName: string;
  totalAssets: number;
  criticalCondition: number;
  poorCondition: number;
  goodCondition: number;
  excellentCondition: number;
}

const analyticsApi = {
  getCountOfAssetsByStatusForEachBranch: (
    options?: { myBranchesOnly: boolean, categoryFilter: string, subCategoryFilter: string },
    actions?: {
      onData?: (data: CountOfAssetsByStatusForEachBranch) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<CountOfAssetsByStatusForEachBranch>({
      route: `analytic/countOfAssetsByStatusForEachBranch?myBranchesOnly=${options?.myBranchesOnly}&categoryFilter=${options?.categoryFilter}&subCategoryFilter=${options?.subCategoryFilter}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getContractsEndingAndBeginningByBranch: (
    options?: { dateStartingFrom: string; myBranchesOnly: boolean },
    actions?: {
      onData?: (data: ContractsEndingAndBeginningByBranch) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<ContractsEndingAndBeginningByBranch>({
      route: `analytic/contractsEndingAndBeginningByBranch?dateStartingFrom=${options?.dateStartingFrom}&myBranchesOnly=${options?.myBranchesOnly}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getAverageAssetConditionByBranch: (
    options?: { myBranchesOnly: boolean },
    actions?: {
      onData?: (
        data: { branchName: string; averageAssetCondition: number }[]
      ) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{ branchName: string; averageAssetCondition: number }[]>({
      route: `analytic/averageAssetConditionByBranch?myBranchesOnly=${options?.myBranchesOnly}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getAssetConditionByBranchByRange: (
    options?: { myBranchesOnly: boolean },
    actions?: {
      onData?: (data: BranchAssetConditionRanges[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<BranchAssetConditionRanges[]>({
      route: `analytic/assetConditionByBranchByRange?myBranchesOnly=${options?.myBranchesOnly}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getTotalRentalRates: (
    options?: { myBranchesOnly: boolean, categoryFilter: string, subCategoryFilter: string },
    actions?: {
      onData?: (data: { totalRentalRates: number }) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{ totalRentalRates: number }>({
      route: `analytic/totalRentalRate?myBranchesOnly=${options?.myBranchesOnly}&categoryFilter=${options?.categoryFilter}&subCategoryFilter=${options?.subCategoryFilter}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getRevenueForecastByBranch: (
    options?: { myBranchesOnly: boolean },
    actions?: {
      onData?: (data: any) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<any>({
      route: `analytic/revenueForecastByBranch?myBranchesOnly=${options?.myBranchesOnly}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  }
};

export default analyticsApi;
