import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  AutocompleteProps,
  FilterOptionsState,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Asset } from "store/models/Asset";
import { selectAssets } from "store/slices/assetSlice";
import { titleCase } from "utils/util";
import { matchSorter } from "match-sorter";

type Props = Omit<
  AutocompleteProps<Asset, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  textFieldProps?: TextFieldProps;
  name?: string;
  error?: boolean;
  "data-testId"?: string;
  options?: AutocompleteProps<Asset, boolean, boolean, boolean>["options"]
  useAssetNumber?: boolean
};

const AssetAutocomplete: FC<Props> = ({ value, name, error, textFieldProps, options: limitedOptions, useAssetNumber = false, ...rest }) => {
  const assets = useSelector(selectAssets);
  const options = limitedOptions || Object.values(assets);
  const filterOptions = (options: Asset[],  state: FilterOptionsState<Asset>) => {
    return matchSorter(options, state.inputValue, {
      keys: useAssetNumber ? ["assetNumber"] : ["serialNumber"],
    })
  }
  
  return (
    <Autocomplete
      options={options}
      {...rest}
      value={value || null}
      getOptionLabel={(asset) =>
        typeof asset === "string"
          ? asset
          : `${
              useAssetNumber && asset.assetNumber
                ? asset.assetNumber
                : asset.serialNumber
            } - ${titleCase(asset.sizeCode)} - ${titleCase(asset.category)}`
      }
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) => option?._id === value?._id}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          data-testid={rest["data-testId"]}
          error={error}
          margin="none"
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password",
            form: { autocomplete: "off" },
          }}
          {...textFieldProps}
        />
      )}
    />
  );
}

export default AssetAutocomplete;