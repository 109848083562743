import { ListenerEffectAPI } from "@reduxjs/toolkit";
import { AnyAction, Dispatch } from "redux";
import api from "./api";
import { Asset } from "./models/Asset";
import { Contract } from "./models/Contract";
import { setManyAssets, setOneAsset } from "./slices/assetSlice";
import { setContract } from "./slices/contractSlice";
import { STARFLEET_STORE } from "./store";

export type ListenerPredicate = (
  action: AnyAction,
  currentState: STARFLEET_STORE,
  previousState: STARFLEET_STORE
) => boolean;

export type ListenerEffect = (_: AnyAction, listener: ListenerEffectAPI<STARFLEET_STORE, Dispatch<AnyAction> >) => Promise<any>;

export const contractIdUpdated: ListenerPredicate = (
  action: AnyAction,
  currentState: STARFLEET_STORE,
  previousState: STARFLEET_STORE
) => {
  return Boolean(
    currentState.contracts.currentContractId !==
      previousState.contracts.currentContractId ||
      action.type === "contracts/setCurrentContractId"
  );
}

export const movementIdUpdate: ListenerPredicate = (
  action: AnyAction,
  currentState: STARFLEET_STORE,
  previousState: STARFLEET_STORE
) => {
  return Boolean(
    currentState.contracts.currentMovementId !==
      previousState.contracts.currentMovementId ||
      action.type === "contracts/setCurrentMovementId"
  );
}

export const assetIdUpdate: ListenerPredicate = (
  action: AnyAction,
  currentState: STARFLEET_STORE,
  previousState: STARFLEET_STORE
) => {
  return Boolean(
    currentState.assets.currentAssetId !==
      previousState.assets.currentAssetId ||
      action.type === "assets/setCurrentAssetId"
  );
}


export const fetchMissingAssets: ListenerEffect = async (_, listener) => {
  const state = listener.getState();
  const assets = state.assets.assets;
  const contracts = state.contracts.contracts;
  const { dispatch } = listener;
  const currentContractId = state.contracts.currentContractId;

  if (!currentContractId) return;
  listener.cancelActiveListeners();

  const deliverablesWithMissingAssets = contracts[
    currentContractId
  ]?.assetDeliverables.filter(({ asset }) => !Boolean(assets[asset])) || [];

  if (deliverablesWithMissingAssets.length) {
    listener.fork(async () => {
      const missingAssets = (await Promise.all(
        deliverablesWithMissingAssets.map(
          ({ asset }) =>
            new Promise((resolve, reject) => {
              api.assets.getOne(asset, {
                onData: (asset) => resolve(asset),
                onError: () => reject(),
              });
            })
        )
      )) as Asset[];
      dispatch(setManyAssets(missingAssets));
      return missingAssets;
    });
  }
}

export const fetchMissingContract: ListenerEffect = async (_, listener) => {
  const state = listener.getState();
  const contracts = state.contracts.contracts;
  const currentContractId = state.contracts.currentContractId;
  const { dispatch } = listener;

  
  if (!currentContractId) return;
  const contract = contracts[currentContractId];
  
  listener.cancelActiveListeners();

  if (!contract) {
    listener.fork(async () => {
      const missingContract = await new Promise<Contract>((resolve) => {
        api.contracts.getOne(currentContractId, { onData: resolve });
      });
      dispatch(setContract(missingContract));
      return missingContract;
    });
  }
};

export const fetchMissingMovementContract: ListenerEffect = async (_, listener) => {
  const state = listener.getState();
  const contracts = state.contracts.contracts;
  const movementContractId = state.contracts.movementContractId;
  const { dispatch } = listener;

  
  if (!movementContractId) return;
  const contract = contracts[movementContractId];
  
  listener.cancelActiveListeners();

  if (!contract) {
    listener.fork(async () => {
      const missingContract = await new Promise<Contract>((resolve) => {
        api.contracts.getOne(movementContractId, { onData: resolve });
      });
      dispatch(setContract(missingContract));
      return missingContract;
    });
  }
};

export const fetchMissingAsset: ListenerEffect = async (_, listener) => {
  const state = listener.getState();
  const assets = state.assets.assets;
  const { dispatch } = listener;
  const currentAssetId = state.assets.currentAssetId;
  const currentAsset = state.assets.assets[currentAssetId || ""];

  if (!currentAssetId || currentAsset?._id) return;
  listener.cancelActiveListeners();

  listener.fork(async () => {
    const missingAsset = await new Promise<Asset>((resolve, reject) => {
      api.assets.getOne(currentAssetId, {
        onData: (asset) => resolve(asset),
        onError: () => reject(),
      });
    });

    dispatch(setOneAsset(missingAsset));
    return missingAsset;
  });
};




