import _ from "lodash";
import { STARFLEET_STORE } from 'store/store';

export function byIds<T extends {_id: string}>(documents: T[]): {[_id: string]: T} {
    const documentsAsObject: any = {};
    for (const document of documents) {
        documentsAsObject[`${document._id}`] = document
    }
    return documentsAsObject
}

export function populateIds<T>(pathToIds: string, pathToDocuments: string) {
    return (state: STARFLEET_STORE) => {
      var documents: T[] = [];
      for (const documentId of _.get(state, pathToIds) || []) {
        const document = _.get(state, pathToDocuments)[`${documentId}`];
        if (document) documents.push(document);
      }
      return documents;
    };
  }

  export function byProperty<T>(documents: T[], property: keyof T): {[value: string]: T} {
    const documentsAsObject: any = {};
    for (const document of documents) {
        documentsAsObject[`${document[property]}`] = document
    }
    return documentsAsObject
}