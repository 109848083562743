import React from "react";
import { Contract, ContractStatus } from "store/models/Contract";
import api from "store/api";
import { useSelector } from "react-redux";
import { selectLoggedInUser, simpleGlobalMessage } from "store/slices/systemSlice";
import usePermissions, {
  ActionType,
  FeatureType,
} from "hooks/usePermissions";
import { Box, Button, ButtonProps } from "@mui/material";
import ConfirmationModal from "components/ConfirmationModal";
import { selectCurrentContract, setContract } from "store/slices/contractSlice";
import { useAppDispatch } from 'store/store';
import { setManyAssets } from "store/slices/assetSlice";

type ButtonAction = {
  label: string;
  contractStatus: Contract["status"];
  color?: ButtonProps["color"];
  onClick: () => void;
};

type ContactStatusOptions = {
  [status in ContractStatus]: {
    primaryAction?: ButtonAction
    alternateAction?: ButtonAction
    
  };
};

interface Props {}

const ContractStatusChangeButtons: React.FC<Props> = () => {
  const currentContract = useSelector(selectCurrentContract);
  const dispatch = useAppDispatch();
  const loggedInUser = useSelector(selectLoggedInUser);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
  if (!currentContract) return null
  const advanceContractStatus = () => {
    currentContract &&
      api.contracts.advanceContractStatus(currentContract._id, currentContract.status, {
        onData: ({ contract, assets }) => {
          dispatch(setContract(contract));
          dispatch(setManyAssets(assets));
        },
        onComplete: (message) =>
          dispatch(
            simpleGlobalMessage(message, "success")
          ),
      });
  };

const cancelContract = (type: "CANCEL" | "DENY") => {
  currentContract &&
    api.contracts.cancelContract(currentContract._id, type, {
      onData: ({ contract, assets }) => {
        dispatch(setContract(contract));
        dispatch(setManyAssets(assets));
      },
      onComplete: (message: string) =>
        dispatch(
          simpleGlobalMessage(message, "success")
        ),
    });
};
const contractStatusOptionMap: ContactStatusOptions = {
  "AWAITING APPROVAL": {
    primaryAction: {
      label: "Approve",
      contractStatus: "AWAITING CONFIRMATION",
      onClick: advanceContractStatus,
    },
    alternateAction: userCanEditContracts
      ? {
          label: "Deny",
          color: "error",
          contractStatus: "DENIED",
          onClick: () => cancelContract("DENY"),
        }
      : {
          label: "Cancel",
          color: "error",
          contractStatus: "CANCELLED",
          onClick: () => cancelContract("CANCEL"),
        },
  },

  "AWAITING CONFIRMATION": {
    primaryAction: {
      label: "Pending Delivery",
      contractStatus: "PENDING DELIVERY",
      onClick: advanceContractStatus,
    },
    alternateAction: {
      label: "Cancel",
      color: "error",
      contractStatus: "CANCELLED",
      onClick:  () => cancelContract("CANCEL"),
    },
  },
  "PENDING DELIVERY": {
    primaryAction: {
      label: currentContract.contractType === "Rental" ? "Activate" : "Confirm Delivery",
      contractStatus: "ACTIVE",
      onClick: advanceContractStatus,
    },
    alternateAction: {
      label: "Cancel",
      color: "error",
      contractStatus: "CANCELLED",
      onClick:  () => cancelContract("CANCEL"),
    },
  },
  ACTIVE: {
    primaryAction: {
      label: currentContract.contractType === "Rental" ? "Conclude" : "Complete Sale",
      contractStatus: "CONCLUDED",
      onClick: advanceContractStatus,
    },
  },
  DENIED: {},
  CANCELLED: {},
  CONCLUDED: {},
};

  function disableButton(newStatus: Contract["status"]) {
    if (newStatus === "CANCELLED") {
      const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
      const userCanCancelOwnContract =
        userTryingToCancelTheirOwnContract() &&
        checkContractPermissions(ActionType.LIMITED_UPDATE);

      return !(userCanCancelOwnContract || userCanEditContracts);
    } else {
      const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);

      return !userCanEditContracts;
    }
  }

  function userTryingToCancelTheirOwnContract() {
    return loggedInUser?._id === currentContract?.createdBy;
  }

  const { primaryAction, alternateAction } =
  contractStatusOptionMap[currentContract?.status];


  return (
    <Box
      sx={{
        display: "grid",
        columnGap: 1,
        gridAutoFlow: "column",
        gridTemplateColumns: "repeat(1fr)",
      }}
    >
      {primaryAction && (
        <Button
          disabled={disableButton(primaryAction.contractStatus)}
          onClick={primaryAction.onClick}
          variant="outlined"
          fullWidth
          sx={{
            whiteSpace: "nowrap",
            mt: 2,
          }}
          color={primaryAction.color}
        >
          {primaryAction.label}
        </Button>
      )}
      {alternateAction && (
        <ConfirmationModal
          handleConfirmation={alternateAction.onClick}
          message={`Are you sure you want to ${alternateAction.label.toLowerCase()} this contract?`}
          disabled={disableButton(alternateAction.contractStatus)}
        >
          <Button
            disabled={disableButton(alternateAction.contractStatus)}
            variant="contained"
            fullWidth
            sx={{
              whiteSpace: "nowrap",
              mt: 2,
            }}
            color={alternateAction.color}
          >
            {alternateAction.label}
          </Button>
        </ConfirmationModal>
      )}
    </Box>
  );
};
export default ContractStatusChangeButtons;
