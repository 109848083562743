import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";
import Search from "@mui/icons-material/Search";
import {
  Autocomplete,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  styled,
} from "@mui/material";
import UOMAutocomplete from "components/autocomplete/UOMAutoComplete";
import CurrencyTextField from "components/CurrencyTextField";
import DateSelector from "components/DateSelector";
import NumberTextField from "components/NumberTextField";
import DisablePropagation from "components/table/cell-contents/DisablePropagation";
import { isValid, isBefore, isAfter } from "date-fns";
import { Formik, FormikHelpers } from "formik";
import { Dispatch, FC, Fragment, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import IAccessory from "store/models/Accessory";
import { Contract } from "store/models/Contract";
import { selectAssets } from "store/slices/assetSlice";
import { fCurrency } from "utils/formatNumber";
import { accessoryValidationSchema } from "utils/formikAPI";
import { toMMDDYYYY, MMDDYYYY, titleCase } from "utils/util";

const TableHeader = styled(MuiTableCell)(() => ({
  padding: 3,
}));

const TableCell = styled(MuiTableCell)(() => ({
  padding: 0,
  paddingLeft: 8,
}));

interface Props {
  contract: Contract;
  editAccessory: (asset: IAccessory, formikHelpers: FormikHelpers<IAccessory>, setSelectedIndex: Dispatch<SetStateAction<number | undefined>> ) => void;
  removeAccessory: (asset: IAccessory, { setSubmitting }: { setSubmitting: FormikHelpers<IAccessory>["setSubmitting"], setSelectedIndex: Dispatch<SetStateAction<number | undefined>>}) => void;
  isCreatingReservation: boolean;
  disableEditing: boolean;
  sx?: SxProps;
}
const EditableAccessoriesTable: FC<Props> = ({
  contract,
  sx,
  editAccessory,
  removeAccessory,
  isCreatingReservation,
  disableEditing,
}) => {
  const { accessories } = contract;
  const [page, setPage] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const pageStartIndex = page * rowsPerPage;
  const pageEndIndex = page * rowsPerPage + rowsPerPage;
  const assets = useSelector(selectAssets);
  const assetOptions = contract.assetDeliverables.map(({ asset }) => assets[asset]);

  const visibleAccessories = accessories.filter((accessory) => {
    const asset = accessory.asset ? assets[accessory.asset] : undefined;
    switch(true){
      case !Boolean(searchText):
      case accessory.name.toLowerCase().includes(searchText.toLowerCase()):
      case asset && asset.serialNumber.toLowerCase().includes(searchText.toLowerCase()):
      case accessory.customBillingStart?.toLowerCase().includes(searchText.toLowerCase()):
      case accessory.customBillingEnd?.toLowerCase().includes(searchText.toLowerCase()):
        return true
      default: 
        return false
    }
  }) 

  const accessoriesOnPage = visibleAccessories.slice(pageStartIndex, pageEndIndex)
  const fillerCells = hasSecondPage() ? [...Array(rowsPerPage - accessoriesOnPage.length).keys()] : [];
  function hasSecondPage() {
    return accessories.length > rowsPerPage;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer sx={sx}>
      <Table sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Asset</TableHeader>
            <TableHeader>Quantity</TableHeader>
            <TableHeader>UoM</TableHeader>
            {!isCreatingReservation && (
              <>
                <TableHeader>Rate</TableHeader>
                <TableHeader>Billing Start</TableHeader>
                <TableHeader>Billing End</TableHeader>
              </>
            )}
            <TableHeader>
              <TextField
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                color="primary"
                size="small"
                variant="standard"
                InputProps={{
                  startAdornment: <Search />,
                }}
              />
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{ "& > tr": { cursor: "pointer", height: "52px", py: 0 } }}
        >
          {accessoriesOnPage.map((accessory, index) => {
            return (
              <Formik
                initialValues={accessory}
                validationSchema={accessoryValidationSchema}
                onSubmit={(values, formikHelpers) => editAccessory(values, formikHelpers, setSelectedIndex)}
                key={index}
              >
                {({
                  isSubmitting,
                  values,
                  errors,
                  touched,
                  setValues,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setSubmitting,
                }) => {
                  const handleCustomBillingStartChange =
                    (
                      values: IAccessory,
                      setValues: (
                        values: SetStateAction<IAccessory>,
                        shouldValidate?: boolean | undefined
                      ) => void
                    ) =>
                    (date: unknown) => {
                      const newDate = date ? toMMDDYYYY(date as Date) : null;
                      const customBillingEnd =
                        newDate &&
                        isValid(values.customBillingEnd) &&
                        isBefore(
                          new Date(values.customBillingEnd as MMDDYYYY),
                          new Date(newDate)
                        )
                          ? newDate
                          : values.customBillingEnd;
                      setValues(
                        {
                          ...values,
                          customBillingStart: newDate,
                          customBillingEnd,
                        },
                        false
                      );
                    };
                  const handleCustomBillingEndChange =
                    (
                      values: IAccessory,
                      setValues: (
                        values: SetStateAction<IAccessory>,
                        shouldValidate?: boolean | undefined
                      ) => void
                    ) =>
                    (date: unknown) => {
                      const newDate = date ? toMMDDYYYY(date as Date) : null;
                      const customBillingStart =
                        newDate &&
                        isValid(values.customBillingStart) &&
                        isAfter(
                          new Date(values.customBillingStart as MMDDYYYY),
                          new Date(newDate)
                        )
                          ? newDate
                          : values.customBillingStart;
                      setValues(
                        {
                          ...values,
                          customBillingEnd: newDate,
                          customBillingStart,
                        },
                        false
                      );
                    };
                  return selectedIndex === index ? (
                    <ClickAwayListener
                      onClickAway={() => {
                        if(disableEditing) return;
                        setSelectedIndex(undefined)
                        handleSubmit();
                      }}
                      key={index}
                    >
                      <TableRow>
                        <TableCell>{accessory.name}</TableCell>
                        <TableCell>
                          <Autocomplete
                            value={values.asset ? assets[values.asset] : null}
                            onChange={(_, asset) => {
                              setFieldValue("asset", asset?._id || null);
                            }}
                            getOptionLabel={(asset) => `${asset.serialNumber} - ${titleCase(asset.sizeCode)}`}
                            options={assetOptions}
                            placeholder="Asset"
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="asset"
                                margin="none"
                                placeholder="Asset"
                                error={Boolean(touched.asset && errors.asset)}
                              />
                            )}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <NumberTextField
                            value={values.quantity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="quantity"
                            placeholder="Quantity"
                            size="small"
                            error={Boolean(touched.quantity && errors.quantity)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <UOMAutocomplete
                            value={values.unitOfMeasure}
                            onChange={(_, uom) => setFieldValue("unitOfMeasure", uom)}
                            placeholder="Unit of Measure"
                            name="unitOfMeasure"
                            error={Boolean(touched.unitOfMeasure && errors.unitOfMeasure)}
                            fullWidth
                            disableClearable
                          />
                        </TableCell>
                        {!isCreatingReservation && (
                          <>
                            <TableCell>
                              <CurrencyTextField
                                value={values.rate}
                                onChange={(event) => {
                                  setFieldValue("rate.amount", Number(event.target.value) || undefined);
                                }}
                                onCurrencyChange={(event) => {
                                  event.target.value &&
                                  setFieldValue("rate.currency", event.target.value);
                                }}
                                error={Boolean(touched.rate && errors.rate)}
                                placeholder="Rate"
                                size="small"
                                fullWidth
                              />
                            </TableCell>
                            <TableCell>
                              <DateSelector
                                value={values.customBillingStart}
                                onChange={handleCustomBillingStartChange(values, setValues)}
                                label="Billing Start"
                                textFieldProps={{
                                  size: "small",
                                  fullWidth: true,
                                  error: Boolean(
                                    touched.customBillingStart && errors.customBillingStart
                                  ),
                                  "data-testid": "customBillingStart",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <DateSelector
                                value={values.customBillingEnd}
                                onChange={handleCustomBillingEndChange(values, setValues)}
                                label="Billing End"
                                textFieldProps={{
                                  size: "small",
                                  fullWidth: true,
                                  error: Boolean(
                                    touched.customBillingEnd && errors.customBillingEnd
                                  ),
                                  "data-testid": "customBillingEnd",
                                }}
                              />
                            </TableCell>
                          </>
                        )}
                        <TableCell>
                          {isSubmitting ?
                            <CircularProgress size={24} /> : (
                            <>
                              <IconButton disabled={isSubmitting} color="primary" onClick={() => !disableEditing && handleSubmit()}>
                                <Save />
                              </IconButton>
                              <IconButton disabled={isSubmitting} color="error" onClick={() => removeAccessory(accessory, { setSubmitting, setSelectedIndex })}>
                                <Delete />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    </ClickAwayListener>
                  ) : (
                    <TableRow onClick={() => !disableEditing && setSelectedIndex(index)} key={index}>
                      <TableCell>{accessory.name}</TableCell>
                      <TableCell>
                        {accessory.asset && assets[accessory.asset].serialNumber}
                      </TableCell>
                      <TableCell>{accessory.quantity}</TableCell>
                      <TableCell>{accessory.unitOfMeasure}</TableCell>
                      {!isCreatingReservation && (
                        <>
                          <TableCell>
                            {fCurrency(accessory.rate?.amount)}{" "}
                            {accessory.rate?.currency}
                          </TableCell>
                          <TableCell>{accessory.customBillingStart}</TableCell>
                          <TableCell>{accessory.customBillingEnd}</TableCell>
                        </>
                      )}
                      <TableCell>
                        {!disableEditing && (
                          <DisablePropagation>
                            <IconButton
                              disabled={isSubmitting}
                              color="error"
                              onClick={() => {
                                setSubmitting(true);
                                removeAccessory(accessory, {
                                  setSubmitting,
                                  setSelectedIndex,
                                });
                              }}
                            >
                              {isSubmitting ? (
                                <CircularProgress size={24} />
                              ) : (
                                <Delete />
                              )}
                            </IconButton>
                          </DisablePropagation>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }}
              </Formik>
            );
          })}
          {fillerCells.map((_, index) => {
            return (
              <Fragment key={index}>
                <TableRow>
                  <TableCell />
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={accessories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default EditableAccessoriesTable;
