import React, { useEffect, useState } from 'react';
import { Card, Typography } from "@mui/material";
import { selectCurrentAsset } from 'store/slices/assetSlice';
import { useSelector } from 'react-redux';
import api from 'store/api';

interface Props {
}
const AssetAverageRentalRate: React.FC<Props> = () => {
  const currentAsset = useSelector(selectCurrentAsset);
  const [averageRental, changeAverageRentalRate] = useState("");

  useEffect(getAverageRentalRate, [currentAsset._id])

  function getAverageRentalRate() {
    api.assets.averageRentalRate(currentAsset._id, {onData: changeAverageRentalRate})
  };

  return (
    <Card sx={{ px: 3, py: 2 }}>
      <Typography sx={(theme) => ({ marginRight: theme.spacing(2) , color: "text.secondary"})}>{averageRental}</Typography>
    </Card>
  );
};
export default AssetAverageRentalRate;
