import { initializeApp } from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCbqqm7co63LyAXbWozn7sD_x14tIvKrWE',
  authDomain: 'atlas-rental.firebaseapp.com',
  databaseURL: 'https://atlas-rental.firebaseio.com',
  projectId: 'atlas-rental',
  storageBucket: 'atlas-rental.appspot.com',
  messagingSenderId: '916891981772',
  appId: '1:916891981772:web:fd46f2b9cad12293c9af69',
  measurementId: 'G-EFP4J5R4PX',
};

initializeApp(firebaseConfig);