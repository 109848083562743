import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React, { FC, MouseEvent, useState } from "react";

interface Props extends ButtonProps {
  onClick: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => Promise<any>;
}

const AsyncButton: FC<Props & { onClick: ButtonProps["onClick"] }> = ({
  onClick,
  children,
  disabled,
  startIcon,
  ...props
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const handleClick = async (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    setSubmitting(true);
    const onClickPromise = onClick(event);
    await onClickPromise.finally(() => setSubmitting(false));
  };

  return (
    <Button
      {...props}
      onClick={handleClick}
      disabled={isSubmitting || disabled}
      startIcon={startIcon}
    >
      {isSubmitting ? <CircularProgress size={18} /> : children}
    </Button>
  );
};

export default AsyncButton;