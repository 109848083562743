import { useLocation } from "react-router-dom";
import { matchSorter } from "match-sorter";
import "auth/firebaseConfig";
import api from "store/api";
import UserData from "store/models/UserData";
import { Client } from 'store/models/Client';
import { Asset } from 'store/models/Asset';
import { Contract, inactiveContractStatuses } from 'store/models/Contract';
import Movement, { MovementType } from 'store/models/Movement';
import { differenceInDays, format, parse } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { FilterType, Row } from "react-table";
import Address from "store/models/Address";
import { AppDispatch } from "store/store";
import { fetchAllAssetData } from "store/slices/assetSlice";
import { fetchAllClientData } from "store/slices/clientSlice";
import { fetchConfigs } from "store/slices/configSlice";
import { fetchAllUserData } from "store/slices/userSlice";
import { fetchAllBranches, fetchAllYards } from "store/slices/optionSlice";

const daysDifference = (date1: Date, date2: Date) => differenceInDays(new Date(date1), new Date(date2));

const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export type RequireOneOf<T, Keys extends keyof T = keyof T> = Pick< T, Exclude<keyof T, Keys>> & { [K in Keys] - ?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>> }[Keys];

const undefinedOrNull = (valueToCheck: any) => {
  if (Array.isArray(valueToCheck)) {
    for (const value of valueToCheck) {
      if (value === null || value === undefined) {
        return true;
      }
    }
  } else if (valueToCheck === null || valueToCheck === undefined) {
    return true;
  } else {
    return false;
  }
};


export const getUserFullName = (user?: UserData) =>
  user ? `${user.firstName} ${user.lastName}` : "";

export const getUserInitials = (user?: UserData) =>
  user ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}` : "";

const titleCase = (status?: string) => {
  if (!status) return ""
  let result = "";
  status
    .toLowerCase()
    .split(' ')
    .forEach((part) => {
      result += `${part[0].toUpperCase() + part.substring(1, part.length)} `;
    });

  return result.trim();
};

const useQuery = () => new URLSearchParams(useLocation().search);

const getDateFromObjectId = (objectId: string) => {
  const timestamp = objectId.toString().substring(0, 8);
  return new Date(parseInt(timestamp, 16) * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const setCurrentUser = (userDataAsJson: string) => {
  localStorage.setItem('userData', userDataAsJson);
};

const getCurrentUser = () => {
  const userData = localStorage.getItem('userData');
  return userData ? (JSON.parse(userData) as UserData) : null;
};

function fuzzyTextFilter(rows: any, _id: string, filterValue: string) {
  return matchSorter(rows, filterValue, {
    keys: [(row: any) => row.values[_id]],
  });
}

const not = (check: Function | boolean) => {
  if (typeof check === 'function') {
    return !check();
  }
  return !check;
};

// Alias of not()
const notAll = (check: Function | boolean) => not(check);

const startDateIsValid = (
  startDate: Date | string,
  outDate: Date | string,
  endDate: Date | string | null | undefined,
  evergreen?: boolean,
  isSale?: boolean,
) => {
  // start date cannot come before out date
  if (new Date(startDate) < new Date(outDate)) {
    return false;
  }

  // Guard against null end date
  if (endDate && !evergreen && !isSale) {
    // start date cannot come after endDate unless evergreen
    if (new Date(startDate) >= new Date(endDate)) {
      return false;
    }
  }

  return true;
};

const outDateIsValid = (outDate: Date | string, startDate: Date | string) => {
  // out date cannot be after start date
  if (new Date(outDate) > new Date(startDate)) {
    return false;
  }

  return true;
};

const endDateIsValid = (
  endDate: Date | string | null | undefined,
  startDate: Date | string,
  evergreen: boolean | undefined,
  isSale: boolean
) => {
  if (isSale) {
    return true;
  }
  // Guard against null end date, evergreen contracts can skip the contained check
  if (endDate && !evergreen) {
    // end date cannot be before start date unless evergreen
    if (new Date(endDate) <= new Date(startDate)) {
      return false;
    }
  }

  return true;
};

const isPhoneNumberValid = (
  phoneNumber: string,
): { valid: boolean; reason: string } => {
  switch (true) {
    case phoneNumber.replace(/[^0-9]/g, '').length !== 11:
      return { valid: false, reason: 'Should contain 10 numbers' };
    default:
      return { valid: true, reason: '' };
  }
};

const parsePhoneNumberOfDubiousLength = (phoneNumber = '') => {
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

  let formattedPhoneNumber = '';

  if (phoneNumber.length === 0) {
    return '';
  }

  if (phoneNumber.length <= 10) {
    tenOrLessDigitNumber(phoneNumber);
    return formattedPhoneNumber;
  }

  if (phoneNumber.length >= 11) {
    elevenDigitNumber(phoneNumber);
    return formattedPhoneNumber;
  }

  //* * Formats the user input as "(231) 231-2312" */
  function tenOrLessDigitNumber(phoneNumber: string) {
    if (phoneNumber[0]) {
      formattedPhoneNumber += ' (';
      formattedPhoneNumber += phoneNumber[0] || '';
    }

    if (phoneNumber[1]) {
      formattedPhoneNumber += phoneNumber[1];
    }

    if (phoneNumber[2]) {
      formattedPhoneNumber += phoneNumber[2];
    }

    if (phoneNumber[3]) {
      formattedPhoneNumber += ') ';
      formattedPhoneNumber += phoneNumber[3];
    }

    if (phoneNumber[4]) {
      formattedPhoneNumber += phoneNumber[4];
    }

    if (phoneNumber[5]) {
      formattedPhoneNumber += phoneNumber[5];
    }

    if (phoneNumber[6]) {
      formattedPhoneNumber += '-';
      formattedPhoneNumber += phoneNumber[6];
    }

    if (phoneNumber[7]) {
      formattedPhoneNumber += phoneNumber[7];
    }

    if (phoneNumber[8]) {
      formattedPhoneNumber += phoneNumber[8];
    }

    if (phoneNumber[9]) {
      formattedPhoneNumber += phoneNumber[9];
    }
  }

  //* * Formats the user input as "+1 (231) 231-2312" */
  function elevenDigitNumber(phoneNumber: string) {
    if (phoneNumber[0]) {
      formattedPhoneNumber += '+';
      formattedPhoneNumber += phoneNumber[0];
    }

    if (phoneNumber[1]) {
      formattedPhoneNumber += ' (';
      formattedPhoneNumber += phoneNumber[1] || '';
    }

    if (phoneNumber[2]) {
      formattedPhoneNumber += phoneNumber[2];
    }

    if (phoneNumber[3]) {
      formattedPhoneNumber += phoneNumber[3];
    }

    if (phoneNumber[4]) {
      formattedPhoneNumber += ') ';
      formattedPhoneNumber += phoneNumber[4];
    }

    if (phoneNumber[5]) {
      formattedPhoneNumber += phoneNumber[5];
    }

    if (phoneNumber[6]) {
      formattedPhoneNumber += phoneNumber[6];
    }

    if (phoneNumber[7]) {
      formattedPhoneNumber += '-';
      formattedPhoneNumber += phoneNumber[7];
    }

    if (phoneNumber[8]) {
      formattedPhoneNumber += phoneNumber[8];
    }

    if (phoneNumber[9]) {
      formattedPhoneNumber += phoneNumber[9];
    }

    if (phoneNumber[10]) {
      formattedPhoneNumber += phoneNumber[10];
    }
  }

  return formattedPhoneNumber;
};

const parsePhoneNumber = (phoneNumber = '') => {
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

  let formattedPhoneNumber = '';

  if (phoneNumber.length === 0) {
    return phoneNumber;
  }

  //* * Formats the user input as "+1 (231) 231-2312" */

  if (phoneNumber[0]) {
    formattedPhoneNumber += '+';
    formattedPhoneNumber += phoneNumber[0];
  }

  if (phoneNumber[1]) {
    formattedPhoneNumber += ' (';
    formattedPhoneNumber += phoneNumber[1] || '';
  }

  if (phoneNumber[2]) {
    formattedPhoneNumber += phoneNumber[2];
  }

  if (phoneNumber[3]) {
    formattedPhoneNumber += phoneNumber[3];
  }

  if (phoneNumber[4]) {
    formattedPhoneNumber += ') ';
    formattedPhoneNumber += phoneNumber[4];
  }

  if (phoneNumber[5]) {
    formattedPhoneNumber += phoneNumber[5];
  }

  if (phoneNumber[6]) {
    formattedPhoneNumber += phoneNumber[6];
  }

  if (phoneNumber[7]) {
    formattedPhoneNumber += '-';
    formattedPhoneNumber += phoneNumber[7];
  }

  if (phoneNumber[8]) {
    formattedPhoneNumber += phoneNumber[8];
  }

  if (phoneNumber[9]) {
    formattedPhoneNumber += phoneNumber[9];
  }

  if (phoneNumber[10]) {
    formattedPhoneNumber += phoneNumber[10];
  }

  return formattedPhoneNumber;
};

/**
 * @function
 * Automates a click on a provided blob file causing it to be downloaded
 * @param blobPart
 */

const downloadBlob = (blobPart: BlobPart, downloadFileName: string) => {
  const csvBlob = new Blob([blobPart], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(csvBlob);
  link.download = downloadFileName;
  link.click();
};

const mapToObjectIdOnly = <T extends {_id: string}>(document: T) => document._id;

const downloadAssetPdf = (assetId: string, changeCurrentlySubmitting: (isSubmitting: boolean) => void) => {
  api.assets.asPdf(assetId, {
    onData: download,
    currentlySubmitting: changeCurrentlySubmitting,
  });

  function download(res: {data: any}) {
    const pdfAsArrayBuffer = res.data;
    const pdfAsBlob = new Blob(
      [new Uint8Array(pdfAsArrayBuffer, 0, pdfAsArrayBuffer.length)],
      { type: 'application/pdf' },
    );

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(pdfAsBlob);
    link.download = 'asset.pdf';
    link.click();
  }
};

const contractLength = (startDate?: MMDDYYYY, endDate?: MMDDYYYY | null) => {
  if (!startDate) {
    return
  }
  // Contracts without a definite end date should display their length so far
  if (!endDate) {
    // Only display length of evergreen contract if it's already started
    if (dateFromMMDDYYYY(startDate) > new Date()) {
      return "Contract not started yet";
    } else {
      return `${differenceInDays(
        new Date(),
        dateFromMMDDYYYY(startDate)
      )} Days so far (Evergreen)`;
    }
  } else if (startDate && endDate) {
    return `${differenceInDays(dateFromMMDDYYYY(endDate), dateFromMMDDYYYY(startDate))} Days`;
  } else {
    return "??";
  }
};

function clientTableColumnFilter<T extends {client: string}>(clients: {[_id: string]: Client}): FilterType<T> {
  return (
    rows: Row<T>[],
    accessor: string[],
    searchValue: string
  ) => {
    return searchValue
    ? rows.filter((row) =>
        clients[`${row.original.client}`]?.companyName.toLowerCase().includes(
          searchValue.toLowerCase()
        )
      )
    : rows;
  }
}

function assetSerialTableColumnFilter<T extends {asset: string | null | undefined}>(assets: {[_id: string]: Asset}): FilterType<T> {
  return (
    rows: Row<T>[],
    accessor: string[],
    searchValue: string
  ) => {
    return searchValue
    ? rows.filter((row) =>
      assets[`${row.original.asset}`]?.serialNumber.includes(
          searchValue
        )
      )
    : rows;
  }
}

function determineDefaultMovementStatus(contract: Contract | undefined) {
  switch (true) {
    case contract?.status === "PENDING DELIVERY":
      return MovementType.out;
    case contract?.status === "ACTIVE":
      return MovementType.in;
    default:
      return MovementType.out;
  }
}

function toLocalDatePretty(date?: Date) {
  return date ? toMMDDYYYY(toLocalDate(date)) : "";
}

function toLocalDate(date: Date) {
  return utcToZonedTime(date, getTimezone());
}

function localDateToUTC(date: Date | string) {

  if (typeof date === "string") {
    return parse(date, "MM-dd-yyyy", new Date())
  } else {
    return zonedTimeToUtc(date, getTimezone())
  }

}

function parseSizeCode(sizeCode: string | undefined) {
  let splitSizeCode;
  if (sizeCode && sizeCode.includes("X")) {
    splitSizeCode = sizeCode
      // Separate the size code into its two numbers
      .split(/[^0-9]+/)
      .map((value: string) => Number(value));

      // Unless we get two numbers perfectly we'll assume something messed up
      if (splitSizeCode.length !== 2) {
        splitSizeCode = [];
      }
  }
  return {
    width: splitSizeCode?.[0] || 0,
    length: splitSizeCode?.[1] || 0,
    height: 0,
  };
}

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', "Oct", "Nov", "Dec"];

function dateFromObjectId(objectId: string) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export type MMDDYYYY = `${number}${number}-${number}${number}-${number}${number}${number}${number}` | string;
export type DDMMMYYYY = `${number}${number}-${number}${number}-${number}${number}${number}${number}` | string;

export function isMMDDYYYY(date?: string) {
  return date ? /\d{2}-\d{2}-\d{4}/.test(date) : true
}

function toMMDDYYYY(date: Date | string) {
  return format(new Date(date), "MM-dd-yyyy") as MMDDYYYY;
}

export function dateFromMMDDYYYY(date: MMDDYYYY) {
    const [month, day, year] = date.split("-");
    return new Date(`${year}-${month}-${day}T00:00:00`);
}

export function toDDMMMYYYY(date: any) {
  try {
    return date ? format(new Date(date), "dd-MMM-yyyy") as DDMMMYYYY : null;
  } catch (error) {
    return "00-000-0000"
  }
}

export function toPrettyDateTime(date: Date | string) {
  return format(new Date(date), "dd-MMM-yyyy, hh:mm a ")
} 

export function isFalsyString (string: string){
  return string === "undefined" || string === "null" || string === "";
}

export function skipFirstElement(_: any, index: number) {
  return index > 0;
}

export function displayAddress(address?: Address) {
  const isGoogleAddress = Boolean(address?.address);
  const isLatLongAddress = Boolean(address?.latLng);
  
  const addPrefix = () => {
    return address?.prefix && `${address?.prefix}, `
  }

  switch(true){
    case isGoogleAddress: 
      return `${addPrefix()}${address?.address}`;
    case isLatLongAddress: 
      return `${address?.latLng?.lat}, ${address?.latLng?.lng}`;
    default: 
      return "No Address Set"
  }
}

export const getFullName = (
  person?: Object & { firstName: string; lastName?: string }
) => person?.firstName ? `${person?.firstName} ${person?.lastName}` : "";

export function refreshData(dispatch: AppDispatch) {
  dispatch(fetchAllUserData());
  dispatch(fetchAllAssetData());
  dispatch(fetchConfigs());
  dispatch(fetchAllClientData());
}

export function refreshOptionsData(dispatch: AppDispatch) {
  dispatch(fetchAllBranches())
  dispatch(fetchAllYards());
}

export function contractInEndedStatus(status: Contract["status"]) {
  return inactiveContractStatuses.includes(status)
}

export const assignObjectValuesForKeys = <T,K extends keyof T>(targetObject: T,referenceObject: T, keysToCopy: K[], ) => {
  // copy the values of [keys] from Reference Object to Target Object
  const newObj = {...targetObject} as T
  for (const key of keysToCopy) {
    newObj[key] = referenceObject[key]
  }
  return newObj
}

export function isOfDocumentType<T extends { _id: string }>(document: any): document is T{
  return (document as T)?._id !== undefined;
}

export function IsTypeOfContract<T extends { _id: string }>(contract: any): contract is T {
  return isOfDocumentType<Contract>(contract)
}

export function create12MonthsArray(fill?: number) {
  const monthsArray =  Array(12)
  fill || fill === 0 && monthsArray.fill(fill)
  return monthsArray
}

export type Narrowable =
  | string
  | number
  | boolean
  | symbol
  | object
  | {}
  | void
  | null
  | undefined;
  
export const typedTuple = <T extends Narrowable[]>(...args: T) => args;

export const wordOrderInsensitivePartialObjectMatch = (
  searchValue: string,
  comparisonObject: Object
) => {
  const words = searchValue.split(" ").filter((word) => Boolean(word));
  let comparisonStringArr: string[] = [];
  Object.keys(comparisonObject).forEach((key) => {
    ["string", "number"].includes(
      typeof comparisonObject[key as keyof typeof comparisonObject]
    ) &&
      comparisonStringArr.push(
        String(comparisonObject[key as keyof typeof comparisonObject])
      );
  });

  return words.length
    ? words.every((word) => {
        return comparisonStringArr.some((comparisonWord) => {
          return caseInsensitiveMatch();

          function caseInsensitiveMatch() {
            return String(comparisonWord)
              .toLowerCase()
              ?.includes(word.toLocaleLowerCase());
          }
        });
      })
    : true;
};

function objectIdToDate(_id: string) {
  const timestamp = parseInt(_id.substring(0, 8), 16);

  return new Date(timestamp * 1000);
}

function feetToFeetInches(feet: number) {
  const totalInches = Math.round(feet * 12);
  const feetPart = Math.floor(totalInches / 12);
  const inchesPart = totalInches % 12;
  return `${feetPart}' ${inchesPart}"`;
}


export function sortByDDMMMYYYY(a: Movement, b: Movement) {
  return dateFromMMDDYYYY(a.dateOfMove) > dateFromMMDDYYYY(b.dateOfMove) ? 1 : -1;
}

export const proxyUrl = "https://cors-anywhere-node.herokuapp.com/"

export {
  titleCase,
  undefinedOrNull,
  daysDifference,
  useQuery,
  getDateFromObjectId,
  setCurrentUser,
  getCurrentUser,
  fuzzyTextFilter,
  not,
  notAll,
  startDateIsValid,
  outDateIsValid,
  endDateIsValid,
  isPhoneNumberValid,
  parsePhoneNumber,
  parsePhoneNumberOfDubiousLength,
  downloadBlob,
  mapToObjectIdOnly,
  downloadAssetPdf,
  contractLength,
  clientTableColumnFilter,
  assetSerialTableColumnFilter,
  determineDefaultMovementStatus,
  getTimezone,
  toLocalDatePretty,
  toLocalDate,
  toMMDDYYYY,
  localDateToUTC,
  parseSizeCode,
  dateFromObjectId,
  objectIdToDate,
  feetToFeetInches
};
