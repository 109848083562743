import { getQueryStringFromObject, sendToBackend } from "store/api/util";
import { Asset, blankAsset } from "store/models/Asset";
import {
  Contract,
} from "store/models/Contract";
import { errorHandler } from "./shared";
import { Currency } from "dinero.js";
import { createMongoId } from "utils/createMongoId";
import { AssetStatus } from "store/models/AssetStatus";

const assetsApi = {
  getAll: (actions?: {
    onData?: (data: Asset[]) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }) => {
    sendToBackend<Asset[]>({
      route: "asset/",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  
  createOne: (
    asset: Omit<Asset, "_id">,
    actions?: {
      onData?: (data: Asset) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend({
      body: asset,
      method: "POST",
      route: "asset/",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  createMany: (
  file: FormData,
  actions?: {
    onData?: (data: Asset[]) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }
) => {
  sendToBackend({
    file,
    method: "POST",
    route: "asset/createMany",
    onData: actions?.onData,
    onError: (message: string) => { errorHandler(message, actions?.onError); },
    onSuccess: actions?.onComplete,
    currentlySubmitting: actions?.currentlySubmitting,
  });
},
  updateOne: (
    asset: {_id: Asset["_id"]} & Partial<Asset>,
    reasonForUpdate: string,
    actions?: {
      onData?: (data: Asset) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend({
      route: "asset/",
      body: {asset, clientChangeDate: new Date(), reason: reasonForUpdate},
      method: "PUT",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateOneStatus: (
    asset: {_id: Asset["_id"]} & Partial<Asset>,
    reasonForUpdate: string,
    actions?: {
      onData?: (data: Asset) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend({
      route: "asset/status",
      body: {asset, clientChangeDate: new Date(), reason: reasonForUpdate},
      method: "PATCH",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getOne: (
    assetId: string,
    actions?: {
      onData?: (data: Asset) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Asset>({
      route: `asset/specificAsset?assetId=${assetId}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getContractHistory: (
    query: {assetId: string, limit: number},
    actions?: {
      onData?: (data: {contracts: Contract[], totalRecords: number}) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
    }
  ) => {
    sendToBackend<{contracts: Contract[], totalRecords: number}>({
      route: `asset/assetContractHistory?${getQueryStringFromObject(query)}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
    });
  },
  getLastContract: (
    assetId: string,
    actions?: {
      onData?: (data: Contract | null) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
    }
  ) => {
    sendToBackend<Contract | null>({
      route: `asset/assetCurrentContract?assetId=${assetId}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
    });
  },
  asPdf: (
    assetId: string,
    actions?: {
      onData?: (data: {data: any}) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{data: any}>({
      route: `asset/asPdf?assetId=${assetId}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  asCsv: (
    assetIds: string[],
    actions?: {
      onData?: (data: BlobPart) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<BlobPart>({
      body: assetIds,
      route: "asset/exportAssetsToCsv",
      method: "POST",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  averageRentalRate: ( 
    assetId: Asset["_id"],
    actions?: {
      onData?: (data: string) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<string>({
      route: `asset/averageRentalRate?assetId=${assetId}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getIdleAssets: ( 
    actions?: {
      onData?: (data: Asset[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Asset[]>({
      route: `asset/idleAssets`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  queryAssets: ( 
    query: Object,
    actions?: {
      onData?: (data: Asset[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Asset[]>({
      route: `asset/query`,
      method: "POST",
      body: {query},
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },

};

export function initAsset (assetProps?: Partial<Asset> & {currency: Currency}){
  return {
    ...blankAsset({ currency: "CAD" }),
    _id: createMongoId(),
    ...assetProps,
  };
}

export const reserveableAssetStatuses: AssetStatus[] = ["AVAILABLE"];
export const moveableAssetStatuses: AssetStatus[] = ["RESERVED", "ON LEASE", "SOLD PENDING DELIVERY"];

export default assetsApi;
