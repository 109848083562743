import React, { useEffect, useRef, useState } from "react";
import Message from "components/chat/Message";
import { useSelector } from "react-redux";
import {
  TextField,
  IconButton,
  Box,
  BoxProps,
  CircularProgress,
  styled,
} from "@mui/material";
import Send from "@mui/icons-material/Send";
import { IChat, IMessage, initMessage, IPopulatedMessage } from "store/models/Chat";
import { selectLoggedInUser } from "store/slices/systemSlice";
import Scrollbar from "components/Scrollbar";

const ChatInputBox = styled("div")(() => ({
  minHeight: 56,
  width: "100%",
  display: "flex",
  position: "relative",
  alignItems: "center",
}));

interface Props extends Omit<BoxProps, "onSubmit"> {
  chat: IChat;
  label: string;
  onSubmit: (message: IMessage) => void;
  disabled?: boolean;
  isFetching?: boolean;
}

const Chat: React.FC<Props> = ({
  chat,
  label,
  onSubmit,
  disabled,
  isFetching,
}) => {
  const [message, setMessage] = useState("");
  const loggedInUser = useSelector(selectLoggedInUser);
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: any) => {
    setMessage(event?.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (messageIsEmpty() || !loggedInUser) return;
    onSubmit(initMessage({ message: message.trim(), user: loggedInUser._id }));
    setMessage("");
  };

  function messageIsEmpty() {
    return !message.trim();
  }


  useEffect(() => {
    scrollRef.current?.scrollTo({ top: scrollRef.current.scrollHeight, behavior: "smooth" })
  }, [chat]);

  return (
    <>
      <Box sx={{ width: "100%", height: "calc(100vh - 380px)" }}>
        {isFetching ? (
          <Box sx={{ height: "65vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
            {chat.messages.map((message: IPopulatedMessage) => (
              <Message key={message._id} message={message} />
            ))}
          </Scrollbar>
        )}
      </Box>
      <ChatInputBox
        sx={{
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <form
          style={{
            width: "calc(100% - 34px)",
            marginTop: 20,
          }}
          onSubmit={handleSubmit}
        >
          <Box>
            <TextField
              variant="outlined"
              label={label}
              fullWidth
              multiline
              size="small"
              maxRows={3}
              value={message}
              onChange={handleChange}
              autoComplete="off"
              disabled={disabled}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSubmit(event);
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ height: 20 }}
                    size="small"
                    type="submit"
                    disabled={messageIsEmpty()}
                  >
                    <Send />
                  </IconButton>
                ),
              }}
            />
          </Box>
        </form>
      </ChatInputBox>
    </>
  );
};

export default Chat;
