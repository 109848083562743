import React from "react";
import { useSelector } from "react-redux";
import {
  selectBladeMode,
  setBladeMode,
  setGlobalMessage,
} from "store/slices/systemSlice";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import { CircularProgress, IconButton, Tooltip, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Blade from "components/Blade";
import TransportCompanyForm from "./TransportCompanyForm";
import { Form, Formik, FormikHelpers } from "formik";
import { useAppDispatch } from "store/store";
import { TransportCompany } from "store/slices/transportCompanySlice";
import { useCreateTransportCompanyMutation } from "store/services/transportCompany";
import {
  initTransportCompany,
  transportCompanyValidationSchema,
} from "store/api/transportCompany";

interface Props {}

const TransportCompanyCreateBlade: React.FC<Props> = () => {
  const bladeMode = useSelector(selectBladeMode);
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT);
  const userCanCreateTransportCompanies = checkMovementPermissions(
    ActionType.CREATE
  );
  const [createTransportCompany] = useCreateTransportCompanyMutation();
  const initialValues = initTransportCompany({});

  const dispatch = useAppDispatch();

  const handleSubmit = async (
    transportCompany: TransportCompany,
    { setSubmitting, resetForm }: FormikHelpers<TransportCompany>
  ) => {
    try {
      if (!userCanCreateTransportCompanies) {
        dispatch(
          setGlobalMessage({
            messageText:
              "You do not have permission to create transport companies",
            severity: "error",
            show: true,
          })
        );
        return;
      }
      setSubmitting(true);
      await createTransportCompany(transportCompany).unwrap();
      setSubmitting(false);
      dispatch(setBladeMode("none"));
      resetForm();
      dispatch(
        setGlobalMessage({
          messageText: "Successfully Created Transport Company",
          show: true,
        })
      );
    } catch (error: any) {
      setSubmitting(false);
      dispatch(
        setGlobalMessage({
          messageText: error.data.message,
          severity: "error",
          show: true,
        })
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={transportCompanyValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, resetForm, submitForm }) => {
        const closeBlade = () => {
          dispatch(setBladeMode("none"));
          resetForm();
        };

        const bladeActions = () => [
          <Tooltip title="Save" key="save">
            <IconButton color="primary" onClick={submitForm} key="submitUpdate">
              {isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />}
            </IconButton>
          </Tooltip>,
        ];

        return (
          <Form>
            <Blade
              title="Create Transport Company"
              actions={bladeActions()}
              open={bladeMode === "transportCompanyCreate"}
              changeOpen={closeBlade}
              onTop={bladeMode === "transportCompanyCreate"}
            >
              <TransportCompanyForm />
              <Button
                startIcon={
                  isSubmitting ? <CircularProgress size={24} /> : <SaveIcon />
                }
                disabled={isSubmitting}
                variant="contained"
                onClick={submitForm}
              >
                Save
              </Button>
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TransportCompanyCreateBlade;
