import { onAuthStateChanged, getAuth } from "firebase/auth"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  loginUser,
  setGlobalMessage,
  selectLoggedInUser,
  setLoggedInUserId,
  setLoggedInUserLoading,
} from "store/slices/systemSlice";
import { useAppDispatch } from 'store/store';

const useAuthController = () => {
  const loggedInUser = useSelector(selectLoggedInUser);
  const dispatch = useAppDispatch();
  const [reconnectCount, setReconnectCount] = useState(0);


  useEffect(() => {
    let timeout: NodeJS.Timeout; 

    if(reconnectCount === 3) {
      dispatch(setLoggedInUserLoading(false));
      dispatch(
        setGlobalMessage({
          messageText: "Could Not Connect To Server",
          show: true,
          severity: "error",
          duration: 2000,
        })
      );
    }
  
    const auth = getAuth();
    const unsubscribeFromAuthChanges = onAuthStateChanged(auth, (user) => {
      timeout = setTimeout(() => {
        if(!loggedInUser){
          setReconnectCount(count => count + 1)
        }
      }, 5000)

      function autoLogin() {
        if (user?.email && !loggedInUser) {
          dispatch(loginUser);
        }
      }

      if (user && reconnectCount < 3) {
        autoLogin();
      } else if (!loggedInUser) {
        dispatch(setLoggedInUserId(undefined));
        dispatch(setLoggedInUserLoading(false))
      }
    });
    
    return () => {
      clearTimeout(timeout)
      unsubscribeFromAuthChanges()
    };
  }, [reconnectCount]);
};
export default useAuthController;
