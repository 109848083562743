import React from "react";
import { useSelector } from "react-redux";
import { selectAssets } from "store/slices/assetSlice";
import { selectContracts } from "store/slices/contractSlice";
import { determineTotalNumberOfContractActionsRequired } from "utils/setActionRequiredOnContracts";

const useSelectNumberOfActionsRequired = () => {
  const assets = useSelector(selectAssets);
  const contracts = useSelector(selectContracts);
  const numberOfActionsRequired = React.useMemo(
    () => determineTotalNumberOfContractActionsRequired(contracts, assets),

    [contracts, assets]
  );

  return numberOfActionsRequired;
};

export default useSelectNumberOfActionsRequired;
