import { ButtonGroup, Button, ButtonGroupProps, SxProps } from '@mui/material';
import { addMonths } from 'date-fns';
import React from 'react';

interface Props extends ButtonGroupProps {
  date: Date;
  changeDate: (newDateString: Date) => void;
  disabled?: boolean;
  sx?: SxProps
}

const DateIncrementor: React.FC<Props> = ({
  date,
  changeDate,
  disabled = false,
  sx,
  ...rest
}) => {
  const updateDate = (amount: number) => {
    if (disabled) return;
    changeDate(addMonths(new Date(date), amount));
  };

  return (
    <ButtonGroup sx={sx} variant="outlined" fullWidth {...rest}>
      <Button onClick={() => updateDate(1)}>+1 Month</Button>
      <Button onClick={() => updateDate(3)}>+3 Month</Button>
      <Button onClick={() => updateDate(6)}>+6 Month</Button>
      <Button onClick={() => updateDate(12)}>+1 Year</Button>
    </ButtonGroup>
  );
};
export default DateIncrementor;
