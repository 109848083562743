import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentContract,
  setContract,
} from "store/slices/contractSlice";
import api from "store/api";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import AssetMovements from "./AssetMovements";
import {
  selectAssets,
  setCurrentAssetId,
} from "store/slices/assetSlice";
import { dateFromMMDDYYYY, isMMDDYYYY, titleCase, toDDMMMYYYY, toMMDDYYYY, useQuery } from "utils/util";
import { isValid } from "date-fns";
import usePermissions, {
  ActionType,
  FeatureType,
} from "hooks/usePermissions";
import {
  Box,
  CardHeader,
  Typography,
  Card,
  CircularProgress,
  CardContent,
  Button,
  Chip,
  IconButton,
} from "@mui/material";
import DateSelector from "components/DateSelector";
import { setBladeMode } from "store/slices/systemSlice";
import { AddAssetModalMode, AssetTableFilters } from "components/modals/AddAssetModal";
import { useAppDispatch } from 'store/store';
import { fCurrency } from "utils/formatNumber";
import Dot from "components/Dot";
import Movement from "store/models/Movement";
import Edit from "@mui/icons-material/Edit";
import CurrencyTextField from "components/CurrencyTextField";
import { Currency } from "dinero.js";
import CopyClipboard from "components/CopyClipboard";
import { GridFilterModel } from "@mui/x-data-grid-pro";

interface Props {
  assetDeliverable: AssetDeliverable;
  sameBillingStart: boolean;
  sameBillingEnd: boolean;
  index: number;
  setAddAssetMode: (mode: AddAssetModalMode, filters?: GridFilterModel, assetDeliverable?: AssetDeliverable) => void;
  setNewMovements: Dispatch<SetStateAction<Movement[]>>;
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

const ContractAsset: React.FC<Props> = ({
  assetDeliverable,
  sameBillingStart,
  sameBillingEnd,
  index,
  setAddAssetMode,
  setNewMovements,
  changeIsSubmitting,
}) => {
  const currentContract = useSelector(selectCurrentContract);
  const [unsavedAssetDeliverable, changeUnsavedAssetDeliverable] = useState<AssetDeliverable>(assetDeliverable);
  const [highlightAsset, changeHighlightAsset] = useState(false);
  const query = useQuery();
  const dispatch = useAppDispatch();
  const [isEditing, setEditing] = useState(false);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);

  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const userCanViewAssets = checkAssetPermissions(ActionType.READ);

  useEffect(() => {
    changeUnsavedAssetDeliverable(assetDeliverable);
  }, [currentContract]);

  useEffect(() => {
    query.get("assetMovementsAccordionToInitOpen") === assetDeliverable.asset &&
      changeHighlightAsset(true);
  }, []);

  const assets = useSelector(selectAssets);
  const asset = assets[assetDeliverable.asset];

  if (!asset) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  const assetMovements = currentContract?.movements.filter((move) => move.asset === asset._id) || [];
  const latestAssetMovement: Movement | undefined = assetMovements[assetMovements.length - 1];

  const requestedDeliveryDate = () => (
    <DateSelector
      data-testid="dateOfMove"
      onChange={onDateOfMoveChange}
      onAccept={handleDateOfMoveAccept}
      value={toDDMMMYYYY(dateFromMMDDYYYY(unsavedAssetDeliverable.deliveryDate))}
      label="Delivery Date"
      textFieldProps={{
        size: "small",
        fullWidth: true,
      }}
    />
  );

  const nextMovement = () => {
    return (
      <Box sx={{whiteSpace: "nowrap", width: "100%", ml: 0.2}}>
        {latestAssetMovement?.dateOfMove
          ? `${latestAssetMovement?.type}: ${toDDMMMYYYY(dateFromMMDDYYYY(latestAssetMovement?.dateOfMove))}` : "???"}
      </Box>
    );
  };

  function onDateOfMoveChange(date: unknown, enteredDate: string | undefined) {
    if (!currentContract) return;
    const newDate = date as Date;
    if (isMMDDYYYY(enteredDate) && isValid(newDate) && userCanEditContracts) {
      changeUnsavedAssetDeliverable((previousValues) => ({
        ...previousValues,
        deliveryDate: toMMDDYYYY(newDate),
      }));

      changeIsSubmitting(true)
      
      api.contracts.updateAssetDeliverables(currentContract._id, [{...unsavedAssetDeliverable, deliveryDate: toMMDDYYYY(newDate) }], {
        onData: (data) => dispatch(setContract(data)),
        onComplete: () => changeIsSubmitting(false),
        onError: () => changeIsSubmitting(false)
      });
    }
  }

  const handleDateOfMoveAccept = (date: unknown) => {
    const newDate = date as Date;
    changeUnsavedAssetDeliverable((previousValues) => ({
      ...previousValues,
      deliveryDate: toMMDDYYYY(newDate),
    }));
  };

  const handleBillingDateAccept =
    (billingDateKey: "customBillingStart" | "customBillingEnd") =>
    (date: unknown) => {
      const newDate = date as Date;
      changeUnsavedAssetDeliverable((previousValues) => ({
        ...previousValues,
        [billingDateKey]: toMMDDYYYY(newDate),
      }));
    };

  function onBillingDateChange(
    billingDateKey: "customBillingStart" | "customBillingEnd",
    index: number
  ) {
    return function (date: unknown, enteredDate: string | undefined) {
      if (!currentContract) return;
      const newValue = date as Date | null;
      if (newValue === null || (isMMDDYYYY(enteredDate) && isValid(newValue) && userCanEditContracts)) {
        const updatedDeliverables = shouldChangeAllDeliverables()
          ? currentContract.assetDeliverables.map<AssetDeliverable>((deliverable) => {
              return {
                ...deliverable,
                [billingDateKey]: newValue ? toMMDDYYYY(newValue) : newValue,
              };
            })
          : [{...unsavedAssetDeliverable, [billingDateKey]: newValue ? toMMDDYYYY(newValue) : newValue}]; 


        changeUnsavedAssetDeliverable((previousValues) => ({
          ...previousValues,
          [billingDateKey]: newValue ? toMMDDYYYY(newValue) : newValue,
        }));

        changeIsSubmitting(true);

        api.contracts.updateAssetDeliverables(currentContract._id, updatedDeliverables, {
          onData: (data) => dispatch(setContract(data)),
          onComplete: () => changeIsSubmitting(false),
          onError: () => changeIsSubmitting(false)
        });

        function shouldChangeAllDeliverables() {
          return (
            index === 0 &&
            ((billingDateKey === "customBillingEnd" && sameBillingEnd) ||
              (billingDateKey === "customBillingStart" && sameBillingStart))
          );
        }
      }
    };
  }

  function onRentalRateChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
   
    changeUnsavedAssetDeliverable((previousValues) => ({
      ...previousValues,
      rentalRate: {
        ...previousValues.rentalRate,
        amount: Number(target.value) || 0,
      }
    }));
  }

  function onRentalRateCurrencyChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
   
    changeUnsavedAssetDeliverable((previousValues) => ({
      ...previousValues,
      rentalRate: {
        ...previousValues.rentalRate,
        currency: target.value as Currency,
      }
    }));
  }

  function invalidAmount(rate: number){
    return !rate || rate <= 0
  }

  function onRentalRateBlur() {
    if (invalidAmount(unsavedAssetDeliverable.rentalRate.amount || 0) || !currentContract) return;

    changeIsSubmitting(true)

    api.contracts.updateAssetDeliverables(currentContract._id, [unsavedAssetDeliverable], {
      onData: (data) => dispatch(setContract(data)),
      onComplete: () => changeIsSubmitting(false),
      onError: () => changeIsSubmitting(false)
    });
  }

  function onSaleAmountChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    userCanEditContracts &&
      changeUnsavedAssetDeliverable((previousValues) => ({
        ...previousValues,
        saleAmount: {
          ...previousValues.saleAmount,
          amount: Number(target.value) || 0,
        },
      }));
  }
  function onSaleCurrencyChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    userCanEditContracts &&
      changeUnsavedAssetDeliverable((previousValues) => ({
        ...previousValues,
        saleAmount: {
          ...previousValues.saleAmount,
          currency: target.value as Currency,
        },
      }));
  }

  function onSaleAmountBlur() {
    if (invalidAmount(unsavedAssetDeliverable.saleAmount.amount || 0) || !currentContract) return;

    changeIsSubmitting(true)

    api.contracts.updateAssetDeliverables(currentContract._id, [unsavedAssetDeliverable], {
      onData: (data) => dispatch(setContract(data)),
      onComplete: () => changeIsSubmitting(false),
      onError: () => changeIsSubmitting(false)
    });
  }

  return (
    <Card onClick={() => highlightAsset && changeHighlightAsset(false)}>
      <CardHeader
        disableTypography
        title={
          <>
            <Button
              onClick={() => {
                dispatch(setCurrentAssetId(asset._id));
                dispatch(setBladeMode("assetViewEdit"));
              }}
              disabled={!userCanViewAssets}
              color="inherit"
              sx={{ ml: -1 }}
            >
              <Typography variant="h5">
                {asset.serialNumber} - {asset.sizeCode}
              </Typography>
            </Button>
            <CopyClipboard
              value={asset.serialNumber}
              tooltipText="Copy Serial #"
              successText={`Copied asset Serial #: ${asset.serialNumber}`}
            />
          </>
        }
        action={
          !assetDeliverable.isActiveOnContract ? (
            <Chip label={<Typography>Not on Contract</Typography>} />
          ) : (
            <IconButton
              onClick={() => setEditing((isEditing) => !isEditing)}
              sx={{ ml: 1 }}
              disabled={
                !Boolean(asset.status !== "SOLD" && userCanEditContracts)
              }
            >
              <Edit />
            </IconButton>
          )
        }
      />
      <CardContent sx={{ pt: 0 }}>
        {isEditing ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
              <Dot
                color={asset.status === "AVAILABLE" ? "success" : "error"}
                sx={{ mb: 1 }}
              />
              {titleCase(asset.status)}:{" "}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                py: 1,
                mb: 1,
              }}
            >
              {currentContract?.contractType !== "Sale" && (
                <>
              <DateSelector
                data-testid="customBillingStart"
                onChange={onBillingDateChange("customBillingStart", index)}
                onAccept={handleBillingDateAccept("customBillingStart")}
                disabled={sameBillingStart && index > 0}
                value={
                  unsavedAssetDeliverable.customBillingStart
                    ? toDDMMMYYYY(
                      dateFromMMDDYYYY(unsavedAssetDeliverable.customBillingStart))
                    : null
                }
                label="Custom Billing Start"
                textFieldProps={{
                  size: "small",
                  fullWidth: true,
                }}
              />
              <DateSelector
                data-testid="customBillingEnd"
                onChange={onBillingDateChange("customBillingEnd", index)}
                onAccept={handleBillingDateAccept("customBillingEnd")}
                disabled={sameBillingEnd && index > 0}
                value={
                  unsavedAssetDeliverable.customBillingEnd
                    ? toDDMMMYYYY(
                      dateFromMMDDYYYY(unsavedAssetDeliverable.customBillingEnd)
                      )
                    : null
                }
                label="Custom Billing End"
                textFieldProps={{
                  size: "small",
                  fullWidth: true,
                }}
              />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              {currentContract?.movements.filter(
                (move) => move.asset === asset._id
              ).length
                ? nextMovement()
                : requestedDeliveryDate()}
              {currentContract?.contractType === "Rental" ? (
                <CurrencyTextField
                  label="Rental Rate"
                  size="small"
                  fullWidth
                  value={unsavedAssetDeliverable.rentalRate}
                  error={!unsavedAssetDeliverable.rentalRate.amount || unsavedAssetDeliverable.rentalRate.amount <= 0}
                  disabled={
                    !userCanEditContracts ||
                    currentContract.status === "CONCLUDED" ||
                    !assetDeliverable.isActiveOnContract
                  }
                  onChange={onRentalRateChange}
                  onCurrencyChange={onRentalRateCurrencyChange}
                  onBlur={onRentalRateBlur}
                  variant="outlined"
                  inputProps={{
                    "data-testid": "dateOfMove",
                  }}
                />
              ) : (
                <CurrencyTextField
                  size="small"
                  label="Sale Amount"
                  value={unsavedAssetDeliverable.saleAmount}
                  error={!unsavedAssetDeliverable.saleAmount.amount || unsavedAssetDeliverable.saleAmount.amount <= 0}
                  onChange={onSaleAmountChange}
                  fullWidth
                  onCurrencyChange={onSaleCurrencyChange}
                  disabled={
                    !userCanEditContracts ||
                    currentContract?.status === "CONCLUDED" ||
                    !assetDeliverable.isActiveOnContract
                  }
                  onBlur={onSaleAmountBlur}
                />
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{ display: "flex", flexDirection: "column", rowGap: 1, mt: 1 }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
              <Dot color={asset.status === "AVAILABLE" ? "success" : "error"} />
              {titleCase(asset.status)}
            </Box>
            {Boolean(
              assetDeliverable.customBillingStart ||
                assetDeliverable.customBillingEnd
            ) && (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="span"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    Start:{" "}
                  </Typography>
                  {assetDeliverable.customBillingStart ||
                    currentContract?.startDate}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    component="span"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    End:{" "}
                  </Typography>
                  {assetDeliverable.customBillingEnd ||
                    currentContract?.endDate ||
                    (currentContract?.evergreen && "Evergreen")}
                </Box>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "100%" }}>
                <Typography sx={{ width: "100%" }}>
                  <Typography
                    component="span"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  >
                    {latestAssetMovement?.type || "No Movements"}:{" "}
                  </Typography>
                  {latestAssetMovement &&
                    toDDMMMYYYY(dateFromMMDDYYYY(latestAssetMovement?.dateOfMove))}
                </Typography>
              </Box>

              <Box sx={{ width: "100%" }}>
                {currentContract?.contractType === "Rental" ? (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      component="span"
                      sx={(theme) => ({
                        color: assetDeliverable.rentalRate.amount
                          ? theme.palette.text.secondary
                          : theme.palette.error.main,
                      })}
                    >
                      Rental Rate:{" "}
                    </Typography>
                    <Typography
                      component="span"
                      sx={(theme) => ({
                        color: assetDeliverable.rentalRate.amount
                          ? theme.palette.text.secondary
                          : theme.palette.error.main,
                      })}
                    >
                      {fCurrency(assetDeliverable.rentalRate?.amount)}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      component="span"
                      sx={(theme) => ({ color: theme.palette.text.secondary })}
                    >
                      Sale Amount:{" "}
                    </Typography>
                    {fCurrency(assetDeliverable.saleAmount?.amount)}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
        <AssetMovements
          assetDeliverable={assetDeliverable}
          setAddAssetMode={setAddAssetMode}
          setNewMovements={setNewMovements}
          changeIsSubmitting={changeIsSubmitting}
          disabled={["CONCLUDED", "CANCELLED", "DENIED"].includes(
            currentContract?.status || ""
          )}
        />
      </CardContent>
    </Card>
  );
};
export default ContractAsset;
