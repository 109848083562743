import { Client } from 'store/models/Client';
import { Contract } from 'store/models/Contract';
import { sendToBackend } from "store/api/util";
import { errorHandler } from "store/api/shared";
import { createMongoId } from 'utils/createMongoId';

const clientsApi = {
  getAll: (
    actions?: {
      onData?: (data: Client[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Client[]>({
      route: `client/`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  createOne: (
    newClient: Omit<Client, '_id'>,
    actions?: {
      onData?: (data: Client) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Client>({
      route: `client/`,
      method: "POST",
      body: newClient,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  updateOne: (
    updatedClient: Client,
    actions?: {
      onData?: (data: Client) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Client>({
      route: `client/`,
      method: "PUT",
      body: updatedClient,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  contracts: (
    clientId: string,
    actions?: {
      onData?: (data: Contract[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Contract[]>({
      route: `client/contracts?clientId=${clientId}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
}

export const initClient = (client: Partial<Client> & {country: Client["country"]}): Client => {
  return ({
    _id: createMongoId(),
    legalName:"",
    companyName:"",
    phoneNumber:"",
    fax:"",
    email:"",
    accountOwner: null,
    accountId: "",
    contacts: [],
    website:"",
    billTos: [],
    shipTos: [],
    accountsPayableContact: "",
    ...client
  })
}

export default clientsApi;