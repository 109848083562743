import Blade from "components/Blade";
import { Formik, FormikHelpers } from "formik";
import _ from "lodash";
import { FC, useMemo } from "react";
import { IChecklistItem, IInspection } from "store/models/Inspection";
import { Photo } from "store/models/Photo";
import CheckListItemForm from "features/maintenance/CheckListItemForm";
import CheckListItemView from "features/maintenance/CheckListItemView";
import { selectLoggedInUser } from "store/slices/systemSlice";
import Save from "@mui/icons-material/Save";
import { IconButton, CircularProgress } from "@mui/material";
import { checkListItemValidationSchema } from "utils/formikAPI";
import { initCheckListItem } from "store/api/workOrder";
import { useSelector } from "react-redux";
import { selectDefaultUserCurrency } from "store/slices/configSlice";
import { useUpdateCheckListItemMutation } from "store/services/inspection";
import { useGetCheckListItemPhotosQuery, useDeleteInspectionCheckListItemPhotoMutation, useAddInspectionCheckListItemPhotoMutation } from "store/services/workOrderPhoto";

interface Props { 
  inspection: IInspection;
  checkListItem: IChecklistItem;
  open: boolean;
  imagesOnly?: boolean;
  onClose: () => void;
}

const CheckListItemViewEditBlade: FC<Props> = ({
  inspection,
  checkListItem,
  open,
  imagesOnly = false,
  onClose,
}) => {

  const loggedInUser = useSelector(selectLoggedInUser);
  const defaultCurrency = useSelector(selectDefaultUserCurrency(loggedInUser));
  const {data: images = [], isLoading: isFetchingImages} = useGetCheckListItemPhotosQuery({inspectionId: inspection._id, checkListItemId: checkListItem._id});
  const [deletePhoto] = useDeleteInspectionCheckListItemPhotoMutation()
  const [addPhoto, {isLoading: isImageSubmitting}] = useAddInspectionCheckListItemPhotoMutation()
  const [updateCheckListItem] = useUpdateCheckListItemMutation()
  const initialValues = useMemo(() => initCheckListItem(defaultCurrency, checkListItem), [checkListItem]);

  const updateInspectionCheckListItem = (
    item: IChecklistItem,
    { setSubmitting }: FormikHelpers<IChecklistItem>
  ) => {
    const checkListCopy = _.cloneDeep(inspection.inspectionChecklist);
    const itemIndex = checkListCopy.findIndex(
      (checkListItem) => checkListItem._id === item._id
    );
    checkListCopy[itemIndex] = { ...item };
    updateCheckListItem({ inspectionId: inspection._id, checklistItem: item })
      .unwrap()
      .then(() => {
        setSubmitting(false);
        onClose();
      });
  };

  const deleteImage = ({ _id: photoId }: Photo) => {
    deletePhoto({inspectionId: inspection._id, checkListItemId: checkListItem._id, photoId})
  };
  const handleImageUpload = (form: FormData) => new Promise((resolve, reject) => {
    addPhoto({inspectionId: inspection._id, checkListItemId: checkListItem._id, photo: form, workOrderId: String(checkListItem.workOrder)}).unwrap()
    .then(resolve).catch(reject)
  });


  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={updateInspectionCheckListItem}
      validationSchema={checkListItemValidationSchema}
    >
      {({ values, isSubmitting, submitForm }) => {

        return (
          <Blade
            open={open}
            changeOpen={onClose}
            backgroundColor="white"
            onTop
            title={checkListItem.taskName}
            actions={[
              <IconButton
                key="save"
                color="primary"
                onClick={submitForm}
                disabled={isImageSubmitting || isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : <Save />}
              </IconButton>,
            ]}
          >
            {!values.workOrder || imagesOnly ? (
              <CheckListItemForm
                images={images}
                isFetchingImages={isFetchingImages}
                handleImageUpload={handleImageUpload}
                deleteImage={deleteImage}
                imagesOnly={imagesOnly}
              />
            ) : (
              <CheckListItemView
                checkListItem={checkListItem}
                images={images}
                isFetchingImages={isFetchingImages}
                handleImageUpload={handleImageUpload}
                deleteImage={deleteImage}
              />
            )}
          </Blade>
        );
      }}
    </Formik>
  )
}

export default CheckListItemViewEditBlade;
