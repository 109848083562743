import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg viewBox="0 0 22 29" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="80%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Splash-Screen"
            transform="translate(-102.000000, -305.000000)"
            fill="#F1AF2A"
          >
            <g id="Group" transform="translate(102.000000, 305.000000)">
              <g id="Group-3">
                <path
                  fill="url(#BG2)"
                  d="M21.9953409,27.2614145 C21.9893219,27.2191528 21.3759039,22.9689229 19.8425039,17.5444087 C18.9412732,14.3564029 17.902705,11.3890393 16.7555632,8.72481209 C15.3170783,5.38399168 13.701785,2.51071686 11.9546523,0.184873261 C11.8721803,0.0751319367 11.745376,0.00765232688 11.6083857,0.000579721734 C11.6004568,0 11.5925279,0 11.584599,0 C11.4561164,0 11.3330161,0.053508316 11.2451617,0.148466736 C11.1702714,0.229337918 9.38563562,2.18050736 7.09771822,6.66193027 C5.7610934,9.28012754 4.52870159,12.2264473 3.43474696,15.4190908 C2.06935822,19.404098 0.916602513,23.787432 0.00860048691,28.4474092 C-0.0308124381,28.6497321 0.067864562,28.8538522 0.250691919,28.9484048 C0.318000629,28.9831301 0.390749596,29 0.462861938,29 C0.586830345,29 0.709004625,28.9502599 0.798595238,28.8558232 C0.821282267,28.8319387 3.09710378,26.4380357 5.75137039,23.9849432 C10.1297354,19.9385435 12.1260203,18.8473913 13.0297396,18.6446626 C13.4360805,18.5534723 13.7811317,18.5091816 14.0845128,18.5091816 C14.9200553,18.5091816 15.5304637,18.8459999 16.2617731,19.7105389 C17.0458067,20.637456 17.904094,22.0968475 19.2031001,24.3059931 C19.7642568,25.2602151 20.4003038,26.3419179 21.1408733,27.5669858 C21.2267599,27.7090177 21.3787398,27.7906425 21.537086,27.7906425 C21.589,27.7906425 21.6415505,27.7818887 21.6927121,27.7635695 C21.8999049,27.689481 22.0263619,27.4795638 21.9953409,27.2614145"
                  id="Fill-1"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
