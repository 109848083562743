import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/material';
import ProgressBar from './ProgressBar';
import StarfleetLoading from './StarfleetLoading';
import React from 'react';

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));


type Props = {
  isDashboard?: boolean;
  sx?: SxProps;
  children?: React.ReactNode
};

export default function LoadingScreen({ isDashboard, children, ...other }: Props) {
  return (
    <>
      <ProgressBar />

      {!isDashboard && (
        <RootStyle {...other}>
         <StarfleetLoading />
        </RootStyle>
      )}
      {children}
    </>
  );
}
