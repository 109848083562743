import { useSelector } from 'react-redux';
import { selectAssets } from 'store/slices/assetSlice';
import { selectCurrentContract } from 'store/slices/contractSlice';

const useContractBladeTabNotifications = (): {
  detailsTabNotifications: number;
  assetsTabNotifications: number;
  invalidContractProperties: string[];
} => {

  const currentContract = useSelector(selectCurrentContract);
  const assets = useSelector(selectAssets);

  let notifications = {
    detailsTabNotifications: 0,
    assetsTabNotifications: 0,
  };

  let invalidContractProperties = []

  if (["AWAITING CONFIRMATION", "PENDING DELIVERY", "ACTIVE"].includes(currentContract?.status || "")) {
    if (!currentContract?.projectNumber) {
      notifications.detailsTabNotifications++;
      invalidContractProperties.push("projectNumber")
    }

    if (currentContract?.contractSigned === false) {
      notifications.detailsTabNotifications++;
      invalidContractProperties.push("contractSigned")
    }

    for (const deliverable of currentContract?.assetDeliverables || []) {
      if (currentContract?.contractType === "Rental" && !deliverable.rentalRate.amount ) {
        notifications.assetsTabNotifications++;
        invalidContractProperties.push("rentalRate")
      }
    }

    for (const deliverable of currentContract?.assetDeliverables || []) {
      if (currentContract?.contractType === "Sale" && !deliverable.saleAmount.amount) {
        notifications.assetsTabNotifications++;
        invalidContractProperties.push("saleAmount")
      }
    }
  }

  if (currentContract?.status === "PENDING DELIVERY") {
    for (const assetId of currentContract.assetDeliverables) {
      if (assets[`${assetId}`]?.status === "SOLD PENDING DELIVERY") {
        notifications.assetsTabNotifications++;
      }
    }
  }

  if (currentContract?.status === "ACTIVE") {
    for (const assetId of currentContract.assetDeliverables) {
      if (assets[`${assetId}`]?.status === "ON LEASE") {
        notifications.assetsTabNotifications++;
      }
    }
  }

  return {...notifications, invalidContractProperties};
};

export default useContractBladeTabNotifications;
