import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STARFLEET_STORE, AppDispatch } from "store/store";
import { byIds } from "store/sliceUtils";
import api from "store/api";
import { Client } from 'store/models/Client';
export interface CLIENTS_STORE {
    clients: {[_id: string]: Client};
    clientsLoading: boolean;
    currentClientId: string | undefined;
    createdClientId: string | undefined;
};

const initialState: CLIENTS_STORE = {
    clients: {},
    clientsLoading: true,
    currentClientId: undefined,
    createdClientId: undefined
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setAllClients(state, action: PayloadAction<Client[]>) {
            state.clients = byIds(action.payload);
        },
        setClient(state, action: PayloadAction<Client>) {
            state.clients[`${action.payload._id}`] = action.payload
        },
        setClientsLoading(state, action: PayloadAction<boolean>) {
            state.clientsLoading = action.payload;
        },
        setCurrentClientId(state, action: PayloadAction<string | undefined>) {
            state.currentClientId = action.payload;
        },
        setCreatedClientId(state, action: PayloadAction<string | undefined>) {
            state.createdClientId = action.payload;
        },
    }
})

export const fetchAllClientData = () => async (dispatch: AppDispatch) => {
    api.clients.getAll({
        onData: (data) => {
            dispatch(setAllClients(data));

        },
        onComplete: () => dispatch(setClientsLoading(false)),
    });
};
export const selectCreatedClientId = (state: STARFLEET_STORE) => state.clients.createdClientId;
export const selectClients = (state: STARFLEET_STORE) => state.clients.clients;
export const selectCurrentClient = (state: STARFLEET_STORE) => state.clients.clients[String(state.clients.currentClientId)] as Client;

export const selectClientsLoading = (state: STARFLEET_STORE) => state.clients.clientsLoading;

export const {
    setAllClients,
    setClient,
    setClientsLoading,
    setCurrentClientId,
    setCreatedClientId
} = clientSlice.actions;

export default clientSlice.reducer;
