import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { FC, SetStateAction, Dispatch, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { Contract, inactiveContractStatuses } from "store/models/Contract";
import { selectClients } from "store/slices/clientSlice";
import EditableAssetsTable from "features/contracts/table/EditableAssetsTable";
import EditableAccessoriesTable from "features/contracts/table/EditableAccessoriesTable";
import { selectLoggedInUser } from "store/slices/systemSlice";
import usePermissions, { FeatureType, ActionType } from "hooks/usePermissions";
import AccessoryAutoComplete from "components/autocomplete/AccessoryAutoComplete";
import AddAssetModal, { AddAssetModalMode } from "components/modals/AddAssetModal";
import { FormikHelpers } from "formik";
import { Asset } from "store/models/Asset";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import IAccessory from "store/models/Accessory";
import BladeTabs from "components/BladeTabs";
import UserData from "store/models/UserData";
import { Row } from "react-table";

export interface ContractModalProps extends DialogProps {
  contract: Contract;
  onClose: () => void;
  updateAssetDeliverables: (deliverables: AssetDeliverable[],  setSubmitting?: FormikHelpers<AssetDeliverable>["setSubmitting"], setSelectedIndex?: Dispatch<SetStateAction<number | undefined>> ) => void;
  removeAsset: (asset: Asset, setSubmitting?: FormikHelpers<AssetDeliverable>["setSubmitting"], setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>) => void;
  addAccessory: (accessoryName: string) => void;
  editAccessory: (accessory: IAccessory, formikHelpers: FormikHelpers<IAccessory>, setSelectedIndex: Dispatch<SetStateAction<number | undefined>> ) => void;
  removeAccessory: (accessory: IAccessory, { setSubmitting }: { setSubmitting: FormikHelpers<IAccessory>["setSubmitting"], setSelectedIndex: Dispatch<SetStateAction<number | undefined>> }) => void;
  isSubmitting: boolean;
  isCreatingReservation?: boolean;
  viewOnly?: boolean;
  additionalActions?: ReactElement<any, any>[];
  assets?: Asset[];
  disableSingleRowSelection?: boolean;
  handleAssetSubmit: (toggleAllRowsSelected: (set?: boolean | undefined) => void, selectedRows:Asset[]) => void;
  addAssetModalMode: AddAssetModalMode,
  setAddAssetModalMode: Dispatch<SetStateAction<AddAssetModalMode>>
}

const ContractModal: FC<ContractModalProps> = ({
  contract,
  onClose,
  handleAssetSubmit,
  isSubmitting,
  addAccessory,
  updateAssetDeliverables,
  removeAsset,
  editAccessory,
  removeAccessory,
  isCreatingReservation = false,
  viewOnly = false,
  disableSingleRowSelection = false,
  additionalActions,
  assets,
  addAssetModalMode,
  setAddAssetModalMode,
  ...rest
}) => {
  const clients = useSelector(selectClients);
  const loggedInUser = useSelector(selectLoggedInUser) as UserData;
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const [onAccessoriesTab, changeIsOnAccessoriesTab] = useState(false);
  const contractIsInactive = inactiveContractStatuses.includes(contract.status);
  function userTryingToCancelTheirOwnContract() {
    return loggedInUser?._id === contract?.createdBy;
  }

  function userCanEditContract() {
    const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
    const userCanEditOwnContract =
      userTryingToCancelTheirOwnContract() &&
      checkContractPermissions(ActionType.LIMITED_UPDATE);
    if (contract.status === "ACTIVE") {
      return userCanEditContracts;
    } else {
      return userCanEditOwnContract || userCanEditContracts;
    }
  }

  return (
    <>
      <Dialog {...rest} onClose={onClose}>
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{fontSize: 25}}>
            {isCreatingReservation
              ? "New Reservation"
              : `${clients[contract.client].companyName} - ${
                  contract.projectNumber
                }`}
          </Typography>
          <Grid item xs />

          <Box sx={{ display: "flex", columnGap: 3, height: "40px" }}>
            {!viewOnly && (
              <>
                {additionalActions}
                {onAccessoriesTab ? (
                  <AccessoryAutoComplete
                    enableConfirm
                    fullWidth
                    blurOnSelect
                    onChange={(_, accessoryName, reason) => {
                      if (reason && reason === "selectOption" && accessoryName) {
                        reason === "selectOption" &&
                        addAccessory(accessoryName as string)
                      }
                    }
                    }
                    placeholder="Add Accessory"
                    name="accessory"
                    loading={isSubmitting}
                    disabled={contractIsInactive}
                    sx={{ minWidth: "200px" }}
                  />
                ) : (
                  <Button
                    disabled={!userCanEditContract() || contractIsInactive}
                    onClick={() => setAddAssetModalMode("add")}
                    sx={{ minWidth: "150px" }}
                    size="small"
                    variant="outlined"
                    startIcon={<Add />}
                  >
                    Asset
                  </Button>
                )}
              </>
            )}
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <BladeTabs
            onTabIndexChange={(tabIndex) =>
              changeIsOnAccessoriesTab(tabIndex == "1")
            }
            sx={{ mt: 3 }}
            tabs={[
              {
                tabName: "Assets",
                tabComponent: (
                  <EditableAssetsTable
                    updateAssetDeliverables={updateAssetDeliverables}
                    removeAsset={removeAsset}
                    contract={contract}
                    isCreatingReservation={isCreatingReservation}
                    disableEditing={viewOnly || contractIsInactive}
                    sx={{ my: 4 }}
                  />
                ),
              },
              {
                tabName: "Accessories",
                tabComponent: (
                  <EditableAccessoriesTable
                    editAccessory={editAccessory}
                    removeAccessory={removeAccessory}
                    contract={contract}
                    isCreatingReservation={isCreatingReservation}
                    disableEditing={viewOnly || contractIsInactive}
                    sx={{ my: 4 }}
                  />
                ),
              },
            ]}
            isClosing
          />
        </DialogContent>
      </Dialog>

      {addAssetModalMode !== "hidden" && (
        <AddAssetModal
          mode={addAssetModalMode}
          setMode={setAddAssetModalMode}
          handleAssetSubmit={handleAssetSubmit}
          isSubmitting={isSubmitting}
          enableSingleRowSelection={!disableSingleRowSelection}
          assets={assets}
        />
      )}
    </>
  );
};

export default ContractModal;
