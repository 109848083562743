import React, { FC, ReactNode } from "react";
import { Card, Grid, TextField, Typography } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { FastField, FastFieldProps, useFormikContext } from "formik";
import { parsePhoneNumber } from "utils/util";
import { TransportCompany } from "store/slices/transportCompanySlice";
import AddressInput from "components/google-map/AddressInput";

interface AssetRowProps {
  label: string;
  input?: ReactNode;
}

const Row: FC<AssetRowProps> = ({ label, input }) => {
  return (
    <Grid container alignItems="center" my={1}>
      <Grid item xs={4} sx={{ color: "text.secondary" }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={8} px={2}>
        {input}
      </Grid>
    </Grid>
  );
};

interface Props {
  isCreating?: boolean;
}

const TransportCompanyForm: React.FC<Props> = ({ isCreating }) => {
  const { values, errors, touched, setFieldValue, handleBlur } =
    useFormikContext<TransportCompany>();

  return (
    <Scrollbar>
      <Card sx={{ p: 3 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ pl: 3 }}
        >
          <Row
            label="Name"
            input={
              <FastField name="name">
                {({ field }: FastFieldProps) => (
                  <TextField
                    {...field}
                    label="Name"
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                    size="small"
                    inputProps={{
                      form: { autocomplete: "off" },
                    }}
                    autoComplete="off"
                  />
                )}
              </FastField>
            }
          />
          <Row
            label="Address"
            input={
              <FastField name="address">
                {({ field }: FastFieldProps) => (
                  <AddressInput
                    onAddressSelect={(address) => {
                      setFieldValue("address", address);
                    }}
                    size="small"
                    address={values.address || undefined}
                    showAddress
                  />
                )}
              </FastField>
            }
          />
          <Row
            label="Phone Number"
            input={
              <TextField
                name="phoneNumber"
                label="Phone"
                size="small"
                value={parsePhoneNumber(values.phoneNumber)}
                onChange={(event) =>
                  setFieldValue(
                    "phoneNumber",
                    parsePhoneNumber(event.currentTarget.value)
                  )
                }
                onBlur={handleBlur}
                type="phoneNumber"
                fullWidth
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                inputProps={{
                  "data-testid": "phoneNumber",
                  form: { autocomplete: "off" },
                }}
                autoComplete="off"
              />
            }
          />
        </Grid>
      </Card>
    </Scrollbar>
  );
};

export default TransportCompanyForm;
