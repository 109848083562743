import React, { useEffect, useState } from "react";
import { Contract } from "store/models/Contract";
import api from "store/api";
import { useSelector } from "react-redux";
import { setCurrentContractId } from "store/slices/contractSlice";
import { setBladeMode, simpleGlobalMessage } from "store/slices/systemSlice";
import { toLocalDatePretty } from "utils/util";
import { selectClients } from "store/slices/clientSlice";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import { fCurrency } from "utils/formatNumber";
import { selectCurrentAsset } from "store/slices/assetSlice";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { useAppDispatch } from 'store/store';

interface Props {}
const AssetContractHistory: React.FC<Props> = () => {
  const [contracts, changeContracts] = useState<Contract[]>([]);
  const [isSubmitting, setSubmitting] = useState(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [limit, setLimit] = useState(5);
  const currentAsset = useSelector(selectCurrentAsset);
  const dispatch = useAppDispatch();
  const clients = useSelector(selectClients)
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT);
  const userCanViewContracts = checkContractPermissions(ActionType.READ);

  useEffect(() => {
    if (currentAsset?._id) {
      setSubmitting(true);
      api.assets.getContractHistory({assetId:currentAsset?._id, limit}, {
        onData: ({contracts, totalRecords}) => {
          changeContracts(contracts);
          setTotalRecords(totalRecords);
        },
        onComplete: () => setSubmitting(false)
      });
    }
  }, [currentAsset?._id, limit]);

  function increaseLimit() {
    setLimit(limit + 5);
  }

  const tableFillerData = () => {
    if (isSubmitting) {
      return (
        <TableRow>
          <TableCell colSpan={2} sx={{ textAlign: "center" }}>
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    } else {
      <TableRow>
        <TableCell>No Contract Records</TableCell>
        <TableCell />
      </TableRow>;
    }
  };

  const amount = (contractType: Contract["contractType"], assetDeliverables: Contract["assetDeliverables"]) => {
    if (contractType === "Rental") {
      const rentalRate = assetDeliverables.find(
        (deliverable) => deliverable.asset === currentAsset?._id
      )?.rentalRate;
      return rentalRate ? `${fCurrency(rentalRate.amount)} ${rentalRate.currency} /month` : "";
    } else {
      
    const saleAmount = assetDeliverables.find(
      (deliverable) => deliverable.asset === currentAsset?._id
    )?.saleAmount;
    return saleAmount ? `${fCurrency(saleAmount.amount)} ${saleAmount.currency}` : "";
    }

  };
  

  const handleViewContract = (contractId: Contract["_id"]) => {
    if (userCanViewContracts) {
      dispatch(setCurrentContractId(contractId));
      dispatch(setBladeMode("contractViewEdit"));
    } else {
      dispatch(
        simpleGlobalMessage("You do not have permission to view contracts")
      );
    }
  };

  const recordsLeftToReveal = totalRecords - limit <= 0 ? 0 : totalRecords - limit;
  

  return (
    <Card>
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contract</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.length > 0 
              ? contracts.map(
                  (
                    {
                      client,
                      startDate,
                      endDate,
                      assetDeliverables,
                      contractType,
                      _id,
                    },
                  ) => {
                    function assetIsActiveOnContract(){
                      return assetDeliverables.find(
                        (deliverable) => deliverable.asset === currentAsset._id
                      )?.isActiveOnContract;
                    }

                    return (
                    <TableRow
                      key={`${_id}`}
                      sx={{
                        mb: 2,
                      }}
                    >
                      <TableCell>
                        <Button
                          variant={assetIsActiveOnContract() ? "outlined" : "text"}
                          onClick={() => handleViewContract(_id)}
                          fullWidth
                        >
                          {clients[client].companyName}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Typography noWrap variant="body2">{toLocalDatePretty(new Date(startDate))}</Typography>
                          <ArrowForward fontSize="small" sx={{ mx: 1 }} />
                          <Typography noWrap variant="body2">
                            {endDate ? toLocalDatePretty(new Date(endDate)) : "Evergreen"}
                          </Typography>
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Typography component="span" sx={{ textAlign: "center", mt: 1, color: "text.secondary" }} noWrap variant="body2">
                          {contractType === "Rental" ? "Rental Rate" : "Sale Amount"}: &nbsp;
                        </Typography>
                        <Typography component="span" sx={{ mt: 1 }} noWrap variant="body2">
                          {amount(contractType, assetDeliverables) || "Not Set"}
                        </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })
              : tableFillerData()}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="center" mb={2}>
      <Button onClick={increaseLimit} disabled={!recordsLeftToReveal} color="inherit">{recordsLeftToReveal ? `Show (${recordsLeftToReveal}) More...` : "All Records Shown"} </Button>
      </Stack>
    </Card>
  );
};
export default AssetContractHistory;
