import ViewContainer from 'components/bladeComponents/ViewContainer';
import ViewRow from 'components/bladeComponents/ViewRow';
import React from 'react'
import { useSelector } from 'react-redux';
import { ClientContact } from 'store/models/ClientContact';
import { selectClients } from 'store/slices/clientSlice';
import { getFullName } from 'utils/util';


interface Props {
    clientContact: ClientContact
}

const ClientContactView: React.FC<Props> = ({clientContact}) => {
    const clients = useSelector(selectClients);
    const companyName = clientContact.currentClient ? clients[clientContact.currentClient].companyName : ""

  return (
    <ViewContainer title={getFullName(clientContact)} subTitle={companyName}>
      <ViewRow label='Job Title' value={clientContact.companyRole} />
      <ViewRow label="Primary Phone" value={clientContact.primaryPhone} />
      <ViewRow label="Alt Phone" value={clientContact.altPhone} />
      <ViewRow label="Email" value={clientContact.email} />
    </ViewContainer>
  );
}

export default ClientContactView