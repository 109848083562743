import React from "react";
import { ReactComponent as StarFleetTextSvg } from "svg/starfleetText.svg";
import { useAppDispatch } from 'store/store';
import {
  loginUser,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import { OAuthProvider, signInWithPopup, getAuth } from "firebase/auth"
import { Box, Button, styled } from "@mui/material";
import Logo from "components/Logo";

const StarFleetText = styled(StarFleetTextSvg)(({ theme }) => ({
  fill: theme.palette.text.primary,
  height: 60,
}));

interface Props {}

const LoginScreen: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const auth = getAuth();
    const provider = new OAuthProvider("microsoft.com")
      .setCustomParameters({ tenant: "atco.com" });
    
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        if(!credential?.accessToken) return;        
        dispatch(loginUser)
      })
      .catch((error) => {
        dispatch(simpleGlobalMessage(error));
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          columnGap: 2,
          rowGap: 2,
          alignItems: "center",
        }}
      >
        <Logo sx={{ height: 48 }} />
        <StarFleetText />
        <Button
          fullWidth
          variant="contained"
          onClick={handleLogin}
          sx={{ gridColumn: "1 / 3" }}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};
export default LoginScreen;
