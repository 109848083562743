import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STARFLEET_STORE } from "store/store";
import Address from "store/models/Address";

export interface TransportCompany {
  _id: string;
  name: string;
  address: Address | null;
  phoneNumber: string;
}

export type TRANSPORT_COMPANY_STORE = {
  currentTransportCompanyId?: TransportCompany["_id"];
};

const initialState: TRANSPORT_COMPANY_STORE = {
  currentTransportCompanyId: undefined,
};

const transportCompanySlice = createSlice({
  name: "transportCompany",
  initialState,
  reducers: {
    setCurrentTransportCompanyId(state, action: PayloadAction<TRANSPORT_COMPANY_STORE["currentTransportCompanyId"]>) {
      state.currentTransportCompanyId = action.payload;
    },
    clearCurrentTransportCompanyId(state) {
      state.currentTransportCompanyId = undefined;
    }
  },
});

export const selectCurrentTransportCompanyId = (state: STARFLEET_STORE) => state.transportCompanies.currentTransportCompanyId;

export const {
    setCurrentTransportCompanyId,
    clearCurrentTransportCompanyId
} = transportCompanySlice.actions;

export default transportCompanySlice.reducer;
