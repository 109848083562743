import { MMDDYYYY } from "utils/util";

export interface WorkOrderTask {
  description: string;
  complete: boolean;
  completedBy: string | null;
  completedDate: MMDDYYYY | null;
}

export const initialTaskState = {
  description: "",
  complete: false,
  completedBy: null,
  completedDate: null,
};
