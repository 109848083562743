import pluralize from "pluralize";

export const formatPhone = (phone: string) => {
  const newPhone = `+${phone.slice(0, phone.length - 10)} (${phone.slice(
    phone.length - 10,
    phone.length - 7
  )}) ${phone.slice(phone.length - 7, phone.length - 4)}-${phone.slice(
    phone.length - 4,
    phone.length
  )}`;
  return newPhone;
};

export function alphabetizeByProperty<T>(propertyName: keyof T) {
  return (a: T, b: T) => {
    return a[propertyName] > b[propertyName]
      ? 1
      : a[propertyName] < b[propertyName]
      ? -1
      : 0;
  };
}

export function highestToLowest(
  a: number | string,
  b: number | string,
){
  return a > b
    ? -1
    : a < b
    ? 1
    : 0;
}

export function hoursToMinutes(time: number){
  return Number((time * 60).toFixed(2));
}

export function minutesToHours(time: number){
  return Number((time / 60).toFixed(2));
}

export const formatTimeRequired = (minutes: number) => {
  const hours = Number((minutes / 60).toFixed(2));

  if (hours < 1) return `${minutes.toFixed(0)} ${pluralize("minute", minutes)}`;
  else {
    return `${hours} ${pluralize("hour", hours)}`;
  }
};

export const richTextToString = (richText: string) => {
  return richText.replace(/<.*?>/g, "")
}
