import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  CardMedia,
  Dialog,
  Box,
  TextField,
  DialogContent,
} from "@mui/material";
import { useAppDispatch } from 'store/store';
import { useTheme } from "@mui/material/styles";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import Address, { defaultAddress } from "store/models/Address";
import PinWithHole from "./PinWithHole";
import BladeTabs from "components/BladeTabs";

export const mapIds = {
  dark: "6473c8501ccc2c2a",
  light: "4842ec0d5c232a5b",
};

const centeredOverCanadaAndUSA = {center: {lat: 50, lng: -96}, zoom: 3}

interface Props {
  address: Address;
  onAddressSelect: (address: Address) => void;
  show: boolean;
  changeShow: Dispatch<SetStateAction<boolean>>;
  restrictToCountry?: "ca" | "us"
}

const AddressSelectDialog: FC<Props> = ({ onAddressSelect, address, show, changeShow, restrictToCountry }) => {
  const [searchValue, changeSearchValue] = useState(address?.address || "");
  const [latLngSearchValue, changeLatLngSearchValue] = useState<
    Address["latLng"]
  >({ lat: "", lng: "" });
  const [currentAddress, changeCurrentAddress] = useState<Address>(address);
  const theme = useTheme();
  const [mode, changeMode] = useState<"address" | "latLng">("address");
  const dispatch = useAppDispatch();
  const mapRef = useRef<HTMLDivElement>(null);
  const markLatLngRef = useRef(null);
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const [map, changeMap] = useState<google.maps.Map>();
  const [marker, changeMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    show && mapRef && autocompleteRef && loadMap();
  }, [mapRef, autocompleteRef, show]);

  useEffect(() => {
    if (!show) {
      changeMode("address");
      changeSearchValue("");
      changeCurrentAddress(defaultAddress);
    } else {
      changeSearchValue(address.address || "");
      changeCurrentAddress(address);
    }
  },[show])

  function addressValid() {

    return addressWhenPresentMustHaveLength() && properLatLngPresent() ? true : false

    function addressWhenPresentMustHaveLength() {
      return !currentAddress.address || currentAddress.address && currentAddress.address.length
    }

    function properLatLngPresent() {
      return Math.abs(Number(currentAddress.latLng.lat)) > 0 && Math.abs(Number(currentAddress.latLng.lng)) > 0
    }
  }

  async function handleMarkLatLng() {

    marker?.setVisible(false);

    changeCurrentAddress(currentAddress => ({
      prefix: currentAddress.prefix,
      address: undefined,
      latLng: {
        lat: String(latLngSearchValue.lat),
        lng: String(latLngSearchValue.lng),
      },
    }));

    const { results: [result] } = await new window.google.maps.Geocoder().geocode({
      location: {
        lat: Number(latLngSearchValue.lat),
        lng: Number(latLngSearchValue.lng),
      },
    });

    if (result.geometry.viewport) {
      map?.fitBounds(result.geometry.viewport);
    } else {
      map?.setCenter(result.geometry.location);
      map?.setZoom(17);
    }

    marker?.setPosition(result.geometry.location);
    marker?.setVisible(true);
  }

  async function loadMap() {
    const {lat, lng} = address.latLng
    const initialAddress = Boolean(address.latLng.lat) ? {center: {lat: parseInt(lat), lng: parseInt(lng)}, zoom: 7} : centeredOverCanadaAndUSA

    const map = new window.google.maps.Map(mapRef.current!, {
      ...initialAddress,
      mapId: theme.palette.mode === "light" ? mapIds.light : mapIds.dark,
      mapTypeControlOptions: {
        mapTypeIds: [],
      },
      zoomControl: false,
      streetViewControl: false,
      gestureHandling: "cooperative", // Set to "none" to disable ability to move map around
    });

    changeMap(map);

    const options: google.maps.places.AutocompleteOptions = {
      componentRestrictions: { country: restrictToCountry ? [restrictToCountry] : ["us", "ca"] },
      fields: ["address_components", "geometry", "formatted_address"],
      strictBounds: false,
    };

    const marker = new window.google.maps.Marker({
      position:  address ? {lat: parseInt(lat), lng: parseInt(lng)} : undefined,
      map,
      draggable: true,
      icon: {
        path: PinWithHole,
        fillColor: theme.palette.primary.main,
        fillOpacity: 1,
        strokeColor: theme.palette.primary.dark,
        strokeWeight: 1,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(12, 22),
      },
    });

    changeMarker(marker);

    marker.addListener("dragend", (e: any) => {
      let { lat, lng } = e.latLng;
      lat = lat();
      lng = lng();
      latLngToAddress(lat, lng);
      changeLatLngSearchValue({lat: String(lat), lng: String(lng)});
    });

    marker.setVisible(Boolean(address));

    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current!,
      options
    );

    autocomplete.addListener("place_changed", () => {

      marker.setVisible(false);

      let place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        dispatch(simpleGlobalMessage("No address selected", "warning"));
        return;
      }

      changeSearchValue(place.formatted_address!);

      const lat = place.geometry.location.lat().toString()
      const lng = place.geometry.location.lng().toString()

      changeCurrentAddress(currentAddress => ({
        prefix: currentAddress.prefix,
        address: place.formatted_address,
        latLng: {
          lat,
          lng
        },
      }));

      changeLatLngSearchValue({
        lat: place.geometry.location.lat().toString(),
        lng: place.geometry.location.lng().toString(),
      });

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
        map.setZoom(16);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(16);
      }

      marker?.setPosition(place.geometry.location);
      marker?.setVisible(true);
    });
  }

  async function latLngToAddress(lat: number, lng: number) {
    const coder = new window.google.maps.Geocoder();
    const { results } = await coder.geocode({
      location: { lat, lng },
    });

    changeSearchValue(results[0].formatted_address || `${lat}, ${lng}`);
    changeCurrentAddress(currentAddress => ({
      prefix: currentAddress.prefix,
      address: results[0].formatted_address,
      latLng: { lat: String(lat), lng: String(lng) },
    }));
  }

  return (
    <Dialog open={show} onClose={() => changeShow(false)} maxWidth="xs" fullWidth keepMounted>
      <DialogContent sx={{ width: "100%", padding: 1, display: "grid", rowGap: 1 }}>
      <BladeTabs
        disableRerenderOnChange
        onTabIndexChange={(newIndex) => changeMode(Number(newIndex) == 1 ? "latLng" : "address")}
        sx={{mx: -1}}
        tabs={[
          {
            tabComponent: (
              <Box sx={{px: 1, display: "flex", flexDirection: "column", rowGap: 1}}>
              <TextField
                fullWidth
                placeholder="Prefix"
                size="small"
                value={currentAddress.prefix}
                onChange={(e) => {
                  const prefix = e.currentTarget.value
                  changeCurrentAddress(currentAddress => ({...currentAddress, prefix}))
                }}
              />
              <TextField
                fullWidth
                placeholder="Address"
                size="small"
                value={searchValue}
                onChange={(e) => changeSearchValue(e.currentTarget.value)}
                inputRef={autocompleteRef}
                onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              />
              </Box>
            ),
            tabName: "Address",
          },
          {
            tabComponent: (
              <Box sx={{px: 1}}>
                <TextField
                  sx={{mb: 1}}
                  size="small"
                  fullWidth
                  value={latLngSearchValue.lat}
                  placeholder="Lat"
                  onChange={(e) =>
                    changeLatLngSearchValue({
                      ...latLngSearchValue,
                      lat: e.currentTarget.value,
                    })
                  }
                />
                <TextField
                  sx={{mb: 1}}
                  size="small"
                  fullWidth
                  value={latLngSearchValue.lng}
                  placeholder="Long"
                  onChange={(e) =>
                    changeLatLngSearchValue({
                      ...latLngSearchValue,
                      lng: e.currentTarget.value,
                    })
                  }
                />
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={
                    () => {
                      handleMarkLatLng();
                    }
                  }
                  ref={markLatLngRef}
                >
                  Search
                </Button>
              </Box>
            ),
            tabName: "Lat/Lng",
          },
        ]}
        isClosing={!show}
      />
      <Box sx={{borderRadius: 1, height: 400, overflow: "hidden"}}>
        <CardMedia
          sx={{ borderRadius: 1, height: 440, width: "100%"}}
          ref={mapRef}
          component="div"
          id="googleMap"
        />
      </Box>
        <Button
          disabled={!addressValid()}
          onClick={() => {
            onAddressSelect(mode === "address" ? currentAddress : {...currentAddress, address: "", prefix: ""});
            changeShow(false);
          }}
          variant="contained"
          fullWidth
        >
          Select
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AddressSelectDialog;
