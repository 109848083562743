import { ReactNode } from 'react';
import isString from 'lodash/isString';
import { Box, Typography, Link, Grid, useMediaQuery, useTheme } from '@mui/material';
import Breadcrumbs, { Props as BreadcrumbsProps }  from 'components/header/Breadcrumbs';

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  moreLink?: string | string[];
}

function PageHeader({
  links,
  action,
  heading,
  moreLink = '' || [],
  ...other
}: Props) {
  const theme = useTheme();
  const belowXlScreen =  useMediaQuery(theme.breakpoints.down("xl"));

  const adjustedBreadCrumbStyles = belowXlScreen ? {
    display: "flex",
    alignItems: "center",
    gap: 3,
  } : {

  };

  return (
    <Box
      sx={(theme) => ({
        mb: 5,
        [theme.breakpoints.down("xl")]: {
          mb: 0,
        },
      })}
    >
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ flexGrow: 1, ...adjustedBreadCrumbStyles }}>
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
            <Breadcrumbs links={links} {...other} />
          </Box>
        </Grid>

        {action && (
          <Grid
            item
            container
            xs={12}
            sm={6}
            gap={1}
            sx={(theme) => ({
              justifyContent: "flex-end",
              [theme.breakpoints.down("sm")]: {
                mt: 1,
                justifyContent: "flex-start",
              },
            })}
          >
            {action}
          </Grid>
        )}
      </Grid>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
export default PageHeader;
