import LoginScreen from "auth/LoginScreen";
import GlobalMessage from "components/GlobalMessage";
import React from "react";
import { useSelector } from "react-redux";
import { selectLoggedInUserId, selectLoggedInUserLoading } from "store/slices/systemSlice";
import useAuthController from "auth/useAuthController";
import Starfleet from "routes/Starfleet";
import LoadingScreen from "components/LoadingScreen";

const Main: React.FC = () => {
  const loggedInUserId = useSelector(selectLoggedInUserId);
  const userIsLoggedIn = Boolean(loggedInUserId);
  const loggedInUserLoading = useSelector(selectLoggedInUserLoading);
  useAuthController();

  const main = () => {
    switch (true) {
      case loggedInUserLoading:        
        return <LoadingScreen />;
      case userIsLoggedIn:        
        return <Starfleet />;
      default:
        return <LoginScreen />;
    }
  };

  return (
    <>
      <GlobalMessage />
      {main()}
    </>
  );
};
export default Main;
