import React from "react";
import LoadingAnimation from "components/LoadingAnimation";
import { Box } from "@mui/material";

const LoadingScreen = () => {
  return (
    <Box
      sx={{
        position: "fixed",
        display: "grid",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 200,
      }}
    >
      <LoadingAnimation />
    </Box>
  );
};
export default LoadingScreen;
