import Address from "store/models/Address"
import { ClientContact } from "store/models/ClientContact"
import UserData from "store/models/UserData"
import { ICurrency } from "types/Currency"

export interface Lead {
  _id: string,
  clientRef: string | null
  clientStr: string,
  contact: string,
  status: LeadStatus,
  createdOn: string,
  closeProbability: number,
  projectLocation: Address | null,
  projectStart:string,
  estimatedValue: ICurrency,
  products: string[],
  type: string,
  term: number,
  assignedTo: string | null,
  leadSource: string,
  leadSourceId: string | undefined,
  actionRecords: LeadAction[],
  branch: string | null,
  notes: string,
  country: string
  attachments?: string[];
}

export interface LeadWithPopulatedContact extends Omit<Lead, "contact"> {
  contact: ClientContact
}

export enum LeadStatus {
  OPEN = "Open",
  UNQUALIFIED = "Unqualified",
  QUALIFIED= "Qualified",
  CLOSEDWON = "Closed Won",
  CLOSEDLOST= "Closed Lost",
  CLOSEDCANCELLED = "Closed Cancelled",

}

export interface LeadAction {
  _id: string,
  by: UserData["_id"],
  date: string,
  actionType: string,
  notes: string,
  complete: boolean
}
