import React from "react";
import { Field, FastFieldProps } from "formik";
import { TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";

const FastTimeInput: React.FC<{ label: string; name: string, onChange?: (value: any, keyboardInputValue?: string | undefined) => void }> = ({
  label,
  name,
  onChange,
  ...props
}) => {
  return (
    <Field {...props} name={name}>
      {({ field, form, meta }: FastFieldProps) => {
        return (
          <TimePicker
            {...field}
            {...props}
            inputFormat="hh:mm a"
            label={label}
            onChange={(newTime, ...props) => {
              if (onChange) {
                onChange(newTime, ...props)
              } else {
                form.setFieldValue(name, newTime);
              }
            }}
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                fullWidth
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "hh:mm AM/PM",
                }}
                name={name}
                onBlur={field.onBlur}
                error={meta.touched && !!meta.error}
              />
            )}
          />
        );
      }}
    </Field>
  );
};

export default FastTimeInput;
