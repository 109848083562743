import React, { FC, ReactNode } from "react";
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { parsePhoneNumber } from "utils/util";
import { selectUserRoles } from "store/slices/configSlice";
import usePermissions, {
  ActionType,
  FeatureType,
  USER_ROLE,
} from "hooks/usePermissions";
import { selectBranches } from "store/slices/optionSlice";
import BranchAutocomplete from "components/autocomplete/BranchAutoComplete";
import { Branch } from "store/models/Branch";
import UserData from "store/models/UserData";
import { CountryAutoComplete } from "components/autocomplete";

interface UserRowProps {
  label: string;
  input?: ReactNode;
}

const UserRow: FC<UserRowProps> = ({ label, input }) => {
  return (
    <Grid container alignItems="center" my={1}>
      <Grid item xs={4} sx={{ color: "text.secondary" }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        {input}
      </Grid>
    </Grid>
  );
};

interface Props {}

const UserForm: FC<Props> = () => {
  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    values,
  } = useFormikContext<UserData>();

  const checkUserPermissions = usePermissions(FeatureType.USER);
  const userCanCreatePowerUsers = checkUserPermissions(ActionType.PROMOTE);
  const roles = useSelector(selectUserRoles);
  const branches = useSelector(selectBranches);

  function isDisabled(role: USER_ROLE) {
    return (
      (!userCanCreatePowerUsers && role === USER_ROLE.SUPER_USER) ||
      (!userCanCreatePowerUsers && role === USER_ROLE.ADMINISTRATOR)
    );
  }

  const selectedBranches = values.branches.map(
    (branchId) => branches[branchId]
  );

  function onCountryChange(_: React.SyntheticEvent<Element, Event>, countries: string | string[] | null) {
    setFieldValue(
      "branches",
      values.branches.filter(filterBranchesIfCountryMissing)
    );
    setFieldValue("countries", countries);
    
    function filterBranchesIfCountryMissing(branchId: string) {
      return countries?.includes(branches[branchId].country);
    }
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <UserRow
        label="First Name"
        input={
          <TextField
            name="firstName"
            size="small"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            type="firstName"
            inputProps={{
              "data-testid": "firstName",
            }}
            error={Boolean(touched.firstName && errors.firstName)}
          />
        }
      />

      <UserRow
        label="Last Name"
        input={
          <TextField
            name="lastName"
            size="small"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            type="lastName"
            inputProps={{
              "data-testid": "lastName",
            }}
            error={Boolean(touched.lastName && errors.lastName)}
          />
        }
      />

      <UserRow
        label="Email"
        input={
          <TextField
            name="email"
            size="small"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            type="email"
            inputProps={{
              "data-testid": "email",
            }}
          />
        }
      />

      <UserRow
        label="Phone"
        input={
          <TextField
            name="phone"
            size="small"
            value={parsePhoneNumber(values.phone)}
            onChange={(event) =>
              setFieldValue(
                "phone",
                parsePhoneNumber(event.currentTarget.value)
              )
            }
            onBlur={handleBlur}
            type="phone"
            fullWidth
            error={Boolean(touched.phone && errors.phone)}
            inputProps={{
              "data-testid": "phone",
            }}
          />
        }
      />

      <UserRow
        label="Role"
        input={
          <Select
            data-testid="role"
            size="small"
            name="role"
            error={Boolean(touched.role && errors.role)}
            value={values.role}
            fullWidth
            onChange={handleChange}
          >
            {roles.map((role, index) => {
              return (
                <MenuItem
                  key={index}
                  value={role}
                  disabled={isDisabled(role as USER_ROLE)}
                  data-testid={`role-${role}`}
                >
                  {role}
                </MenuItem>
              );
            })}
          </Select>
        }
      />

      <UserRow
        label="Disabled"
        input={
          <Box display="flex" justifyContent="flex-end">
            <Checkbox
              name="isDisabled"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.isDisabled}
              data-testid="isDisabled"
            />
          </Box>
        }
      />

      <UserRow label="Countries" />
      <CountryAutoComplete
        name="countries"
        value={values.countries}
        size="small"
        onBlur={handleBlur}
        multiple
        onChange={onCountryChange}
        fullWidth
        error={Boolean(touched.countries && errors.countries)}
        data-testid="countries"
      />

      <UserRow label="Branches" />
      <BranchAutocomplete
        data-testid="branches"
        value={selectedBranches}
        getOptionDisabled={(branch) =>
          !values.countries.includes(branch.country)
        }
        size="small"
        name="branches"
        multiple
        disableCloseOnSelect
        onChange={(_, value) => {
          const newBranches = value as Branch[];
          setFieldValue(
            "branches",
            newBranches.map((branch) => branch._id)
          );
        }}
        fullWidth
        error={Boolean(touched.branches && errors.branches)}
      />
    </Grid>
  );
};

export default UserForm;
