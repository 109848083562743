import React from "react";
import ThemeProvider from './theme';
import ThemeColorPresets from './components/ThemeColorPresets';
import GlobalStyles from './theme/globalStyles';
import { ProgressBarStyle } from './components/ProgressBar';
import { ChartStyle } from './components/chart';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import 'simplebar/dist/simplebar.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Router from "routes/Router";
import { Wrapper as ReactGoogleMapsWrapper } from "@googlemaps/react-wrapper";
import { ErrorBoundary } from "react-error-boundary"
import Page500 from "routes/Page500";


const App: React.FC = () => {

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <MotionLazyContainer>
          <ReactGoogleMapsWrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!} libraries={["places"]}>
            <GlobalStyles />
            <ChartStyle />
            <ProgressBarStyle />
            <ErrorBoundary FallbackComponent={Page500}>
              <Router />
            </ErrorBoundary>
          </ReactGoogleMapsWrapper> 
        </MotionLazyContainer>
      </ThemeColorPresets>
    </ThemeProvider>
  );
};

export default App;
