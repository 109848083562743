import { IChat, IMessage } from "store/models/Chat";
import { errorHandler } from "./shared";
import { sendToBackend } from "./util";

export const getChat = (
  chatId: string,
  actions?: {
    onData?: (data: IChat) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }
) => {
  sendToBackend<IChat>({
    route: `chat?chatId=${chatId}`,
    method: "GET",
    onData: actions?.onData,
    onError: (message: string) => { errorHandler(message, actions?.onError); },
    onSuccess: actions?.onComplete,
    currentlySubmitting: actions?.currentlySubmitting,
  });
}

export const updateWorkOrderChat = (
  documentId: string, 
  message: IMessage,
  actions?: {
    onData?: (data: IMessage) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }
) => {
  sendToBackend<IMessage>({
    route: "chat",
    method: "PATCH",
    body: {
      documentId,
      message,
      schema: "workOrder"
    },
    onData: actions?.onData,
    onError: (message: string) => { errorHandler(message, actions?.onError); },
    onSuccess: actions?.onComplete,
    currentlySubmitting: actions?.currentlySubmitting,
  });
}
