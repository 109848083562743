import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import { Photo } from "store/models/Photo";
import { proxyUrl } from "utils/util";

interface Props {
  images: Array<Photo | string>;
  imagesLoading: boolean;
  dataId: string;
}

const useCompressImages = ({ images, imagesLoading, dataId }: Props) => {

  const [compressedImages, setCompressedImages] = useState<Array<string>>([]);
  const [isCompressing, setIsCompressing] = useState(false);
  const [currentDataId, setCurrentDataId] = useState<string>("");
  const [imagesAlreadyCompressed, setImagesAlreadyCompressed] = useState<
    { img: string; compressedUrl: string }[]
  >([]);

  useEffect(() => {

    if (shouldSkipCompression()) return;
    
    if (dataIdChanged()) {
      setCurrentDataId(dataId);
      resetCompression();
    }

    if (!images.length) {
      resetCompression();
      return;
    }

    const imagesToAdd = getImagesToAdd();
    const imagesToRemove = getImagesToRemove();

    // Process the images in batches
    handleCompressionInBatches(imagesToAdd); // Batch compression
    handleImageRemoval(imagesToRemove);

  }, [images, imagesLoading, dataId]);

  const shouldSkipCompression = () =>
    (currentDataId === dataId && compressedImages.length === images.length) ||
    (!currentDataId && !dataId) ||
    imagesLoading ||
    isCompressing;

  const dataIdChanged = () => currentDataId !== dataId;

  const resetCompression = () => {
    setImagesAlreadyCompressed([]);
    setCompressedImages([]);
  };

  const getImagesToAdd = () =>
    images.filter(
      (img) =>
        (typeof img === "string" && !imagesAlreadyCompressed.some(({ img: cachedImg }) => cachedImg === img)) ||
        (typeof img !== "string" && !imagesAlreadyCompressed.some(({ img: cachedImg }) => cachedImg === img.web_url))
    );

  const getImagesToRemove = () =>
    imagesAlreadyCompressed.filter(({ img }) =>
      !images.some((image) => (typeof image === "string" ? img === image : img === image.web_url))
    );

  const handleCompressionInBatches = async (imagesToAdd: Array<Photo | string>) => {
    if (!imagesToAdd.length) return;
    setIsCompressing(true);

    const batchSize = 2; // Compress images in batches of 2
    let newImagesAlreadyCompressed = [...imagesAlreadyCompressed];

    // Use a recursive function to handle batch processing without blocking the main thread
    const processBatch = async (startIndex: number) => {
      if (startIndex >= imagesToAdd.length) {
        setIsCompressing(false);
        return;
      }

      const batch = imagesToAdd.slice(startIndex, startIndex + batchSize);
      const compressedBatch = await Promise.all(
        batch.map(async (img) => {
          if (typeof img === "string") {
            newImagesAlreadyCompressed.push({ img, compressedUrl: img });
            return img;
          }

          const photo = img as Photo;
          const compressedBlobUrl = await compressImage(photo.web_url);
          newImagesAlreadyCompressed.push({ img: photo.web_url, compressedUrl: compressedBlobUrl });
          return compressedBlobUrl;
        })
      );

      // Append compressed batch to compressedImages
      setCompressedImages((prev) => [...prev, ...compressedBatch]);

      // Process the next batch
      processBatch(startIndex + batchSize);
    };

    await processBatch(0); // Start processing from the first batch
    setImagesAlreadyCompressed(newImagesAlreadyCompressed);
  };


  const compressImage = async (imageUrl: string) => {
    const response = await fetch(proxyUrl + imageUrl);
    const blob = await response.blob();

    const compressedBlob = await imageCompression(blob as File, {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true, // Continue using web worker for compression
    });

    const compressedBlobUrl = URL.createObjectURL(compressedBlob);
    return compressedBlobUrl;
  };

  const handleImageRemoval = (imagesToRemove: Array<{ img: string; compressedUrl: string }>) => {
    if (!imagesToRemove.length) return;

    setCompressedImages((prev) =>
      prev.filter((compressedImg) => !imagesToRemove.some(({ compressedUrl }) => compressedUrl === compressedImg))
    );
    setImagesAlreadyCompressed((prev) =>
      prev.filter(({ img }) => !imagesToRemove.some((removedImg) => removedImg.img === img))
    );
  };

  return { compressedImages, isCompressing };
};

export default useCompressImages;
