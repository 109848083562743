import { Contract } from "store/models/Contract";
import { IContractHistory } from "store/models/ContractHistory";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";

export const contractApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getContractById: builder.query<Contract, string>({
      query: (contractId) => `contract/single?contractId=${contractId}`,
      transformResponse: getResponseBody<Contract>,
      providesTags: (result, error, id) => [
        { type: "Contracts", id: result?._id },
      ],
    }),
    getContractHistory: builder.query<IContractHistory, string>({
      query: (contractId) => `contract/history?contractId=${contractId}`,
      transformResponse: getResponseBody<IContractHistory>,
      providesTags: (result, error, id) => [
        { type: "ContractHistory", id },
      ],
    }),
  }),
});

export const { useGetContractByIdQuery, useGetContractHistoryQuery } = contractApi;
