import { FC, useCallback, useMemo } from "react";
import { IChecklistItem } from "store/models/Inspection";
import { richTextToString } from "utils/formatUtils";
import { toDDMMMYYYY, dateFromMMDDYYYY } from "utils/util";
import { Card, Typography } from "@mui/material";
import { WebWOQueryResponse } from "store/services/workOrder";
import StyledDataGrid from "components/data-grid/styled-data-grid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

interface Props {
  checkListItems: IChecklistItem[];
  onRowClick?: (checkListItem: IChecklistItem) => void;
  generatedWorkOrders: WebWOQueryResponse[];
}

const FailedListItemTable: FC<Props> = ({
  checkListItems,
  onRowClick,
  generatedWorkOrders,
}) => {
  const generatedWorkOrderHash = generatedWorkOrders.reduce(
    (hash: Record<string, WebWOQueryResponse>, workOrder) => {
      hash[workOrder._id] = workOrder;
      return hash;
    },
    {}
  );

  const isInvalid = (checkListItem: IChecklistItem) => {
    return !Boolean(
      checkListItem.estimatedMaterials.amount >= 0 &&
        checkListItem.estimatedHours >= 0 &&
        checkListItem.dueDate &&
        checkListItem.title &&
        checkListItem.workOrderCategory &&
        checkListItem.workOrderSubcategory &&
        checkListItem.priority
    );
  };

  const renderStyledTaskName = useCallback(
    ({ value, row }: GridRenderCellParams) =>
      isInvalid(row) ? (
        <Typography color="error">{value}</Typography>
      ) : (
        value
      ),
    [checkListItems]
  );

  const styledColumns: GridColDef<IChecklistItem>[] = useMemo(
    () => [
      {
        headerName: "WO #",
        field: "workOrder",
        type: "number",
        align: "center",
        headerAlign: "center",
        valueFormatter: (params) =>  generatedWorkOrderHash[params.value || ""]?.number || "",
      },
      {
        headerName: "Task Name",
        field: "taskName",
        renderCell: renderStyledTaskName,
        flex: 1,
      },
      {
        headerName: "Title",
        field: "title",
        renderCell: renderStyledTaskName,
        flex: 1,
      },

      {
        headerName: "Description",
        field: "description",
        valueFormatter: (params) =>  params.value ? richTextToString(params.value) : "",
        flex: 1,
      },
      {
        headerName: "Hours",
        field: "estimatedHours",
        align: "center",
        headerAlign: "center",
      },
      {
        headerName: "Due Date",
        field: "dueDate",
        align: "center",
        headerAlign: "center",
        width: 150,
        valueGetter: (params) =>  params.value ? dateFromMMDDYYYY(params.value) : null,
        valueFormatter: (params) =>  params.value && toDDMMMYYYY(params.value),
      },
      {
        headerName: "Priority",
        field: "priority",
        align: "center",
        width: 150,
        headerAlign: "center",
      },
      {
        headerName: "Category",
        field: "workOrderCategory",
        align: "center",
        width: 150,
        headerAlign: "center",
      },
      {
        headerName: "Sub Category",
        field: "workOrderSubcategory",
        align: "center",
        width: 150,
        headerAlign: "center",
      },
    ],
    []
  );

  return (
    <Card sx={{ px: 2 }}>
      <StyledDataGrid
        rows={checkListItems}
        disableRowSelectionOnClick
        tableId="check-list-item-table"
        getRowId={(row: IChecklistItem) => row._id}
        onRowClick={(params) => onRowClick && onRowClick(params.row)}
        columns={styledColumns}
        disableFontSizeSelector
        persistColumnOrder
        persistPinnedColumns
        persistFilter
        persistSort
        pageSizeOptions={[10, 15, 25, 50, 100, 250, 500]}
        sx={{ maxHeight: "75vh" }}
      />
    </Card>
  );
};

export default FailedListItemTable;
