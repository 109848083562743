import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Blade from 'components/Blade';
import {
  selectCurrentWorkOrderId,
 setCurrentWorkOrderId,
} from 'store/slices/maintenanceSlice';
import { selectBladeMode, selectLoggedInUser, simpleGlobalMessage } from 'store/slices/systemSlice';
import WorkOrderView from './WorkOrderView';
import usePermissions, {
  FeatureType,
  ActionType,
} from "hooks/usePermissions";
import { Formik, FormikHelpers } from 'formik';
import { CircularProgress, IconButton, SvgIcon, Tooltip } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import Save from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import Summarize from '@mui/icons-material/Summarize';
import WorkOrderForm from 'features/maintenance/WorkOrderForm';
import _ from "lodash"
import { inspectionWorkOrderValidation, workOrderValidationSchema } from 'utils/formikAPI';
import { useAppDispatch } from 'store/store';
import { ReactComponent as PdfIcon } from "svg/pdf.svg";
import { selectAssets } from 'store/slices/assetSlice';
import OpenWith from '@mui/icons-material/OpenWith';
import InspectionModal from 'features/maintenance/modal/InspectionModal';
import { titleCase, toMMDDYYYY } from 'utils/util';
import BladeTabs from 'components/BladeTabs';
import Chat from 'components/chat/Chat';
import { IChat, IMessage, initChat, IPopulatedMessage } from 'store/models/Chat';
import { getChat, updateWorkOrderChat } from 'store/api/chat';
import { SocketContext } from 'contexts/SocketContext';
import { selectContracts } from 'store/slices/contractSlice';
import useInvalidFieldsAlert from 'hooks/useInvalidFieldsAlert';
import { PDFDownloadLink } from "@react-pdf/renderer";
import ServiceDamageReport from 'features/maintenance/pdf/ServiceDamageReport';
import { selectBranches } from 'store/slices/optionSlice';
import { selectClients } from 'store/slices/clientSlice';
import { WebWOQueryResponse, useGetGeneratedWorkOrdersByInspectionIdQuery, useGetWorkOrderByIdQuery, useUpdateWorkOrderMutation } from 'store/services/workOrder';
import { useGetInspectionByIdQuery } from 'store/services/inspection';
import { useGetClientContactByQueryQuery } from 'store/services/clientContact';
import { Contract } from 'store/models/Contract';
import { useGetPhotosByInspectionIdQuery, useGetPhotosByWorkOrderIdQuery } from 'store/services/workOrderPhoto';
import { oneMinute } from 'utils/timeValues';
import WorkOrderPDF from 'features/maintenance/pdf/WorkOrderPDF';
import { selectUsers } from 'store/slices/userSlice';
import useCompressImages from 'hooks/useCompressImages';

interface Props {
  // You only get a work order response when you're looking at the blade from the inspection modal
  workOrder?: WebWOQueryResponse;
  open?: boolean;
  onClose?: () => void;
}

const WorkOrderViewEditBlade: React.FC<Props> = ({workOrder, open, onClose}) => {
  const dispatch = useAppDispatch();
  const currentWorkOrderId = useSelector(selectCurrentWorkOrderId);
  const {data: currentWorkOrder, isLoading: isLoadingWorkOrder} = useGetWorkOrderByIdQuery(workOrder?._id || currentWorkOrderId || "", {skip: !workOrder && !currentWorkOrderId, pollingInterval: oneMinute})
  const {data: inspection, isLoading: inspectionLoading} = useGetInspectionByIdQuery(currentWorkOrder?.inspection || "", {skip: !currentWorkOrder?.inspection})
  const {data: generatedWorkOrders = []} = useGetGeneratedWorkOrdersByInspectionIdQuery({inspectionId: currentWorkOrder?.inspection || ""}, {skip: !currentWorkOrder?.inspection})
  const [updateWorkOrder] = useUpdateWorkOrderMutation()
  const assets = useSelector(selectAssets);
  const [mode, changeMode] = useState<'view' | 'edit'>('view');
  const bladeMode = useSelector(selectBladeMode);
  const checkMaintenancePermissions = usePermissions(FeatureType.MAINTENANCE);
  const userCanEditWorkOrders = checkMaintenancePermissions(ActionType.UPDATE); 
  const [showInspectionModal, setShowInspectionModal] = useState(false)
  const [chat, setChat] = useState<IChat>(initChat());
  const [isFetching, setFetching] = useState(true);
  const socketContext = useContext(SocketContext);
  const socket = socketContext?.socket;
  const showInvalidFieldAlert = useInvalidFieldsAlert<WebWOQueryResponse>();
  const users = useSelector(selectUsers)
  const branches = useSelector(selectBranches);
  const contracts = useSelector(selectContracts);
  const clients = useSelector(selectClients);
  const loggedInUser = useSelector(selectLoggedInUser);
  const workOrderBranchLabourRate = branches[assets[currentWorkOrder?.asset._id || ""]?.branch]?.labourCost;
  const contract: Contract | undefined = contracts[String(currentWorkOrder?.contract?._id)];
  const client = clients[contract?.client];
  const userHasFullMaintenanceRead = usePermissions(FeatureType.MAINTENANCE)(ActionType.READ)
  const userCanExportDamageReport = usePermissions(FeatureType.MAINTENANCE)(ActionType.UPDATE)
  const assetIsLoaded = Boolean(assets[currentWorkOrder?.asset?._id || ""]);
  const showServiceReportExport = currentWorkOrder?.inspection && inspection && loggedInUser && userCanExportDamageReport && assetIsLoaded;
  const {data: workOrderPhotos = [], isFetching: isLoadingWorkOrderPhotos} = useGetPhotosByWorkOrderIdQuery({workOrderId: currentWorkOrder?._id || ''}, {skip: !currentWorkOrder})
  const {data: inspectionPhotos = [], isFetching: isLoadingInspectionPhotos} = useGetPhotosByInspectionIdQuery({inspectionId: currentWorkOrder?.inspection || ''}, {skip: !currentWorkOrder?.inspection})

  const images = [...workOrderPhotos]
  if(currentWorkOrder?.inspection && inspectionPhotos.length) images.push(...inspectionPhotos)

  const { compressedImages, isCompressing } = useCompressImages({
    images,
    imagesLoading: isLoadingWorkOrderPhotos || isLoadingInspectionPhotos,
    dataId: currentWorkOrder?._id || "",
  });

  const { data: clientContact = [], isLoading: isLoadingClientContact } = useGetClientContactByQueryQuery(
    {_id: contract?.clientContact!},
      { skip: !contract?.clientContact }
    );

  const updateChat = (message: IPopulatedMessage) => {
    setChat(previousValues => ({ ...previousValues, messages: [...previousValues.messages, message]}));
  }

  function loadChat(chatId: IChat["_id"]) {
    setFetching(true);
    getChat(chatId, {
      onData: (data) => {
        if (!data) {
          dispatch(simpleGlobalMessage("Chat could not be loaded"));
          return;
        }
        setChat(data);
        setFetching(false);
      },
      onError: () => setFetching(false),
    });
  }

  useEffect(() => {
    const chatId = currentWorkOrder?.chat;
    const workOrderId = currentWorkOrder?._id;
    socket?.on("chat", updateChat)
    socket?.emit('subscribe-to-document', { documentId: workOrderId })

    if (chatId) {
      loadChat(chatId);
    } else {
      setChat(initChat());
      setFetching(false);
    }
    
    return () => {
      socket?.off("chat", updateChat)
      socket?.emit('unsubscribe-from-document', { documentId: workOrderId })
    }

  }, [currentWorkOrder?._id, currentWorkOrder?.chat]);


  useEffect(() => changeMode('view'), [currentWorkOrder?._id]);
  if(!currentWorkOrder) return null

  const handleUpdateWorkOrder = (
    workOrder: WebWOQueryResponse,
    { setSubmitting, resetForm, setValues }: FormikHelpers<WebWOQueryResponse>
  ) => {
    if (
      workOrder.status === "COMPLETE" &&
      currentWorkOrder.status !== "COMPLETE"
    ) {
      workOrder.completedDate = toMMDDYYYY(new Date());
    }
    if (
      currentWorkOrder.status === "COMPLETE" &&
      workOrder.status !== "COMPLETE"
    ) {
      workOrder.completedDate = null;
    }
    updateWorkOrder(workOrder)
      .then(() => {
        changeMode("view");
        resetForm();
      })
      .catch(() =>
        dispatch(simpleGlobalMessage("Work order could not be updated"))
      )
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleViewInspection = () => {
    setShowInspectionModal(true)
  }

  return (
    <Formik
      initialValues={currentWorkOrder}
      enableReinitialize
      onSubmit={handleUpdateWorkOrder}
      validationSchema={
        currentWorkOrder?.inspection
          ? inspectionWorkOrderValidation
          : workOrderValidationSchema
      }
    >
      {({ values, errors, isSubmitting, resetForm, submitForm }) => {
        const closeBlade = () => {
          onClose ? onClose() : dispatch(setCurrentWorkOrderId(undefined));
          resetForm();
        };
        const cancelEdit = () => {
          resetForm();
          changeMode("view");
        };
        const uploadMessage = (message: IMessage) => {
          if (!values) return;

          updateWorkOrderChat(values._id, message);
        };

        const disableSubmit =
          isSubmitting || _.isEqual(values, currentWorkOrder);

        const bladeActions =
          mode === "edit"
            ? [
                <IconButton
                  key="save"
                  color="primary"
                  onClick={() => {
                    submitForm();
                    showInvalidFieldAlert(errors);
                  }}
                  disabled={disableSubmit}
                >
                  {isSubmitting ? <CircularProgress size={24} /> : <Save />}
                </IconButton>,
                <IconButton color="primary" key="Cancel" onClick={cancelEdit}>
                  <Cancel />
                </IconButton>,
              ]
            : [
                isCompressing && showServiceReportExport ? <CircularProgress size={22} sx={{mr: 2}} /> : showServiceReportExport ?
                (
                  <PDFDownloadLink
                    document={
                      <ServiceDamageReport
                        images={compressedImages}
                        generatedWorkOrders={generatedWorkOrders}
                        labourCost={workOrderBranchLabourRate}
                        asset={assets[currentWorkOrder.asset._id]}
                        contract={contract}
                        client={client}
                        user={loggedInUser}
                        clientContact={clientContact}
                        workOrder={currentWorkOrder}
                      />
                    }
                    fileName={`${currentWorkOrder.asset.serialNumber}-Damage-Report`}
                    style={{ textDecoration: "none" }}
                    onClick={generatedWorkOrders.length ? () => {} : undefined}
                    key="service-damage-report"
                  >
                    <Tooltip title="Service Damage Report">
                      <IconButton
                        color="primary"
                        disabled={isLoadingClientContact}
                      >
                        <SvgIcon fontSize="small">
                          <Summarize />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </PDFDownloadLink>
                ) : (
                  <div />
                ),
                isCompressing ? <CircularProgress size={22} sx={{mr: 2}} /> : userHasFullMaintenanceRead && loggedInUser && !inspectionLoading && !isLoadingWorkOrder && !isCompressing  ? (
                  <PDFDownloadLink
                    document={
                      <WorkOrderPDF
                        images={compressedImages}
                        branch={
                          branches[assets[currentWorkOrder.asset._id]?.branch]
                        }
                        asset={assets[currentWorkOrder.asset._id]}
                        contract={contract}
                        client={client}
                        user={loggedInUser}
                        clientContact={clientContact}
                        workOrder={currentWorkOrder}
                        users={users}
                        inspection={currentWorkOrder.inspection ? inspection : undefined}
                      />
                    }
                    fileName={`${currentWorkOrder.asset.serialNumber}-${
                      currentWorkOrder.number
                    } - ${
                      currentWorkOrder.title
                        ? currentWorkOrder.title
                        : currentWorkOrder.inspectionType
                    }`}
                    style={{ textDecoration: "none" }}
                    onClick={generatedWorkOrders.length ? () => {} : undefined}
                    key={`${currentWorkOrder?._id}work-order-pdf`}
                  >
                    <Tooltip
                      title={`${
                        currentWorkOrder.inspection
                          ? "Inspection Report"
                          : "Work Order Report"
                      }`}
                    >
                      <IconButton
                        color="primary"
                        disabled={isLoadingClientContact}
                        key="pdf"
                      >
                        <SvgIcon fontSize="small">
                          <PdfIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </PDFDownloadLink>
                ) : (
                  <div />
                ),
                <IconButton
                  key="edit"
                  color="primary"
                  disabled={!userCanEditWorkOrders}
                  onClick={() => changeMode("edit")}
                >
                  <Edit />
                </IconButton>,
                <IconButton
                  color="primary"
                  key="full-screen-inspection-view"
                  onClick={handleViewInspection}
                  disabled={inspectionLoading}
                  sx={{
                    display: !currentWorkOrder.inspection ? "none" : "block",
                    height: 40,
                  }}
                >
                  {inspectionLoading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <OpenWith />
                  )}
                </IconButton>,
              ];

        return (
          <>
            <Blade
              open={open || Boolean(currentWorkOrderId)}
              changeOpen={closeBlade}
              backgroundColor="white"
              onTop={bladeMode === "workOrderViewEdit"}
              actions={bladeActions}
              title={`#${currentWorkOrder.number} -  ${
                currentWorkOrder.inspection
                  ? titleCase(currentWorkOrder.inspectionType)
                  : currentWorkOrder.title
              }`}
            >
              <BladeTabs
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                tabs={[
                  {
                    tabName: "details",
                    tabComponent:
                      mode === "edit" ? (
                        <WorkOrderForm
                          mode="edit"
                          isInspection={Boolean(currentWorkOrder.inspection)}
                        />
                      ) : (
                        <WorkOrderView workOrder={currentWorkOrder} />
                      ),
                  },
                  {
                    tabName: "Chat",
                    tabComponent: (
                      <Chat
                        chat={chat}
                        label="Message"
                        onSubmit={uploadMessage}
                        isFetching={isFetching}
                      />
                    ),
                  },
                ]}
                isClosing
              />
            </Blade>
            {showInspectionModal && inspection && (
              <InspectionModal
                inspection={inspection}
                open={showInspectionModal}
                setOpen={setShowInspectionModal}
                workOrder={currentWorkOrder}
              />
            )}
          </>
        );
      }}
    </Formik>
  );  
};

export default WorkOrderViewEditBlade;


