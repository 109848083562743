/* eslint-disable import/no-import-module-exports */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import { CollapseDrawerProvider } from "contexts/CollapseDrawerContext";
import { SettingsProvider } from "contexts/SettingsContext";
import { BrowserRouter } from "react-router-dom";
import './utils/highlight';
import 'react-quill/dist/quill.snow.css';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseInfo } from '@mui/x-license-pro';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY!);

const render = () => {
  const App = require("./App").default;
  ReactDOM.render(
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </Provider>,
    document.getElementById("root")
  );
};

render();

// Hot-reload component tree whenever a component file changes
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}
