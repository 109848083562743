import { AdditionalItem } from "types/AdditionalItem";
import { ArticleAttributes } from "types/ArticleAttributes";
import { initCurrency } from "types/Currency";
import { MMDDYYYY } from "utils/util";
import Address from "./Address";

export interface BillOfLading {
  _id: string;
  billOfLadingNumber: string;
  deliveryDate: MMDDYYYY | null;
  projectNumber: string;
  purchaseOrderNumber: string;
  pointOfOrigin: string;
  originator: string;

  consignor: string;
  consignorPhone: string;
  consignorAddress: string;

  scheduledPickupTimeAtOrigin: string;
  scheduledDeliveryTimeAtDestination: string;

  consigneeName: string;
  consigneeAddress: string;
  consigneeDestination: string;
  consigneeContactName: string;
  consigneeContactPhone: string;

  assetArticles: { asset: string; attributes: ArticleAttributes }[];
  additionalItems: (AdditionalItem & ArticleAttributes)[];
  specialAgreementBetweenConsignorAndCarrier: string;
  declaredValuation: number | null;

  carrierCompanyName: string;
  carrierPhone: string;

  consignorLoadingTimeTotalMinutes: number | null;
  consignorDepartureTime: string;

  consigneeSiteArrivalTime: string;
  consigneeSiteDepartureTime: string;
  consigneeUnloadingTimeTotalMinutes: number | null;

  consigneeOtherTimeAtOriginOrDestinationTotalMinutes1: number | null;
  consigneeOtherTimeAtOriginOrDestinationExplanation1: string;

  consigneeOtherTimeAtOriginOrDestinationTotalMinutes2: number | null;
  consigneeOtherTimeAtOriginOrDestinationExplanation2: string;

  consigneeOtherTimeAtOriginOrDestinationTotalMinutes3: number | null;
  consigneeOtherTimeAtOriginOrDestinationExplanation3: string;

  comments: string;

  acknowledgementAtOriginConsignorName: string;
  acknowledgementAtOriginConsignorDate?: MMDDYYYY | null;

  acknowledgementAtOriginCarrierName: string;
  acknowledgementAtOriginCarrierDate?: MMDDYYYY | null;

  acknowledgementAtDestinationConsigneeName: string;
  acknowledgementAtDestinationConsigneeDate?: MMDDYYYY | null;

  acknowledgementAtDestinationCarrierName: string;
  acknowledgementAtDestinationCarrierDate?: MMDDYYYY | null;

  billTo: Address | null;
}

export const blankBillOfLading = (): BillOfLading => ({
  _id: "",
  billOfLadingNumber: "",
  deliveryDate: null,
  projectNumber: "",
  purchaseOrderNumber: "",
  pointOfOrigin: "",
  originator: "",

  consignor: "",
  consignorPhone: "",
  consignorAddress: "",

  scheduledPickupTimeAtOrigin: "",
  scheduledDeliveryTimeAtDestination: "",

  consigneeName: "",
  consigneeAddress: "",
  consigneeDestination: "",
  consigneeContactName: "",
  consigneeContactPhone: "",

  assetArticles: [],
  additionalItems: [],
  specialAgreementBetweenConsignorAndCarrier: "",
  declaredValuation: 0,

  carrierCompanyName: "",
  carrierPhone: "",

  consignorLoadingTimeTotalMinutes: 0,
  consignorDepartureTime: "",

  consigneeSiteArrivalTime: "",
  consigneeSiteDepartureTime: "",

  consigneeUnloadingTimeTotalMinutes: 0,

  consigneeOtherTimeAtOriginOrDestinationTotalMinutes1: 0,
  consigneeOtherTimeAtOriginOrDestinationExplanation1: "",

  consigneeOtherTimeAtOriginOrDestinationTotalMinutes2: 0,
  consigneeOtherTimeAtOriginOrDestinationExplanation2: "",

  consigneeOtherTimeAtOriginOrDestinationTotalMinutes3: 0,
  consigneeOtherTimeAtOriginOrDestinationExplanation3: "",

  comments: "",

  acknowledgementAtOriginConsignorName: "",
  acknowledgementAtOriginConsignorDate: null,

  acknowledgementAtOriginCarrierName: "",
  acknowledgementAtOriginCarrierDate: null,

  acknowledgementAtDestinationConsigneeName: "",
  acknowledgementAtDestinationConsigneeDate: null,

  acknowledgementAtDestinationCarrierName: "",
  acknowledgementAtDestinationCarrierDate: null,

  billTo: null
});

export const blankAdditionalItem = () : AdditionalItem & ArticleAttributes => ({
  description: "",
  quantity: 1,
  hazardous: false,
  width: 0,
  length: 0,
  height: 0,
  weight: 0,
  amount: initCurrency(),
  notes: ""
});
