import store from "store/store";
import { createMongoId } from "utils/createMongoId";
import { toMMDDYYYY } from "utils/util";
import { AssetDeliverable } from "./AssetDeliverable";
import { Branch } from "./Branch";
import { Contract } from "./Contract";
import UserData from "./UserData";
import { ICurrency } from "types/Currency";
import { PurchaseOrder } from "./WorkOrder";
import IAccessory from "./Accessory";
import { Asset } from "./Asset";

interface BaseMovement {
  _id: string;
  dateOfMove: string;
  branch: string;
  type: MovementType;
  status: MovementStatus;
  billOfLading: string | null;
  transportCompany: string | null;
  transportCompanyName: string;
  transportInvoiceNumber: string;
  createdBy: UserData["_id"];
  dateCompleted: Date | null;
  contract?: Contract["_id"];
  billOfLadingNumber: string;
  to: string;
  from: string;
  batch: string;
  notes: string;
  bidCost?: ICurrency;
  actualCost?: ICurrency;
  purchaseOrder?: PurchaseOrder
  tentative: boolean;
  leaseTakeover: boolean
}

export interface AssetMovement extends BaseMovement {
  asset: Asset["_id"];
  accessory: null | undefined
  nonAsset: null | undefined
}

export interface AccessoryMovement extends BaseMovement {
  accessory: IAccessory["_id"];
  asset: null | undefined
  nonAsset: null | undefined
}

export interface NonContractAccessoryMovement extends BaseMovement {
  accessory: null | undefined;
  asset: null | undefined;
  nonAsset: {
    description: string;
    branch: Branch["_id"];
    _id: string;
  };
}

type Movement = AssetMovement | NonContractAccessoryMovement | AccessoryMovement;

export default Movement;

export enum MovementStatus {
  cancelled = "CANCELLED",
  scheduled = "SCHEDULED",
  dispatched = "DISPATCHED",
  completed = "COMPLETED",
}

export enum MovementType {
  in = "IN",
  out = "OUT",
  relocate = "RELOCATE",
}

export const initMovement = (movement?: Partial<Movement>): Movement => {
  const createdBy = store.getState().system.loggedInUserId as string;
  
  const newMovement: BaseMovement =  {
    _id: createMongoId(),
    dateOfMove: toMMDDYYYY(new Date()),
    branch: '',
    transportCompany: null,
    type: MovementType.in,
    status: MovementStatus.completed,
    billOfLading: null,
    transportCompanyName: "",
    transportInvoiceNumber: "",
    contract: undefined,
    dateCompleted: null,
    createdBy,
    billOfLadingNumber: "",
    to: "",
    from: "",
    batch: "",
    notes: "",
    tentative: false,
    leaseTakeover: false,
    ...movement,
  };

  if (movement?.asset) {
    return newMovement as AssetMovement;
  } else if (movement?.accessory) {
    return newMovement as AccessoryMovement;
  } else {
    return newMovement as NonContractAccessoryMovement;
  }
};

export const findAndUpdateMovement = (
  movementId: string,
  movementUpdate: Partial<BaseMovement>,
  contract: Contract
) => {
  const newMovements = contract.movements.map((movement) =>
    movement._id === movementId ? { ...movement, ...movementUpdate } : movement
  );
  return newMovements;
};

export const isContractMovement = (movement: BaseMovement) => Boolean((movement).contract);

export function determineDefaultDateOfMove(
  movementStatus: MovementType,
  movementItem: AssetDeliverable | IAccessory,
  contract: Contract
) {
  let date;

  if (movementStatus === MovementType.out) {
    date = movementItem.customBillingStart || contract?.outDate;
  } else {
    date = movementItem.customBillingEnd || contract?.endDate;
  }

  return date || toMMDDYYYY(new Date());
}

