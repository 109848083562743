import { Card, Grid } from "@mui/material";
import React from "react";

interface Props {
    children: React.ReactNode
}

const FormContainer: React.FC<Props> = ({children}) => {
  return (
    <Card sx={{ p: 3 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        {children}
      </Grid>
    </Card>
  );
};

export default FormContainer;
