import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Blade from "components/Blade";
import { selectBladeMode, setBladeMode } from "store/slices/systemSlice";
import { Box, Card, IconButton, Typography } from "@mui/material";
import UserForm from "features/users/blade/UserForm";
import { selectCurrentUser, setCurrentUserId } from "store/slices/userSlice";
import UserData from "store/models/UserData";
import { Form, Formik, FormikHelpers } from "formik";
import api from "store/api";
import UserView from "features/users/UserView";
import { initUser } from "store/api/user";
import Edit from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import usePermissions, {
  FeatureType,
  ActionType,
  userIsSuper,
  userIsAdministrator,
} from "hooks/usePermissions";
import { userValidationSchema } from "utils/formikAPI";
import { getUserFullName } from "utils/util";
import _ from "lodash"
import { useAppDispatch } from 'store/store';

interface Props {}

const UserViewEditBlade: React.FC<Props> = () => {
  const userBladeModes = ["userCreate", "userViewEdit"];
  const dispatch = useAppDispatch();
  const bladeMode = useSelector(selectBladeMode);
  const currentUser = useSelector(selectCurrentUser) as UserData;
  const isOpen = userBladeModes.includes(bladeMode) && Boolean(currentUser);
  const [isEditing, setEditing] = useState(false);
  const checkUserPermissions = usePermissions(FeatureType.USER);
  const userCanEditUsers = checkUserPermissions(ActionType.UPDATE);
  const userCanEditPowerUsers = checkUserPermissions(ActionType.PROMOTE);

  useEffect(
    function resetToViewOnUserChange() {
      if (isEditing) {
        setEditing(false);
      }
  }, [currentUser?._id]);

  const updateUser = (
    user: UserData,
    { setSubmitting }: FormikHelpers<UserData>
  ) => {
    api.users.updateOne(user, {
      onData: () => setEditing(false),
      onError: () => setSubmitting(false),
    });
  };

  const handleModalClose = () => {
    dispatch(setCurrentUserId(undefined));
    dispatch(setBladeMode("none"));
    setEditing(false);
  };

  function disableEdit() {
    if (userIsSuper(currentUser) || userIsAdministrator(currentUser)) {
      return !userCanEditPowerUsers;
    } else {
      return !userCanEditUsers;
    }
  }
  const initialValues = initUser(currentUser);

  return isOpen ? (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={userValidationSchema}
      onSubmit={updateUser}
    >
      {({ resetForm, submitForm, values }) => {
        const bladeActions = isEditing ? [
          <IconButton
            color="primary"
            onClick={submitForm}
            disabled={_.isEqual(currentUser, values)}
            data-testid="submitUser"
            key="submitUser"
          >
            <SaveIcon />
          </IconButton>,
          <IconButton
            key="cancel"
            onClick={() => {
              resetForm();
              setEditing(false);
            }}
          >
            <CancelIcon />
          </IconButton>,
        ] : [
          <IconButton
            key="edit"
            disabled={disableEdit()}
            color="primary"
            onClick={() => {
              resetForm();
              setEditing(true);
            }}
          >
            <Edit />
          </IconButton>,
        ]

        return (
          <Form>
            <Blade
              open
              actions={bladeActions}
              changeOpen={handleModalClose}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ mb: -2 }}
              >
                <Typography variant="h5">
                  {getUserFullName(initialValues)}
                </Typography>
              </Box>
              <Card sx={{ p: 3 }}>
                {isEditing ? <UserForm /> : <UserView user={currentUser} />}
              </Card>
            </Blade>
          </Form>
        )
      }}
    </Formik>
  ) : null;
};
export default UserViewEditBlade;
