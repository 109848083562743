import { Asset } from "store/models/Asset"
import { Contract, inactiveContractStatuses } from "store/models/Contract"

export function assetOnActiveContractCanBeRemoved(contract: Contract, asset: Asset) {
    const assetDeliverable = contract.assetDeliverables.find(deliverable => deliverable.asset === asset._id)
    const contractNotConcluded = contract && !inactiveContractStatuses.includes(contract.status)
    const assetHasNoMovements = !contract?.movements.some(movement => movement.asset === asset._id)
    const assetIsActiveOnContract = assetDeliverable?.isActiveOnContract
    const assetIsNotOnLease = asset.status !== "ON LEASE"
    return contractNotConcluded && assetHasNoMovements && assetIsActiveOnContract && assetIsNotOnLease
  }
  