import React, { FC } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { TextFieldProps, TextField } from "@mui/material";
import { PrimitiveSelect } from "./select";
import { ICurrency } from "types/Currency";
import { useSelector } from "react-redux";
import { selectCurrencies } from "store/slices/configSlice";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps<number>, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onFocus={event => {
        if (event.target.value === "$0" && onChange) {
          onChange({target: {name: props.name, value: "$"}});
        }
      }}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  }
);

const CurrencySelect: FC<TextFieldProps> = (props) => {
  const currencies = useSelector(selectCurrencies);
  return (
    <PrimitiveSelect
      {...props}
      options={currencies}
      variant="standard"
      sx={{
        width: 100,
        padding: 0,
      }}
    />
  )
}

interface CurrencyTextFieldProps {
  value: Omit<ICurrency, "amount"> & { amount?: number };
  onCurrencyChange: TextFieldProps["onChange"];
  disableCurrencySelect?: boolean
}

const CurrencyTextField: FC<TextFieldProps & CurrencyTextFieldProps> = ({
  value,
  onCurrencyChange,
  name,
  onBlur,
  disabled,
  disableCurrencySelect = false,
  ...props
}) => {
  const displayValue = value;
  return (
    <TextField
      {...props}
      value={displayValue.amount}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        endAdornment: (
          <CurrencySelect
            onChange={onCurrencyChange}
            value={displayValue.currency}
            onBlur={onBlur}
            name={`${name}.currency`}
            disabled={disabled || disableCurrencySelect}
          />
        ),
      }}
    />
  );
};

export default CurrencyTextField; 
