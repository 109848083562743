import React, { useState, useEffect } from 'react';
import api from 'store/api';
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
} from "@mui/material";
import NoWrap from "components/NoWrap";
import { selectCurrentAsset } from 'store/slices/assetSlice';
import { useSelector } from 'react-redux';
import { AssetActionRecord } from 'store/models/AssetActionRecord';
import { toLocalDatePretty } from 'utils/util';

const AssetStatusHistory: React.FC<{}> = () => {
  const currentAsset = useSelector(selectCurrentAsset);
  const [statusRecords, changeStatusRecords] = useState<AssetActionRecord[]>([]);
  const [isSubmitting, setSubmitting] = useState(true);

  function refreshStatusRecords(assetId: string) {
    api.assetActionRecord.getAllAssetActionRecords({ asset: assetId, fieldChanged: "status", limit: 5 }, {onData: (data) => {
      changeStatusRecords(data)
      setSubmitting(false)
    }})
  }

  useEffect(() => {
    if (currentAsset?._id) {
      setSubmitting(true);
      refreshStatusRecords(currentAsset._id);
    }
  }, [currentAsset]);

  const tableFillerData = () => (
    <TableRow>
      <TableCell>No</TableCell>
      <TableCell>Records</TableCell>
      <TableCell>Available</TableCell>
    </TableRow>
  );

  return (
    <Card>
    <TableContainer sx={{ overflowX: "initial" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusRecords.length > 0 && !isSubmitting
            ? statusRecords.map(
              ({
                _id,
                actionContext,
                createdDate,
                newValueAsHumanReadable,
              }) => (
                <TableRow key={_id} sx={{ cursor: "default" }}>
                  <TableCell>{newValueAsHumanReadable}</TableCell>
                  <TableCell>
                    <NoWrap>{toLocalDatePretty(createdDate)}</NoWrap>
                  </TableCell>
                  <TableCell>{actionContext}</TableCell>
                </TableRow>
              ),
            )
            : !isSubmitting && tableFillerData()}
        </TableBody>
      </Table>
        {isSubmitting && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )}
    </TableContainer>
    </Card>
  );
};

export default AssetStatusHistory;
