import {
  Contract, ContractStatus,
} from "store/models/Contract";
import { errorHandler } from "./shared";
import { toMMDDYYYY } from 'utils/util';
import { createMongoId } from "utils/createMongoId";
import { addHours } from "date-fns";
import { Asset } from "store/models/Asset";
import { sendToBackend } from "./util";
import { Currency } from "dinero.js";
import { ICurrency, initCurrency } from "types/Currency";
import Movement from "store/models/Movement";
import { IContractHistory } from "store/models/ContractHistory";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import IAccessory from "store/models/Accessory";

const contractsApi = {
  getAllActive: (
    actions?: {
      onData?: (data: Contract[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Contract[]>({
      route: `contract`,
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getAllInactive: (
    actions?: {
      onData?: (data: Contract[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Contract[]>({
      route: `contract/inactive`,
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getOne: (
    contractId: Contract["_id"],
    actions?: {
      onData?: (data: Contract) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Contract>({
      route: `contract/single?contractId=${contractId}`,
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateContract: (
    contract: Partial<Contract> & {_id: Contract["_id"]},
    actions?: {
      onData?: (data: { assets: Asset[]; contract: Contract }) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{ assets: Asset[]; contract: Contract }>({
      route: "contract/",
      method: "PATCH",
      body: contract,
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  createOne: (
    contract: Omit<Contract, "_id">,
    actions?: {
      onData?: (data: { assets: Asset[]; contract: Contract }) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{ assets: Asset[]; contract: Contract }>({
      route: "contract/",
      method: "POST",
      body: contract,
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  swapAsset: (
    contractId: string,
    oldAssetId: string,
    newAssetId: string,
    actions?: {
      onData?: (data: {contract: Contract, newAsset: Asset, oldAsset: Asset}) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract, newAsset: Asset, oldAsset: Asset}>({
      route: "contract/swapAsset",
      method: "PATCH",
      body: { contractId, oldAssetId, newAssetId },
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  removeAsset: (
    contractId: string,
    assetToRemoveId: string,
    actions?: {
      onData?: (data: {contract: Contract, removedAsset: Asset}) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract, removedAsset: Asset}>({
      route: "contract/removeAsset",
      method: "PATCH",
      body: { contractId, assetToRemoveId },
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  removeAccessory: (
    contractId: string,
    accessoryToRemoveId: string,
    actions?: {
      onData?: (data: {contract: Contract }) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract }>({
      route: "contract/removeAccessory",
      method: "PATCH",
      body: { contractId, accessoryToRemoveId },
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  addAsset: (
    contractId: string,
    assetsToAdd: string[],
    actions?: {
      onData?: (data: {contract: Contract, addedAssets: Asset[] }) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract, addedAssets: Asset[] }>({
      route: "contract/addAsset",
      method: "PATCH",
      body: { contractId, assetsToAdd },
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  addAccessory: (
    contractId: string,
    accessory: IAccessory,
    actions?: {
      onData?: (data: {contract: Contract }) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract }>({
      route: "contract/addAccessory",
      method: "PATCH",
      body: { contractId, accessory },
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateAssetDeliverables: (
    contractId: string,
    assetDeliverables: AssetDeliverable[],
    actions?: {
      onData?: (contract: Contract) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Contract>({
      route: "contract/assetDeliverables",
      method: "PATCH",
      body: { contractId, assetDeliverables},
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateAccessories: (
    contractId: string,
    accessories: IAccessory[],
    actions?: {
      onData?: (contract: Contract) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Contract>({
      route: "contract/updateAccessories",
      method: "PATCH",
      body: { contractId, accessories},
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  revertContractStatus: (
    contractId: string,
    currentStatus: Contract["status"],
    actions?: {
      onData?: (data: {contract: Contract, assets: Asset[]}) => void;
      onError?: (message: string) => void;
      onComplete?: (message: string) => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract, assets: Asset[]}>({
      route: "contract/revertContractStatus",
      method: "PATCH",
      body: { contractId, currentStatus },
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  advanceContractStatus: (
    contractId: string,
    currentStatus: Contract["status"],
    actions?: {
      onData?: (data: {contract: Contract, assets: Asset[]}) => void;
      onError?: (message: string) => void;
      onComplete?: (message: string) => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract, assets: Asset[]}>({
      route: "contract/advanceContractStatus",
      method: "PATCH",
      body: { contractId, currentStatus },
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  cancelContract: (
    contractId: string,
    action: "CANCEL" | "DENY",
    actions?: {
      onData?: (data: {contract: Contract, assets: Asset[]}) => void;
      onError?: (message: string) => void;
      onComplete?: (message: string) => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract, assets: Asset[]}>({
      route: "contract/cancel",
      method: "PATCH",
      body: { contractId, action },
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getCSV: (
    actions?: {
      onData?: (data: BlobPart) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<BlobPart>({
      route: `contract/csv`,
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateContractMovements: (
    contractId: string,
    movementUpdates: Movement[],
    action: "Add" | "Remove" | "Update",
    actions?: {
      onData?: (data: {contract: Contract, assets: Asset[]}) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract, assets: Asset[]}>({
      route: `contract/movements`,
      body: {contractId, movementUpdates, action},
      method: "PATCH",
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateMovementStatus: (
    contractId: string,
    movementId: string,
    newStatus: string,
    actions?: {
      onData?: (data: {contract: Contract, assets: Asset[]}) => void;
      onError?: (message: string) => void;
      onComplete?: (meassge: string) => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<{contract: Contract, assets: Asset[]}>({
      route: `contract/movements/status`,
      body: {contractId, movementId, newStatus},
      method: "PATCH",
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
};


export const placeholderAddress = {prefix: "", latLng: {lat: "", lng: ""}};

export const initContract = (
  contract?: Partial<
    Contract & {
      createdBy: string;
      securityDeposit: { received: boolean; amount: ICurrency };
    }
  >,
  currency?: Currency,
): Contract => ({
  _id: createMongoId(),
  branches: [],
  customerName: "",

  additionalComments: "",
  deliveryInstructions: "",
  contractType: "Rental",
  contractSigned: false,
  startDate: toMMDDYYYY(new Date()),
  endDate: toMMDDYYYY(new Date()),
  outDate: toMMDDYYYY(new Date()),
  reservationExpiry: toMMDDYYYY(addHours(new Date(), 72)),
  evergreen: false,
  assetDeliverables: [],
  status: "AWAITING APPROVAL",
  projectNumber: "",
  createdBy: "",
  client: "",
  movements: [],
  billTo: placeholderAddress,
  shipTo: placeholderAddress,
  accessories: [],
  securityDeposit: {
    received: false,
    amount: initCurrency({amount: 0, currency: currency || "CAD"})
  },
  clientPoNumber: "",
  quoteNumber: "",
  ppsaRegistration: "",
  clientContact: null,
  siteContact: null,
  ...contract,
});

export const initAccessory = (
  accessory: Partial<IAccessory> & { name: string; rate: IAccessory["rate"]}
): IAccessory => ({
  _id: createMongoId(),
  asset: null,
  unitOfMeasure: "EA",
  quantity: 1,
  ...accessory,
  rate: accessory.rate,
  name: accessory.name,
  customBillingStart: null,
  customBillingEnd: null,
});

export const contractAssetMoveableStatuses: ContractStatus[] = ["AWAITING CONFIRMATION", "PENDING DELIVERY", "ACTIVE"];

export default contractsApi;
