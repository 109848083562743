import AssetCreateBlade from "features/assets/blades/AssetCreateBlade";
import AssetViewEditBlade from "features/assets/blades/view-edit/AssetViewEditBlade";
import ClientCreateBlade from "features/clients/blades/ClientCreateBlade";
import ClientViewEditBlade from "features/clients/blades/ClientViewEditBlade";
import ContractViewEditBlade from "features/contracts/blades/ContractViewEditBlade";
import WorkOrderCreateBlade from "features/maintenance/blades/WorkOrderCreateBlade";
import WorkOrderViewEditBlade from "features/maintenance/blades/WorkOrderViewEditBlade";
import MovementViewEditBlade from "features/movements/blades/MovementViewEditBlade";
import TransportCompanyCreateBlade from "features/transportCompanies/TransportCompanyCreateBlade";
import TransportCompanyViewEditBlade from "features/transportCompanies/TransportCompanyViewEditBlade";
import UserCreateBlade from "features/users/blade/UserCreateBlade";
import UserViewEditBlade from "features/users/blade/UserViewEditBlade";
import React from "react";

interface Props {}

const Blades: React.FC<Props> = () => {
  return (
    <>
      <MovementViewEditBlade />
      <UserCreateBlade />
      <UserViewEditBlade />
      <AssetViewEditBlade />
      <AssetCreateBlade />
      <ClientCreateBlade />
      <ClientViewEditBlade />
      <ContractViewEditBlade />
      <WorkOrderCreateBlade />
      <WorkOrderViewEditBlade />
      <TransportCompanyViewEditBlade />
      <TransportCompanyCreateBlade />
    </>
  );
};
export default Blades;
