import React from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import StarfleetLoading from './StarfleetLoading';

// ----------------------------------------------------------------------

export default function LoadingOverlay({
  sx,
  children,
  loading,
  ...other
}: BoxProps & { loading: boolean }) {
  return (
    <Box
      sx={{
        opacity: loading ? 0.4 : 1,
        position: 'relative',
        pointerEvents: loading ?'none' : 'inherit'

      }}
      {...other}
    >
      {loading && (
        <Box
          sx={{
            maxWidth: 360,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
          }}
        >
          <StarfleetLoading />
        </Box>
      )}

      {children}
    </Box>
  );
}
