import { createMongoId } from 'utils/createMongoId';
import { TransportCompany } from 'store/slices/transportCompanySlice';
import * as Yup from 'yup';
import { latLngRequired, yupAddress } from 'utils/formikAPI';

export const transportCompanyValidationSchema = Yup.object<
  Partial<Record<keyof TransportCompany, Yup.AnySchema>>
>({
  name: Yup.string().required("Required"),
  phoneNumber: Yup.string().default(""),
  address: Yup.object(yupAddress).nullable(),
});

export const initTransportCompany = (transportCompany: Partial<TransportCompany>): TransportCompany => {
  return ({
    _id: createMongoId(),
    name: "",
    phoneNumber: "",
    address: null, 
    ...transportCompany
  })
}