import DeleteForever from '@mui/icons-material/DeleteForever';
import BillOfLadingIcon from '@mui/icons-material/AssignmentOutlined';
import { IconButton, ButtonGroup, ButtonProps, Button, Box } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal';
import DateSelector from 'components/DateSelector';
import usePermissions, { ActionType, FeatureType, UIActionType } from 'hooks/usePermissions';
import React, { Dispatch, SetStateAction } from 'react'
import { useSelector } from 'react-redux';
import Movement, { MovementStatus } from 'store/models/Movement';
import { setMovementContractId, setCurrentBillOfLadingId, setBillOfLadingMovementId, selectCurrentContract, setContract } from 'store/slices/contractSlice';
import { setGlobalMessage, setModalMode, simpleGlobalMessage } from 'store/slices/systemSlice';
import { useAppDispatch } from 'store/store';
import { isValid } from 'date-fns';
import api from 'store/api';
import { MMDDYYYY, isMMDDYYYY, toMMDDYYYY } from 'utils/util';
import { setManyAssets } from 'store/slices/assetSlice';
import { movementApi } from 'store/services/movement';


interface Props {
  movement: Movement;
  disabled?: boolean;
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

const ContractMovement: React.FC<Props> = ({
  movement,
  disabled,
  changeIsSubmitting
}) => {
  const currentContract = useSelector(selectCurrentContract);
  const checkBOLPermissions = usePermissions(FeatureType.BILL_OF_LADING);
  const userCanViewBOLs = checkBOLPermissions(ActionType.READ);
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT);
  const userCanEditMovements = checkMovementPermissions(ActionType.UPDATE);

  const dispatch = useAppDispatch();

  const viewBillOfLading = () => {
    const userCanReadBOLs = checkBOLPermissions(
      ActionType.READ,
      UIActionType.ONCLICK
    );
    if (!userCanReadBOLs) return;
    dispatch(setMovementContractId(currentContract?._id));
    dispatch(setCurrentBillOfLadingId(movement.billOfLading));
    dispatch(
      setModalMode(
        movement.billOfLading ? "billOfLadingViewEdit" : "billOfLadingCreate"
      )
    );
    dispatch(setBillOfLadingMovementId(movement._id));
  };


  const removeMovement = () => {
    const userCanEditMovements = checkMovementPermissions(
      ActionType.UPDATE,
      UIActionType.ONCLICK
    );
    if (!userCanEditMovements || !currentContract) return;

    if (movement.status === MovementStatus.completed) {
      dispatch(simpleGlobalMessage("You cannot remove COMPLETED movements"))
      return;
    }

    changeIsSubmitting(true);

    api.contracts.updateContractMovements(
      currentContract._id,
      [movement],
      "Remove",
      {
        onData: ({ contract, assets }) => {
          dispatch(setContract(contract));
          dispatch(setManyAssets(assets));
        },
        onComplete: () => {
          changeIsSubmitting(false);
          dispatch(
            simpleGlobalMessage("Successfully removed movement", "success")
          );
          dispatch(movementApi.util.invalidateTags([{type: "Movements", id: "LIST"}]))
        },
        onError: (message) => {
          changeIsSubmitting(false);
          dispatch(
            setGlobalMessage({messageText: message, severity: "error", show: true})
          );
        }
      }
    );
  };

  const handleDateOfMoveInputChange = (_: unknown, enteredDate?: string) => {
    if (!currentContract) return;
    const newDate = enteredDate as MMDDYYYY;
    if (isMMDDYYYY(newDate) && isValid(new Date(newDate))) {
      api.contracts.updateContractMovements(
        currentContract._id,
        [{ ...movement, dateOfMove: toMMDDYYYY(newDate) }],
        "Update",
        {
          onData: (data) => {
            dispatch(setContract(data.contract));
          },
        }
      );
    }
  };

  const handleDateOfMoveAccept = (date: unknown) => {
    if (!currentContract) return;
    const newDate = date as Date;
    if (newDate && isValid(new Date(newDate))) {
      api.contracts.updateContractMovements(
        currentContract._id,
        [{ ...movement, dateOfMove: toMMDDYYYY(newDate) }],
        "Update",
        {
          onData: (data) => {
            dispatch(setContract(data.contract));
            dispatch(movementApi.util.invalidateTags([{type: "Movements", id: "LIST"}]))
          },
        }
      );
    }
  }

  function updateMovementStatus(status: MovementStatus) {
    if (!currentContract || status === movement.status) return;
    if (currentContract.status === "CONCLUDED") {
      dispatch(
        simpleGlobalMessage(
          "You cannot change the status of movements on concluded contracts"
        )
      );
      return;
    }
    changeIsSubmitting(true);
    api.contracts.updateMovementStatus(
      currentContract._id,
      movement._id,
      status,
      {
        onData: (data) => {
          dispatch(setContract(data.contract));
          dispatch(setManyAssets(data.assets));
        },
        onComplete: (message) => {
          dispatch(
            setGlobalMessage({
              messageText: message,
              severity: "success",
              show: true,
            })
          );
          changeIsSubmitting(false)
          dispatch(movementApi.util.invalidateTags([{type: "Movements", id: "LIST"}]))
        },
        onError: () => changeIsSubmitting(false),
      }
    );
  }

  const isDisabled = !userCanEditMovements || disabled
  

  return (
    <Box
      key={movement._id}
      sx={{
        display: "grid",
        justifyItems: "start",
        alignItems: "center",
        gridTemplateColumns: "auto 40px 40px",
        rowGap: 1,
        gridTemplateAreas: `
                "movementStatus movementStatus type"
                "dateOfMove remove billOfLading"`,
        mb: 2,
        "&:not(:first-of-type)": {
          mt: 3,
        },
      }}
    >
      <DateSelector
        disabled={isDisabled}
        value={movement.dateOfMove}
        className="dateOfMove"
        onChange={handleDateOfMoveInputChange}
        onAccept={handleDateOfMoveAccept}
        textFieldProps={{
          size: "small",
          fullWidth: true,
        }}
      />
      <Box
        sx={(theme) => ({
          gridArea: "type",
          justifySelf: "center",
          fontSize: theme.typography.body2,
        })}
      >
        {movement.type.substring(0, 3)}:{" "}
      </Box>

      <ConfirmationModal
        handleConfirmation={() => removeMovement()}
        message="Are you sure you want to delete this movement?"
        sx={{
          gridArea: "remove",
        }}
        disabled={isDisabled}
      >
        <IconButton
          color="primary"
          disabled={isDisabled}
        >
          <DeleteForever />
        </IconButton>
      </ConfirmationModal>
      <Box sx={{ gridArea: "movementStatus" }}>
        <ButtonGroup
          size="small"
          disableElevation
          disabled={isDisabled}
        >
          {Object.values(MovementStatus).map((status) => {
            const color: ButtonProps["color"] =
              movement.status === status ? "primary" : "inherit";

            return (
              <Button
                disabled={disabled}
                key={status}
                color={color}
                onClick={() => updateMovementStatus(status)}
              >
                {status}
              </Button>
            );
          })}
        </ButtonGroup>
      </Box>
      <IconButton
        onClick={viewBillOfLading}
        disabled={!userCanViewBOLs}
        color="primary"
        sx={{
          gridArea: "billOfLading",
        }}
      >
        <BillOfLadingIcon
          color={movement.billOfLading ? "primary" : "disabled"}
        />
      </IconButton>
    </Box>
  );
};

export default ContractMovement