import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentClient, setCurrentClientId, setClient } from 'store/slices/clientSlice';
import { selectBladeMode, simpleGlobalMessage } from 'store/slices/systemSlice';
import { Form, Formik, FormikHelpers } from 'formik';
import { initClient } from 'store/api/client';
import { clientValidationSchema } from 'utils/formikAPI';
import usePermissions, { ActionType, FeatureType, UIActionType } from 'hooks/usePermissions';
import Save from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import Edit from '@mui/icons-material/Edit';
import { Box, Card, Typography, IconButton, CircularProgress } from '@mui/material';
import BladeTabs from 'components/BladeTabs';
import { Client } from 'store/models/Client';
import api from 'store/api';
import _ from "lodash"
import Blade from 'components/Blade';
import { useAppDispatch } from 'store/store';
import { selectDefaultUserCountry } from 'store/slices/configSlice';
import ClientContracts from './bladeContent/ClientContracts';
import ClientForm from './bladeContent/ClientForm';
import ClientView from './bladeContent/ClientView';
import ClientPersonnel from './bladeContent/ClientPersonnel';
import { parseAddress } from 'store/models/Address';

interface Props {}
const ClientViewEditBlade: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const currentClient = useSelector(selectCurrentClient);
  const bladeMode = useSelector(selectBladeMode);
  const currentClientSelected = Boolean(currentClient);
  const defaultCountry = useSelector(selectDefaultUserCountry);
  const initialValues = currentClient || initClient({country: defaultCountry});
  const checkClientPermissions = usePermissions(FeatureType.CLIENTS);
  const userCanEditClients = checkClientPermissions(ActionType.UPDATE);
  const [isEditing, setEditing] = useState(false);

  const updateClient = (client: Client, { setSubmitting, resetForm }: FormikHelpers<Client>) => {
    api.clients.updateOne(client, {
      onData: (data) => {
        dispatch(setClient(data));
        resetForm();
        setEditing(false);
      },
      onError: (message) => {
        setSubmitting(false);
        dispatch(simpleGlobalMessage(message))
      },
    });
  };

  useEffect(function resetEditModeOnModuleOrClientChange() {
    setEditing(false);
  }, [currentClient])

  const enableEditing = () => {
    const userCanEditClients = checkClientPermissions(ActionType.UPDATE, UIActionType.ONCLICK);
    if(!userCanEditClients) return;
    setEditing(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={updateClient}
      validationSchema={clientValidationSchema}
    >
      {({
        resetForm,
        submitForm,
        values,
        isSubmitting,
      }) => {
        const closeBlade = () => {
          dispatch(setCurrentClientId(undefined));
          resetForm();
        }
        const bladeActions = isEditing ? [
          <IconButton
            color="primary"
            onClick={submitForm}
            disabled={_.isEqual(values, currentClient) || isSubmitting}
            data-testid="submitClientForm"
            key="submitClientForm"
          >
            {isSubmitting ? <CircularProgress size={24} /> : <Save />}
          </IconButton>,
          <IconButton
            onClick={() => {
              setEditing(false);
              resetForm();
            }}
            key="cancel"
          >
            <Cancel />
          </IconButton>

        ] : [
          <IconButton key="edit" onClick={enableEditing} color="primary" disabled={!userCanEditClients}>
            <Edit />
          </IconButton>
        ]

        return (
          <Form>
            <Blade
              open={currentClientSelected}
              changeOpen={closeBlade}
              backgroundColor="white"
              onTop={bladeMode === "clientViewEdit"}
              actions={bladeActions}
            >
              <Box>
                <Typography variant="h5">
                  {currentClient?.companyName}
                </Typography>
                <Typography variant="body2">
                  {currentClient?.billTos[0] ? parseAddress(currentClient?.billTos[0]) : "-"}
                </Typography>
              </Box>
              <BladeTabs
                tabs={[
                  {
                    tabName: "Details",
                    tabComponent: (
                      <Card sx={{ p: 3 }}>
                        {isEditing ? <ClientForm /> : <ClientView />}
                      </Card>
                    ),
                  },
                  {
                    tabName: "Contracts",
                    tabComponent: (
                      <Card sx={{ p: 3 }}>
                        <ClientContracts />
                      </Card>
                    ),
                  },  {
                    tabName: "People",
                    tabComponent: (
                      <Card sx={{ p: 3 }}>
                        <ClientPersonnel />
                      </Card>
                    ),
                  },
                ]}
                isClosing
              />
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ClientViewEditBlade;
