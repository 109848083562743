import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import { useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import _ from "lodash";
import { initWorkOrderTask } from 'store/api/workOrder';
import { IStandardWorkOrder, WorkOrderStatus } from 'store/models/WorkOrder';
import { simpleGlobalMessage } from 'store/slices/systemSlice';
import DeleteForever from '@mui/icons-material/DeleteForever';
import usePermissions, {
  FeatureType,
  ActionType,
} from "hooks/usePermissions";
import { useAppDispatch } from 'store/store';
import { useToggleWorkOrderTaskCompletionMutation } from "store/services/workOrder";

interface Props {
  mode: "view" | "edit" | "create";
}

const Tasks: React.FC<Props> = ({ mode }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext<IStandardWorkOrder>();
  const [task, setTask] = useState(initWorkOrderTask())
  const [toggleWorkOrderTask] = useToggleWorkOrderTaskCompletionMutation()
  const taskInputRef = useRef<HTMLInputElement | null>(null)
  const dispatch = useAppDispatch();
  const isComplete = values.status === WorkOrderStatus.COMPLETE;
  const checkMaintenancePermissions = usePermissions(FeatureType.MAINTENANCE);
  const userCanEditTasks = userCanEditTasksFromView();
  
  function userCanEditTasksFromView (){
    return mode === "view" ? checkMaintenancePermissions(ActionType.LIMITED_UPDATE) : true;
  } 

  const createNewTask = () => {
    const newTasksArray = _.cloneDeep(values.tasks);
    if (!task.description.length) {
      dispatch(simpleGlobalMessage('You need to give your task a description'));
      return;
    }
    setFieldValue("tasks", [...newTasksArray, task]);
    setTask(initWorkOrderTask());
    taskInputRef.current?.focus()
  };

  const deleteTask = (index: number) => {
    const newTasksArray = _.cloneDeep(values.tasks);
    newTasksArray.splice(index, 1);
    setFieldValue("tasks", newTasksArray);
  };

  const completeTask = (taskIndex: number) => {
    toggleWorkOrderTask({
      workOrderId: values._id,
      taskIndex: taskIndex,
    });
  };

  const isCreateOrEditMode = ["edit","create"].includes(mode);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {isCreateOrEditMode && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: 2,
          }}
        >
          <TextField
            inputRef={taskInputRef}
            value={task?.description || ""}
            onChange={({ currentTarget }) => {
              setTask((previousValues) => ({
                ...previousValues,
                description: currentTarget.value,
              }));
            }}
            label="Task"
            fullWidth
            size="small"
            error={Boolean(touched.tasks && errors.tasks)}
            onKeyPress={(e) => e.key === "Enter" && createNewTask()}
            disabled={
              [WorkOrderStatus.CANCELLED, WorkOrderStatus.COMPLETE].includes(
                values.status
              ) || !userCanEditTasks
            }
          />
          <Button
            variant="outlined"
            onClick={createNewTask}
            disabled={isComplete || !userCanEditTasks}
          >
            Add
          </Button>
        </Box>
      )}
      <List>
        {values.tasks
          ? values.tasks.map((task, index) => (
              <ListItem key={`${task.description}-${index}`}>
                <ListItemText
                  sx={{
                    color: task.complete ? "text.secondary" : undefined,
                    textDecoration: task.complete ? "line-through" : undefined,
                    maxWidth: "300px",
                  }}
                >
                  {index + 1}. {task.description}
                </ListItemText>
                <ListItemSecondaryAction>
                  {!isCreateOrEditMode ? (
                    <Button
                      onClick={() => completeTask(index)}
                      disabled={isComplete || !userCanEditTasks}
                    >
                      {task.complete ? "Undo" : "Complete"}
                    </Button>
                  ) : (
                    <IconButton color="error" disabled={task.complete} onClick={() => deleteTask(index)}>
                      <DeleteForever />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))
          : ""}
      </List>
    </Box>
  );
};
export default Tasks;
