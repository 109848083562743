import React, { FC, useState } from "react";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  FilterOptionsState,
  AutocompleteChangeReason,
  createFilterOptions,
  AutocompleteChangeDetails,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useGetTransportCompaniesQuery } from "store/services/transportCompany";
import { TransportCompany } from "store/slices/transportCompanySlice";
import { initTransportCompany } from "store/api/transportCompany";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import { setBladeMode, simpleGlobalMessage } from "store/slices/systemSlice";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (transportCompany: TransportCompany) => transportCompany.name,
});

type Props = Omit<
  AutocompleteProps<TransportCompany, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  onChange: (
    event: React.SyntheticEvent,
    value: TransportCompany,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TransportCompany>
  ) => void;
  confirmBeforeCreate?: boolean;
  confirmBeforeCreateAction?: () => void
};

const TransportCompanyAutoComplete: FC<Props> = ({
  value,
  name,
  error,
  placeholder,
  onChange,
  confirmBeforeCreate = false,
  confirmBeforeCreateAction = () => {},
  ...rest
}) => {
  const { data: transportCompanies = [] } = useGetTransportCompaniesQuery();

  const dispatch = useDispatch();
  const checkPermissions = usePermissions(FeatureType.MOVEMENT);
  const canCreate = checkPermissions(ActionType.CREATE);
  const [open, setOpen] = useState(false);

  function addNewTransportCompanyOptionIfNoMatch(
    options: TransportCompany[],
    params: FilterOptionsState<TransportCompany>
  ) {
    const filteredOptions = filterOptions(options, params);
    if (params.inputValue && filteredOptions.length === 0) {
      filteredOptions.push(
        initTransportCompany({
          name: `New Transport Company: "${params.inputValue}"`,
        })
      );
    }
    return filteredOptions;
  }

  function handleChange(
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | TransportCompany | (string | TransportCompany)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TransportCompany> | undefined
  ) {
    newValue = newValue as TransportCompany;
    if (newValue && newValue.name.includes("New Transport Company")) {
      handleTransportCompanyCreate();
    } else {
      onChange(event, newValue, reason, details);
    }
  }

  function handleTransportCompanyCreate() {
    if (!canCreate) {
      dispatch(
        simpleGlobalMessage(
          "You do not have the permissions required to create clients"
        )
      );
      return;
    }

    if (!confirmBeforeCreate) {
      dispatch(setBladeMode("transportCompanyCreate"));
      return;
    }

    if (!open) {
      setOpen(true)
      return
    }
    
    confirmBeforeCreateAction()
    setOpen(false)
    dispatch(setBladeMode("transportCompanyCreate"));
    
  }

  return (
    <>
      <Autocomplete
        {...rest}
        onChange={handleChange}
        value={value}
        options={transportCompanies}
        getOptionLabel={(transportCompany) =>
          typeof transportCompany === "string"
            ? transportCompany
            : transportCompany.name
        }
        filterOptions={addNewTransportCompanyOptionIfNoMatch}
        size="small"
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            error={error}
            margin="none"
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              autocomplete: "new-password",
              form: { autocomplete: "off" },
            }}
          />
        )}
      />
      <Dialog open={open} onClose={() => setOpen(false)} keepMounted>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Create Movements modal needs to be closed in order to perform this
            action, your data input in this modal so far will be lost. Is this
            ok?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTransportCompanyCreate}>Yes</Button>
          <Button onClick={() => setOpen(false)}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransportCompanyAutoComplete;
