import { FormikErrors } from "formik";
import { capitalize } from "lodash";
import { setGlobalMessage } from "store/slices/systemSlice";
import { useAppDispatch } from "store/store"


export default function useInvalidFieldsAlert<T> () {
  const dispatch = useAppDispatch();
  return (errors: FormikErrors<T>) => {
    const errorKeys = Object.keys(errors)
    if (Boolean(errorKeys.length)) {
      dispatch(setGlobalMessage({
        show: true,
        messageText: `Invalid Fields: ${String(errorKeys.map(key => capitalize(key)))} `,
        severity: "warning"
      }))
    }
    return;
   
  }
}