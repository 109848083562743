import Save from "@mui/icons-material/Save";
import Close from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import AccessoryAutoComplete from "components/autocomplete/AccessoryAutoComplete";
import UOMAutocomplete from "components/autocomplete/UOMAutoComplete";
import CurrencyTextField from "components/CurrencyTextField";
import DateSelector from "components/DateSelector";
import { isAfter, isBefore, isValid } from "date-fns";
import { Formik, FormikHelpers } from "formik";
import { isEqual } from "lodash";
import { Dispatch, FC, SetStateAction } from "react";
import { useSelector } from "react-redux";
import api from "store/api";
import Accessory from "store/models/Accessory";
import { Asset } from "store/models/Asset";
import { selectAssets } from "store/slices/assetSlice";
import {
  selectCurrentContract,
  setContract,
} from "store/slices/contractSlice";
import { MMDDYYYY, titleCase, toMMDDYYYY } from "utils/util";
import { accessoryValidationSchemaBranchRequired } from "utils/formikAPI";
import { useAppDispatch } from 'store/store';
import NumberTextField from "components/NumberTextField";
import { selectBranches } from "store/slices/optionSlice";
import { Branch } from "store/models/Branch";
import { setGlobalMessage } from "store/slices/systemSlice";

interface Props {
  accessory: Accessory;
  changeMode: Dispatch<SetStateAction<"view" | "edit">>;
}

const ContractAccessoryForm: FC<Props> = ({ accessory, changeMode }) => {
  const assets = useSelector(selectAssets);
  const branches = useSelector(selectBranches)
  const currentContract = useSelector(selectCurrentContract);
  const contractBranches = Object.values(branches).filter(branch => currentContract?.branches.includes(branch._id))
  const dispatch = useAppDispatch();
  const assetOptions = currentContract?.assetDeliverables.map(
    ({ asset }) => assets[asset]) || [];

  function assetOptionLabel(asset: Asset) {
    return `${asset.serialNumber} - ${titleCase(asset.sizeCode)}`;
  }

  function branchOptionLabel(branch: Branch){
    return branch.name
  }

  function updateContract(
    values: Accessory,
    formikHelpers: FormikHelpers<Accessory>
  ) {
    if (!currentContract) return;
    formikHelpers.setSubmitting(true);

    const accessories = currentContract.accessories.map((accessory) =>
      accessory._id === values._id ? values : accessory
    );

    api.contracts.updateAccessories(currentContract._id, accessories, {
      onData: (contract) => {
        dispatch(setContract(contract));
      },
      onError: (error) => {
        formikHelpers.setSubmitting(false);
        dispatch(setGlobalMessage({messageText: error, show: true, severity: "error"}));
      },
      onComplete: () => {
        formikHelpers.setSubmitting(false);
        changeMode("view");
      },
    });
  }

  const handleCustomBillingStartChange =
  (
    values: Accessory,
    setValues: (
      values: SetStateAction<Accessory>,
      shouldValidate?: boolean | undefined
    ) => void
  ) =>
  (date: unknown) => {
    const newDate = date ? toMMDDYYYY(date as Date) : null;

    const customBillingEnd =
      newDate &&
      isValid(values.customBillingEnd) &&
      isBefore(
        new Date(values.customBillingEnd as MMDDYYYY),
        new Date(newDate)
      )
        ? newDate
        : values.customBillingEnd;

    setValues(
      {
        ...values,
        customBillingStart: newDate,
        customBillingEnd,
      },
      false
    );
  };

  const handleCustomBillingEndChange =
    (
      values: Accessory,
      setValues: (
        values: SetStateAction<Accessory>,
        shouldValidate?: boolean | undefined
      ) => void
    ) =>
    (date: unknown) => {
      const newDate = date ? toMMDDYYYY(date as Date) : null;

      const customBillingStart =
        newDate &&
        isValid(values.customBillingStart) &&
        isAfter(
          new Date(values.customBillingStart as MMDDYYYY),
          new Date(newDate)
        )
          ? newDate
          : values.customBillingStart;

      setValues(
        {
          ...values,
          customBillingEnd: newDate,
          customBillingStart,
        },
        false
      );
    };

    function accessoryHasMovement() {
      return currentContract?.movements.some(move => move.accessory === accessory._id)
    }

    function handleBranchChange(setFieldValue: FormikHelpers<Branch>["setFieldValue"], branch: Branch | null) {
      if (accessoryHasMovement()) {
        dispatch(setGlobalMessage({messageText: "You cannot change the branch for an accessory that has movements", show: true, severity: "warning"}))
        return
      }
      setFieldValue("branch", branch?._id || null)
    }

  return (
    <Formik
      initialValues={accessory}
      validationSchema={accessoryValidationSchemaBranchRequired}
      onSubmit={updateContract}
    >
      {({
        values,
        setValues,
        setFieldValue,
        handleChange,
        handleBlur,
        submitForm,
        touched,
        errors,
        isSubmitting,

      }) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 5, mx: 2 }}>
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                alignItems: "center",
              }}
            >
              <AccessoryAutoComplete
                fullWidth
                sx={{ flex: 1 }}
                value={values.name}
                onChange={(_, accessory) => setFieldValue("name", accessory)}
                placeholder="Accessory"
                name="name"
                error={Boolean(touched.name && errors.name)}
              />
              <NumberTextField
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                name="quantity"
                placeholder="Quantity"
                size="small"
                sx={{ width: 60 }}
                error={Boolean(touched.quantity && errors.quantity)}
              />
              <UOMAutocomplete
                sx={{ flex: 0.4 }}
                value={values.unitOfMeasure}
                onChange={(_, uom) => setFieldValue("unitOfMeasure", uom)}
                placeholder="Unit of Measure"
                name="unitOfMeasure"
                error={Boolean(touched.unitOfMeasure && errors.unitOfMeasure)}
                disableClearable
              />
              <IconButton
                sx={{ marginLeft: "auto" }}
                size="small"
                color="primary"
                disabled={isEqual(values, accessory) || isSubmitting}
                onClick={submitForm}
              >
                {isSubmitting ? <CircularProgress size={24} /> : <Save />}
              </IconButton>
              <IconButton size="small" onClick={() => changeMode("view")}>
                <Close />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Autocomplete
                sx={{ flex: 1 }}
                value={values.asset ? assets[values.asset] : null}
                onChange={(_, asset) => {
                  setFieldValue("asset", asset?._id || null);
                  setFieldValue("branch", asset?.branch|| null);
                }}
                getOptionLabel={assetOptionLabel}
                options={assetOptions}
                placeholder="Asset"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="asset"
                    margin="none"
                    placeholder="Asset"
                    error={Boolean(touched.asset && errors.asset)}
                  />
                )}
              />
              <Autocomplete
                sx={{ flex: 1 }}
                value={values.branch ? branches[values.branch] : null}
                onChange={(_, branch) => {
                  handleBranchChange(setFieldValue, branch)
                }}
                getOptionLabel={branchOptionLabel}
                options={contractBranches}
                placeholder="Branch"
                disabled={Boolean(values.asset)}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="branch"
                    margin="none"
                    placeholder="Branch"
                    error={Boolean(errors.branch)}
                  />
                )}
              />
              <CurrencyTextField
                sx={{ flex: 1.5}}
                value={values.rate}
                onChange={(event) => {
                  setFieldValue(
                    "rate.amount",
                    Number(event.target.value) || undefined
                  );
                }}
                onCurrencyChange={(event) => {
                  event.target.value &&
                    setFieldValue("rate.currency", event.target.value);
                }}
                error={Boolean(touched.rate && errors.rate)}
                placeholder="Rate"
                size="small"
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <DateSelector
                value={values.customBillingStart}
                onChange={handleCustomBillingStartChange(values, setValues)}
                label="Billing Start"
                textFieldProps={{
                  sx: { flex: 1, mr: 1 },
                  size: "small",
                  error: Boolean(
                    touched.customBillingStart && errors.customBillingStart
                  ),
                  "data-testid": "customBillingStart",
                }}
              />
              <DateSelector
                value={values.customBillingEnd}
                onChange={handleCustomBillingEndChange(values, setValues)}
                label="Billing End"
                textFieldProps={{
                  sx: { flex: 1 },
                  size: "small",
                  error: Boolean(
                    touched.customBillingEnd && errors.customBillingEnd
                  ),
                  "data-testid": "customBillingEnd",
                }}
              />
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};

export default ContractAccessoryForm;
