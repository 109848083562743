import React, { FC } from "react";
import { selectBranches } from "store/slices/optionSlice";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { Branch } from "store/models/Branch";
import { selectLoggedInUser } from "store/slices/systemSlice";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (branch: Branch) => branch.name,
});

type Props = Omit<
  AutocompleteProps<Branch, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  label?: string;
  "data-testId"?: string
  userBranchesOnly?: boolean
};

const BranchAutocomplete: FC<Props> = ({ value, name, error, label, userBranchesOnly, ...rest }) => {
  const branches = useSelector(selectBranches);
  const loggedInUser = useSelector(selectLoggedInUser);
  const filteredOptions = Object.values(branches).filter((branch) =>
    userBranchesOnly
      ? loggedInUser?.branches.includes(branch._id)
      : loggedInUser?.countries.includes(branch.country)
  );

  return (
    <Autocomplete
      {...rest}
      value={value || null}
      key={value ? "branch" : "no-branch"}
      getOptionLabel={(branch) =>
        typeof branch === "string"
          ? branch
          : `${branches[branch._id].name} (${branches[branch._id].country})`
      }
      filterOptions={filterOptions}
      options={filteredOptions}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          data-testid={rest["data-testId"]}
          error={error}
          margin="none"
          label={label}
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password",
            form: { autocomplete: "off" },
          }}
        />
      )}
    />
  );
}

export default BranchAutocomplete;