import { ICurrency, initCurrency } from "types/Currency";
import { MMDDYYYY, toMMDDYYYY } from "utils/util";
import { WorkOrderTask } from "./WorkOrderTask";
import UserData from "./UserData";

export enum Priority {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  URGENT = "URGENT",
}

export enum WorkOrderStatus {
  TO_DO = "TO DO",
  IN_PROGRESS = "IN PROGRESS",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

export interface IWorkOrderBase<UserType = string, AssetType = string>{
  _id: string;
  asset: AssetType;
  isBlocking: boolean;
  dueDate: MMDDYYYY;
  priority: Priority;
  status: WorkOrderStatus;
  createdOn: MMDDYYYY;
  createdBy: UserType;
  assignedTo: UserType | null;
  photos: string[];
  completedBy: UserType | null;
  completedDate: MMDDYYYY | null;
  number: number;
  purchaseOrder: PurchaseOrder | null
  attachments?: string[];
  chat: string | null;
  contract?: string;
}

export interface IStandardWorkOrder<UserType = string, AssetType = string> extends IWorkOrderBase<UserType, AssetType> {
  title: string;
  description: string;
  category: string;
  subcategory: string;
  tasks: WorkOrderTask[];
  estimatedHours: number;
  estimatedMaterials: ICurrency;
  actualHoursToComplete: number | null;
  actualMaterialsToComplete: ICurrency;
  billableToClient: boolean;
  photos: string[];
  parent: {inspection: string, workOrder: string, inspectionChecklistItem: string} | null;
  inspection: null;
  inspectionType?: undefined;
}

export interface IInspectionWorkOrder<UserType = string, AssetType = string> extends IWorkOrderBase<UserType, AssetType> {
  title: undefined;
  description: undefined;
  category: undefined;
  subcategory: undefined;
  tasks: undefined;
  estimatedHours: undefined;
  estimatedMaterials: undefined;
  actualHoursToComplete: undefined;
  actualMaterialsToComplete: undefined;
  billableToClient: undefined;
  parent: undefined;
  inspection: string;
  inspectionType: string;
}

export type WorkOrder<UserType = string, AssetType = string> = IInspectionWorkOrder<UserType, AssetType> | IStandardWorkOrder<UserType, AssetType>;

export type PopulatedWorkOrder = WorkOrder<UserData, {_id: string, serialNumber: string}>


export const initPurchaseOrder = (currency: Partial<ICurrency> ) => {
  return {amount: initCurrency(currency), poNumber: ""}
  }

export const initialWorkOrderState: IStandardWorkOrder = {
  _id: "",
  tasks: [],
  asset: "",
  isBlocking: false,
  dueDate: toMMDDYYYY(new Date()),
  priority: Priority.LOW,
  number: 0,
  title: "",
  description: "",
  status: WorkOrderStatus.TO_DO,
  createdOn: toMMDDYYYY(new Date()),
  createdBy: "",
  assignedTo: null,
  category: "",
  subcategory: "",
  estimatedHours: 0,
  estimatedMaterials: initCurrency(),
  actualHoursToComplete: 0,
  actualMaterialsToComplete: initCurrency(),
  billableToClient: false,
  photos: [],
  inspection: null,
  parent: null,
  completedBy: null,
  completedDate: null,
  purchaseOrder: initPurchaseOrder(initCurrency()),
  attachments: [],
  chat: null,
};

export interface PurchaseOrder {
  poNumber: string;
  amount: ICurrency
}

