import React, { FC, useState } from "react";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  FilterOptionsState,
} from "@mui/material";
import Address, { defaultAddress, parseAddress } from "store/models/Address";
import AddressSelectDialog from "components/google-map/AddressSelectDialog";
import { isMatch } from "lodash";
import { placeholderAddress } from "store/api/contract";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (address: Address) => `${address.prefix} ${address.address}` || "",
});

type Props = Omit<
  AutocompleteProps<Address, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  "data-testId"?: string;
  label?: string;
  options: Address[];
  onAddressSelect: (address: Address) => void;
  allowClientUpdates?: boolean;
  readOnly?: boolean;
};

const AddressAutoComplete: FC<Props> = ({
  options,
  value,
  name,
  error,
  label,
  onChange,
  readOnly = false,
  onAddressSelect,
  allowClientUpdates = false,
  ...rest
}) => {
  const [show, changeShow] = useState(false);

  function handleChange(
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | Address | (string | Address)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Address> | undefined
  ): void {
    newValue = newValue as Address;
    if (newValue && newValue?.address === "New Address") {
      changeShow(true);
    } else {
      onChange && onChange(event, newValue, reason, details);
    }
  }

  function addOptionForNewClientIfNoClientMatch(options: Address[], params: FilterOptionsState<Address>) {
    const filteredOptions = filterOptions(options, params);
    return allowClientUpdates
      ? [
          ...filteredOptions,
          {
            ...defaultAddress,
            address: "New Address",
          },
        ]
      : filteredOptions;
  }

  function isPlacholderAddress(address: any) {
    return isMatch(placeholderAddress, address)
  }

  return (
    <>
      <Autocomplete
        {...rest}
        key={String(value)}
        onChange={handleChange}
        value={isPlacholderAddress(value) ? null : value}
        getOptionLabel={(option) => typeof option === "string" ? option : parseAddress(option)}
        filterOptions={addOptionForNewClientIfNoClientMatch}
        options={options}
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            data-testid={rest["data-testId"]}
            error={error}
            margin="none"
            label={label}
            inputProps={{ ...params.inputProps, readOnly, autocomplete: "new-password", form: { autocomplete: "off" } }}
          />
        )}
      />
      <AddressSelectDialog
        address={defaultAddress}
        show={show}
        changeShow={changeShow}
        onAddressSelect={onAddressSelect}
      />
    </>
  );
};

export default AddressAutoComplete;
