import { errorHandler } from "./shared";
import { sendToBackend } from "./util";
import { LocationData } from "features/dashboards/geo/AssetLocations";
import { Asset } from "store/models/Asset";
import { Contract } from "store/models/Contract";
import { MovementData } from "features/dashboards/movements/MovementsDashboard";


export const dashboardApi = {
  getAssetLocations: (actions?: {
    onData?: (data: LocationData[]) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }) => {
    sendToBackend<LocationData[]>({
      route: "dashboard/assetLocations",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getContractInfo: (    
  contractId: Contract["_id"],
  actions?: {
    onData?: (data: Contract<Asset>) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }) => {
    sendToBackend<Contract<Asset>>({
      route: `dashboard/contractInfo/${contractId}`,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getAssetFinanceInfo: (    
    actions?: {
      onData?: (data: any) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }) => {
      sendToBackend<any>({
        route: `dashboard/assetFinanceInfo`,
        onData: actions?.onData,
        onError: (message: string) => { errorHandler(message, actions?.onError); },
        onSuccess: actions?.onComplete,
        currentlySubmitting: actions?.currentlySubmitting,
      });
    },
  getContractMovements: (
    actions?: {
      onData?: (data: MovementData[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<MovementData[]>({
      route: `contract/movements`,
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
}

export default dashboardApi;