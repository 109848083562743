import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import LoadingComponent from "components/LoadingComponent";
import _ from "lodash";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { IContractLog } from "store/models/ContractHistory";
import { useGetContractHistoryQuery } from "store/services/contract";
import { selectAssets, setCurrentAssetId } from "store/slices/assetSlice";
import { selectCurrentContract } from "store/slices/contractSlice";
import { setBladeMode } from "store/slices/systemSlice";
import { selectUsers } from "store/slices/userSlice";
import { useAppDispatch } from "store/store";
import { getFullName, toDDMMMYYYY } from "utils/util";

interface Props {}

const ContractHistroy: FC<Props> = () => {
  const currentContract = useSelector(selectCurrentContract);

  const { data: contractHistory, isFetching, currentData } = useGetContractHistoryQuery(
    currentContract?._id || "",
    { skip: !currentContract, refetchOnMountOrArgChange: true }
  );

  const sortedContractHistory = _.orderBy(contractHistory?.updateLog, "updatedDate", "desc");

  return (
    <LoadingComponent isLoading={isFetching && !currentData}>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        {sortedContractHistory.length ? sortedContractHistory.map((updateLog, index) => (
          <ContractHistoryCard key={index} updateLog={updateLog} />
        )) : <Typography sx={{alignSelf: "center" }}>No Contract Changes Yet!</Typography>}
      </Box>
    </LoadingComponent>
  );
}

export default ContractHistroy;

interface CardProps {
  updateLog:  IContractLog
};


const ContractHistoryCard: FC<CardProps> = ({updateLog}) => {

  const dispatch = useAppDispatch()
  const assets = useSelector(selectAssets)

  const handleSerialClick = (serial: string) => {
    const assetId = Object.keys(assets).find(key => assets[key].serialNumber === serial)
    if (!assetId) return
    dispatch(setCurrentAssetId(assetId))
    dispatch(setBladeMode("assetViewEdit"))
  }

  const users = useSelector(selectUsers);
  const updateDescription = updateLog.updateDescription
  const array = parseStringWithLinks(updateDescription)
  const serialRegex = /\/assets\/(\d+)/
  const arrayWithRawSerialNumber = array.map((item, index) => {
    if (item.includes("href")) {
      const serialMatch = item.match(serialRegex)
      const serial = serialMatch ? serialMatch[1] : ''
      return (
        <Typography sx={{cursor: "pointer"}} color="primary" key={item} onClick={() => handleSerialClick(serial)}>{serial}</Typography>
      )
    } else {
      return <Typography key={item}>{item}</Typography>;
    }
  })

  return (
    <Card>
      <CardHeader titleTypographyProps={{variant: 'subtitle1'}} title={toDDMMMYYYY(updateLog.updatedDate)} />
      <CardContent sx={{paddingTop: 1}}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ display: "flex", flexWrap: 'wrap', gap: 1 }}>
            {arrayWithRawSerialNumber.map((item) => item)}
          </Box>{" "}
          {updateLog.updatedBy && <Typography fontWeight="bold">by {getFullName(users[updateLog.updatedBy])}</Typography>}
        </Box>
      </CardContent>
    </Card>
  );
}


function parseStringWithLinks(input: string) {

  const regex = /(<a.*?>.*?<\/a>)/g;
  const parts = input.split(regex);
  
  return parts
}