import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MovementQuery } from 'store/services/movement';
import { addDays, subDays } from 'date-fns';
import { toMMDDYYYY } from 'utils/util';
import { STARFLEET_STORE } from 'store/store';

export interface MOVEMENT_STORE {
    movementQueryArgs: MovementQuery
};

const initialQuery: MovementQuery = {
  startDate: toMMDDYYYY(subDays(new Date(), 90)),
  endDate: toMMDDYYYY(addDays(new Date(), 30)),
  type: [],
  transport: "",
  projectNumber: "",
  branch: "",
  yard: "",
  client: ""
};

const initialState: MOVEMENT_STORE = {
    movementQueryArgs: initialQuery
};

const movementSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        setMovementQueryArgs(state, action: PayloadAction<MovementQuery>) {
            state.movementQueryArgs = action.payload
        },
    }
})

export const selectMovementQueryArgs = (state: STARFLEET_STORE) => state.movements.movementQueryArgs;

export const {
    setMovementQueryArgs,
} = movementSlice.actions;

export default movementSlice.reducer;
