import useSettings from "hooks/useSettings";
import React from "react";
import LightMode from '@mui/icons-material/WbSunny';
import DarkMode from '@mui/icons-material/Brightness2';
import { IconButton } from "@mui/material";

const ToggleThemeButton = () => {
  const { onToggleMode, themeMode } = useSettings();
  return (
    <IconButton onClick={onToggleMode}>
      {themeMode === "light" ? <DarkMode /> : <LightMode />}
    </IconButton>
  );
}

export default ToggleThemeButton;