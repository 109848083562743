import { Box } from '@mui/material';
import React from 'react';
import AssetAverageRentalRate from './AssetAverageRentalRate';
import AssetContractHistory from './AssetContractHistory';
import AssetStatusHistory from './AssetStatusHistory';

interface Props {
}
const AssetHistory: React.FC<Props> = () => (
  <Box display="flex" flexDirection="column" gap={3}>
    <AssetAverageRentalRate />
    <AssetStatusHistory />
    <AssetContractHistory />
  </Box>
);
export default AssetHistory;
