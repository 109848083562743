import DeleteForever from "@mui/icons-material/DeleteForever";
import {
  Box,
  TextField,
  Button,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List,
} from "@mui/material";
import { FC, useRef, useState } from "react";
import { initWorkOrderTask } from "store/api/workOrder";
import { IStandardWorkOrder } from "store/models/WorkOrder";
import { WorkOrderTask } from "store/models/WorkOrderTask";

interface Props {
  tasks: IStandardWorkOrder["tasks"];
  addTask: (task: WorkOrderTask) => void;
  completeTask: (index: number) => void;
  deleteTask: (index: number) => void;
  disabled?: boolean;
  error?: boolean;
}

const CheckListTasks: FC<Props> = ({
  tasks,
  addTask,
  completeTask,
  deleteTask,
  disabled,
  error,
}) => {
  const [task, setTask] = useState(initWorkOrderTask());
  const taskInputRef = useRef<HTMLInputElement | null>(null);
  const createNewTask = () => {
    const description = task.description.trim();
    if(!description) return;

    addTask({ ...task, description});
    setTask(initWorkOrderTask());
  } 

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
        }}
      >
        <TextField
          inputRef={taskInputRef}
          value={task?.description || ""}
          onChange={({ currentTarget }) => {
            setTask((previousValues) => ({
              ...previousValues,
              description: currentTarget.value,
            }));
          }}
          label="Task"
          fullWidth
          size="small"
          onKeyPress={(e) => e.key === "Enter" && createNewTask()}
          disabled={disabled}
          error={error}
        />
        <Button disabled={disabled} variant="outlined" onClick={() => createNewTask()}>
          Add
        </Button>
      </Box>
      <List>
        {tasks
          ? tasks.map((task, index) => (
              <ListItem key={`${task.description}-${index}`}>
                <ListItemText
                  sx={{
                    color: task.complete ? "text.secondary" : undefined,
                    textDecoration: task.complete ? "line-through" : undefined,
                    maxWidth: "300px",
                  }}
                >
                  {index + 1}. {task.description}
                </ListItemText>
                <ListItemSecondaryAction>
                  <Button disabled={disabled} onClick={() => completeTask(index)}>
                    {task.complete ? "Undo" : "Complete"}
                  </Button>
                  <IconButton disabled={disabled} color="error" onClick={() => deleteTask(index)}>
                    <DeleteForever />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          : ""}
      </List>
    </Box>
  );
};

export default CheckListTasks;
