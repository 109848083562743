// @mui
import { useTheme } from '@mui/material/styles';
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  return (
    <MUIGlobalStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        a: {
          textDecoration: "none",
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        // ".MuiAccordion-root.Mui-expanded": {
        //   boxShadow: "none"
        // },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled,
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled,
          },
          '&::placeholder': {
            color: theme.palette.text.disabled,
          },
        },
        img: { display: 'block', maxWidth: '100%' },
        // Google Maps Places Autocomplete styling
        ".pac-container": {
              zIndex: 999999,
              marginBottom: "-4px",
              paddingBottom: "-4px",
              backgroundColor: theme.palette.background.paper,
              borderRadius: theme.spacing(1)
          },
        ".pac-item": {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          border: 0,
          cursor: "pointer"
        },
        ".pac-item:hover": {
          backgroundColor: theme.palette.background.default
        },
        ".pac-item-query": {
          color: theme.palette.text.primary
        },
        ".pac-icon": {
          fill: theme.palette.primary.main,
          color: theme.palette.primary.main
        },
        ".pac-container:after": {
          backgroundImage: "none !important",
          height: "0px"
        }
      }}
    />
  );
}
