import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  Box,
  FilterOptionsState,
  ListItem,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ClientContactModal from "components/modals/ClientContactModal";
import { FormikHelpers } from "formik";
import { throttle } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClientContact, initClientContact } from "store/models/ClientContact";
import { useCreateClientContactMutation, useLazySearchClientContactsQuery } from "store/services/clientContact";
import { selectClients } from "store/slices/clientSlice";
import { getFullName } from "utils/util";

interface Props
  extends Omit<
    AutocompleteProps<ClientContact, boolean, boolean, boolean>,
    "renderInput" | "options"
  > {
  clientContact?: ClientContact;
  handleChange: (value: ClientContact | null) => void;
  disableCreateNew?: boolean;
  limitResultsToClientId?: string
  error?: boolean
  sx?: SxProps
}



const ClientContactSelect: React.FC<Props> = ({
  clientContact,
  handleChange,
  disableCreateNew = false,
  limitResultsToClientId,
  error = false,
  sx
}) => {

  const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchClientContacts, {isLoading: isSearching, data = []}] = useLazySearchClientContactsQuery()
  const [createClientContact] = useCreateClientContactMutation()
  const theme = useTheme();
  const clients = useSelector(selectClients)

  const fetchContacts = React.useMemo(
    () =>
      throttle((searchString: string) => {
        searchClientContacts({search: searchString, clientId: limitResultsToClientId})
        
      }, 500),
    []
  );

  const handleSubmit = (
    values: ClientContact,
    formikHelpers: FormikHelpers<ClientContact>
  ) => {
    createClientContact(values).unwrap().then(() => {
      setShowNewContactModal(false)
    }
    ).catch(() => {
      formikHelpers.setSubmitting(false)
    }
    )
  };

  function addNewContactOptionIfNoClientMatch(
    options: ClientContact[],
    params: FilterOptionsState<ClientContact>
  ) {
    const filteredOptions = [...data];
    if (params.inputValue && filteredOptions.length === 0 && !disableCreateNew) {
      filteredOptions.push(
        initClientContact({firstName: "Create New Contact" })
      );
    }
    return filteredOptions;
  }

  const handleSelectChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ClientContact | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ClientContact> | undefined
  ) => {
    if (value && value.firstName.includes("Create New Contact")) {
      setShowNewContactModal(true);
    } else {
      handleChange(value);
    }
  };
  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    setSearchInput(value)
  }



  useEffect(() => {
    searchInput && fetchContacts(searchInput);
  }, [searchInput]);

  return (
    <>
      <Autocomplete
        key={clientContact ?String(clientContact) : 'no-contact'}
        options={data}
        autoComplete
        loading={isSearching}
        value={clientContact}
        filterOptions={addNewContactOptionIfNoClientMatch}
        includeInputInList
        filterSelectedOptions
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Contacts"
            variant="outlined"
            inputProps={{ ...params.inputProps, autocomplete: "new-password", form: { autocomplete: "off" } }}
            error={error}
          />
        )}
        fullWidth
        size="small"
        sx={{ my: 2, ...sx }}
        getOptionLabel={option => getFullName(option)}
        renderOption={(props, option) => {
          return (
            <ListItem sx={{ display: "flex", flexDirection: "column", my: 2 }} {...props}>
              <Box sx={{display: "flex", justifyContent: "space-between", gap: 2, width: "100%"}}>
                <Typography>{getFullName(option)}</Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.companyRole},
                </Typography>
              </Box>
              <Box sx={{display: "flex", justifyContent: "space-between", gap: 2, width: "100%"}}>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.currentClient && clients[option.currentClient].companyName}
                </Typography>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {option.email}
                </Typography>
              </Box>
            </ListItem>
          );
        }}
      />
    {showNewContactModal && <ClientContactModal open={showNewContactModal} setOpen={setShowNewContactModal} handleSubmit={handleSubmit} clientId={limitResultsToClientId} />}
    </>
  );
};

export default ClientContactSelect;
