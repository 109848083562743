import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectAssetContractHistoryLookup,
  selectAssets,
  selectSelectedAssetIds,
  setSelectedAssetIds,
} from "store/slices/assetSlice";
import { useAppDispatch } from "store/store";
import { Asset } from "store/models/Asset";
import StyledDataGrid, { StyledDataGridProps } from "components/data-grid/styled-data-grid";
import { GRID_ACTIONS_COLUMN_TYPE, GRID_CHECKBOX_SELECTION_FIELD, GridRowId, GridRowModel, GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { Card } from "@mui/material";
import useAssetsTableColumns from "./useStyledAssetsTableColumns";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useGetOpenHoursByAssetIdQuery } from "store/services/workOrder";
import { contractIsActive, findRentalRate, selectContracts } from "store/slices/contractSlice";
import { selectBranches, selectYards } from "store/slices/optionSlice";
import { oneMinute } from "utils/timeValues";

interface Props extends Partial<StyledDataGridProps> {
  onAssetTableRowClick?: Function;
  tableActions?: JSX.Element[];
  assets?: Asset[];
  initialState?: GridInitialStatePro;
  tableId: string;
  selectRowOnRowClick?: boolean;
  onRowClick?: (params: GridRowParams<Asset>) => void;
  onRowSelectionModelChange?: (params: GridRowSelectionModel) => void;
  rowSelectionModel?: GridRowSelectionModel;
}

const StyledAssetsTable: React.FC<Props> = ({
  tableActions,
  assets: defaultAssets,
  initialState,
  tableId,
  onRowClick,
  onRowSelectionModelChange,
  rowSelectionModel,
  selectRowOnRowClick,
  onAssetTableRowClick,
  ...otherProps

}) => {
  const assets = useSelector(selectAssets);
  const selectedAssetIds = useSelector(selectSelectedAssetIds);
  const dispatch = useAppDispatch();
  const rowData = defaultAssets ? Object.values(defaultAssets) : Object.values(assets)
  const branches = useSelector(selectBranches);
  const yards = useSelector(selectYards);
  const contracts = useSelector(selectContracts)
  const contractsByAsset = useSelector(selectAssetContractHistoryLookup);

  const [pinnedRowsIds, setPinnedRowsIds] = useState<{
    top: GridRowId[];
    bottom: GridRowId[];
  }>({
    top: [],
    bottom: [],
  });
  const {data: hoursByAssetId = {}, isLoading: isLoadingHoursByAssetId} = useGetOpenHoursByAssetIdQuery(undefined, {pollingInterval: oneMinute * 5});
  const assetColumns = useAssetsTableColumns({pinnedRowsIds, setPinnedRowsIds});

  const populatedRows = rowData.map((row) => {
    const contract = contracts[row._id];
    const branch = branches[row.branch];
    const yard = yards[row.yard]
    return {
      ...row,
      contractNumber: contract?.projectNumber,
      branch: branch?.name,
      yard: yard?.name,
      starfleetHours: hoursByAssetId[row._id],
      currentRentalRate: getAssetRentalRate(row._id),
      payback: getPayback(row)
    };
  })

  function getAssetRentalRate(assetId: string) {
    const currentContractId = contractsByAsset[assetId]?.currentContract || "";
    const currentContract = contracts[currentContractId];
    let currentMonthlyRentalRate;
    currentMonthlyRentalRate =
      currentContract && contractIsActive(currentContract)
        ? findRentalRate(assetId, currentContract)
        : null;
    return currentMonthlyRentalRate
      ? currentMonthlyRentalRate
      : null;
  }

  function getPayback({ _id, capitalCost }: Asset) {
    const currentContractId = contractsByAsset[_id]?.currentContract || "";
    const currentContract = contracts[currentContractId];
    let currentMonthlyRentalRate;
    currentMonthlyRentalRate =
      currentContract && contractIsActive(currentContract)
        ? findRentalRate(_id, currentContract)
        : null;
    return currentMonthlyRentalRate && capitalCost.amount
      ? (capitalCost.amount / currentMonthlyRentalRate).toFixed(0)
      : "-";
  }

  const { rows, pinnedRows } = React.useMemo(() => {
    const rowsData: GridRowModel[] = [];
    const pinnedRowsData: { top: GridRowModel[]; bottom: GridRowModel[] } = {
      top: [],
      bottom: [],
    };

    populatedRows.forEach((row) => {
      if (pinnedRowsIds.top.includes(row._id)) {
        pinnedRowsData.top.push(row);
      } else if (pinnedRowsIds.bottom.includes(row._id)) {
        pinnedRowsData.bottom.push(row);
      } else {
        rowsData.push(row);
      }
    });

    return {
      rows: rowsData,
      pinnedRows: pinnedRowsData,
    };
  }, [pinnedRowsIds, hoursByAssetId, assets, contracts]);
  
  const handleRowClick = (params: GridRowParams<Asset>) => {
    if (onRowClick) {
      onRowClick(params);
    } else {
      if (selectRowOnRowClick) {
        dispatch(
          setSelectedAssetIds([
            ...Object.values(selectedAssetIds).map((id) => String(id)),
            params.row._id,
          ])
        );
      }
      onAssetTableRowClick && onAssetTableRowClick(params.row._id);
    }
  }

  const handleRowSelectionModelChange = (selectedAssetIds: GridRowSelectionModel) => {
    if (onRowSelectionModelChange) {
      onRowSelectionModelChange(selectedAssetIds)
    } else {
      dispatch(
        setSelectedAssetIds(
          Object.values(selectedAssetIds).map((id) => String(id))
        )
      );
    }
  }

  return (
    <Card sx={{ px: 2 }}>
      <StyledDataGrid
        rows={rows}
        pinnedRows={pinnedRows}
        checkboxSelection
        disableRowSelectionOnClick
        tableId={tableId}
        getRowId={(row: Asset) => row._id}
        onRowClick={handleRowClick}
        columns={assetColumns}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        additionalActions={tableActions}
        rowSelectionModel={
          rowSelectionModel
            ? rowSelectionModel
            : (selectedAssetIds as GridRowSelectionModel)
        }
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_FIELD, GRID_ACTIONS_COLUMN_TYPE],
          },
          ...initialState,
        }}
        loading={isLoadingHoursByAssetId}
        disableFontSizeSelector
        persistColumnOrder
        persistPinnedColumns
        persistFilter
        persistSort
        pageSizeOptions={[10, 15, 25, 50, 100, 250, 500]}
        sx={{ maxHeight: "75vh" }}
        csvOptions={{fileName: `Starfleet Assets`}}
        {...otherProps}
      />
    </Card>
  );
};
export default StyledAssetsTable;
