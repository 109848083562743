import { GridFilterModel } from "@mui/x-data-grid-pro";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { HttpSuccessResponse } from "types/HttpResponse";

export interface IDataGridFilterFavorite {
  _id: string;
  user: string;
  filterName: string;
  tableId: string;
  filterModel: GridFilterModel;
}


export const dataGridFilterFavoritesApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getDataGridFilterFavoritesBy: builder.query<{[filterName: string]: IDataGridFilterFavorite}, {user: string, tableId: string}>({
      query: (body) => ({url: `/dataGridFilterFavorite/query`, method: "POST", body}),
      transformResponse: (response: HttpSuccessResponse<IDataGridFilterFavorite[]>) => getResponseBody(response)
      .reduce((filterFavoriteMap, filterFavorite) => {
        filterFavoriteMap[filterFavorite.filterName] = filterFavorite
        return filterFavoriteMap;
      }, {} as {[filterName: string]: IDataGridFilterFavorite}),
      providesTags: (result, error, id) => [{type: "DataGridFilterFavorites", id: id.tableId}],
    }),
    deleteDataGridFilterFavorite: builder.mutation<void, IDataGridFilterFavorite>({
      query: (body) => ({url: `/dataGridFilterFavorite`, method: "DELETE", body }),
      transformResponse: getResponseBody,
      invalidatesTags: (result, error, id) => [{type: "DataGridFilterFavorites", id: id.tableId}],
      extraOptions: {maxRetries: 0},
    }),
    createGridFilterFavorites: builder.mutation<void, Omit<IDataGridFilterFavorite, "_id">>({
      query: (body) => ({url: `/dataGridFilterFavorite`, method: "POST", body }),
      transformResponse: getResponseBody,
      invalidatesTags: (result, error, id) => [{type: "DataGridFilterFavorites", id: id.tableId}],
      extraOptions: {maxRetries: 0},
    }),
  }),
});

export const {
  useGetDataGridFilterFavoritesByQuery,
  useCreateGridFilterFavoritesMutation,
  useDeleteDataGridFilterFavoriteMutation
} = dataGridFilterFavoritesApi;

