import { Theme } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      defaultProps: {
        maxWidth: false,

      },
      styleOverrides: {
        root: {
          [theme.breakpoints.up("md")]: {
            marginTop: theme.spacing(-3),
          },
          paddingTop: theme.spacing(4),
          height: "100%",
        
        },
      },
    },
  };
}
