import React from 'react';
import BladeTabs from 'components/BladeTabs';
import AssetDetails from './AssetDetails';
import AssetHistory from './history/AssetHistory';
import { Typography, Box, Card } from '@mui/material';
import Dot from 'components/Dot';
import Scrollbar from 'components/Scrollbar';
import { titleCase } from 'utils/util';
import { selectCurrentAsset } from 'store/slices/assetSlice';
import { useSelector } from 'react-redux';
import CopyClipboard from 'components/CopyClipboard';
import OpenWorkOrders from 'features/assets/blades/view-edit/view/OpenWorkOrders';

interface Props {}
const AssetView: React.FC<Props> = () => {
  const currentAsset = useSelector(selectCurrentAsset);
  if (!currentAsset) {
    return null;
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" columnGap={1}>
        <Box>
          <Typography variant="h5">
            {titleCase(currentAsset.sizeCode)} -{" "}
            {titleCase(currentAsset.category)}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body2">{currentAsset.serialNumber}</Typography>{" "}
            <CopyClipboard
              value={currentAsset.serialNumber}
              tooltipText="Copy Serial #"
              successText={`Copied asset Serial #: ${currentAsset.serialNumber}`}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="flex-start" columnGap={1} flexDirection="column">
          <Box display="flex" alignItems="center" columnGap={1}>
            <Dot
              color={currentAsset.status === "AVAILABLE" ? "success" : "error"}
            />
            <Typography variant="subtitle2">{currentAsset.status}</Typography>
          </Box>
          {currentAsset.inProduction && (
            <Card elevation={6} sx={(theme) => ({ py: 1, px: 2, mt: 2, backgroundColor: theme.palette.background.neutral })}>
              <Typography variant="subtitle2">In Production</Typography>
            </Card>
          )}
        </Box>
      </Box>
      <Scrollbar>
        <BladeTabs
          tabs={[
            { tabComponent: <AssetDetails />, tabName: "Details" },
            { tabComponent: <AssetHistory />, tabName: "History" },
            { tabComponent: <OpenWorkOrders assetId={currentAsset._id} />, tabName: "Work Orders" },
          ]}
          isClosing
        />
      </Scrollbar>
    </>
  );
};
export default AssetView;
