export default interface Address {
    prefix: string;
    latLng: {
      lat: string;
      lng: string;
    };
    address?: string;
  }

export const defaultAddress = { prefix: "", address: "", latLng: { lat: "", lng: "" }}

export function parseAddress(address: Address = defaultAddress) {
  return address.address
    ? `${address.prefix ? `${address.prefix},` : ""} ${address.address}`
    : `${address.latLng.lat ? `${address.latLng.lat},` : ""} ${address.latLng.lng}`;
}