import React, { FC, ReactNode } from "react";
import UserData from "store/models/UserData";
import { parsePhoneNumber } from "utils/util";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles"
import { UserAutoComplete, CountryAutoComplete } from "components/autocomplete";
import { FieldArray, useFormikContext } from "formik";
import { Client } from "store/models/Client";
import { useSelector } from "react-redux";
import { selectUsers } from "store/slices/userSlice";
import AddressInput from "components/google-map/AddressInput";
import Delete from "@mui/icons-material/Delete";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { parseAddress } from "store/models/Address";

interface ClientRowProps {
  label: string;
  input?: ReactNode;
}

const ClientRow: FC<ClientRowProps> = ({ label, input }) => {
  return (
    <Grid container alignItems="center" my={1}>
      <Grid item xs={4} sx={{ color: "text.secondary" }}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={8} paddingRight={2}>
        {input}
      </Grid>
    </Grid>
  );
};

const AddressAccordion = styled(Accordion)({
  width: "100%",
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
})

interface Props { }

const ClientEdit: React.FC<Props> = () => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<Client>();
  const users = useSelector(selectUsers);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{ pl: 3 }}
    >
      <ClientRow
        label="Legal Name"
        input={(
          <TextField
            name="legalName"
            size="small"
            value={values.legalName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            error={Boolean(touched.legalName && errors.legalName)}
            inputProps={{
              "data-testid": "legalName",
            }}
            autoComplete="none"
          />
        )}
      />
      <ClientRow
        label="Company Name"
        input={(
          <TextField
            name="companyName"
            size="small"
            value={values.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            error={Boolean(touched.companyName && errors.companyName)}
            inputProps={{
              "data-testid": "companyName",
            }}
            autoComplete="none"
          />
        )}
      />

      <ClientRow
        label="Country"
        input={
          <CountryAutoComplete
            name="country"
            value={values.country}
            onBlur={handleBlur}
            onChange={(_, country) => setFieldValue("country", country)}
            fullWidth
            data-testid="country"
            size="small"
            error={Boolean(touched.country && errors.country)}
          />
        }
      />

      <ClientRow
        label="Phone Number"
        input={(
          <TextField
            name="phoneNumber"
            size="small"
            value={parsePhoneNumber(values.phoneNumber)}
            onChange={(event) =>
              setFieldValue(
                "phoneNumber",
                parsePhoneNumber(event.currentTarget.value)
              )
            }
            onBlur={handleBlur}
            type="phoneNumber"
            fullWidth
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            inputProps={{
              "data-testid": "phoneNumber",
            }}
            autoComplete="none"
          />
        )}
      />
      <ClientRow
        label="Fax"
        input={(
          <TextField
            name="fax"
            size="small"
            value={parsePhoneNumber(values.fax)}
            onChange={(event) =>
              setFieldValue("fax", parsePhoneNumber(event.currentTarget.value))
            }
            onBlur={handleBlur}
            fullWidth
            error={Boolean(touched.fax && errors.fax)}
            inputProps={{
              "data-testid": "fax",
            }}
            autoComplete="none"
          />
        )}
      />
      <ClientRow
        label="Email"
        input={(
          <TextField
            name="email"
            size="small"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            inputProps={{
              "data-testid": "email",
            }}
            autoComplete="none"
          />
        )}
      />
      <ClientRow
        label="Website"
        input={(
          <TextField
            name="website"
            size="small"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            inputProps={{
              "data-testid": "website",
            }}
            autoComplete="none"
          />
        )}
      />
      <ClientRow
        label="Accounts Payable Contact"
        input={(
          <TextField
            name="accountsPayableContact"
            size="small"
            value={values.accountsPayableContact}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            inputProps={{
              "data-testid": "accountsPayableContact",
            }}
            autoComplete="none"
          />
        )}
      />
      <ClientRow
        label="Account Owner"
        input={(
          <UserAutoComplete
            name="accountOwner"
            size="small"
            value={values.accountOwner && users[values.accountOwner]}
            onChange={(_, value) =>
              setFieldValue("accountOwner", (value as UserData)?._id || null)
            }
            onBlur={handleBlur}
            fullWidth
            autoComplete={false}
          />
        )}
      />

      <ClientRow label="Bill Tos" />
      <FieldArray name="billTos">
        {({ remove, push, move }) => (
          <AddressAccordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {values?.billTos.length
                  ? `${values?.billTos.length} Addresses` : "None"}
              </Typography>
              <AddressInput onAddressSelect={push} />
            </AccordionSummary>
            <AccordionDetails>
              {values?.billTos
                .map((address, index) => {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      mb={1}
                    >
                      {index === 0 ? (
                        <Tooltip title="Primary Billing Address" placement="left-start">
                          <Typography color={index === 0 ? "primary" : undefined}>{parseAddress(address)}</Typography>
                        </Tooltip>
                      ) : (
                        <Typography color={index === 0 ? "primary" : undefined}>{parseAddress(address)}</Typography>
                      )}
                      <IconButton size="small" onClick={() => move(index, index + 1)}>
                        <ArrowDropDown />
                      </IconButton>
                      <IconButton size="small" onClick={() => move(index, index - 1)}>
                        <ArrowDropUp />
                      </IconButton>
                      <IconButton size="small" onClick={() => remove(index)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  );
                })}
            </AccordionDetails>
          </AddressAccordion>
        )}
      </FieldArray>

      <ClientRow label="Ship Tos" />
      <FieldArray name="shipTos">
        {({ remove, push, move }) => (
          <AddressAccordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography
                sx={{ width: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {values?.shipTos.length
                  ? `${values?.shipTos.length} Addresses`
                  : "None"}
                <AddressInput onAddressSelect={push} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {values?.shipTos
                .map((address, index) => {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography>{parseAddress(address)}</Typography>
                      <Box sx={{ width: 200 }} display="flex" justifyContent="flex-end">
                        <IconButton size="small" onClick={() => move(index, index + 1)}>
                          <ArrowDropDown />
                        </IconButton>
                        <IconButton size="small" onClick={() => move(index, index - 1)}>
                          <ArrowDropUp />
                        </IconButton>
                        <IconButton size="small" onClick={() => remove(index)}>
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
            </AccordionDetails>
          </AddressAccordion>
        )}
      </FieldArray>
    </Grid>
  );
};
export default ClientEdit;
