import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ClientAutocomplete from "components/autocomplete/ClientAutoComplete";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { Client } from "store/models/Client";
import { ClientContact, initClientContact } from "store/models/ClientContact";
import { selectClients } from "store/slices/clientSlice";
import { contactValidationSchema } from "utils/formikAPI";
import { isOfDocumentType, parsePhoneNumber } from "utils/util";


interface Props {
    open: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    handleSubmit: (values: ClientContact, formikHelpers: FormikHelpers<ClientContact>) => void
    clientContact?: ClientContact
    clientId?: string
}

const ClientContactModal: React.FC<Props> = ({open, setOpen, handleSubmit, clientContact, clientId}) => {

 const initialValues = clientContact ? clientContact : initClientContact({currentClient: clientId})
 const clients = useSelector(selectClients)

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>{clientContact ? "Update Contact Info" : "Create new Contact"}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={contactValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            touched,
            errors,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    my: 5,
                    width: 500,
                  }}
                >
                  <TextField
                    value={values.firstName}
                    size="small"
                    fullWidth
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    onChange={handleChange}
                    error={Boolean(touched.firstName && errors.firstName)}
                    type="text"
                    autoComplete="off"
                    inputProps={{ form: { autocomplete: "off" } }}
                  />
                  <TextField
                    value={values.lastName}
                    size="small"
                    fullWidth
                    name="lastName"
                    placeholder="Last Name"
                    label="Last Name"
                    onChange={handleChange}
                    error={Boolean(touched.lastName && errors.lastName)}
                    type="text"
                    autoComplete="off"
                  />
                  <TextField
                    value={values.email}
                    size="small"
                    fullWidth
                    name="email"
                    placeholder="Email"
                    label="Email"
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    type="text"
                    autoComplete="off"
                    inputProps={{
                      form: { autocomplete: "off" },
                      autocomplete: "new-password",
                    }}
                  />
                  <TextField
                    name="primaryPhone"
                    size="small"
                    placeholder="Primary Phone"
                    label="Primary Phone #"
                    value={parsePhoneNumber(values.primaryPhone)}
                    onChange={(event) =>
                      setFieldValue(
                        "primaryPhone",
                        parsePhoneNumber(event.currentTarget.value)
                      )
                    }
                    type="phone"
                    fullWidth
                    error={Boolean(touched.primaryPhone && errors.primaryPhone)}
                    autoComplete="off"
                    inputProps={{
                      form: { autocomplete: "off" },
                      autocomplete: "new-password",
                    }}
                  />
                  <TextField
                    name="altPhone"
                    size="small"
                    placeholder="Alt Phone"
                    label="Alt Phone #"
                    value={parsePhoneNumber(values.altPhone || "")}
                    onChange={(event) =>
                      setFieldValue(
                        "altPhone",
                        parsePhoneNumber(event.currentTarget.value)
                      )
                    }
                    type="altPhone"
                    fullWidth
                    error={Boolean(touched.altPhone && errors.altPhone)}
                    inputProps={{
                      form: { autocomplete: "off" },
                      autocomplete: "new-password",
                    }}
                  />
                  <ClientAutocomplete
                    onChange={(_, value) => {
                      if (isOfDocumentType<Client>(value)) {
                        setFieldValue("currentClient", value._id);
                      }
                    }}
                    value={clients[values.currentClient]}
                    error={Boolean(touched.currentClient && errors.currentClient)}
                  />
                  <TextField
                    value={values.companyRole}
                    size="small"
                    fullWidth
                    name="companyRole"
                    placeholder="Title"
                    label="Title"
                    onChange={handleChange}
                    error={Boolean(touched.companyRole && errors.companyRole)}
                    type="text"
                    autoComplete="off"
                  />
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={22} />
                  ) : clientContact ? (
                    "Update"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ClientContactModal
