import store from 'store/store';
import { simpleGlobalMessage } from 'store/slices/systemSlice';

const errorHandler = (message: string, onError: Function | undefined, disableDefaultErrorMessage: boolean = false) => {
  if (
    message.toLowerCase().includes("app version mismatch") &&
    window.location.pathname !== "/"
  ) {
    window.location.pathname = "/";
  } else {
    !disableDefaultErrorMessage && store.dispatch(simpleGlobalMessage(`An error occured: ${message}`));
    onError && onError(message)
  }
     
};

export { errorHandler };