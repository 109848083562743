import { Yard } from "store/models/Yard";
import { errorHandler } from "./shared";
import { sendToBackend } from "store/api/util";
import { createMongoId } from "utils/createMongoId";

const yardsApi = {
  getAll: (actions?: {
    onData?: (data: Yard[]) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }) => {
    sendToBackend<Yard[]>({
      route: "yard/",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateOne: (
    updatedUard: Yard,
    actions?: {
      onData?: (data: Yard) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Yard>({
      route: `yard/`,
      method: "PATCH",
      body: updatedUard,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
};

export default yardsApi;

export const initYard = (yard: Partial<Yard>) => ({
  _id: createMongoId(),
  name: "",
  address: "",
  ...yard,
})