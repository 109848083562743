import { IUserPreference } from "store/slices/configSlice";
import { sendToBackend } from "store/api/util";
import { errorHandler } from "./shared";

const userPreferenceApi = {
  updateUserPreference: (
    body: IUserPreference,
    actions?: {
      onData?: (data: IUserPreference) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<IUserPreference>({
      route: "userPreference",
      method: "PUT",
      body,
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getLoggedInUserPreference: (
    userPreference: IUserPreference["name"],
    actions?: {
      onData?: (data: IUserPreference) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    },
  ) => {
    sendToBackend<IUserPreference>({
      route: `userPreference/?userPreference=${userPreference}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => {
        errorHandler(message, actions?.onError);
      },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
};

export default userPreferenceApi;
