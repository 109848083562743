import { TextField } from "@mui/material";
import { ClientAutoComplete } from "components/autocomplete";
import { useFormikContext } from "formik";
import React, { useEffect, FocusEvent } from "react";
import { useSelector } from "react-redux";
import { ClientContact } from "store/models/ClientContact";
import { selectClients, selectCreatedClientId } from "store/slices/clientSlice";
import { isOfDocumentType, parsePhoneNumber } from "utils/util";
import FormRow from "components/bladeComponents/FormRow";
import FormContainer from "components/bladeComponents/FormContainer";
import { Client } from "store/models/Client";
import { useAppDispatch } from "store/store";
import { setGlobalMessage } from "store/slices/systemSlice";

interface Props {
  clientId?: string
  mode?: "view" | "edit" | "new"
}
const ClientContactForm: React.FC<Props> = ({clientId, mode}) => {

  const { values, errors, touched, handleChange, handleBlur: formikHandleBlur, setFieldValue } =
    useFormikContext<ClientContact>();
  const clients = useSelector(selectClients);
  const dispatch = useAppDispatch()
  const createdClientId = useSelector(selectCreatedClientId)
  useEffect(() => {
    if (createdClientId) setFieldValue("currentClient", createdClientId)
  },[createdClientId])

  const handleBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFieldValue(event.target.name, event.target.value.trim());
  };
  const handleClientChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: NonNullable<string | Client> | (string | Client)[] | null
  ) => {
    if (isOfDocumentType<Client>(value)) {
      const currentCountry = clients[values.currentClient]?.country;
      if (mode !== "new" && value.country !== currentCountry) {
        dispatch(
          setGlobalMessage({
            show: true,
            messageText: `Selected client has country ${value.country}. Only a new client from ${currentCountry} can be selected`,
            severity: "warning",
          })
        );
        return;
      }
      setFieldValue("currentClient", (value as Client)._id);
    }
  };
  

  return (
    <FormContainer>
      <FormRow
        label="Job Title"
        input={
          <TextField
            name="companyRole"
            size="small"
            value={values.companyRole}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            error={Boolean(touched.companyRole && errors.companyRole)}
            autoComplete="none"
          />
        }
      />
      <FormRow
        label="First Name"
        input={
          <TextField
            name="firstName"
            size="small"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            error={Boolean(touched.firstName && errors.firstName)}
            autoComplete="none"
          />
        }
      />
      <FormRow
        label="Last Name"
        input={
          <TextField
            name="lastName"
            size="small"
            value={values.lastName}
            onChange={handleChange}
            onBlur={(event) =>
              setFieldValue(event.target.name, event.target.value.trim())
            }
            fullWidth
            error={Boolean(touched.lastName && errors.lastName)}
            autoComplete="none"
          />
        }
      />
      <FormRow
        label="Email"
        input={
          <TextField
            name="email"
            size="small"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            error={Boolean(touched.email && errors.email)}
            autoComplete="none"
          />
        }
      />
      <FormRow
        label="Primary Phone"
        input={
          <TextField
            name="primaryPhone"
            size="small"
            value={parsePhoneNumber(values.primaryPhone)}
            onChange={(event) =>
              setFieldValue(
                "primaryPhone",
                parsePhoneNumber(event.currentTarget.value)
              )
            }
            onBlur={handleBlur}
            type="phoneNumber"
            fullWidth
            error={Boolean(touched.primaryPhone && errors.primaryPhone)}
            inputProps={{
              "data-testid": "phoneNumber",
            }}
            autoComplete="none"
          />
        }
      />
      <FormRow
        label="Alternate Phone"
        input={
          <TextField
            name="altPhone"
            size="small"
            value={parsePhoneNumber(values.altPhone || "")}
            onChange={(event) =>
              setFieldValue(
                "altPhone",
                parsePhoneNumber(event.currentTarget.value)
              )
            }
            onBlur={handleBlur}
            type="phoneNumber"
            fullWidth
            error={Boolean(touched.altPhone && errors.altPhone)}
            autoComplete="none"
          />
        }
      />
      <FormRow
        label="Client"
        input={
          <ClientAutoComplete
            name="currentClient"
            size="small"
            value={clientId ? clients[clientId] : clients[values.currentClient]}
            onChange={handleClientChange}
            onBlur={formikHandleBlur}
            error={Boolean(touched.currentClient && errors.currentClient)}
            disabled={Boolean(clientId)}
          />
        }
      />
    </FormContainer>
  );
};

export default ClientContactForm;

