import { Typography, Box } from "@mui/material";
import { FC } from "react";

interface ViewRowProps {
    label: string;
    value?: string | JSX.Element | undefined | null;
  }
  
  const ViewRow: FC<ViewRowProps> = ({ label, value }) => {
    return (
      <Box
        sx={{ whiteSpace: "nowrap" }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {label}
        </Typography>
        <Typography variant="body2">{value}</Typography>
      </Box>
    );
  };

  export default ViewRow