import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Lead, LeadWithPopulatedContact } from 'store/models/Lead';
import api from 'store/api';
import { STARFLEET_STORE } from "store/store";

export type LEADS_STORE = {
  leads: {[_id: string]: LeadWithPopulatedContact};
  leadsLoading: boolean;
  currentLeadId: string | undefined;
};

const initialState: LEADS_STORE = {
  leads: {},
  leadsLoading: false,
  currentLeadId: undefined
}

const leadSlice = createSlice({
    name: 'lead',
    initialState,
    reducers: {
        setLeads(state, action: PayloadAction<LeadWithPopulatedContact[]>) {
            for (const lead of action.payload) {
              state.leads[lead._id] = lead
            }
        },
        setOneLead(state, action: PayloadAction<LeadWithPopulatedContact>) {
            state.leads[action.payload._id] = action.payload
        },
    }
})

export const selectLeads = (state: STARFLEET_STORE) => state.leads.leads;

export const {
    setLeads,
    setOneLead,
} = leadSlice.actions;

export default leadSlice.reducer;