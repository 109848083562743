import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { TransportCompany } from "store/slices/transportCompanySlice";

export const transportCompanyApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getTransportCompanies: builder.query<TransportCompany[],void>({
      query: () => `/transportCompany`,
      transformResponse: getResponseBody,
      providesTags: ["TransportCompanies"],
    }),
    updateTransportCompany: builder.mutation<TransportCompany[],TransportCompany>({
      query: (body) => ({url: `/transportCompany`, method: "PATCH", body }),
      transformResponse: getResponseBody,
      invalidatesTags: ["TransportCompanies"],
      extraOptions: {maxRetries: 0}
    }),
    createTransportCompany: builder.mutation<TransportCompany[],TransportCompany>({
      query: (body) => ({url: `/transportCompany`, method: "POST", body }),
      transformResponse: getResponseBody,
      invalidatesTags: ["TransportCompanies"],
      extraOptions: {maxRetries: 0}
    }),
  }),
});

export const {
  useGetTransportCompaniesQuery,
  useUpdateTransportCompanyMutation,
  useCreateTransportCompanyMutation
} = transportCompanyApi;

