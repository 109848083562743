import { createMongoId } from "utils/createMongoId";
import { Client } from "./Client";

export interface ClientContact {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone: string;
  altPhone: string | null;
  companyRole: string | null;
  currentClient: Client["_id"];
}
  


export const initClientContact = (
  clientContact: Partial<ClientContact>
): ClientContact => {
  return {
    _id: createMongoId(),
    firstName: "",
    lastName: "",
    email: "",
    primaryPhone: "",
    altPhone: "",
    companyRole: "",
    currentClient: "",
    ...clientContact,
  };
};
  