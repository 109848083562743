import React from "react";
import { Asset } from "store/models/Asset";
import api from "store/api";
import { useSelector } from "react-redux";
import AssetForm from "features/assets/AssetForm";
import Blade from "components/Blade";
import {
  selectBladeMode,
  selectLoggedInUser,
  setBladeMode,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import { Form, Formik, FormikHelpers } from "formik";
import { assetValidationSchema } from "utils/formikAPI";
import _ from "lodash";
import Cancel from "@mui/icons-material/Cancel";
import Save from "@mui/icons-material/Save";
import { IconButton } from "@mui/material";
import { selectDefaultUserCurrency } from "store/slices/configSlice";
import { setOneAsset } from "store/slices/assetSlice";
import { useAppDispatch } from 'store/store';
import { initAsset } from "store/api/asset";
import { LoadingButton } from "@mui/lab";

interface Props {}
const AssetCreateBlade: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const bladeMode = useSelector(selectBladeMode);
  const currentUser = useSelector(selectLoggedInUser);
  const defaultCurrency = useSelector(selectDefaultUserCurrency(currentUser));
  const initialValues = initAsset({currency: defaultCurrency})

  const createAsset = (
    asset: Asset,
    { setSubmitting, resetForm, setFieldValue }: FormikHelpers<Asset>
  ) => {
    api.assets.createOne(asset, {
      onData: (asset) => {
        resetForm({ values: initAsset({ currency: defaultCurrency }) });
        setFieldValue("residualValue", 0)
        dispatch(setBladeMode("none"));
        dispatch(simpleGlobalMessage("Successfully Created Asset", "success"));
        dispatch(setOneAsset(asset));
      },
      onError: (message) => {
        setSubmitting(false);
        dispatch(simpleGlobalMessage(message));
      },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={assetValidationSchema}
      onSubmit={createAsset}
    >
      {({ values, isSubmitting, resetForm }) => {

        
        const bladeActions = [
              <IconButton
                color="primary"
                type="submit"
                disabled={_.isEqual(initialValues, values)}
                data-testid="submitUser"
                key="submitUser"
              >
                <Save />
              </IconButton>,
              <IconButton
                onClick={() => resetForm()}
                key="close"
              >
                <Cancel />
              </IconButton>,
            ];
        return (
          <Form>
            <Blade
              open={bladeMode === "assetCreate"}
              changeOpen={() => dispatch(setBladeMode("none"))}
              backgroundColor="white"
              onTop={bladeMode === "assetCreate"}
              title="New Asset"
              actions={bladeActions}
            >
              <AssetForm isCreating />
              <LoadingButton
                color="primary"
                variant="contained"
                fullWidth
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssetCreateBlade;
