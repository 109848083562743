import React, { FC } from "react";
import { Box } from "@mui/material";

const NoWrap: FC = ({ children }) => {
  return (
    <Box sx={{ whiteSpace: "nowrap"}}>
      {children}
    </Box>
  )
}

export default NoWrap;