import { ReactNode, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import {
  GridColumnVisibilityModel,
  GridCallbackDetails,
  GridDensity,
  GridState,
  GridPaginationState,
  GridPaginationModel,
  GridSortModel,
  GridFilterModel,
  GridInitialState,
  GridColumnOrderChangeParams,
  GridEventListener,
  GridPinnedColumns,
} from '@mui/x-data-grid-pro';
import { oneHour } from 'utils/timeValues';
import { useAppDispatch } from 'store/store';
import { setGlobalMessage } from 'store/slices/systemSlice';

interface Props {
  tableId: string;
  persistPagination: boolean;
  persistSort: boolean;
  persistFilter: boolean;
  persistColumnOrder: boolean;
  persistPinnedColumns?: boolean;
  orderedFields: string[];
  initialState: GridInitialState | undefined;
}

const usePersistTableSettings = ({
  tableId,
  persistPagination,
  persistSort,
  persistFilter,
  persistPinnedColumns = false,
  orderedFields: initOrderedFields,
  initialState
}: Props): {
  hiddenColumns: GridColumnVisibilityModel;
  handleColumnVisibiltyChange: (
    model: GridColumnVisibilityModel,
    details: GridCallbackDetails
  ) => void;
  tableDensity: GridDensity;
  handleStateChange: (state: GridState) => void;
  paginationState: GridPaginationState['paginationModel'];
  handlePaginationChange: (model: GridPaginationModel, details: GridCallbackDetails<any>) => void;
  fontSize: number,
  handleFontSizeChange: (event: SelectChangeEvent<number>, child: ReactNode) => void;
  sortModel: GridSortModel;
  handleSortModelChange: (model: GridSortModel, details: GridCallbackDetails<any>) => void;
  pinnedColumns: GridPinnedColumns;
  handlePinnedColumnsChange: (model: GridPinnedColumns, details: GridCallbackDetails<any>) => void;
  filterModel: GridFilterModel;
  handleFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails<any>) => void;
  handleColumnOrderChange: GridEventListener<"columnOrderChange">;
  orderedFields: string[];
  hiddenSelected: number;
  handleSetUserDefaultFilters: () => void;
  userDefaultFilters: GridFilterModel
} => {

  const dispatch = useAppDispatch();
  
  // HIDDEN COLUMNS
  const hiddenColumnsSotrageId = `${tableId}TableState`;
  const hidden = localStorage.getItem(hiddenColumnsSotrageId);
  const [hiddenColumns, setHiddenColumns] = useState<GridColumnVisibilityModel>(
    hidden ? JSON.parse(hidden) : {}
  );
  const [hiddenSelected, setHiddenSelected] = useState(0);

  function setHiddenColumnsToLocalStorage(model: GridColumnVisibilityModel) {
    localStorage.setItem(hiddenColumnsSotrageId, JSON.stringify(model));
  }

  const handleColumnVisibiltyChange = (
    model: GridColumnVisibilityModel,
    details: GridCallbackDetails
  ) => {
    setHiddenColumnsToLocalStorage(model);
    setHiddenColumns(model);
  };

  // TABLE DENSITY

  const densityStorageId = `${tableId}TableDensity`;
  const savedDensity = localStorage.getItem(densityStorageId);
  function setDensityToLocalStorage(density: GridDensity) {
    localStorage.setItem(densityStorageId, JSON.stringify(density));
  }
  const [tableDensity, setTableDensity] = useState<GridDensity>(
    savedDensity ? JSON.parse(savedDensity) : 'compact'
  );

  const handleStateChange = (state: GridState) => {

    let hiddenSelectedCount = 0;

    for (const rowId of state.rowSelection) {
      if (state.visibleRowsLookup[rowId] === false) {
        hiddenSelectedCount++
      }
    }

    setHiddenSelected(hiddenSelectedCount)

    setDensityToLocalStorage(state.density.value);
    setTableDensity(state.density.value);
  };

  // ROWS PER PAGE
  
  const rowsPerPageStorageId = `${tableId}RowsPerPage`;

  const savedRowsPerPageJSON = localStorage.getItem(rowsPerPageStorageId);

  const thirtyMinutes = 1000 * 60 * 30;

  const savedRowsPerPage = savedRowsPerPageJSON ? JSON.parse(savedRowsPerPageJSON) : null;

  // PAGINATION
  const [paginationTimeout, setPaginationTimeout] = useState<NodeJS.Timeout>();
  const pageStorageId = `${tableId}Page`;
  const savedPageJSON = localStorage.getItem(pageStorageId);
  const savedPage = savedPageJSON ? JSON.parse(savedPageJSON) : 0;
  function setRowsPerPageToLocalStorage(rowsPerPage: GridPaginationState['paginationModel']['pageSize']) {
    localStorage.setItem(rowsPerPageStorageId, JSON.stringify(rowsPerPage));
  }
  function setPageToLocalStorage(page: GridPaginationState['paginationModel']['page']) {
    if (persistPagination) { 
      localStorage.setItem(pageStorageId, JSON.stringify(page))
    }
  }
  const [paginationState, setPaginationState] = useState<GridPaginationState['paginationModel']>(
    { pageSize: Number.isInteger(savedRowsPerPage) ? savedRowsPerPage : 10, page: savedPage }
  );

  const handlePaginationChange = (
    model: GridPaginationModel,
    details: GridCallbackDetails<any>
  ) => {
    setRowsPerPageToLocalStorage(model.pageSize);
    setPageToLocalStorage(model.page);
    setPaginationState(model);
  };

  useEffect(function resetPageCache() {
    if (persistPagination && savedPageJSON) {
      clearTimeout(paginationTimeout);
      const timeout = setTimeout(() => {
        localStorage.removeItem(`${tableId}Page`);
      }, thirtyMinutes);
      setPaginationTimeout(timeout);
    }
  }, [savedPageJSON, tableId, persistPagination])

  // SORTING
  const [sortTimeout, setSortTimeout] = useState<NodeJS.Timeout>();
  const sortStorageId = `${tableId}SortModel`;
  const savedSortModelJSON = localStorage.getItem(sortStorageId)
  const savedSortModel = savedSortModelJSON ? JSON.parse(savedSortModelJSON) : [];
  const [sortModel, setSortModel] = useState<GridSortModel>(
    persistSort && savedSortModelJSON
      ? savedSortModel
      : initialState?.sorting?.sortModel
      ? initialState.sorting.sortModel
      : []
  );

  const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails<any>) => {
    setSortModel(model);
    localStorage.setItem(sortStorageId, JSON.stringify(model));
  }

  useEffect(function resetSortCache() {
    if (persistSort && savedSortModelJSON) {
      clearTimeout(sortTimeout);
      const timeout = setTimeout(() => {
        localStorage.removeItem(`${tableId}SortModel`);
      }, oneHour * 8);
      setSortTimeout(timeout);
    }
  }, [savedSortModelJSON, tableId, persistSort])

    // PINNING

    const pinStorageId = `${tableId}PinModel`;
    const savedPinModelJSON = localStorage.getItem(pinStorageId)
    const savedPinModel = savedPinModelJSON ? JSON.parse(savedPinModelJSON) : [];
    const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>(
      persistPinnedColumns && savedPinModelJSON
        ? savedPinModel
        : initialState?.pinnedColumns
        ? initialState.pinnedColumns
        : []
    );
  
    const handlePinnedColumnsChange = (
      model: GridPinnedColumns,
      details: GridCallbackDetails<any>
    ) => {
      setPinnedColumns(model);
      localStorage.setItem(pinStorageId, JSON.stringify(model));
    };

  // FILTERING

  const [filterTimeout, setFilterTimeout] = useState<NodeJS.Timeout>();
  const filterStorageId = `${tableId}FilterModel`;
  const userDefaultFilterStorageId = `${tableId}UserDefaultFilterModel`;
  const defaultFilterModel = {items: []};
  const savedUserDefaultFilterModelJSON = localStorage.getItem(userDefaultFilterStorageId);
  const [userDefaultFilters, setUserDefaultFilters] = useState<GridFilterModel>(savedUserDefaultFilterModelJSON ? JSON.parse(savedUserDefaultFilterModelJSON) : defaultFilterModel);
  const savedFilterModelJSON = localStorage.getItem(filterStorageId)
  const savedFilterModel = savedFilterModelJSON ? JSON.parse(savedFilterModelJSON) : defaultFilterModel;

  function getInitialFilters() {
    switch (true) {
      case !persistFilter:
        return initialState?.filter?.filterModel || defaultFilterModel;
      case !!savedFilterModelJSON:
        // user has changes for this session in local storage
        return savedFilterModel;
      case !!savedUserDefaultFilterModelJSON:
        // savedFilterModel has been cleared, and user has default filters set
        return userDefaultFilters;
      default:
        return initialState?.filter?.filterModel || defaultFilterModel;
    }
  }

  const [filterModel, setFilterModel] = useState<GridFilterModel>(getInitialFilters());


  const handleSetUserDefaultFilters = (reason?: "set" | "clear") => {

    if (reason === "clear") {
      localStorage.removeItem(userDefaultFilterStorageId);
      setUserDefaultFilters(defaultFilterModel);
      dispatch(
        setGlobalMessage({
          messageText: "Default filters cleared",
          severity: "success",
          show: true,
        })
      );
      return;
    }

    if (!filterModel.items.length) {
      dispatch(
        setGlobalMessage({
          messageText: "No filters currently active",
          severity: "warning",
          show: true,
        })
      );
      return;
    } else {
      localStorage.setItem(
        userDefaultFilterStorageId,
        JSON.stringify(filterModel)
      );
      setUserDefaultFilters(filterModel);
      dispatch(
        setGlobalMessage({
          messageText: "Default filters set",
          severity: "success",
          show: true,
        })
      );
    }
  };
  
  const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails<any>) => {
    setFilterModel(model);
    const { quickFilterValues, ...modelWithoutQuickFilter } = model;
    localStorage.setItem(filterStorageId, JSON.stringify(modelWithoutQuickFilter));
  }

  useEffect(function resetFilterCache() {
    if (persistFilter && savedFilterModelJSON) {
      clearTimeout(filterTimeout);
      const timeout = setTimeout(() => {
        localStorage.removeItem(`${tableId}FilterModel`);
      }, oneHour * 8);
      setFilterTimeout(timeout);
    }
  }, [savedFilterModelJSON, tableId, persistFilter])


  // COLUMN ORDERING

  const savedOrderedFieldsJSON = localStorage.getItem(`${tableId}OrderedFields`);
  const savedOrderedFields = savedOrderedFieldsJSON ? JSON.parse(savedOrderedFieldsJSON) : null;
  let newFields: string[] = []
  if (savedOrderedFields) {
    for (const field of initOrderedFields) {
      if (!savedOrderedFields.includes(field)) {
        newFields.push(field)
      }
    }
  }

  let fieldsRemoved = savedOrderedFields ? [...savedOrderedFields].filter((field) => !initOrderedFields.includes(field)) : []

  const newSavedFields = [...(savedOrderedFields || []), ...newFields].filter((field) => !fieldsRemoved.includes(field))

  useEffect(() => {
    localStorage.setItem(`${tableId}OrderedFields`, JSON.stringify(newSavedFields));
  },[initOrderedFields])

  const [orderedFields, setOrderedFields] = useState<string[]>(() => savedOrderedFields ? newSavedFields : initOrderedFields);
  
  const handleColumnOrderChange: GridEventListener<"columnOrderChange"> = (params: GridColumnOrderChangeParams) => {
    const fieldRemoved = [...orderedFields].filter((field) => field !== params.column.field)
    fieldRemoved.splice(params.targetIndex, 0, params.column.field);
    setOrderedFields(fieldRemoved)
    localStorage.setItem(`${tableId}OrderedFields`, JSON.stringify(fieldRemoved));
  }

  // FONT SIZE
  const savedFontSize = localStorage.getItem(`${tableId}FontSize`);
  const [fontSize, setFontSize] = useState(savedFontSize ? Number(savedFontSize) : 12);
  const handleFontSizeChange = (event: SelectChangeEvent<number>, child: ReactNode) => {
    setFontSize(Number(event.target.value));
    localStorage.setItem(`${tableId}FontSize`, String(event.target.value));
  }

  return {
    hiddenColumns,
    handleColumnVisibiltyChange,
    tableDensity,
    handleStateChange,
    paginationState,
    handlePaginationChange,
    fontSize,
    handleFontSizeChange,
    sortModel,
    handleSortModelChange,
    pinnedColumns,
    handlePinnedColumnsChange,
    filterModel,
    handleFilterModelChange,
    handleColumnOrderChange,
    orderedFields,
    hiddenSelected,
    handleSetUserDefaultFilters,
    userDefaultFilters,
  };
};

export default usePersistTableSettings;
