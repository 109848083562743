import { createMongoId } from "utils/createMongoId";

export interface IChat {
  _id: string;
  messages: IPopulatedMessage[];
}

export interface IPopulatedMessage {
  _id: string;
  user: string;
  userName: string;
  date: string;
  message: string;
}

export interface IMessage {
  _id: string;
  user: string;
  date: string;
  message: string;
}

export function initChat(
  chat?: Partial<IChat>
): IChat {
  return {
    _id: createMongoId(),
    messages: [],
    ...chat,
  };
}

export function initMessage(
  message: Partial<IMessage> & { user: IMessage["user"] }
): IMessage {
  return {
    _id: createMongoId(),
    date: new Date().toISOString(),
    message: "",
    ...message,
  };
}