import { Box } from '@mui/material';
import React from 'react';

interface Props {
  userInitials: string;
}

const Avatar: React.FC<Props> = ({ userInitials }) => {

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: 6,
        height: 6,
        backgroundColor: "primary.main",
        borderWidth: "13px",
        borderStyle: "solid",
        borderColor: "primary.main",
        color: "white",
        fontSize: "10px",
        fontWeight: 600,
      }}
    >
      {userInitials}
    </Box>
  );
};

export default Avatar;
