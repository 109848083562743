import { Photo } from "store/models/Photo";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";
import { getQueryStringFromObject } from "store/api/util";


export const workOrderPhotoApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getPhotosByWorkOrderId: builder.query<Photo[], { workOrderId: string }>({
      query: ({ workOrderId }) =>
        `workOrderPhoto/byWorkOrderId?workOrderId=${workOrderId}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, { workOrderId }) => [
        { type: "WorkOrderPhotos", id: workOrderId },
      ],
    }),
    getPhotosByInspectionId: builder.query<Photo[], { inspectionId: string }>({
      query: ({ inspectionId }) =>
        `workOrderPhoto/inspection?inspectionId=${inspectionId}`,
      transformResponse: getResponseBody,
      providesTags: (result, error, { inspectionId }) => [
        { type: "InspectionPhotos", id: inspectionId },
      ],
    }),
    addWorkOrderPhoto: builder.mutation<
      Photo,
      { workOrderId: string; photo: FormData }
    >({
      query: ({ workOrderId, photo }) => {
        return {
          url: `workOrderPhoto?workOrderId=${workOrderId}`,
          method: "POST",
          body: photo,
        };
      },
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody,
      invalidatesTags: (result, error, { workOrderId }) => [
        { type: "WorkOrderPhotos", id: workOrderId }, {type: "WorkOrder", id: workOrderId}, "InspectionPhotos"
      ],
    }),
    removeWorkOrderPhoto: builder.mutation<
      null,
      { workOrderId: string; photoId: string }
    >({
      query: ({ workOrderId, photoId }) => ({
        url: `/workOrderPhoto?workOrderId=${workOrderId}&photoId=${photoId}`,
        method: "DELETE",
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: (result, error, { workOrderId }) => [
        { type: "WorkOrderPhotos", id: workOrderId },{type: "WorkOrder", id: workOrderId}, "InspectionPhotos"
      ],
    }),
    getCheckListItemPhotos: builder.query<
    Photo[],
    { inspectionId: string; checkListItemId: string }
  >({
    query: ({ inspectionId, checkListItemId }) =>
      `/inspection/checkListItemPhotos?inspectionId=${inspectionId}&checkListItemId=${checkListItemId}`,
    transformResponse: getResponseBody<Photo[]>,
    providesTags: (result, error, { checkListItemId }) => [
      { type: "CheckListItemPhotos", id: checkListItemId },
    ],
  }),
    addInspectionCheckListItemPhoto: builder.mutation<
      Photo,
      { inspectionId: string; checkListItemId: string; photo: FormData, workOrderId: string }
    >({
      query(body) {
        const {photo, ...rest} = body;
        return {
          url: `/workOrderPhoto/inspection?${getQueryStringFromObject(rest)}`,
          method: "POST",
          body: photo,
        };
      },
      extraOptions: { maxRetries: 0 },
      transformResponse: getResponseBody<Photo>,
      invalidatesTags: (result, error, { checkListItemId, inspectionId, workOrderId }) => [
        { type: "CheckListItemPhotos", id: checkListItemId }, {type: "Inspection", id: inspectionId}, { type: "WorkOrderPhotos", id: workOrderId }, { type: "InspectionPhotos", id: inspectionId}
      ],
    }),
    deleteInspectionCheckListItemPhoto: builder.mutation<
      null,
      { inspectionId: string; checkListItemId: string; photoId: string }
    >({
      query(query) {
        return {
          url: `/workOrderPhoto/inspection?${getQueryStringFromObject(query)}`,
          method: "DELETE",
        };
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: (result, error, { checkListItemId, inspectionId }) => [
        { type: "CheckListItemPhotos", id: checkListItemId },{type: "Inspection", id: inspectionId}, { type: "InspectionPhotos", id: inspectionId}
      ],
    }),
   
  }),
});

export const {
  useGetPhotosByWorkOrderIdQuery,
  useAddWorkOrderPhotoMutation,
  useRemoveWorkOrderPhotoMutation,
  useGetCheckListItemPhotosQuery,
  useAddInspectionCheckListItemPhotoMutation,
  useDeleteInspectionCheckListItemPhotoMutation,
  useGetPhotosByInspectionIdQuery
} = workOrderPhotoApi;
  
  