import React from "react";
import { useSelector } from "react-redux";
import Blade from "components/Blade";
import {
  selectBladeMode,
  selectLoggedInUser,
  setBladeMode,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import UserForm from "features/users/blade/UserForm";
import { selectCurrentUser, setCurrentUserId } from "store/slices/userSlice";
import UserData from "store/models/UserData";
import { Form, Formik, FormikHelpers } from "formik";
import api from "store/api";
import { Button, IconButton } from "@mui/material";
import { initUser } from "store/api/user";
import { userValidationSchema } from "utils/formikAPI";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { createMongoId } from "utils/createMongoId";
import { useAppDispatch } from 'store/store';

interface Props {}

const UserCreateBlade: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const bladeMode = useSelector(selectBladeMode);
  const currentUser = useSelector(selectCurrentUser);
  const isOpen = bladeMode === "userCreate" && !currentUser;
  const loggedInUser = useSelector(selectLoggedInUser);

  const createUser = (
    user: UserData,
    { setSubmitting, resetForm, setFieldValue }: FormikHelpers<UserData>
  ) => {
    api.users.createOne(user, {
      onData: ({ email }: UserData) => {
        dispatch(
          simpleGlobalMessage(`User ${email} created successfully`, "success")
        );
        dispatch(setBladeMode("userCreate"));
        resetForm();
        setFieldValue("_id", createMongoId())
      },
      onError: () => setSubmitting(false),
    });
  };

  const handleClose = () => {
    dispatch(setCurrentUserId(undefined));
    dispatch(setBladeMode("none"));
  };

  return (
    <Formik
      initialValues={initUser({countries: loggedInUser?.countries})}
      validationSchema={userValidationSchema}
      onSubmit={createUser}
    >
      {({ resetForm }) => {
        const resetCardForm = () => {
          resetForm();
          handleClose();
        };

        return (
          <Form>
            <Blade
              open={isOpen}
              changeOpen={resetCardForm}
              actions={[
                <IconButton
                  color="primary"
                  type="submit"
                  data-testid="submitUser"
                  key="submitUser"
                >
                  <SaveIcon />
                </IconButton>,
                <IconButton key="cancel" onClick={() => resetForm()}>
                  <CancelIcon />
                </IconButton>,
              ]}
            >
              <UserForm />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Submit
              </Button>
            </Blade>
          </Form>
        );
      }}
    </Formik>
  );
};
export default UserCreateBlade;
