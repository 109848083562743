import {
  DataGridProProps,
  DataGridPro,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridCsvExportOptions,
  GridToolbarContainerProps,
  GridRowClassNameParams,
  GridPrintExportOptions,
  GridExportDisplayOptions,
  GridApiPro,
  GridToolbarColumnsButton,
  GridFilterPanel,
  GridFilterModel,
  GridCallbackDetails,
  GridDeleteForeverIcon,
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_ACTIONS_COLUMN_TYPE,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  Box,
  InputAdornment,
  LinearProgress,
  Button,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  alpha,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  ListItemText,
  ListItemIcon,
  MenuList,
  Popover,
  Divider,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Dialog
} from '@mui/material';
import { format } from 'date-fns';
import EmptyContent from 'components/empty-content/empty-content';
import usePersistTableSettings from 'hooks/usePersistTableSettings';
import { Dispatch, MutableRefObject, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import Iconify from "components/iconify/iconify"
import pluralize from 'pluralize';
import Tune from '@mui/icons-material/Tune';
import Restore from '@mui/icons-material/Restore';
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps';
import { IDataGridFilterFavorite } from 'store/services/dataGridFilterFavorite';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddIcon from '@mui/icons-material/Add';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ConfirmationModal from 'components/ConfirmationModal';
import useFilterFavorites from 'hooks/useFilterFavorites';

interface ToolbarProps extends GridToolbarContainerProps {
  additionalActions?: React.ReactNode[];
  handleAddNew?: VoidFunction;
  addNewButtonText: string;
  csvOptions?: GridCsvExportOptions;
  fontSize: number;
  handleFontSizeChange: (event: SelectChangeEvent<number>, child: ReactNode) => void;
  printOptions?: GridPrintExportOptions & GridExportDisplayOptions;
  disableFontSizeSelector: boolean;
  disableExport: boolean;
  hiddenSelected: number;
  handleFavoritesClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  handleFavoriteFilterDelete: (filterName: string) => void,
  handleFavoriteFilterClick: (filterName: string) => void,
  favoriteFilterMaxReached: boolean;
  userFavoriteFilters: { [filterName: string]: IDataGridFilterFavorite };
  anchorEl: HTMLButtonElement | null;
  setCreateDialogOpen: Dispatch<SetStateAction<boolean>>;
  filterActive: (filterName: string) => boolean;

}

function StyledToolbar({
    additionalActions,
    handleAddNew,
    fontSize,
    handleFontSizeChange,
    printOptions,
    disableFontSizeSelector,
    disableExport,
    addNewButtonText,
    csvOptions,
    hiddenSelected,
    handleFavoritesClick,
    handleClose,
    handleFavoriteFilterDelete,
    handleFavoriteFilterClick,
    favoriteFilterMaxReached,
    userFavoriteFilters,
    anchorEl,
    setCreateDialogOpen,
    filterActive,
    ...toolbarContainerProps
  }: ToolbarProps) {
    const buttonSize = "medium";
    const fontSizeOptions = [10, 12, 14, 16];

    return (
      <>
        <GridToolbarContainer sx={{ padding: 0 }} {...toolbarContainerProps}>
          <Box
            sx={(theme) => ({
              justifyContent: "flex-end",
              alignItems: "center",
              flexWrap: "wrap",
              display: "flex",
              flex: 1,
              gap: 2,
              [theme.breakpoints.down("sm")]: {
                display: "grid",
                gap: 1,
                gridTemplateColumns: "repeat(2, 1fr)",
              },
            })}
          >
            <Box>
              <GridToolbarQuickFilter
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled" }}
                      />
                    </InputAdornment>
                  ),
                }}
                size="small"
                inputProps={{ sx: { height: 19 } }}
              />
              {hiddenSelected ? (
                <Box display="flex" flex={1}>
                  <Typography variant="caption" color="error">
                    {`${pluralize(
                      "row",
                      hiddenSelected,
                      true
                    )} hidden by your current filter`}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box
              flex={1}
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  display: "none",
                },
              })}
            />
            {!disableFontSizeSelector && (
              <FormControl
                sx={(theme) => ({
                  width: 120,
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                  },
                })}
              >
                <InputLabel id="demo-simple-select-label">Font Size</InputLabel>
                <Select
                  size="small"
                  value={fontSize}
                  label="Font Size"
                  onChange={handleFontSizeChange}
                  sx={{ height: 36 }}
                >
                  {fontSizeOptions.map((size) => (
                    <MenuItem key={size} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!disableExport && (
              <GridToolbarExport
                size={buttonSize}
                variant="text"
                csvOptions={{
                  ...csvOptions,
                  fileName: csvOptions?.fileName
                    ? `${csvOptions?.fileName} - ${format(
                        new Date(),
                        "dd-MMM-yyyy"
                      )}`
                    : undefined,
                }}
                printOptions={printOptions}
                sx={(theme: Theme) => ({
                  color:
                    theme.palette.mode === "light"
                      ? theme.palette.primary.contrastText
                      : "white",
                })}
              />
            )}
            <GridToolbarDensitySelector
              size={buttonSize}
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.contrastText
                    : "white",
              })}
            />
            <GridToolbarFilterButton
              componentsProps={{
                button: {
                  variant: "text",
                  size: buttonSize,
                },
              }}
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.contrastText
                    : "white",
              })}
            />
            <Button
              startIcon={<StarIcon />}
              onClick={handleFavoritesClick}
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.contrastText
                    : "white",
              })}
            >
              Favourites
            </Button>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuList sx={{ minWidth: 200 }}>
                {Object.keys(userFavoriteFilters).map((filterName, index) => {
                  return (
                    <MenuItem key={`${filterName}-${index}`}>
                      <Box
                        display="flex"
                        width="100%"
                        alignItems="center"
                        onClick={() => handleFavoriteFilterClick(filterName)}
                      >
                        <ListItemIcon>
                          {filterActive(filterName) ? (
                            <StarIcon fontSize="small" color="primary" />
                          ) : (
                            <StarBorderIcon fontSize="small" />
                          )}
                        </ListItemIcon>
                        <ListItemText>{filterName}</ListItemText>
                      </Box>

                      <ConfirmationModal
                        handleConfirmation={() =>
                          handleFavoriteFilterDelete(filterName)
                        }
                        message={`Are you sure you want to delete your ${filterName} filter?`}
                        sx={{ alignSelf: "flex-end", marginLeft: 2 }}
                      >
                        <IconButton size="small">
                          <DeleteForever fontSize="small" />
                        </IconButton>
                      </ConfirmationModal>
                    </MenuItem>
                  );
                })}

                <Divider  />
                <MenuItem
                  sx={(theme) => ({
                    pointerEvents: "auto",
                    color: favoriteFilterMaxReached
                      ? theme.palette.action.disabled
                      : undefined,
                  })}
                  onClick={() => setCreateDialogOpen(true)}
                >
                  <ListItemIcon>
                    <AddIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>New</ListItemText>
                </MenuItem>
              </MenuList>
            </Popover>
            <GridToolbarColumnsButton
              size={buttonSize}
              variant="text"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "light"
                    ? theme.palette.primary.contrastText
                    : "white",
              })}
            />
            {handleAddNew && (
              <Button
                onClick={handleAddNew}
                startIcon={<Iconify icon="eva:plus-fill" />}
                variant="contained"
                color="primary"
                size={buttonSize}
                sx={{ whiteSpace: "nowrap" }}
              >
                {addNewButtonText}
              </Button>
            )}

            {additionalActions}
          </Box>
        </GridToolbarContainer>
        <Box />
      </>
    );
  };

type FilterPanelProps = GridProSlotProps["filterPanel"] & {
  persistFilter: boolean;
  handleSetUserDefaultFilters: (reason?: string) => void;
  userDefaultFilters: GridFilterModel;
  handleFilterModelChange: (model: GridFilterModel, details: GridCallbackDetails<any>) => void
};

function StyledFilterPanel({persistFilter, handleSetUserDefaultFilters, userDefaultFilters,handleFilterModelChange, ...props}: FilterPanelProps) {
  const userHasDefaultFilters = Boolean(userDefaultFilters?.items?.length)

  return (
    <Box sx={{ display: "flex" }}>
      {persistFilter && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: 2
          }}
        >
          <Tooltip title="Set Default Filters">
            <IconButton
              onClick={() => handleSetUserDefaultFilters()}
              color={userHasDefaultFilters ? "primary" : "inherit"}
              sx={{ height: 40 }}
            >
              <Tune />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset Table to Default Filters">
            <span>
              <IconButton
                onClick={() =>
                  handleFilterModelChange(userDefaultFilters, {
                    reason: "reset",
                  })
                }
                color="primary"
                disabled={!userHasDefaultFilters}
                sx={{ height: 40 }}
              >
                <Restore />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Clear Default Filters">
            <span>
              <IconButton
                onClick={() => handleSetUserDefaultFilters("clear")}
                color={userHasDefaultFilters ? "error" : "inherit"}
                disabled={!userHasDefaultFilters}
                sx={{ height: 40 }}
              >
                <GridDeleteForeverIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
      <GridFilterPanel {...props} sx={{ minWidth: "50vw" }} />
    </Box>
  );
}

export interface StyledDataGridProps
  extends Omit<DataGridProProps, 'columnVisibilityMdel' | 'onColumnVisibilityChange'> {
  additionalActions?: React.ReactNode[];
  handleAddNew?: VoidFunction;
  addNewButtonText?: string;
  csvOptions?: GridCsvExportOptions;
  toolbarContainerProps?: GridToolbarContainerProps;
  tableId: string;
  noResultsContent?: { title: string; description?: string; action?: React.ReactNode };
  highlightRow?: (params: GridRowClassNameParams<any>) => string;
  printOptions?: GridPrintExportOptions & GridExportDisplayOptions;
  disableFontSizeSelector?: boolean;
  disableExport?: boolean;
  disableToolbar?: boolean;
  persistPagination?: boolean;
  persistPinnedColumns?: boolean;
  persistSort?: boolean;
  persistFilter?: boolean;
  persistColumnOrder?: boolean;
  apiRef?: MutableRefObject<GridApiPro>;
}

const StyledDataGrid = (props: StyledDataGridProps) => {
  const {
    columns,
    rows,
    additionalActions,
    handleAddNew,
    csvOptions,
    toolbarContainerProps,
    tableId,
    sx,
    noResultsContent = { title: 'No Results Found' },
    highlightRow = () => {},
    printOptions,
    disableFontSizeSelector = false,
    disableExport = false,
    addNewButtonText = 'Add New',
    persistPagination = false,
    persistPinnedColumns = false,
    persistFilter = true,
    persistSort = false,
    persistColumnOrder = true,
    disableToolbar = false,
    initialState,
    apiRef,
    pinnedRows,
    ...otherProps
  } = props;
  
  const {
    hiddenColumns,
    handleColumnVisibiltyChange,
    handleStateChange,
    tableDensity,
    paginationState,
    handlePaginationChange,
    fontSize,
    handleFontSizeChange,
    sortModel,
    handleSortModelChange,
    filterModel,
    handleFilterModelChange,
    handleColumnOrderChange,
    orderedFields,
    hiddenSelected,
    pinnedColumns,
    handlePinnedColumnsChange,
    handleSetUserDefaultFilters,
    userDefaultFilters,
  } = usePersistTableSettings({
    tableId,
    persistPagination,
    persistSort,
    persistFilter,
    persistColumnOrder,
    persistPinnedColumns,
    initialState,
    orderedFields: [
      ...(otherProps.checkboxSelection ? [GRID_CHECKBOX_SELECTION_FIELD] : []),
      ...(columns.find((column) => column.field === "actions")? [GRID_ACTIONS_COLUMN_TYPE] : []),
      ...columns.map(({field}) => field).filter(field => field !== "actions")
    ],
  });
  
  const gripApiRef = useGridApiRef();
  const apiRefToUse = apiRef || gripApiRef;

  useEffect(() => {
    if (apiRefToUse) {
      apiRefToUse.current.setState((state) => ({
        ...state,
        columns: { ...state.columns, orderedFields },
      }));
    }
  }, [pinnedRows]);
  
  const tableRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const setFilterModel = apiRefToUse.current.setFilterModel
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [favouriteName, setFavoriteName] = useState("");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);


  const {
    handleFavoritesClick,
    handleClose,
    handleFavoriteFilterClick,
    handleFavoriteFilterDelete,
    handleFavoriteFilterCreate,
    userFavoriteFilters,
    filterActive,
    favoriteFilterMaxReached
  } = useFilterFavorites({
    tableId,
    setAnchorEl,
    setFilterModel,
    favouriteName,
    setFavoriteName,
    setCreateDialogOpen,
    apiRefToUse
  }); 

   return (
    <>
      <DataGridPro
        apiRef={apiRefToUse}
        ref={tableRef}
        columns={columns}
        columnVisibilityModel={hiddenColumns}
        onColumnVisibilityModelChange={handleColumnVisibiltyChange}
        onColumnOrderChange={handleColumnOrderChange}
        pinnedRows={pinnedRows}
        pagination
        rows={rows}
        sx={{
          "& > .MuiDataGrid-toolbarContainer": {
            py: 3,
            backgroundColor: theme.palette.background.paper,
          },
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
            {
              outline: "none !important",
            },
          "& .MuiDataGrid-row:hover": {
            cursor:
              otherProps.onRowClick || otherProps.checkboxSelection
                ? "pointer"
                : undefined,
          },
          "& .MuiDataGrid-overlayWrapper": {
            minHeight: 200,
          },
          "& .MuiDataGrid-cell *": {
            fontSize,
          },
          "& .super-app-theme--highlight-row": {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },

          "& .MuiDataGrid-virtualScroller": {
            // Base scrollbar style
            "&::-webkit-scrollbar": {
              width: "12px",
              height: "12px",
            },
            // Track style
            "&::-webkit-scrollbar-track": {
              background: theme.palette.background.paper,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.primary.main,
              borderRadius: "8px",
            },
            // Scrollbar hover style
            "&:hover::-webkit-scrollbar": {
              width: "16px",
              height: "16px",
            },
          },

          overFlowX: "scroll",
          fontSize,
          height: !rows.length ? 500 : "auto",
          minHeight: 360,
          ...sx,
        }}
        getRowClassName={(params: GridRowClassNameParams<any>) =>
          `super-app-theme--${highlightRow(params) ? "highlight-row" : ""}`
        }
        paginationModel={paginationState}
        hideFooterRowCount
        hideFooterPagination={false}
        onPaginationModelChange={handlePaginationChange}
        sortModel={sortModel}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        onSortModelChange={handleSortModelChange}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        slots={{
          toolbar: disableToolbar ? null : StyledToolbar,
          filterPanel: StyledFilterPanel,
          loadingOverlay: LinearProgress,
          noResultsOverlay: () => <EmptyContent {...noResultsContent} />,
          noRowsOverlay: () => <EmptyContent {...noResultsContent} />,
        }}
        slotProps={{
          toolbar: {
            handleAddNew,
            addNewButtonText,
            additionalActions,
            fontSize,
            disableFontSizeSelector,
            handleFontSizeChange,
            printOptions,
            csvOptions,
            hiddenSelected,
            disableExport,
            handleFavoritesClick,
            handleClose,
            handleFavoriteFilterDelete,
            handleFavoriteFilterClick,
            favoriteFilterMaxReached,
            userFavoriteFilters,
            anchorEl,
            filterActive,
            setCreateDialogOpen,
            ...toolbarContainerProps,
          },
          filterPanel: {
            persistFilter,
            handleSetUserDefaultFilters,
            userDefaultFilters,
            handleFilterModelChange,
          } as FilterPanelProps,
        }}
        onStateChange={handleStateChange}
        density={tableDensity}
        {...otherProps}
        initialState={{
          ...initialState,
          columns: { ...initialState?.columns, orderedFields },
        }}
      />
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        <DialogTitle>Save current table filters as:</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              size="small"
              label="Favorite Name"
              value={favouriteName}
              fullWidth
              onChange={(e) => setFavoriteName(e.currentTarget.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!favouriteName}
            onClick={handleFavoriteFilterCreate}
          >
            Save
          </Button>
          <Button onClick={() => setCreateDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StyledDataGrid;
