import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  BoxProps,
} from "@mui/material";
import React, { MouseEventHandler, FC, useState } from "react";


interface Props extends BoxProps {
  handleConfirmation: MouseEventHandler<HTMLButtonElement>;
  message: string;
  title?: string;
  disabled?: boolean;
}

const ConfirmationModal: FC<Props> = ({
  message,
  title = "Are you sure?",
  children,
  handleConfirmation,
  disabled,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          if(disabled) return;
          setOpen(true);
        }}
        {...rest}
      >
        {children}
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        keepMounted
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(props) => {
              handleConfirmation(props);
              setOpen(false);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setOpen(false)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
