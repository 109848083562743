import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';
// utils

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', ...other }, ref) => {

  return (
    <Box ref={ref} {...other}>
        <title>{`${title} | Minimal-UI`}</title>
      {children}
    </Box>
  );
});

export default Page;
