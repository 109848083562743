import { TextField, TextFieldProps } from "@mui/material";
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { PickersActionBar } from "@mui/x-date-pickers/PickersActionBar";
import { PickersActionBarAction } from "@mui/x-date-pickers/PickersActionBar/PickersActionBar";

interface PickerProps extends Omit<DatePickerProps<string, string>, "renderInput"> {
  textFieldProps: Partial<TextFieldProps> & {"data-testid"?: string}
  clearable?: boolean
}

const DateSelector: React.FC<PickerProps>= ({ textFieldProps, clearable = false, ...props}) => {

  const pickerActions: PickersActionBarAction[] = ["today", "cancel", ]
  clearable && pickerActions.push("clear")
  props.closeOnSelect === false && pickerActions.push("accept")

  return (
    <DatePicker
      components={{ActionBar: PickersActionBar}}
      componentsProps={{ actionBar: {actions: pickerActions}}}
      inputFormat="MM-dd-yyyy"
      disableMaskedInput
      {...props}
      renderInput={(params) => (
        <TextField {...params} {...textFieldProps} />
      )}
    />
  );
};

export default DateSelector;
