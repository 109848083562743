/* eslint-disable react/no-unstable-nested-components */
import React, { FC, ReactNode } from "react";
import { Typography, Box } from "@mui/material";
import Scrollbar from "components/Scrollbar";
import { TransportCompany } from "store/slices/transportCompanySlice";
import { parseAddress } from "store/models/Address";

interface Props {
  currentTransportCompany: TransportCompany | undefined;
}
const TransportCompanyView: React.FC<Props> = ({ currentTransportCompany }) => {
  const Row: FC = ({ children }) => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        sx={(theme) => ({ py: 0.5, fontSize: theme.typography.subtitle2 })}
      >
        {children}
      </Box>
    );
  };

  function Field({ children }: { children: ReactNode }) {
    return (
      <Typography
        variant="subtitle2"
        sx={{ color: "text.secondary", alignSelf: "center" }}
        gutterBottom
      >
        {children}
      </Typography>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" columnGap={1}>
        {/* <Box>
          <Typography variant="h5">
            {titleCase(currentAsset.sizeCode)} -{" "}
            {titleCase(currentAsset.category)}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body2">{currentAsset.serialNumber}</Typography>{" "}
            <CopyClipboard
              value={currentAsset.serialNumber}
              tooltipText="Copy Serial #"
              successText={`Copied asset Serial #: ${currentAsset.serialNumber}`}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-start" columnGap={1} flexDirection="column">
        </Box> */}
      </Box>
      <Scrollbar>
        <Row>
          <Field>Name:</Field>
          {currentTransportCompany?.name}
        </Row>

        <Row>
          <Field>Address:</Field>
          {currentTransportCompany?.address
            ? parseAddress(currentTransportCompany.address)
            : "-"}
        </Row>

        <Row>
          <Field>Phone Number:</Field>
          {currentTransportCompany?.phoneNumber || "-"}
        </Row>
      </Scrollbar>
    </>
  );
};
export default TransportCompanyView;
