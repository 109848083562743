import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { Contract } from "store/models/Contract";
import { selectContracts } from "store/slices/contractSlice";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (contract: Contract) => `${contract.customerName} ${contract.projectNumber}`,
});

export type ContractAutoCompleteProps = Omit<
  AutocompleteProps<Contract, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  label?: string;
  "data-testId"?: string
  options?: AutocompleteProps<Contract, boolean, boolean, boolean>["options"]
};

const ContractAutocomplete: FC<ContractAutoCompleteProps> = ({ value, name, error, label, options: limitedOptions, ...rest }) => {
  const contracts = useSelector(selectContracts);
  const options = limitedOptions || Object.values(contracts);

  return (
    <Autocomplete
      options={options}
      {...rest}
      value={value || null}
      getOptionLabel={(contract) => typeof contract === "string" ? contract : `${contract?.customerName} (${contract?.projectNumber})`} 
      filterOptions={filterOptions}
      clearOnBlur={false}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          data-testid={rest["data-testId"]}
          error={error}
          margin="none"
          label={label}
          inputProps={{ ...params.inputProps, autocomplete: "new-password", form: { autocomplete: "off" } }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option._id}>
            {`${option?.customerName} (${option?.projectNumber})`}
          </li>
        );
      }}
    />
  );
}

export default ContractAutocomplete;