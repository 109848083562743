import {
  Badge,
  CircularProgress ,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FilterIcon from "@mui/icons-material/FilterList";
import { IconButtonAnimate } from "components/animate";
import MenuPopover from "components/MenuPopover";
import {
  selectLoadInactiveContracts,
  selectLoadingInactiveContracts,
  setLoadInactiveContracts,
} from "store/slices/contractSlice";
import { useAppDispatch } from 'store/store';
import api from "store/api";
import { Asset } from "store/models/Asset";
import { removeSoldAssetsFromStore, setManyAssets } from "store/slices/assetSlice";
import { setGlobalMessage } from "store/slices/systemSlice";

interface FilterProps {
  label: string;
  filterInput?: JSX.Element | null;
  secondaryAction?: JSX.Element | null;
  isLoading?: boolean;
}

const Filter: React.FC<FilterProps> = ({
  label,
  filterInput = null,
  secondaryAction = null,
  isLoading,
}) => {
  return (
    <ListItem>
      <ListItemText
        disableTypography
        primary={label}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={filterInput}
        sx={{
          whiteSpace: "nowrap",
        }}
      />
      <ListItemSecondaryAction>
        {isLoading ? <CircularProgress size={24} /> : secondaryAction}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const AppFilter = () => {
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [loadSoldAssets, setLoadSoldAssets] = useState(false)
  const [isLoadingSoldAssets, setIsLoadingSoldAssets] = useState(false)
  const loadInactiveContracts = useSelector(selectLoadInactiveContracts);
  const isLoadingInactiveContracts = useSelector(selectLoadingInactiveContracts);
  const activeFilters = numberOfActiveFilters();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function numberOfActiveFilters() {
    let count = 0;
    loadInactiveContracts && count++;
    return count;
  }

  const toggleArchivedContracts = () => {
    dispatch(setLoadInactiveContracts(!loadInactiveContracts));
  }
  const toggleSoldAssets = () => {
    if (loadSoldAssets) {
      dispatch(removeSoldAssetsFromStore())
      setLoadSoldAssets(false)
    } else {
      setLoadSoldAssets(true)
      setIsLoadingSoldAssets(true)
      getSoldAssets()
    }
  }

 const getSoldAssets = () => {
   api.assets.queryAssets({ status: "SOLD" }, {
     onData: (data: Asset[]) => dispatch(setManyAssets(data)),
     onComplete: () => {
       dispatch(
         setGlobalMessage({
           show: true,
           messageText: "Successfully loaded Sold assets",
           severity: "success",
         })
       );
       setIsLoadingSoldAssets(false);
     },
   });
 };


  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        size="large"
        color={isOpen ? "primary" : "default"}
        onClick={handleOpen}
      >
        <Badge badgeContent={activeFilters}>
          <FilterIcon color={activeFilters ? "primary" : "inherit"} />
        </Badge>
      </IconButtonAnimate>
      <MenuPopover
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <List>
          <Filter
            label="Show Inactive Contracts"
            secondaryAction={
              <Switch
                id="view-inactive-contracts-toggle"
                edge="end"
                checked={loadInactiveContracts}
                onChange={toggleArchivedContracts}
              />
            }
            isLoading={isLoadingInactiveContracts}
          />
          <Filter
            label="Load Sold Assets"
            secondaryAction={
              <Switch
                id="view-sold-assets-toggle"
                edge="end"
                checked={loadSoldAssets}
                onChange={toggleSoldAssets}
              />
            }
            isLoading={isLoadingSoldAssets}
          />
        </List>
      </MenuPopover>
    </>
  );
};

export default AppFilter;
