import { Config } from 'store/slices/configSlice';
import { sendToBackend } from "store/api/util";
import { errorHandler } from "./shared";
import { InspectionConfig } from 'store/slices/maintenanceSlice';

const configApi = {
  getAll: (
    actions?: {
      onData?: (data: Config[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Config[]>({
      route: `config/`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  getInspectionOptions: (
    actions?: {
      onData?: (data: InspectionConfig[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<InspectionConfig[]>({
      route: `config/inspectionOptions`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    })
  },
  updateOne: (
    data: Config,
    actions?: {
      onData?: (data: Config) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Config>({
      route: "config/",
      method: "PATCH",
      body: data,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
};

export default configApi;