import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addYears, getMonth, getYear } from "date-fns";
import { activeContractStatuses, billableContractStatuses, Contract } from 'store/models/Contract';
import api from "store/api";
import { STARFLEET_STORE } from "store/store";
import { MMDDYYYY, toDDMMMYYYY } from "utils/util";
import { Asset } from "store/models/Asset";
import { selectAssets } from "./assetSlice";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import IAccessory from "store/models/Accessory"
import _ from "lodash";

export type CONTRACTS_STORE = {
  contracts: { [_id: string]: Contract };
  contractsLoading: boolean;
  contractsLoadingInactive: boolean;
  loadInactiveContracts: boolean;
  currentContractId: string | undefined;
  currentBillOfLadingId: string | undefined | null;
  currentMovementId: string | undefined;
  billOfLadingMovementId: string | undefined;
  currentRevenueYear: number;
  newReservation: Contract | undefined;
  // ContractId for Movement Blade RTK Query fetch
  movementContractId: string | undefined;
};



const initialState: CONTRACTS_STORE = {
  contracts: {},
  contractsLoading: true,
  contractsLoadingInactive: false,
  loadInactiveContracts: false,
  currentContractId: undefined,
  currentBillOfLadingId: undefined,
  currentMovementId: undefined,
  billOfLadingMovementId: undefined,
  currentRevenueYear: getYear(new Date()),
  newReservation: undefined,
  movementContractId: undefined,
};

const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setContract(state, action: PayloadAction<Contract>) {
      state.contracts[action.payload._id] = action.payload
    },
    setMultipleContracts(
      state,
      action: PayloadAction<Contract[]>
    ) {
      for (const contract of action.payload) {
        state.contracts[contract._id] = contract
      } ;
    },
    setContractsLoading(state, action: PayloadAction<boolean>) {
      state.contractsLoading = action.payload;
    },
    setInactiveContractsLoading(state, action: PayloadAction<boolean>) {
      state.contractsLoadingInactive = action.payload;
    },
    setLoadInactiveContracts(state, action: PayloadAction<boolean>) {
      state.contractsLoadingInactive = true;
      state.loadInactiveContracts = action.payload;
    },
    setCurrentContractId(state, action: PayloadAction<string | undefined>) {
      state.currentContractId = action.payload
    },
    setCurrentBillOfLadingId(state, action: PayloadAction<string | undefined | null>) {
      state.currentBillOfLadingId = action.payload
    },
    setCurrentMovementId(state, action: PayloadAction<string | undefined>) {
      state.currentMovementId = action.payload
    },
    setBillOfLadingMovementId(state, action: PayloadAction<string | undefined>) {
      state.billOfLadingMovementId = action.payload
    },
    setCurrentRevenueYear(state, action: PayloadAction<number>) {
      state.currentRevenueYear = action.payload;
    },
    setNewReservation(state, action: PayloadAction<CONTRACTS_STORE["newReservation"]>) {
      state.newReservation = action.payload;
    },
    setMovementContractId(state, action: PayloadAction<string | undefined>) {
      state.movementContractId = action.payload;
    }
  },
});

export const fetchAllActiveContractData = () => {
  return (dispatch: Function) => {
    api.contracts.getAllActive(
      {
        onData: (data) => {
          dispatch(setMultipleContracts(data));
        },
        onComplete: () => {
          dispatch(setInactiveContractsLoading(false));
          dispatch(setContractsLoading(false));
        },
      }
    );
  };
};

export const fetchAllInactiveContractData = () => {
  return (dispatch: Function) => {
    api.contracts.getAllInactive(
      {
        onData: (data) => {
          dispatch(setMultipleContracts(data));
        },
        onComplete: () => {
          dispatch(setInactiveContractsLoading(false));
          dispatch(setContractsLoading(false));
        },
      }
    );
  };
};

export const selectLoadingInactiveContracts = (state: STARFLEET_STORE) => state.contracts.contractsLoadingInactive
export const selectLoadInactiveContracts = (state: STARFLEET_STORE) => state.contracts.loadInactiveContracts
export const selectContracts = (state: STARFLEET_STORE) => state.contracts.contracts
export const selectCurrentMovementId = (state: STARFLEET_STORE) => state.contracts.currentMovementId
export const selectCurrentContractId = (state: STARFLEET_STORE) => state.contracts.currentContractId
export const selectMovementContractId = (state: STARFLEET_STORE) => state.contracts.movementContractId
export const selectContractsLoading = (state: STARFLEET_STORE) => state.contracts.contractsLoading
export const selectBillOfLadingMovementId = (state: STARFLEET_STORE) => state.contracts.billOfLadingMovementId
export const selectCurrentBillOfLadingId = (state: STARFLEET_STORE) => state.contracts.currentBillOfLadingId
export const selectNewReservation = (state: STARFLEET_STORE) => state.contracts.newReservation

export const contractExistsInStore = (
  contracts: { [_id: string]: Contract },
  contractIdToFind: string
) => {
  return Boolean(contracts[contractIdToFind]);
};

export const selectCurrentRevenueYear = (state: STARFLEET_STORE) => state.contracts.currentRevenueYear;
export const selectCurrentContract = (state: STARFLEET_STORE) => state.contracts.currentContractId ? state.contracts.contracts[state.contracts.currentContractId] : undefined
export const selectCurrentContractClient = (state: STARFLEET_STORE) => {
  const currentContract: Contract | undefined =
    state.contracts.contracts[String(state.contracts.currentContractId)];
  return currentContract
    ? state.clients.clients[String(currentContract.client)]
    : undefined;
}
export const selectBranchRevenueByYear = (state: STARFLEET_STORE) => {
  const branches = state.options.branches;
  const assets = selectAssets(state);
  const users = state.users.users;
  const defaultMonthsRevenue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const loggedInUserId = state.system.loggedInUserId as string;

  //years additional for evergreen
  const evergreenDurationAssumption = 1;
  const rentalContracts = Object.values(state.contracts.contracts)
    .filter(
      (contract) =>
        contract.contractSigned &&
        billableContractStatuses.includes(contract.status) &&
        contract.contractType === "Rental"
    )
    .map((contract) => {
      return {
        ...contract,
        endDate: contract.endDate
          ? contract.endDate
          : toDDMMMYYYY(addYears(new Date(), evergreenDurationAssumption)),
      };
    });

  const currentRevenueYear = state.contracts.currentRevenueYear;
  const userBranchIds = users[loggedInUserId]?.branches || [];
  const userBranches = userBranchIds.map((branchId) => branches[branchId]);
  const defaultBranchesForYear = userBranches.reduce(
    (defaultBranches, branch) => ({
      ...defaultBranches,
      [branch.name]: [...defaultMonthsRevenue],
    }),
    {} as { [branchName: string]: number[] }
  );

  const branchRevenueByYearLookup = {
    [currentRevenueYear]: defaultBranchesForYear,
  };

  let currentMonth = 0; // 0 represents Jan
  do {
    // eslint-disable-next-line no-loop-func
    rentalContracts.forEach((contract) => {
      contract.assetDeliverables.forEach((deliverable) => {
        const asset = assets[deliverable.asset];
        // When an asset is missing it should not be included in any renvenue branchCounts
        // Assets should only be missing when a user does not have access to its branch on a Contract
        if (!asset || !userBranchIds.includes(asset.branch)) return;

        const rentalRate = deliverable.rentalRate
          ? Number(deliverable.rentalRate.amount)
          : 0;
        const branch = branches[asset.branch];

        if (
          isBillableMonth(
            deliverable,
            contract,
            currentRevenueYear,
            currentMonth
          )
        ) {
          branchRevenueByYearLookup[currentRevenueYear][branch.name][
            currentMonth
          ] += rentalRate;
        }
      });

      const assetBranches = contract.assetDeliverables
        .filter((deliverable) => Boolean(assets[deliverable.asset]))
        .map((deliverable) => branches[assets[deliverable.asset].branch]._id);

      const totalAssets = contract.assetDeliverables.length;
      const branchCounts = _.countBy(assetBranches);
      const proportionalRevenue: { [branch: string]: number } = {};

      for (const branch in branchCounts) {
        if (true) {
          proportionalRevenue[branch] = branchCounts[branch] / totalAssets;
        }
      }
      contract.accessories.forEach((accessory) => {
        const rentalRate = accessory.rate ? Number(accessory.rate.amount) : 0;

        if (
          isBillableMonth(accessory, contract, currentRevenueYear, currentMonth)
        ) {
          if (accessory.asset && assets[accessory.asset] && branches[assets[accessory.asset].branch]) {
            const branchName = branches[assets[accessory.asset].branch].name;
            branchRevenueByYearLookup[currentRevenueYear][branchName][
              currentMonth
            ] += rentalRate * accessory.quantity;
          } else {
            for (const branch in proportionalRevenue) {
              if (branches[branch]) {
                const branchName = branches[branch].name;
                branchRevenueByYearLookup[currentRevenueYear][branchName][
                  currentMonth
                ] += rentalRate * proportionalRevenue[branch] * accessory.quantity;
              }
            }
          }
        }
      });
    });
    currentMonth++;
  } while (currentMonth < 12);

  return branchRevenueByYearLookup;
};

function isBillableMonth(deliverable: AssetDeliverable | IAccessory, contract: Contract, currentRevenueYear: number, currentRevenueMonth: number ){
  const assetEndMonth = getItemEndMonth(deliverable, contract)
  const assetStartMonth = getItemStartMonth(deliverable, contract)
  const assetStartYear = getItemStartYear(deliverable, contract)
  const assetEndYear = getItemEndYear(deliverable, contract)
  return (
    contractDurationWithinCurrentYear() ||
    contractDurationStartsInCurrentYear() ||
    contractDurationStartsBeforeCurrentYear() ||
    contractDurationExceedsCurrentYear() 
  );

  function contractDurationWithinCurrentYear(){
    return (
      assetStartYear === currentRevenueYear &&
      currentRevenueYear === assetEndYear &&
      assetStartMonth <= currentRevenueMonth &&
      currentRevenueMonth <= assetEndMonth 
    )
  }

  function contractDurationStartsInCurrentYear(){
    return (
      assetStartYear === currentRevenueYear &&
      currentRevenueYear < assetEndYear &&
      assetStartMonth <= currentRevenueMonth  
    )
  }

  function contractDurationStartsBeforeCurrentYear(){
    return (
      assetStartYear < currentRevenueYear &&
      currentRevenueYear === assetEndYear &&
      currentRevenueMonth <= assetEndMonth 
    )
  }

  function contractDurationExceedsCurrentYear(){
    return (
      assetStartYear < currentRevenueYear &&
      currentRevenueYear < assetEndYear
    ) 
  }
}

function getItemEndMonth(item: AssetDeliverable | IAccessory, contract: Contract) {
  return item.customBillingEnd
    ? getMonth(new Date(item.customBillingEnd as MMDDYYYY))
    : getMonth(new Date(contract.endDate as MMDDYYYY));
}
function getItemStartMonth(item: AssetDeliverable | IAccessory, contract: Contract) {
  return item.customBillingStart
    ? getMonth(new Date(item.customBillingStart as MMDDYYYY))
    : getMonth(new Date(contract.startDate));
}

function getItemEndYear(item: AssetDeliverable | IAccessory, contract: Contract) {
  return item.customBillingEnd
    ? getYear(new Date(item.customBillingEnd))
    : getYear(new Date(contract.endDate as MMDDYYYY));
}
function getItemStartYear(item: AssetDeliverable | IAccessory, contract: Contract) {
  return item.customBillingStart
    ? getYear(new Date(item.customBillingStart))
    : getYear(new Date(contract.startDate));
}


export const selectContractProjectNumberLookup = (state: STARFLEET_STORE) => {
  const contracts = Object.values(state.contracts.contracts);
  return contracts.reduce((contractsByProjectNumber, contract) => {
    contractsByProjectNumber[contract.projectNumber] = contract;
    return contractsByProjectNumber;
  }, {} as { [projectNumber: string]: Contract })
}

export const selectRevenueYearRange = (state: STARFLEET_STORE) => {
  const years: number[] = [];
  let currentYear = getYear(new Date())
  let maxEndYear = getYear(new Date())
  for (const contractId in state.contracts.contracts) {
    if (contractId) {
      const year = getYear(
        new Date(state.contracts.contracts[contractId].endDate || "")
      );
      if (year > maxEndYear) {
        maxEndYear = year;
      }
    }
  }
  do {
    years.push(currentYear);
    currentYear++
  } while ( currentYear <= maxEndYear);

  return years;
}

export function contractIsActive(contract: Contract) {
  return activeContractStatuses.includes(contract.status);
}

export function findRentalRate(assetid: Asset["_id"], contract?: Contract) {
  return (
    contract?.assetDeliverables.find(
      (assetDeliverable) => assetDeliverable.asset === assetid
    )?.rentalRate.amount || 0
  );
}


export const {
  setContract,
  setMultipleContracts,
  setContractsLoading,
  setInactiveContractsLoading,
  setLoadInactiveContracts,
  setCurrentContractId,
  setCurrentBillOfLadingId,
  setCurrentMovementId,
  setBillOfLadingMovementId,
  setCurrentRevenueYear,
  setNewReservation,
  setMovementContractId
} = contractSlice.actions;

export default contractSlice.reducer;
