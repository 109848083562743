import React, { FC } from "react";
import { BadgeProps, Box, BoxProps } from "@mui/material";

interface Props extends BoxProps {
  color: BadgeProps["color"];
}

const Dot: FC<Props> = ({ color = "info", ...rest }) => {
  return (
    <Box
      {...rest}
      sx={{
        borderRadius: "100%",
        height: "8px",
        width: "8px",
        backgroundColor: `${color}.main`,
        ...rest.sx
      }}
    />
  );
};

export default Dot;
