import React, { useEffect, useMemo } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import FastTextInput from "components/formik/FastTextInput";
import FastNumberInput from "components/formik/FastNumberInput";
import FastPhoneInput from "components/formik/FastPhoneInput";
import FastDateInput from "components/formik/FastDateInput";
import FastTimeInput from "components/formik/FastTimeInput";
import { useSelector } from "react-redux";
import {
  selectBillOfLadingMovementId,
  selectContracts,
  selectCurrentBillOfLadingId,
  selectMovementContractId,
  setCurrentBillOfLadingId,
} from "store/slices/contractSlice";
import { BillOfLading, blankBillOfLading } from "store/models/BillOfLading";
import { ReactComponent as PdfIcon } from "svg/pdf.svg";
import { selectLoggedInUser, setGlobalMessage } from "store/slices/systemSlice";
import FastHrsMinsInput from "components/formik/FastHrsMinsInput";
import {
  displayAddress,
  getFullName,
  getUserFullName,
  parseSizeCode,
  toMMDDYYYY,
} from "utils/util";
import { selectAssets } from "store/slices/assetSlice";
import { selectBranches, selectYards } from "store/slices/optionSlice";
import { selectClients } from "store/slices/clientSlice";
import BillOfLadingArticles from "features/billOfLading/BillOfLadingArticles";
import usePermissions, { FeatureType, ActionType } from "hooks/usePermissions";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import UserData from "store/models/UserData";
import { billOfLadingValidationSchema } from "utils/formikAPI";
import { initCurrency } from "types/Currency";
import { selectDefaultUserCurrency } from "store/slices/configSlice";
import { Client } from "store/models/Client";
import { useGetMovementByIdQuery } from "store/services/movement";
import {
  useCreateBillOfLadingMutation,
  useGetBOLByIdQuery,
  useUpdateBillOfLadingMutation,
} from "store/services/billOfLading";
import { useAppDispatch } from "store/store";
import LoadingOverlay from "components/LoadingOverlay";
import { useGetClientContactByQueryQuery } from "store/services/clientContact";
import { ArticleAttributes } from "types/ArticleAttributes";
import { AdditionalItem } from "types/AdditionalItem";
import Address from "store/models/Address";
import AddressAutoComplete from "components/autocomplete/AddressAutoComplete";
import { PDFDownloadLink } from "@react-pdf/renderer";
import BillOfLadingPdf from "./BillOfLadingPdf";

interface Props {
  onClose: () => void;
}

const BillOfLadingForm: React.FC<Props> = ({ onClose }) => {
  const currentBillOfLadingId = useSelector(selectCurrentBillOfLadingId);
  const contractId = useSelector(selectMovementContractId);
  const loggedInUser = useSelector(selectLoggedInUser) as UserData;
  const defaultUserCurrency = useSelector(
    selectDefaultUserCurrency(loggedInUser)
  );
  const assets = useSelector(selectAssets);
  const branches = useSelector(selectBranches);
  const yards = useSelector(selectYards);
  const contracts = useSelector(selectContracts);
  const clients = useSelector(selectClients);
  const movementId = useSelector(selectBillOfLadingMovementId) as string;
  const dispatch = useAppDispatch();
  const checkBillOfLadingPermissions = usePermissions(
    FeatureType.BILL_OF_LADING
  );
  const { data: movement, isLoading: movementLoading } =
    useGetMovementByIdQuery({ movementId, contractId });
  const { data: billOfLading, isLoading: bolLoading } = useGetBOLByIdQuery(
    currentBillOfLadingId || "",
    { skip: !currentBillOfLadingId }
  );
  const [createBillOfLading, { isLoading: isCreatingBol }] =
    useCreateBillOfLadingMutation();
  const [updateBillOfLading, { isLoading: isUpdatingBol }] =
    useUpdateBillOfLadingMutation();

  const branch = getBranch();
  const contract = movement?.contract
    ? contracts[movement.contract._id]
    : undefined;
  const { data: [clientContact] = [], isLoading: clientContactLoading } =
    useGetClientContactByQueryQuery(
      { _id: contract?.siteContact! },
      { skip: Boolean(currentBillOfLadingId || !contract?.siteContact) }
    );
  const client = contract ? clients[contract.client] : undefined;
  const asset = movement?.asset ? assets[movement?.asset._id] : undefined;
  const yard = movement?.asset
    ? yards[assets[movement?.asset._id]?.yard]
    : undefined;

  function getBranch() {
    switch (true) {
      case Boolean(movement?.asset?.branch):
        return branches[movement?.asset?.branch || ""];
      case Boolean(movement?.nonAsset):
        return branches[movement?.nonAsset?.branch || ""];
      case Boolean(movement?.accessory):
        return branches[movement?.accessory?.branch || ""];
      default:
        return undefined;
    }
  }

  const initialValues = billOfLading || autoPopulateNewBillOfLading();

  function autoPopulateNewBillOfLading() {
    const { width, length } = parseSizeCode(asset?.sizeCode);

    const height = asset?.height || 0;

    const autoPopulatedFields: Partial<BillOfLading> = {
      originator: determineOriginator(),
      consignor: determineConsignor(),
      consignorAddress: determineConsignorAddress(),
      pointOfOrigin: determinePointOfOrigin(),
      consignorPhone: determineConsignorPhone(),
      deliveryDate: movement?.dateOfMove
        ? toMMDDYYYY(movement.dateOfMove)
        : null,
      carrierCompanyName: movement?.transportCompanyName,
      projectNumber: contract ? contract.projectNumber : "",
      consigneeContactName: determineConsigneeContactName(),
      consigneeName: determineConsigneeName(),
      consigneeContactPhone: determineConsigneePhone(),
      consigneeAddress: determineConsigneeAddress(),
      consigneeDestination: determineConsigneeDestionation(),
      declaredValuation: 0,
      billTo: contract?.billTo || null,
      assetArticles: movement?.asset
        ? [
          {
            asset: movement?.asset._id,
            attributes: {
              width,
              length,
              height,
              weight: 0,
              amount: initCurrency({ currency: defaultUserCurrency }),
              hazardous: false,
              notes: ""
            },
          },
        ]
        : [],
      additionalItems: movement?.nonAsset
        ? [
          {
            description: movement.nonAsset.description,
            quantity: 1,
            hazardous: false,
            width,
            length,
            height,
            weight: 0,
            amount: initCurrency({ currency: defaultUserCurrency }),
            notes: ""
          },
        ]
        : movement?.accessory
        ? [
            {
              description: movement.accessory.name,
              quantity: 1,
              hazardous: false,
              width,
              length,
              height,
              weight: 0,
              amount: initCurrency({ currency: defaultUserCurrency }),
              notes: ""
            },
          ]
        : movement?.asset
        ? [...accessoriesAssignedToAssetAsAdditionalItems()]
        : [],
    };

    function accessoriesAssignedToAssetAsAdditionalItems(): (AdditionalItem & ArticleAttributes)[] {
      return (contract?.accessories || []).filter((accessory) => accessory.asset === movement?.asset?._id).map((accessory) => {
        return {
          description: accessory.name,
          quantity: accessory.quantity,
          hazardous: false,
          width,
          length,
          height,
          weight: 0,
          amount: initCurrency({ currency: defaultUserCurrency }),
          notes: ""
        }
      })
    }

    function determineOriginator() {
      return getUserFullName(loggedInUser);
    }

    function determineConsignor() {
      return movement?.type === "IN"
        ? (client as Client)?.legalName
        : loggedInUser.countries[0] === "USA"
        ? "ATCO Structures & Logistics (USA) Inc."
        : "ATCO Structures & Logistics Ltd";
    }

    function determineConsignorAddress() {
      if (!client || !contract) {
        return branch?.address.address || "Address not found";
      }
      return movement?.type === "IN"
        ? displayAddress(contract.billTo) || "Address Not Found"
        : movement?.type === "OUT"
        ? branch?.address.address || "Branch Not Found"
        : "";
    }

    function determineConsigneeName() {
      if (!client) return "";

      return movement?.type === "IN"
        ? getUserFullName(loggedInUser)
        : movement?.type === "OUT"
        ? client.legalName
        : "";
    }

    function determineConsigneeContactName() {
      return movement?.type === "OUT" && contract?.siteContact
        ? getFullName(clientContact)
        : "";
    }

    function determineConsigneePhone() {
      if (!client) return "";

      return movement?.type === "IN"
        ? loggedInUser.phone
        : movement?.type === "OUT"
        ? contract?.siteContact
          ? clientContact?.primaryPhone
          : client.phoneNumber
        : "";
    }

    function determineConsigneeDestionation() {
      if (!client || !contract) return "";

      return movement?.type === "IN"
        ? branch?.address.address || "Branch not found"
        : movement?.type === "OUT"
        ? displayAddress(contract.shipTo)
        : "";
    }

    function determineConsignorPhone() {
      return movement?.type === "IN" ? "" : loggedInUser.phone || "";
    }

    return { ...blankBillOfLading(), ...autoPopulatedFields };
  }

  function determinePointOfOrigin() {
    if (!contract) return "";

    return movement?.type === "OUT"
      ? yard?.address.address || "Address not found"
      : movement?.type === "IN"
      ? displayAddress(contract.shipTo)
      : "";
  }

  function pointOfOriginErrorMessage() {
    const recommendedPointOfOrigin = determinePointOfOrigin();
    switch (true) {
      case movement?.type === "OUT":
        return `OUT movements should match yard address ${recommendedPointOfOrigin}`;
      case movement?.type === "IN":
        return `IN movements should match contract Ship To ${recommendedPointOfOrigin}`;
      default:
        return "";
    }
  }
  function determineConsigneeAddress() {
    if (!contract) return "";

    return movement?.type === "IN"
      ? branch?.address.address || "Branch not found"
      : movement?.type === "OUT"
      ? displayAddress(contract.billTo)
      : "";
  }

  function consigneeAddressErrorMessage() {
    const recommendedConsigneeAddress = determineConsigneeAddress();
    switch (true) {
      case movement?.type === "IN":
        return `IN movements should match branch address ${recommendedConsigneeAddress}`;
      case movement?.type === "OUT":
        return `OUT movements should match contract Ship To ${recommendedConsigneeAddress}`;
      default:
        return "";
    }
  }

  function getDateAndTime(date: Date, time: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const hours = new Date(time).getHours();
    const minutes = new Date(time).getMinutes();
    const seconds = new Date(time).getSeconds();
    const milliseconds = new Date(time).getMilliseconds();

    return new Date(
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    )
  }

  const save = (billOfLading: BillOfLading) => {
    if (!movement) return;
    if (Boolean(billOfLading._id)) {
      updateBillOfLading({
        billOfLading,
        contractId: movement?.contract?._id || "",
        movementId: movement?._id,
      })
        .unwrap()
        .then(() =>
          dispatch(
            setGlobalMessage({
              messageText: `Successfully updated BOL ${billOfLading.billOfLadingNumber}`,
              severity: "success",
              show: true,
            })
          )
        ).catch((error) => {
          dispatch(
            setGlobalMessage({
              messageText: error.data.message,
              severity: "error",
              show: true,
            })
          );
        });
    } else {
      const { _id, ...rest } = billOfLading;
      createBillOfLading({
        billOfLading: rest,
        movementId: movementId,
        contractId: movement?.contract?._id,
      })
        .unwrap()
        .then((bol) => {
          dispatch(setCurrentBillOfLadingId(bol._id));
          dispatch(
            setGlobalMessage({
              messageText: `Successfully created BOL ${billOfLading.billOfLadingNumber}`,
              severity: "success",
              show: true,
            })
          );
        }).catch((error) => {
          dispatch(
            setGlobalMessage({
              messageText: error.data.message,
              severity: "error",
              show: true,
            })
          );
        });
    }
  };

  const pdfName =
    client && contract
      ? `BOL-${billOfLading?.billOfLadingNumber}-${client?.legalName}-${contract?.projectNumber}.pdf`
      : `BOL-${billOfLading?.billOfLadingNumber}`;

  function disableForm() {
    if (currentBillOfLadingId) {
      const userCanEditBOL = checkBillOfLadingPermissions(ActionType.UPDATE);
      return !userCanEditBOL;
    } else {
      const userCanCreateBOL = checkBillOfLadingPermissions(ActionType.CREATE);
      return !userCanCreateBOL;
    }
  }

  const loading =
    movementLoading ||
    bolLoading ||
    isUpdatingBol ||
    isCreatingBol ||
    clientContactLoading;

  return (
    <LoadingOverlay loading={loading}>
      <Formik
        initialValues={initialValues}
        validationSchema={billOfLadingValidationSchema}
        onSubmit={save}
        enableReinitialize
      >
        {(formik) => {
         
          useEffect(() => {
            if (formik.submitCount > 0) {
              let errors = [];

              for (const error of Object.keys(formik.errors)) {
                errors.push(
                  error
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .replace(/(^|\s)\S/g, (l) => l.toUpperCase())
                );
              }

              if (Object.keys(formik.errors).length) {
                dispatch(
                  setGlobalMessage({
                    messageText: `${errors
                      .join(" Invalid. ")} Invalid.`,
                    severity: "error",
                    show: true,
                  })
                );
              }
            }
          }, [formik.submitCount]);
          
          return (
          <Form>
            <DialogTitle
              sx={(theme) => ({
                position: "fixed",
                backgroundColor: theme.palette.background.paper,
                zIndex: 10,
                width: "100%",
              })}
            >
              <Box
                sx={{
                  textAlign: "right",
                  marginBottom: 2,
                }}
              >
                {billOfLading ? (
                  <PDFDownloadLink
                    fileName={pdfName}
                    document={
                      <BillOfLadingPdf
                        billOfLading={billOfLading}
                        assets={billOfLading.assetArticles.map(
                          ({ asset }) => assets[asset]
                        )}
                        branch={branch}
                      />
                    }
                  >
                    <Tooltip title="Bill of Lading">
                      <IconButton
                        color="primary"
                        disabled={!Boolean(billOfLading?._id)}
                        sx={{ marginRight: 2 }}
                      >
                        <SvgIcon>
                          <PdfIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </PDFDownloadLink>
                ) : (
                  <IconButton color="primary" disabled sx={{ marginRight: 2 }}>
                    <SvgIcon>
                      <PdfIcon />
                    </SvgIcon>
                  </IconButton>
                )}
                <Button
                  color="primary"
                  size="large"
                  variant="outlined"
                  className={`save ${formik.dirty ? "unsavedChanges" : ""}`}
                  onClick={formik.submitForm}
                  disabled={disableForm() || isCreatingBol || isUpdatingBol}
                >
                  {isCreatingBol || isUpdatingBol ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Save"
                  )}
                </Button>
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    marginLeft: 2,
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "grid",
                  columnGap: 6,
                  rowGap: 3,
                  gridTemplateColumns: "repeat(12, 1fr)",
                  overflowY: "auto",
                  m: 6,
                  px: 6,
                  pt: 7,
                }}
              >
                <Typography variant="h3" sx={{ gridColumn: "1 / 10" }}>
                  BILL OF LADING
                </Typography>
                <Box sx={{ gridColumn: "10 / 13" }}>
                  <FastTextInput
                    name="billOfLadingNumber"
                    label="Bill Of Lading #"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 6" }}>
                  <FastTextInput
                    name="pointOfOrigin"
                    label="Point of Origin / At"
                    textFieldProps={{
                      helperText:
                        formik.values.pointOfOrigin !== determinePointOfOrigin()
                          ? pointOfOriginErrorMessage()
                          : "",
                    }}
                  />
                </Box>

                <Box sx={{ gridColumn: "6 / 10" }}>
                  <FastTextInput name="originator" label="Originator" />
                </Box>

                <Box sx={{ gridColumn: "10 / 13" }}>
                  <FastDateInput name="deliveryDate" label="Delivery Date" />
                </Box>

                <Box sx={{ gridColumn: "1 / 6" }}>
                  <FastTextInput name="consignor" label="Consignor" />
                </Box>

                <Box sx={{ gridColumn: "6 / 10" }}>
                  <FastPhoneInput
                    name="consignorPhone"
                    label="Consignor Phone"
                  />
                </Box>

                <Box sx={{ gridColumn: "10 / 13" }}>
                  <FastTextInput name="projectNumber" label="Project Number" />
                </Box>

                <Box sx={{ gridColumn: "1 / 10" }}>
                  <FastTextInput
                    name="consignorAddress"
                    label="Consignor Address"
                  />
                </Box>

                <Box sx={{ gridColumn: "10 / 13" }}>
                  <FastTextInput
                    name="purchaseOrderNumber"
                    label="Purchase Order #"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 4" }}>
                  <FastDateInput
                    name="scheduledPickupTimeAtOrigin"
                    label="Scheduled Pickup Date at Origin"
                  />
                </Box>
                <Box sx={{ gridColumn: "4 / 6" }}>
                  <FastTimeInput
                    name="scheduledPickupTimeAtOrigin"
                    label="Scheduled Pickup Time at Origin"
                    onChange={(value) => {
                      if (
                        !formik.values.scheduledPickupTimeAtOrigin &&
                        formik.values.deliveryDate
                      ) {
                        formik.setFieldValue(
                          "scheduledPickupTimeAtOrigin",
                          getDateAndTime(
                            new Date(formik.values.deliveryDate),
                            value
                          )
                        );
                      } else {
                        formik.setFieldValue(
                          "scheduledPickupTimeAtOrigin",
                          value
                        );
                      }
                    }}
                  />
                </Box>

                <Box sx={{ gridColumn: "6 / 10" }}>
                  <FastDateInput
                    name="scheduledDeliveryTimeAtDestination"
                    label="Scheduled Delivery Date at Destination"
                  />
                </Box>

                <Box sx={{ gridColumn: "10 / 13" }}>
                  <FastTimeInput
                    name="scheduledDeliveryTimeAtDestination"
                    label="Scheduled Delivery Time at Destination"
                    onChange={(value) => {
                      if (
                        !formik.values.scheduledDeliveryTimeAtDestination &&
                        formik.values.deliveryDate
                      ) {
                        formik.setFieldValue(
                          "scheduledDeliveryTimeAtDestination",
                          getDateAndTime(
                            new Date(formik.values.deliveryDate),
                            value
                          )
                        );
                      } else {
                        formik.setFieldValue(
                          "scheduledDeliveryTimeAtDestination",
                          value
                        );
                      }
                    }}
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 6" }}>
                  <FastTextInput name="consigneeName" label="Consignee Name" />
                </Box>

                <Box sx={{ gridColumn: "6 / 13" }}>
                  <FastTextInput
                    name="consigneeAddress"
                    label="Consignee Address"
                    textFieldProps={{
                      helperText:
                        formik.values.consigneeAddress !==
                        determineConsigneeAddress()
                          ? consigneeAddressErrorMessage()
                          : "",
                    }}
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 6" }}>
                  <AddressAutoComplete
                    label="Contract Bill To"
                    name="billTo"
                    disabled={!contract}
                    readOnly
                    value={formik.values.billTo}
                    error={Boolean(
                      formik.touched.billTo && formik.errors.billTo
                    )}
                    options={contract ? clients[contract.client]?.billTos : []}
                    size="small"
                    onChange={(_, value) =>
                      formik.setFieldValue("billTo", value as Address)
                    }
                    onAddressSelect={(address: Address) =>
                      formik.setFieldValue("billTo", address)
                    }
                  />
                </Box>

                <Box sx={{ gridColumn: "6 / 13" }}>
                  <FastTextInput
                    name="consigneeDestination"
                    label="Consignee Destination"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 10" }}>
                  <FastTextInput
                    name="consigneeContactName"
                    label="Consignee Contact Name"
                  />
                </Box>

                <Box sx={{ gridColumn: "10 / 13" }}>
                  <FastPhoneInput
                    name="consigneeContactPhone"
                    label="Consignee Contact Phone"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 13", mt: 3 }}>
                  <BillOfLadingArticles
                    assetArticles={formik.values.assetArticles}
                    additionalItems={formik.values.additionalItems}
                    formik={formik}
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 10" }}>
                  <FastTextInput
                    name="specialAgreementBetweenConsignorAndCarrier"
                    label="Special Agreement Between Consignor and Carrier"
                  />
                </Box>

                <Box sx={{ gridColumn: "10 / 13" }}>
                  <FastNumberInput
                    name="declaredValuation"
                    label="Declared Valuation"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 10" }}>
                  <FastTextInput
                    name="carrierCompanyName"
                    label="Carrier Company Name"
                  />
                </Box>

                <Box sx={{ gridColumn: "10 / 13" }}>
                  <FastPhoneInput name="carrierPhone" label="Carrier Phone" />
                </Box>

                <Box sx={{ gridColumn: "1 / 4" }}>
                  <FastHrsMinsInput
                    name="consignorLoadingTimeTotalMinutes"
                    label="Consignor Loading Time"
                  />
                </Box>

                <Box sx={{ gridColumn: "4 / 13" }}>
                  <FastTimeInput
                    name="consignorDepartureTime"
                    label="Consignor Departure Time"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 4" }}>
                  <FastHrsMinsInput
                    name="consigneeUnloadingTimeTotalMinutes"
                    label="Consignee Unloading Time"
                  />
                </Box>

                <Box sx={{ gridColumn: "4 / 7" }}>
                  <FastTimeInput
                    name="consigneeSiteArrivalTime"
                    label="Consignee Site Arrival Time"
                  />
                </Box>

                <Box sx={{ gridColumn: "7 / 13" }}>
                  <FastTimeInput
                    name="consigneeSiteDepartureTime"
                    label="Consignee Site Departure Time"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 4" }}>
                  <FastHrsMinsInput
                    name="consigneeOtherTimeAtOriginOrDestinationTotalMinutes1"
                    label="Consignee Other Time"
                  />
                </Box>

                <Box sx={{ gridColumn: "4 / 13" }}>
                  <FastTextInput
                    name="consigneeOtherTimeAtOriginOrDestinationExplanation1"
                    label="Explanation"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 4" }}>
                  <FastHrsMinsInput
                    name="consigneeOtherTimeAtOriginOrDestinationTotalMinutes2"
                    label="Consignee Other Time"
                  />
                </Box>

                <Box sx={{ gridColumn: "4 / 13" }}>
                  <FastTextInput
                    name="consigneeOtherTimeAtOriginOrDestinationExplanation2"
                    label="Explanation"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 4" }}>
                  <FastHrsMinsInput
                    name="consigneeOtherTimeAtOriginOrDestinationTotalMinutes3"
                    label="Consignee Other Time"
                  />
                </Box>

                <Box sx={{ gridColumn: "4 / 13" }}>
                  <FastTextInput
                    name="consigneeOtherTimeAtOriginOrDestinationExplanation3"
                    label="Explanation"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 13" }}>
                  <FastTextInput
                    name="comments"
                    label="Comments"
                    textFieldProps={{ multiline: true, minRows: 3 }}
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 5" }}>
                  <FastTextInput
                    name="acknowledgementAtOriginConsignorName"
                    label="Acknowledgement at Origin Consignor Name"
                  />
                </Box>

                <Box sx={{ gridColumn: "5 / 7" }}>
                  <FastDateInput
                    name="acknowledgementAtOriginConsignorDate"
                    label="Date"
                  />
                </Box>

                <Box sx={{ gridColumn: "7 / 11" }}>
                  <FastTextInput
                    name="acknowledgementAtDestinationConsigneeName"
                    label="Acknowledgement at Destination Consignee Name"
                  />
                </Box>

                <Box sx={{ gridColumn: "11 / 13" }}>
                  <FastDateInput
                    name="acknowledgementAtDestinationConsigneeDate"
                    label="Date"
                  />
                </Box>

                <Box sx={{ gridColumn: "1 / 5" }}>
                  <FastTextInput
                    name="acknowledgementAtOriginCarrierName"
                    label="Acknowledgement at Origin Carrier Name"
                  />
                </Box>

                <Box sx={{ gridColumn: "5 / 7" }}>
                  <FastDateInput
                    name="acknowledgementAtOriginCarrierDate"
                    label="Date"
                  />
                </Box>

                <Box sx={{ gridColumn: "7 / 11" }}>
                  <FastTextInput
                    name="acknowledgementAtDestinationCarrierName"
                    label="Acknowledgement at Destination Carrier Name"
                  />
                </Box>

                <Box sx={{ gridColumn: "11 / 13" }}>
                  <FastDateInput
                    name="acknowledgementAtDestinationCarrierDate"
                    label="Date"
                  />
                </Box>
              </Box>
            </DialogContent>
          </Form>
        )}}
      </Formik>
    </LoadingOverlay>
  );
};
export default BillOfLadingForm;
