import { getQueryStringFromObject } from "store/api/util";
import { Asset } from "store/models/Asset";
import { Contract } from "store/models/Contract";
import { starfleetApi } from "store/services/api";
import { getResponseBody } from "store/services/utils";

export const assetApi = starfleetApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAssetById: builder.query<Asset, string>({
      query: (id) => `/asset/specificAsset?assetId=${id}`,
      transformResponse: getResponseBody<Asset>,
      providesTags: (result, error, id) => [{ type: "Asset", id: result?._id }],
    }),
    getAssetContractHistory: builder.query<{contracts: Contract[], totalRecords: number}, {assetId: string, limit: number}>({
      query: (query) => `asset/assetContractHistory?${getQueryStringFromObject(query)}`,
      transformResponse: getResponseBody<{contracts: Contract[], totalRecords: number}>,
      providesTags: (result, error, {assetId}) => [{ type: "Asset", id: `Hsitory:${assetId}` }],
    }),
  }),
});

export const { useGetAssetByIdQuery, useGetAssetContractHistoryQuery } = assetApi;
