import React from "react";
import { FastField, FastFieldProps } from "formik";
import { Checkbox } from "@mui/material";

const FastCheckbox: React.FC<{ name: string }> = ({
  name,
  ...props
}) => {
  return (
    <FastField {...props} name={name}>
      {({ field }: FastFieldProps) => {
        return (
          <Checkbox
            {...field}
            checked={field.value}
            size="medium"
            color="default"
          />
        );
      }}
    </FastField>
  );
};

export default FastCheckbox;
