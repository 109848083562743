import { Photo } from 'store/models/Photo';
import { sendToBackend } from 'store/api/util';
import { errorHandler } from "./shared";

export default {
  getAll: (
    workOrderId: string,
    actions?: {
      onData?: (data: Photo[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Photo[]>({
      route: `workOrderPhoto/byWorkOrderId?workOrderId=${workOrderId}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getAllForInspection: (
    inspectionId: string,
    actions?: {
      onData?: (data: Photo[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Photo[]>({
      route: `workOrderPhoto/inspection?inspectionId=${inspectionId}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  createOne: (
    workOrderId: string,
    form: FormData,
    actions?: {
      onData?: (data: Photo) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Photo>({
      route: `workOrderPhoto/?workOrderId=${workOrderId}`,
      method: "POST",
      file: form,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  createOneForCheckListItem: (
    inspectionId: string,
    checkListItemId: string,
    form: FormData,
    actions?: {
      onData?: (data: Photo) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Photo>({
      route: `workOrderPhoto/inspection?inspectionId=${inspectionId}&checkListItemId=${checkListItemId}`,
      method: "POST",
      file: form,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  deleteOne: (
    workOrderId: string,
    photoId: string,
    actions?: {
      onData?: (data: undefined) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<undefined>({
      route: `workOrderPhoto/?workOrderId=${workOrderId}&photoId=${photoId}`,
      method: "DELETE",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  deleteOneForCheckListItem: (
    inspectionId: string,
    checkListItemId: string,
    photoId: string,
    actions?: {
      onData?: (data: undefined) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<undefined>({
      route: `workOrderPhoto/inspection?inspectionId=${inspectionId}&checkListItemId=${checkListItemId}&photoId=${photoId}`,
      method: "DELETE",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  }
};

