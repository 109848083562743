import React, { useState } from "react";
import {
  setCurrentWorkOrderId,
} from "store/slices/maintenanceSlice";
import { setBladeMode } from "store/slices/systemSlice";
import {
  Box,
  Button,
  Chip,
  ChipProps,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { useAppDispatch } from "store/store";
import { dateFromMMDDYYYY, toDDMMMYYYY } from "utils/util";
import { WorkOrderStatus } from "store/models/WorkOrder";
import { WebWOQueryResponse, useGetWorkOrdersByAssetIdQuery } from "store/services/workOrder";
import BladeLoading from "components/BladeLoading";

interface Props {
  assetId: string;
}
const OpenWorkOrders: React.FC<Props> = ({ assetId }) => {

  const [showInactive, setShowInactive] = useState(false)
  const {data: assetWorkOrders = [], isLoading, isFetching} = useGetWorkOrdersByAssetIdQuery({assetId, loadInactive: showInactive}, {skip: !assetId})
  const dispatch = useAppDispatch();
  const theme = useTheme()

  if (!assetId) {
    return null;
  }

  const handleWorkOrderClick = (workOrder: WebWOQueryResponse) => {
    dispatch(setCurrentWorkOrderId(workOrder._id));
    dispatch(setBladeMode("workOrderViewEdit"));
  };

  function byDueDate(a: WebWOQueryResponse, b: WebWOQueryResponse) {
    return new Date(a.dueDate) > new Date(b.dueDate) ? 1 : -1
  }

  function getChipTextColor(workOrder: WebWOQueryResponse): ChipProps["color"] {
    switch(workOrder.status) {
      case WorkOrderStatus.CANCELLED: 
      return undefined
      case WorkOrderStatus.COMPLETE:
        return "success"
      default: 
        return dateFromMMDDYYYY(workOrder.dueDate) > new Date() ? undefined
        : "error"
    }
  }

  const totalHours = assetWorkOrders.reduce((hours, workOrder) => {
    if ([WorkOrderStatus.TO_DO, WorkOrderStatus.IN_PROGRESS].includes(workOrder.status)) {
    hours += workOrder.actualHoursToComplete || workOrder.estimatedHours || 0;
    }

    return hours;
  }, 0);

  return (
    <BladeLoading isLoading={isLoading}>
      <TableContainer component={Paper} sx={{ maxHeight: "70vh" }}>
        <Table aria-label="inspection-table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="center">
                <Typography sx={{ whiteSpace: "nowrap" }}>
                  Hrs{" "}
                  <Tooltip title="Total Remaining Est. Hours">
                    <Chip
                      sx={{
                        whiteSpace: "nowrap",
                        "& .MuiChip-label": {
                          paddingRight: "8px",
                          paddingLeft: "8px",
                   
                        },
                        fontSize: "0.75rem",
                        fontWeight: "bold",
                      }}
                      label={`${totalHours}`}
                      variant="outlined"
                      color="default"
                    />
                  </Tooltip>
                </Typography>
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">
                <Button
                  onClick={() =>
                    setShowInactive((showInactive) => !showInactive)
                  }
                  variant="outlined"
                  sx={{ minWidth: "135px" }}
                  size="small"
                >
                  {showInactive ? "Due / Completed" : "Due"}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ maxHeight: "500px" }}>
            {!!assetWorkOrders.length && assetWorkOrders
              .filter((workOrder) =>
                showInactive
                  ? true
                  : [
                      WorkOrderStatus.TO_DO,
                      WorkOrderStatus.IN_PROGRESS,
                    ].includes(workOrder.status)
              )
              .sort(byDueDate)
              .map((workOrder) => {
                return (
                  <TableRow
                    key={workOrder._id}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleWorkOrderClick(workOrder)}
                    hover
                  >
                    <TableCell
                      sx={{
                        borderTopLeftRadius: theme.spacing(1),
                        borderBottomLeftRadius: theme.spacing(1),
                        boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
                        width: "40%",
                      }}
                    >
                      {workOrder.inspection
                        ? workOrder.inspectionType
                        : workOrder.title}
                    </TableCell>
                    <TableCell align="center">
                      {workOrder.estimatedHours}
                    </TableCell>
                    <TableCell align="center">{workOrder.status}</TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderTopRightRadius: theme.spacing(1),
                        borderBottomRightRadius: theme.spacing(1),
                        boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
                      }}
                    >
                      <Chip
                        label={toDDMMMYYYY(
                          dateFromMMDDYYYY(workOrder.completedDate || workOrder.dueDate)
                        )}
                        color={getChipTextColor(workOrder)}
                        variant="outlined"
                        sx={{ width: "135px", cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {!assetWorkOrders.length && !isFetching && (
          <Box sx={{ pt: 3 }}>
            <Typography sx={{ textAlign: "center"}}>
              No Work Orders
            </Typography>
          </Box>
        )}
      </TableContainer>
      {isFetching && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </BladeLoading>
  );
};

export default OpenWorkOrders;
