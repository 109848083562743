import {
  Badge,
  Box,
  Typography,
  Button,
} from "@mui/material";
import _ from "lodash";
import { Dispatch, SetStateAction, FC } from "react";
import { IChecklistItem, IChecklistResultOption } from "store/models/Inspection";

import AddPhotoAlternate from "@mui/icons-material/AddPhotoAlternate";
import OpenInNew from "@mui/icons-material/OpenInNew";


interface ItemProps {
  item: IChecklistItem;
  handleItemToggle: (item: IChecklistItem, value: IChecklistResultOption) => void;
  setSelectedListItemId?: Dispatch<SetStateAction<string | undefined>>;
}

const InspectionItem: FC<ItemProps> = ({ item, handleItemToggle, setSelectedListItemId }) => {
  const handlePass = () => {
    handleItemToggle(item, "Pass")
    setSelectedListItemId && setSelectedListItemId(item._id)
  }

  const handleFail = () => {
    handleItemToggle(item, "Fail")
    setSelectedListItemId && setSelectedListItemId(item._id)
  }
  
  const handleNotApplicable = () => {
    handleItemToggle(item, "N/A")
    setSelectedListItemId && setSelectedListItemId(undefined)
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: 3,
        width: 400,
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
      }}
    >
      <Typography>{item.taskName}{item.quantity ? ` x${item.quantity}` : ""}</Typography>
      <Box marginRight={2} display="flex">
        <Button
          endIcon={(
            <Badge badgeContent={item.result === "Pass" ? item.photos.length : 0} color="info">
              <AddPhotoAlternate />
            </Badge>
          )}
          onClick={handlePass}
          variant={item.result === "Pass" ? "contained" : "outlined"}
          sx={{
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          fullWidth
        >
          Pass
        </Button>
        <Button
          variant={item.result === "Fail" ? "contained" : "outlined"}
          color="error"
          endIcon={<OpenInNew />}
          onClick={handleFail}
          sx={{ borderRadius: 0 }}
          fullWidth
        >
          Fail
        </Button>
        <Button
          variant={item.result === "N/A" ? "contained" : "outlined"}
          onClick={handleNotApplicable}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          }}
          fullWidth
        >
          N/A
        </Button>
      </Box>
    </Box>
  );
};

export default InspectionItem
