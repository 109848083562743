import { Dispatch, FC, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentContract,
} from "store/slices/contractSlice";
import ContractAccessory from "./ContractAccessory";
import { Box } from "@mui/material";
import AccessoryAutoComplete from "components/autocomplete/AccessoryAutoComplete";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import { useAppDispatch } from 'store/store';
import { contractInEndedStatus } from "utils/util";
import BulkMovementCreate from "features/contracts/BulkMovementCreate";
import Movement from "store/models/Movement";
import useContractBladeUtils from "features/contracts/blades/useContractBladeUtils";

interface Props {
  changeIsSubmitting: Dispatch<SetStateAction<boolean>>
}

const ContractAccessories: FC<Props> = ({changeIsSubmitting}) => {
  const [newMovements, setNewMovements] = useState<Movement[]>([]);
  const currentContract = useSelector(selectCurrentContract);
  const dispatch = useAppDispatch();

  const {addAccessoryToContract} = useContractBladeUtils()
  if (!currentContract) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}>
      <AccessoryAutoComplete
        fullWidth
        enableConfirm
        blurOnSelect
        onChange={(_, accessoryName, reason) => {
          if (!currentContract) return;
          if (contractInEndedStatus(currentContract.status)) {
            dispatch(
              simpleGlobalMessage(
                `${currentContract.status} contracts cannot have accessories added to them`
              )
            );
          } else if (reason === "selectOption" && accessoryName) {
              addAccessoryToContract(String(accessoryName));
          }
        }}
        placeholder="New Accessory"
        name="accessory"
      />
      {currentContract?.accessories.map((accessory) => (
        <ContractAccessory key={accessory._id} accessory={accessory} changeIsSubmitting={changeIsSubmitting} />
      ))}
      {Boolean(newMovements.length) && (
        <BulkMovementCreate
          currentContract={currentContract}
          open={Boolean(newMovements.length)}
          onClose={() => setNewMovements([])}
          maxWidth="xl"
          initialMovements={newMovements}
        />
      )}
    </Box>
  );
};

export default ContractAccessories;
