import { Box, BoxProps, CircularProgress } from "@mui/material";
import React from "react";

interface Props extends BoxProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const LoadingComponent: React.FC<Props> = ({
  isLoading,
  children,
  ...boxProps
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          minHeight: 400,
          alignItems: "center",
          justifyContent: "center",
          width: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return <Box {...boxProps}>{children}</Box>;
};

export default LoadingComponent;
