import { sendToBackend } from "store/api/util";
import UserData from "store/models/UserData";
import { errorHandler } from "./shared";
import { createMongoId } from "utils/createMongoId";

const usersApi = {
  getAll: (actions?: {
    onData?: (data: UserData[]) => void;
    onError?: (message: string) => void;
    onComplete?: () => void;
    currentlySubmitting?: (isSubmitting: boolean) => void;
  }) => {
    sendToBackend<UserData[]>({
      route: "user/returnAll",
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getOne: (
    userId: string,
    actions?: {
      onData?: (data: UserData) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<UserData>({
      route: `user/specificUser?userId=${userId}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getUserDocument: (
    body: UserData,
    actions?: {
      onData?: (data: UserData) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<UserData>({
      route: "user/returnUserForEmailAddress",
      method: "POST",
      body,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  createOne: (
    data: UserData,
    actions?: {
      onData?: (data: UserData) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<UserData>({
      route: "user/createUser",
      method: "POST",
      body: data,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  updateOne: (
    data: UserData,
    actions?: {
      onData?: (data: UserData) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<UserData>({
      route: "user/updateUser",
      method: "POST",
      body: data,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  loginUser: (
    data: { idToken: string, lastLoggedIn: UserData["lastLoggedIn"] },
    actions?: {
      onData?: (data: UserData) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<UserData>({
      route: "user/login",
      method: "POST",
      body: data,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
};

export const initUser = (user?: Partial<UserData>): UserData => {
  return { 
    _id: createMongoId(),
    email: "",
    phone: "",
    role: "VIEWER",
    branches: [],
    isDisabled: false,
    countries: [],
    lastName: "",
    firstName: "",
    ...user
  }
}

export default usersApi;
