import React from "react";
import { FastField, FastFieldProps } from "formik";
import { TextField } from "@mui/material";
import { parsePhoneNumberOfDubiousLength } from 'utils/util';

const FastPhoneInput: React.FC<{ label: string; name: string }> = ({
  label,
  name,
  ...props
}) => {

  return (
    <FastField {...props} name={name}>
      {({ field, form, meta }: FastFieldProps) => (
        <TextField
          {...field}
          {...props}
          label={label}
          value={parsePhoneNumberOfDubiousLength(field.value)}
          fullWidth
          variant="outlined"
          onChange={(newValue) => {
            form.setFieldValue(name, newValue.currentTarget.value.replace(/[^0-9]/g, ""));
          }}
          error={meta.touched && !!meta.error}
          helperText={meta.error || " "}
          size="small"
        />
      )}
    </FastField>
  );
};

export default FastPhoneInput;
