import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STARFLEET_STORE } from "store/store";
import { ICountry } from "./configSlice";
import { WorkOrderQuery } from "store/services/workOrder";

type InspectionType = `offRent${ICountry["name"]}` | `onRent${ICountry["name"]}`;

export type InspectionConfig =  { displayName: string, name: InspectionType };

const initialQuery: WorkOrderQuery = {
  workOrderNumber: undefined,
  assetNumber: undefined,
  serialNumber: undefined,
  statuses: [],
  branch: undefined,
  priority: undefined,
  category: undefined,
  subCategory: undefined,
  pendingMovement: undefined,
  workOrderType: undefined,
  startDate: undefined,
  endDate: undefined,
  assignedTo: undefined,
  createdBy: undefined,
  client: undefined,
  projectNumber: undefined,
};

export type MAINTENANCE_STORE = {
  currentWorkOrderId: string | undefined;
  availableInspectionTypes?: InspectionConfig[]
  workOrderQueryArgs: WorkOrderQuery
};

const initialState: MAINTENANCE_STORE = {
  currentWorkOrderId: undefined,
  availableInspectionTypes: undefined,
  workOrderQueryArgs: initialQuery,
};

const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setCurrentWorkOrderId: (state, action: PayloadAction<string | undefined>) => {
      state.currentWorkOrderId = action.payload;
    },
    setAvailableInspectionTypes(state, action: PayloadAction<InspectionConfig[]>) {
      state.availableInspectionTypes = action.payload;
    },
    setWorkOrderQueryArgs(state, action: PayloadAction<WorkOrderQuery>) {
      state.workOrderQueryArgs = action.payload;
    }
  },
});

export const selectWorkOrderQueryArgs = (state: STARFLEET_STORE) => state.maintenance.workOrderQueryArgs;


export const selectInspectionTypes = (state: STARFLEET_STORE) => {
  return state.maintenance.availableInspectionTypes
    ?.reduce(
      (inspectionTypeMap, { name, displayName }) => {
        inspectionTypeMap[displayName] = name;
        return inspectionTypeMap;
      },
      {} as { [displayName: string]: string }
    )};

export const selectCurrentWorkOrderId = (state: STARFLEET_STORE) => state.maintenance.currentWorkOrderId;

export const {
  setCurrentWorkOrderId,
  setAvailableInspectionTypes,
  setWorkOrderQueryArgs
} = maintenanceSlice.actions;


export default maintenanceSlice.reducer;
