import { TabContext, TabPanel } from "@mui/lab";
import { Badge, BadgeProps, Box, BoxProps, SxProps, Tab, Tabs, TabsProps, Theme } from "@mui/material";
import React, { useEffect, useState, ReactElement } from "react";

interface Props extends BoxProps {
  tabs: {
    tabComponent: ReactElement;
    tabName: string;
    tabNotificationCount?: number;
    badgeColor?: BadgeProps["color"];
    sx?: SxProps<Theme>;
  }[];
  isClosing: boolean;
  initTab?: string;
  disableRerenderOnChange?: boolean;
  onTabIndexChange?: (index: string) => void;
  variant?: TabsProps["variant"];
  tabsBottomMargin?: number;
}

const BladeTabs: React.FC<Props> = ({
  tabs,
  isClosing,
  initTab,
  disableRerenderOnChange = false,
  onTabIndexChange = () => {},
  variant = "fullWidth",
  tabsBottomMargin = 3,
  ...boxProps
}) => {
  const [activeTabIndex, changeActiveTabIndex] = useState("0");

  useEffect(() => {
    isClosing && changeActiveTabIndex("0");
  }, [isClosing]);

  useEffect(() => {
    if (initTab) {
      const tabIndex = tabs.findIndex((tab) => tab.tabName === initTab);
      if (tabIndex >= 0) {
        changeActiveTabIndex(String(tabIndex + 1));
      }
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onTabIndexChange(newValue);
    changeActiveTabIndex(newValue);
  };

  return (
    <Box {...boxProps}>
      <TabContext value={String(activeTabIndex)}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: tabsBottomMargin }}>
          <Tabs
            value={Number(activeTabIndex)}
            onChange={handleChange}
            variant={variant}
          >
            {tabs.map((tab, index) => (
              <Tab
                value={index}
                iconPosition="end"
                icon={(
                  <Badge
                    sx={{ left: 3, top: -12 }}
                    max={9}
                    color={tab.badgeColor}
                    badgeContent={tab.tabNotificationCount}
                  />
                )}
                label={tab.tabName}
                key={tab.tabName}
              />
            ))}
          </Tabs>
        </Box>
        {tabs.map(({tabComponent, tabName, ...rest}, index) => (
          <TabPanel
            {...rest}
            value={disableRerenderOnChange ? String(activeTabIndex) : String(index)}
            hidden={disableRerenderOnChange ? index !== Number(activeTabIndex) : false}
            key={index}
          >
            {tabComponent}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};
export default BladeTabs;
