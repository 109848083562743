import { sendToBackend } from "store/api/util";
import { Attachment } from "store/models/Attachment";
import { errorHandler } from "./shared";

const attachmentsApi = {
  getAllByContract: (
    documentId: string,
    documentType: string,
    actions?: {
      onData?: (data: Attachment[]) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Attachment[]>({
      route: `attachment/byContractId?documentId=${documentId}&documentType=${documentType}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  getOne: (
    documentId: string,
    documentType: string,
    attachmentId: string,
    actions?: {
      onData?: (data: any) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<Attachment[]>({
      route: `attachment?attachmentId=${attachmentId}&documentId=${documentId}&documentType=${documentType}`,
      method: "GET",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  createOne: (
    documentId: string,
    documentType: string,
    form: FormData,
    actions?: {
      onData?: (data: undefined) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<undefined>({
      route: `attachment/?documentId=${documentId}&documentType=${documentType}`,
      method: "POST",
      file: form,
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
  deleteOne: (
    documentId: string,
    documentType: string,
    attachmentId: string,
    actions?: {
      onData?: (data: undefined) => void;
      onError?: (message: string) => void;
      onComplete?: () => void;
      currentlySubmitting?: (isSubmitting: boolean) => void;
    }
  ) => {
    sendToBackend<undefined>({
      route: `attachment/?documentId=${documentId}&attachmentId=${attachmentId}&documentType=${documentType}`,
      method: "DELETE",
      onData: actions?.onData,
      onError: (message: string) => { errorHandler(message, actions?.onError); },
      onSuccess: actions?.onComplete,
      currentlySubmitting: actions?.currentlySubmitting,
    });
  },
};

export default attachmentsApi;
