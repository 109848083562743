import React, { ReactNode } from 'react';
import { Alert, AlertProps, Button, ClickAwayListenerProps, Snackbar } from '@mui/material';

export interface ToastProps {
  messageContent: ReactNode;
  action?: {
    actionName: string;
    actionFunction: React.MouseEventHandler<HTMLButtonElement>;
  };
  severity?: AlertProps["severity"];
  show?: boolean;
  disableClickAwayListener?: boolean
  duration?: number;
  onClose?: () => void;
  xPosition?: "right" | "left" | "center";
  disableAutoHide?: boolean
};

const Toast: React.FC<ToastProps> = ({
  messageContent,
  action,
  severity,
  show,
  disableClickAwayListener,
  duration = 2000,
  onClose,
  disableAutoHide = false,
  xPosition = "center"
}) => {

  const clickAwayListenerProps:
    | Partial<ClickAwayListenerProps>
    | undefined = {
      onClickAway: onClose,
      mouseEvent: "onMouseDown",
      touchEvent: "onTouchStart",
    };
  if (disableClickAwayListener) clickAwayListenerProps.onClickAway = () => {};


  const actionButton = () => {
    if (action) {
      return (
        <Button
          color="inherit"
          onClick={action!.actionFunction}
        >
          {action!.actionName.toLocaleUpperCase()}
        </Button>
      );
    }
  };

  return messageContent ? (
    <Snackbar
      open={show}
      autoHideDuration={disableAutoHide ? undefined : duration}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: xPosition }}
      message={messageContent}
      ClickAwayListenerProps={clickAwayListenerProps}
    >
      <Alert
        onClose={onClose}
        action={actionButton()}
        severity={severity}
      >
        {messageContent}
      </Alert>
    </Snackbar>
  ) : null;
};

export default Toast;
