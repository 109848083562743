import { AddAssetModalMode } from "components/modals/AddAssetModal";
import { FormikHelpers } from "formik";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import _, { add } from "lodash";
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Row } from "react-table";
import api from "store/api";
import { moveableAssetStatuses } from "store/api/asset";
import { contractAssetMoveableStatuses, initAccessory } from "store/api/contract";
import IAccessory from "store/models/Accessory";
import { Asset } from "store/models/Asset";
import { AssetDeliverable } from "store/models/AssetDeliverable";
import { activeContractStatuses, Contract } from "store/models/Contract";
import Movement, {
  AssetMovement,
  determineDefaultDateOfMove,
  initMovement,
  MovementStatus,
} from "store/models/Movement";
import UserData from "store/models/UserData";
import {
  selectAssets,
  setManyAssets,
  setOneAsset,
} from "store/slices/assetSlice";
import { selectDefaultUserCurrency } from "store/slices/configSlice";
import { selectCurrentContract, setContract } from "store/slices/contractSlice";
import {
  selectLoggedInUser,
  setGlobalMessage,
  simpleGlobalMessage,
} from "store/slices/systemSlice";
import { useAppDispatch } from "store/store";
import { contractInEndedStatus, dateFromMMDDYYYY, determineDefaultMovementStatus } from "utils/util";


const useContractBladeUtils = () => {
  const currentContract = useSelector(selectCurrentContract);
  const dispatch = useAppDispatch();
  const loggedInUser = useSelector(selectLoggedInUser);
  const defaultCurrency = useSelector(selectDefaultUserCurrency(loggedInUser));
  const assets = useSelector(selectAssets);
  const checkContractPermissions = usePermissions(FeatureType.CONTRACT)
  const userCanEditContracts = checkContractPermissions(ActionType.UPDATE)
  const checkMovementPermissions = usePermissions(FeatureType.MOVEMENT)
  const userCanCreateMovements = checkMovementPermissions(ActionType.CREATE)
  const [addAssetModalMode, setAddAssetModalMode] = useState<AddAssetModalMode>("hidden");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, changeIsSubmitting] = useState(false);

  const handleContractAssetSubmit = useCallback(
      (
        toggleAllRowsSelected: (set?: boolean | undefined) => void,
        selectedRows: Asset[],
        assetDeliverable?: AssetDeliverable | undefined
      ) => {
        if (!currentContract) return;
        const selectedAssetIds = selectedRows.map((row) => row._id);
        const assetsNotReady = selectedAssetIds.filter((selectedAssetIds) => {
          const asset = assets[selectedAssetIds];
          return asset.inServiceDate && dateFromMMDDYYYY(asset.inServiceDate) > dateFromMMDDYYYY(currentContract?.startDate);
        })
        .map((assetId) => assets[assetId].serialNumber);
  
        if (assetsNotReady.length) {
          dispatch(
            setGlobalMessage({
              messageText: `The following Assets are In Service after Contract Start: \n${JSON.stringify(assetsNotReady)}`,
              show: true,
              severity: "warning",
            })
          );
          return;
        }

        if (selectedAssetIds.length > 50) {
          dispatch(
            setGlobalMessage({
              messageText: `You cannot add more than 50 assets at a time to a contract`,
              show: true,
              severity: "warning",
            })
          );
          return;
        }


        switch (addAssetModalMode) {
          case "add": 
          changeIsSubmitting(true);
          if (contractHasAssetAlready()) {
            dispatch(
              simpleGlobalMessage(
                `The following Assets are Already on Contract:${getAssetsAlreadyOnContract()}`
              )
            );
            changeIsSubmitting(false);
            return;
          } else if (notAllAssetsAvailable()) {
            dispatch(
              simpleGlobalMessage(
                `The following Assets are Unavailable:${getUnavailableAssets()}`
              )
            );
            changeIsSubmitting(false);
            return;
          }
          api.contracts.addAsset(currentContract?._id, selectedAssetIds, {
            onData: ({ contract, addedAssets }) => {
              dispatch(setContract(contract));
              dispatch(setManyAssets(addedAssets));
              setAddAssetModalMode("hidden");
            },
            onComplete: () => {
              dispatch(
                simpleGlobalMessage(
                  `Successfully Added Asset${
                    selectedRows.length > 1 ? "s" : ""
                  }`,
                  "success"
                )
              );
              toggleAllRowsSelected(false);
              changeIsSubmitting(false);
            },
            onError: () => {
              changeIsSubmitting(false);
            }
          });
          break;
          case "swap": 
          if (!currentContract?._id) return;

          api.contracts.swapAsset(
            currentContract?._id,
            assetDeliverable!.asset,
            selectedRows[0]._id,
            {
              onData: (data) => {
                dispatch(setContract(data.contract));
                dispatch(setManyAssets([data.newAsset, data.oldAsset]));
                setAddAssetModalMode("hidden");
              },
              onComplete: () => {
                dispatch(
                  simpleGlobalMessage("Successfully swapped asset", "success")
                );
                toggleAllRowsSelected(false);
                changeIsSubmitting(false);
              },
              onError: () => {
                changeIsSubmitting(false);
              }
            }
          );
        }
     


       

        function contractHasAssetAlready() {
          return currentContract?.assetDeliverables.some(({ asset }) => {
            return selectedAssetIds.includes(asset);
          });
        }

        function getAssetsAlreadyOnContract() {
          return JSON.stringify(
            _.uniq(
              selectedRows.reduce((unavailableAssets, { _id, serialNumber }) => {
                return selectedAssetIds.includes(_id)
                  ? [...unavailableAssets, serialNumber]
                  : unavailableAssets;
              }, [] as string[])
            )
          );
        }

        function notAllAssetsAvailable() {
          return selectedRows.some(
            ({ status }) => status !== "AVAILABLE"
          );
        }
        function getUnavailableAssets() {
          return JSON.stringify(
            _.uniq(
              selectedRows.reduce((unavailableAssets, { status, serialNumber }) => {
                if (status !== "AVAILABLE") {
                  unavailableAssets.push(serialNumber)
                }
                return unavailableAssets;
              }, [] as string[])
            )
          );
        }
      },
    [currentContract, assets, addAssetModalMode]
  );

  const removeAssetFromContract = useCallback(
      (
        asset: Asset,
        setSubmitting?: FormikHelpers<AssetDeliverable>["setSubmitting"],
        setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>
        
      ) => {
        if (!currentContract) return;
        const assetHasAccessories = currentContract?.accessories.some(
          (accessory) => accessory.asset === asset._id
        );
        if (assetHasAccessories) {
          dispatch(
            simpleGlobalMessage("Asset has Accessories associated with it")
          );
          return;
        }
        if (isLastAssetInContract()) {
          dispatch(
            simpleGlobalMessage(
              "There must always be at least one asset in a contract"
            )
          );
          return;
        }

        api.contracts.removeAsset(currentContract?._id, asset._id, {
          onData: ({ removedAsset, contract }) => {
            dispatch(setOneAsset(removedAsset));
            dispatch(setContract(contract));
          },
          onComplete: () => {
            setSubmitting && setSubmitting(false);
            setSelectedIndex && setSelectedIndex(undefined);
          },
          onError: () => {
            setSubmitting && setSubmitting(false);
          }
        });

        function isLastAssetInContract() {
          return currentContract && currentContract?.assetDeliverables?.length <= 1;
        }
      },
    [currentContract]
  );

  const updateAssetDeliverables = useCallback(
          (
        deliverables: AssetDeliverable[],
        setSubmitting?: FormikHelpers<AssetDeliverable>["setSubmitting"],
        setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>
      ) => {
        if (!currentContract) return;

        api.contracts.updateAssetDeliverables(currentContract._id, deliverables, {
          onData: (data) => {
            dispatch(setContract(data));
          },
          onComplete: () => {
            setSubmitting && setSubmitting(false);
            setSelectedIndex && setSelectedIndex(undefined);
          },
           onError: () => {
            setSubmitting && setSubmitting(false);
          }
        });
      },
    [currentContract]
  );

  const addAccessoryToContract = useCallback(
    (accessoryName: string) => {
      const newAccessory = initAccessory({
        name: accessoryName,
        rate: {
          amount: 0,
          currency: defaultCurrency,
        },
      });
      if (!currentContract) return;
      changeIsSubmitting(true);
      api.contracts.addAccessory(currentContract._id, newAccessory, {
          onData: ({ contract }) => {
            dispatch(setContract(contract));
          },
          onComplete: () => changeIsSubmitting(false),
          onError: () => {
            changeIsSubmitting(false);
          }
        }
      );
    },
    [currentContract]
  );

  const handleScheduleAllMovements = useCallback(
    (setNewMovements: Dispatch<SetStateAction<Movement[]>>) => {
      if (!currentContract) return;
      if (hasPendingMovements()) {
        dispatch(
          simpleGlobalMessage(
            `The following assets have a pending Movement:${getAssetsWithPendingMovements()}`
          )
        );
        return;
      }
      if (!contractAssetMoveableStatuses.includes(currentContract.status)) {
        dispatch(simpleGlobalMessage("Contracts must be PENDING DELIVERY or ACTIVE to schedule Movements"));
        return;
      }
      if (!Boolean(userCanEditContract && userCanCreateMovements)) {
        dispatch(simpleGlobalMessage("You do not have permission to Create Movements"));
        return;
      }

      const defaultMovementStatus = determineDefaultMovementStatus(currentContract);

      const assetsToScheduleMovementsFor: AssetMovement[] =
        currentContract?.assetDeliverables
          .filter(
            (deliverable) =>
              deliverable.isActiveOnContract &&
              moveableAssetStatuses.includes(assets[deliverable.asset].status)
          )
          .map(
            (deliverable) =>
              initMovement({
                asset: deliverable.asset,
                type: defaultMovementStatus,
                status: MovementStatus.scheduled,
                contract: currentContract?._id,
                createdBy: (loggedInUser as UserData)._id,
                dateCompleted: null,
                dateOfMove: determineDefaultDateOfMove(defaultMovementStatus, deliverable, currentContract)
              }) as AssetMovement
          );

      assetsToScheduleMovementsFor.length
        ? setNewMovements(assetsToScheduleMovementsFor)
        : dispatch(simpleGlobalMessage("No movements to schedule"));

        function hasPendingMovements() {
          const activeAssets = currentContract?.assetDeliverables
            .filter(onlyActiveAssets)
            .map(({ asset }) => asset);
          return currentContract?.movements
            .filter(onlyMovementsOfActiveAssets)
            .some((movement) =>
              [MovementStatus.scheduled, MovementStatus.dispatched].includes(
                movement.status
              )
            );
            function onlyActiveAssets(deliverable: AssetDeliverable<string>) {
              return deliverable.isActiveOnContract;
            }
        
            function onlyMovementsOfActiveAssets(movement: Movement) {
              return movement.asset && activeAssets?.includes(movement.asset);
            }
        }


      function getAssetsWithPendingMovements() {
        return JSON.stringify(
          _.uniq(
            currentContract?.movements
              .filter(hasPendingMovements)
              .filter((movement) => movement.asset)
              .reduce((acc, movement) => {
                const currentAsset = movement.asset
                  ? assets[movement.asset]
                  : undefined;
                if(currentAsset) {
                  acc.push(`${currentAsset.serialNumber} - ${currentAsset.sizeCode}`)
                }
                return acc;
              }, [] as string[])
          )
        );
      }
    },
    [currentContract, assets]
  );

  const saveContract = useCallback((contract: Contract, { setSubmitting, resetForm}: FormikHelpers<Contract>) => {
    api.contracts.updateContract(contract, {
      onData: ({ contract, assets }) => {
        dispatch(setContract(contract));
        dispatch(setManyAssets(assets));
        dispatch(
          simpleGlobalMessage("Successfully saved contract", "success")
        );
        setIsEditing(false);
        resetForm();
      },
      onError: () => setSubmitting(false),
    });
  },[])

  const canEnterEditMode = useCallback(() =>  {
     if (!currentContract) return {allowed: false, message: "No contract"}
    switch (true) {
      case contractInEndedStatus(currentContract?.status):
        return {
          allowed: false,
          message: `You cannot edit contracts that are ${currentContract?.status}`,
        };
      case userCannotEditAwaitingContract():
        return {
          allowed: false,
          message: `You cannot edit ${currentContract?.status} contracts that are not your own unless you are an Administrator or a Sales Coordinator`,
        };
      case userCannotEditActiveContract():
        return {
          allowed: false,
          message: `You cannot edit ${currentContract?.status} contracts unless you are an Administrator or a Sales Coordinator`,
        };
      default:
        return { allowed: true, message: "" };
    }

    function userCannotEditAwaitingContract() {
      return (
        ["AWAITING APPROVAL", "AWAITING CONFIRMATION"].includes(
          currentContract?.status || ""
        ) &&
        !userCanEditContract
      );
    }
    function userCannotEditActiveContract() {
      return (
        currentContract && activeContractStatuses.includes(currentContract?.status || "") &&
        !userCanEditContracts
      );
    }
  },[currentContract])

  const revertContract = useCallback(() => {
    if (!userCanEditContract || statusNotAllowedToBeReverted()) {
      dispatch(
        simpleGlobalMessage(
          statusNotAllowedToBeReverted()
            ? `You cannot revert ${currentContract?.status} contracts`
            : "You cannot revert contracts unless you are an Administrator"
        )
      );
      return;
    }

    currentContract &&
      api.contracts.revertContractStatus(currentContract?._id, currentContract.status, {
        onData: ({ contract, assets }) => {
          dispatch(setContract(contract));
          dispatch(setManyAssets(assets));
        },
        onComplete: (message) => {
          dispatch(
            setGlobalMessage({
              messageText: message,
              severity: "success",
              show: true,
            })
          )
          changeIsSubmitting(false)
        },
        onError: () => {
          changeIsSubmitting(false)
        }
          
      });
  },[currentContract])

  const userCanEditContract = useMemo(() => {
    const userCanEditContracts = checkContractPermissions(ActionType.UPDATE);
    const userCanEditOwnContract = loggedInUser?._id === currentContract?.createdBy && checkContractPermissions(ActionType.LIMITED_UPDATE);
    if (currentContract?.status === "ACTIVE") {
      return userCanEditContracts;
    } else {
      return (userCanEditOwnContract || userCanEditContracts);
    }
  },[currentContract])


  function statusNotAllowedToBeReverted() {
    return currentContract ? ["CANCELLED", "DENIED"].includes(currentContract?.status) : true
  }


  const handleEditAccessory = useCallback((
    values: IAccessory,
    formikHelpers: FormikHelpers<IAccessory>,
    setSelectedIndex: Dispatch<SetStateAction<number | undefined>>
  ) => {
    if (!currentContract) return;
    formikHelpers.setSubmitting(true);

    const accessories = currentContract.accessories.map((accessory) =>
      accessory._id === values._id ? values : accessory
    );

    api.contracts.updateAccessories(
      currentContract._id,
      accessories,
      {
        onData: (contract) => {
          dispatch(setContract(contract));
        },
        onComplete: () => {
          formikHelpers.setSubmitting(false);
          setSelectedIndex(undefined)
        },
        onError: () => {
          formikHelpers.setSubmitting(false);
        }
      }
    );
  },[currentContract])

  const handleRemoveAccessory = useCallback((
    accessory: IAccessory,
    {
      setSubmitting,
      setSelectedIndex,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
      setSelectedIndex: Dispatch<SetStateAction<number | undefined>>;
    }
  ) => {
    if (!currentContract) return;
    setSubmitting(true);
    api.contracts.updateContract(
      {
        ...currentContract,
        accessories: currentContract.accessories.filter(
          ({ _id }) => _id !== accessory._id
        ),
      },
      {
        onData: ({ contract }) => {
          dispatch(setContract(contract));
        },
        onComplete: () => {
          setSubmitting(false);
          setSelectedIndex(undefined);
        },
        onError: () => setSubmitting(false)
      }
    );
  },[currentContract])



  return {
    handleContractAssetSubmit,
    removeAssetFromContract,
    updateAssetDeliverables,
    addAccessoryToContract,
    handleScheduleAllMovements,
    saveContract,
    userCanEditContract,
    revertContract,
    canEnterEditMode,
    handleRemoveAccessory,
    handleEditAccessory,
    addAssetModalMode, 
    setAddAssetModalMode,
    isEditing,
    setIsEditing,
    isSubmitting,
    changeIsSubmitting,
  };
};

export default useContractBladeUtils;
