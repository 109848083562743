import React from "react";
import { FastField, FastFieldProps } from "formik";
import NumberTextField from "components/NumberTextField";

const FastNumberInput: React.FC<{ label?: string, name: string; leaveSpaceForHelperText?: boolean, allowHelperText?: boolean}> = ({ label = "", name, leaveSpaceForHelperText, allowHelperText, ...props }) => {
  return (
    <FastField {...props} name={name}>
      {({ field, meta }: FastFieldProps) => (
        <NumberTextField
          {...field}
          label={label}
          error={meta.touched && !!meta.error}
          helperText={allowHelperText ? meta.error || leaveSpaceForHelperText ? " " : "" : ""}
          variant="outlined"
          size="small"
          fullWidth
        />
      )}
    </FastField>
  );
  
};

export default FastNumberInput
