import { Box, Card, Typography } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
}

const ViewContainer: React.FC<Props> = ({ children, title, subTitle }) => {
  return (
    <Card
      sx={(theme) => ({
        p: 2.5,
        mb: 2,
        fontSize: theme.typography.subtitle2,
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
      })}
    >
      <Box>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2">{subTitle}</Typography>
      </Box>
      {children}
    </Card>
  );
};

export default ViewContainer;
