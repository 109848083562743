import { TextFieldProps, TextField } from "@mui/material";
import { FC, useEffect, useRef } from "react";

const leadingZeros = /^0+/;

const NumberTextField: FC<TextFieldProps> = ({ value, onChange, ...rest }) => {
  const numberFieldRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const ignoreScroll = (e: WheelEvent) => e.preventDefault();

    if (numberFieldRef.current) {
      numberFieldRef.current.addEventListener("wheel", ignoreScroll, {
        passive: false,
      });
    }
    return () =>
      numberFieldRef.current?.removeEventListener("wheel", ignoreScroll);
  }, []);

  function removeLeadingZero(value: string) {
    return value.includes(".") ? value : value.replace(leadingZeros, "");
  }
 
  function handleOnFocus(
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) {
    const value = event.target.value;
    if (value === "0" && onChange) {
      event.target.value = "";
      onChange(event);
    }
  }

  function handleOnChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const value = event.currentTarget.value;
    event.currentTarget.value =
      value.toString().length === 1 ? value : removeLeadingZero(value);
    onChange && onChange(event);
  }

  return (
    <TextField
      ref={numberFieldRef}
      {...rest}
      onFocus={handleOnFocus}
      value={typeof value === "number" && !isNaN(value) ? value : ""}
      onChange={handleOnChange}
      type="number"
      inputProps={{
        autocomplete: "off",
      }}
    />
  );
};

export default NumberTextField;
