import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserData from "store/models/UserData";
import { AppDispatch, STARFLEET_STORE } from "store/store";
import api from "store/api";
import { simpleGlobalMessage } from "store/slices/systemSlice";
import { byIds } from "../sliceUtils";

export interface USERS_STORE {
  users: { [_id: string]: UserData };
  currentUserId?: string;
  usersLoading: boolean;
}

const initialState: USERS_STORE = {
  users: {},
  currentUserId: undefined,
  usersLoading: true,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<UserData[]>) {
      state.users = byIds(action.payload);
    },
    setUsersLoading(state, action: PayloadAction<boolean>) {
      state.usersLoading = action.payload;
    },
    setOneUser(state, action: PayloadAction<UserData>) {
      state.users[`${action.payload._id}`] = action.payload;
    },
    setCurrentUserId(state, action: PayloadAction<string | undefined>) {
      state.currentUserId = action.payload;
    },
  },
});

export const fetchAllUserData = () => async (
  dispatch: AppDispatch
) => {
  api.users.getAll({
    onData: (data) => {
      dispatch(setUsers(data));
    },
    onError: (message: string) => dispatch(simpleGlobalMessage(message)),
    onComplete: () => dispatch(setUsersLoading(false)),
  });
};

export const selectUsers = (state: STARFLEET_STORE) => state.users.users
export const selectCurrentUser = (state: STARFLEET_STORE) => state.users.currentUserId ? state.users.users[state.users.currentUserId] : undefined;

export const { setUsers, setUsersLoading, setOneUser, setCurrentUserId } = userSlice.actions;

export default userSlice.reducer;
