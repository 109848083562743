import React, { Fragment, useState, KeyboardEvent } from "react";
import {
  Card,
  Chip,
  Container,
  Typography,
  Stack,
  CardContent,
  TextField,
  InputAdornment,
  Button,
  CardHeader,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Config,
  selectSizeCodes,
  selectSizeCodesState,
} from "store/slices/configSlice";
import PageHeader from "components/header/PageHeader";
import useSettings from "hooks/useSettings";
import Add from "@mui/icons-material/Add";
import api from "store/api";
import { simpleGlobalMessage } from "store/slices/systemSlice";

function Settings() {
  const { themeStretch } = useSettings();
  const sizeCodes = useSelector(selectSizeCodes);
  const sizeCodeByCategory = Object.values(sizeCodes).reduce(
    _sizeCodeByCategory,
    {}
  );

  return (
    <Container maxWidth={themeStretch ? false : "lg"}>
      <PageHeader
        heading="Settings"
        links={[{ name: "System" }, { name: "Config" }]}
      />
      <Card>
        <CardHeader title="Size Codes" />
        <CardContent>
          {Object.keys(sizeCodeByCategory)
            .sort(sortByWidthWithMiscOnBottom)
            .map((sizeCodeCategory) => {
              const sizeCodeCategoryText =
                sizeCodeCategory === "Misc"
                  ? "Misc"
                  : `${sizeCodeCategory} X ...`;
              return (
                <Fragment key={sizeCodeCategoryText}>
                  <Typography variant="h6">{sizeCodeCategoryText}</Typography>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    rowGap={1}
                    columnGap={1}
                    ml={-0.3}
                    mt={1}
                    mb={3}
                  >
                    {sizeCodeByCategory[sizeCodeCategory]
                      .sort(sortSizeCodeByLength)
                      .filter((sizeCode) => sizeCode)
                      .map((sizeCode) => (
                        <Chip
                          key={sizeCode}
                          size="medium"
                          label={sizeCode}
                          onDelete={() => {}}
                        />
                      ))}
                    <AddNewSizeCodeLength startAdornment={sizeCodeCategory} />
                  </Stack>
                </Fragment>
              );
            })}
          <AddNewSizeCodeWidth />
        </CardContent>
      </Card>
    </Container>
  );
}

function AddNewSizeCodeLength({ startAdornment }: { startAdornment: string }) {
  const [lengthText, changeLengthText] = useState("");
  const [touched, changeTouched] = useState(false);
  const [mode, changeMode] = useState<"edit" | "clickable">("clickable");
  const sizeCodeState = useSelector(selectSizeCodesState);
  const dispatch = useDispatch();
  const adornmentFormatted = formatAdornment();
  const newSizeCode = formatNewSizeCode();

  const inputType = startAdornment === "Misc" ? "text" : "number";

  function formatNewSizeCode() {
    return (
      startAdornment === "Misc"
        ? lengthText
        : `${adornmentFormatted} ${lengthText.trim()}`
    ).trim();
  }

  function formatAdornment() {
    return startAdornment === "Misc" ? "" : `${startAdornment} X`;
  }

  function createNewSizeCode() {
    changeTouched(true);
    if (!lengthText) {
      dispatch(simpleGlobalMessage("Invalid Size Code"));
      return;
    }

    changeLengthText("");
    changeMode("clickable");

    if (!sizeCodeAlreadyExists(newSizeCode, sizeCodeState)) {
      api.config.updateOne(
        {
          ...sizeCodeState,
          settings: [...sizeCodeState.settings, newSizeCode],
        },
        { onComplete: () => changeTouched(false) }
      );
    } else {
      dispatch(
        simpleGlobalMessage(
          `Size Code ${newSizeCode} already exists`,
          "warning"
        )
      );
      changeTouched(false);
    }
  }

  return mode === "clickable" ? (
    <Chip
      label="New"
      onDelete={() => changeMode("edit")}
      onClick={() => changeMode("edit")}
      deleteIcon={<Add />}
      variant="outlined"
    />
  ) : (
    <>
      <TextField
        value={lengthText}
        sx={{ height: "100%" }}
        type={inputType}
        error={touched && !lengthText}
        onBlur={() => changeTouched(true)}
        InputProps={{
          startAdornment:
            startAdornment === "Misc" ? (
              ""
            ) : (
              <InputAdornment
                sx={{ marginBottom: -0.2, marginRight: 0.7 }}
                position="start"
              >
                {adornmentFormatted}
              </InputAdornment>
            ),
          style: { height: "32px", width: "128px" },
        }}
        onChange={(e) => changeLengthText(e.currentTarget.value)}
        onKeyDown={preventInvalidNumberInput(inputType, createNewSizeCode)}
      />
      <Button variant="contained" size="small" onClick={createNewSizeCode}>
        Add
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={() => changeMode("clickable")}
      >
        Cancel
      </Button>
    </>
  );
}

function AddNewSizeCodeWidth() {
  const [lengthText, changeLengthText] = useState("");
  const [widthText, changeWidthText] = useState("");
  const [touched, changeTouched] = useState({
    lengthText: false,
    widthText: false,
  });
  const [mode, changeMode] = useState<"edit" | "clickable">("clickable");
  const dispatch = useDispatch();
  const sizeCodesState = useSelector(selectSizeCodesState);
  const newSizeCode = formatNewSizeCode();

  const inputType = "number";

  function formatNewSizeCode() {
    return `${widthText.trim()} X ${lengthText.trim()}`.trim();
  }

  function createNewSizeCode() {
    setAllTouched();

    if (!lengthText || !widthText) {
      dispatch(simpleGlobalMessage("Invalid Size Code"));
      return;
    }

    changeLengthText("");
    changeWidthText("");
    changeMode("clickable");

    if (!sizeCodeAlreadyExists(newSizeCode, sizeCodesState)) {
      api.config.updateOne(
        {
          ...sizeCodesState,
          settings: [...sizeCodesState.settings, newSizeCode],
        },
        {
          onComplete: () => {
            dispatch(
              simpleGlobalMessage("Successfully added new Size Code", "success")
            );
            resetTouched();
          },
        }
      );
    } else {
      dispatch(
        simpleGlobalMessage(
          `Size Code ${newSizeCode} already exists`,
          "warning"
        )
      );
    }
  }

  function setAllTouched() {
    changeTouched({ widthText: true, lengthText: true });
  }

  function resetTouched() {
    changeTouched({ widthText: false, lengthText: false });
  }

  return mode === "clickable" ? (
    <Button
      sx={{ marginLeft: -0.3 }}
      variant="contained"
      onClick={() => changeMode("edit")}
    >
      New Width
    </Button>
  ) : (
    <Stack
      direction="row"
      flexWrap="wrap"
      rowGap={1}
      columnGap={1}
      ml={-0.3}
      mt={1}
      mb={3}
      alignItems="center"
    >
      <TextField
        sx={{ width: 128 }}
        type={inputType}
        error={touched.widthText && !widthText}
        onBlur={() => changeTouched((prev) => ({ ...prev, widthText: true }))}
        value={widthText}
        onChange={(e) => changeWidthText(e.currentTarget.value)}
        label="Width"
        size="small"
        onKeyDown={preventInvalidNumberInput(inputType, createNewSizeCode)}
      />
      <Typography>X</Typography>
      <TextField
        sx={{ width: 128 }}
        type={inputType}
        error={touched.lengthText && !lengthText}
        onBlur={() => changeTouched((prev) => ({ ...prev, lengthText: true }))}
        InputProps={{ inputProps: { pattern: "[0-9.]*" } }}
        value={lengthText}
        onChange={(e) => changeLengthText(e.currentTarget.value)}
        label="Length"
        size="small"
        onKeyDown={preventInvalidNumberInput(inputType, createNewSizeCode)}
      />
      <Button variant="contained" onClick={createNewSizeCode}>
        Add
      </Button>
      <Button variant="outlined" onClick={() => changeMode("clickable")}>
        Cancel
      </Button>
    </Stack>
  );
}

function sortByWidthWithMiscOnBottom(a: string, b: string) {
  if (isNaN(Number(a))) {
    return 1;
  } else if (isNaN(Number(b))) {
    return -1;
  }

  return Number(a) - Number(b);
}

function sortSizeCodeByLength(sizeCodeA: string, sizeCodeB: string) {
  const [lengthA, lengthB] = [sizeCodeA, sizeCodeB].map((sizeCode) =>
    sizeCode.split(" X ")[1]?.replace(/\D/g, "")
  );
  return Number(lengthA) - Number(lengthB);
}

function _sizeCodeByCategory(
  sizeCodeMap: Record<string, string[]>,
  sizeCode: string
) {
  const category = sizeCode.includes(" X ") ? sizeCode.split(" X ")[0] : "Misc";

  sizeCodeMap[category]
    ? sizeCodeMap[category].push(sizeCode)
    : (sizeCodeMap[category] = [sizeCode]);

  return sizeCodeMap;
}

function sizeCodeAlreadyExists(
  sizeCode: string,
  sizeCodeState: Config<string[]>
) {
  return sizeCodeState.settings.includes(sizeCode);
}

function preventInvalidNumberInput(
  type: "number" | "text",
  submit: () => void
) {
  return function (event: KeyboardEvent<HTMLDivElement>) {
    preventNonNumberValues();
    submitOnEnter();

    function submitOnEnter() {
      if (event.key === "Enter") {
        submit();
      }
    }

    function preventNonNumberValues() {
      type === "number" &&
        ["e", "-", "+"].includes(event.key) &&
        event.preventDefault();
    }
  };
}

export default Settings;
